import { useState } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as billerActions from "components/Settings/_actions/billerActions";
import { Link, PaddedContainer, Divider, Row, Switch, LoadingIndicator } from "packages/ui";
import { PlatformRoutesConfiguration } from "components/Routing";
import currencyUtil from "packages/utils/currency";
import { APICallRequestState, useApiCall } from "components/Common";
import { billerApi } from "api";
import { RootState } from "store/store";
import { Biller } from "packages/webapi-client";

import "./AdditionalPaymentsSection.scss";

type Props = {
    billerCode: string;
    biller: Biller;
    billerActions?: any;
}

const AdditionalPaymentsSection = ({ billerCode, biller, billerActions }: Props) => {
    const [additionalPayments, additionalPaymentsStatus] = useApiCall(() => biller.isTopUpsEnabled ? billerApi.getAdditionalPayments(billerCode) : undefined, [billerCode]);
    const [additionalPaymentsEnabled, setAdditionalPaymentsEnabled] = useState(biller.isTopUpsEnabled ?? false);

    const toggleAdditionalPayments = async (value: any) => {
        // Set switch
        setAdditionalPaymentsEnabled(value);

        // Toggle in back-end
        await billerApi.updateIsTopUpsEnabled(billerCode, value);

        // Reload billers
        await billerActions.loadBillers();
    };

    return <div>
        <PaddedContainer
            title="Additional Payments"
            className="additional-payments-section"
            button={
                <div className="additional-payments-actions">
                    <Switch onChange={toggleAdditionalPayments} on={additionalPaymentsEnabled} />
                    {biller.isTopUpsEnabled && <Link to={PlatformRoutesConfiguration.settingsRoute?.additionalPayments.generatePath(billerCode)} button>Edit</Link>}
                </div>}
            lessMargin>
            {
                additionalPaymentsStatus === APICallRequestState.LOADING ? <LoadingIndicator /> :
                    biller.isTopUpsEnabled ? ((additionalPayments?.length ?? 0) > 0 ?
                        additionalPayments?.map((additionalPayment) => (<>
                            {(additionalPayment.listOrder ?? 1) > 1 &&
                                <Divider />
                            }
                            <dt className="additional-payment-title">Additional payment {additionalPayment.listOrder}</dt>
                            <Row>
                                <div className="col-sm-6">
                                    <dt>Description</dt>
                                    <dd>{additionalPayment.description}</dd>
                                </div>
                                <div className="col-sm-6">
                                    <dt>Reportable description</dt>
                                    <dd>{additionalPayment.reportableDescription}</dd>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-sm-3">
                                    <dt>Amount</dt>
                                    <dd>{additionalPayment.amountSetting}</dd>
                                </div>
                                <div className="col-sm-3">
                                    <dt>Pre-populated Amount</dt>
                                    <dd>{additionalPayment.prePopulatedAmount ? `${currencyUtil.convertToDisplayString(additionalPayment.prePopulatedAmount)}` : "N/A"}</dd>
                                </div>
                                <div className="col-sm-3">
                                    <dt>Default opt-in</dt>
                                    <dd><Switch disabled={true} on={additionalPayment.optedInByDefault}></Switch></dd>
                                </div>
                                <div className="col-sm-3">
                                    <dt>Opt-out allowed</dt>
                                    <dd><Switch disabled={true} on={additionalPayment.optOutAllowed}></Switch></dd>
                                </div>
                            </Row>
                        </>)) :
                        <Row className="additional-payments-empty">
                            No additional payments exist.
                        </Row>) :
                        <></>
            }
        </PaddedContainer>
    </div>;
};

function mapStateToProps(state: RootState, ownProps: any) {
    const biller = state.accounts.users.billers && state.accounts.users.billers.find((biller: Biller) => biller.billerCode === ownProps.billerCode);

    return {
        biller
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        billerActions: bindActionCreators(billerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalPaymentsSection);
