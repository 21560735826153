import { connect } from "react-redux";
import { RootState } from "store/store";
import { getMerchantOptions } from "@premier/utils/billpay";
import { DropdownField } from "@premier/form";
// @ts-ignore
import labels from "constants/labels";
import { userRoles } from "components/Routing";
import { Merchant } from "packages/utils/models";

import "./MerchantPageCountDropdowns.scss";

type Props = {
    merchant: Merchant;
    name?: string;
    label?: string;
    classNames?: string;
    feature?: userRoles;
    allOption?: boolean;
    useDefaultValue?: boolean;
    onChange: (newValue: string, context: any) => void;
}

/**
 * Merchant dropdown component, rendering a dropdowns of parent merchant & it's child merchants.
 * Allows for custom class names, labels, an allOption to render the 'all' option.
 */
const MerchantDropdown = ({
    merchant, //state values
    name, label, classNames, feature, //data
    allOption, //logic render
    useDefaultValue,
    onChange, //function
    ...otherProps
}: Props) => {

    function handleChange(newValue: string, context: any) {
        onChange && onChange(newValue, context);
    }

    return (<>
        {(merchant?.childMerchants?.length ?? 0) > 0 && (
            <DropdownField
                className={classNames}
                name={name || "merchantNumber"}
                label={label || labels.merchant}
                options={getMerchantOptions(merchant, allOption || false, feature)}
                defaultValue={useDefaultValue ? merchant.merchantNumber : null }
                onChange={handleChange}
                {...otherProps}
            />
        )}
    </>);
};

function mapStateToProps(state: RootState) {
    return {
        merchant: state.accounts.users.merchant
    };
}

export default connect(mapStateToProps)(MerchantDropdown);
