import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import requiredIf from "@premier/utils/requiredIf";
import deprecated from "@premier/utils/deprecatedPropType";
import _ from "lodash";
import FormContext from "../FormContext";

const withField = (WrappedComponent) => {
    const FieldComponent = (props) => {
        const {
            name,
            label,
            labelText,
            mandatory,
            noLabels,
            help,
            helpWrapXs,
            className,
            validateOnChange, // groupProps
            onChange,
            onBlur, // fieldProps to be overwritten
            ...fieldProps
        } = props;

        const context = useContext(FormContext);

        const stringLabel = labelText || label;
        useEffect(() => {
            context.registerField(name, { label: stringLabel });
            return () => {
                context.unRegisterField(name);
            };
        }, [stringLabel]);

        function getValue() {
            return context.getValue(name);
        }

        /** Sets the form value and fire onChange (if it exists) */
        function setValue(newValue) {
            if (_.isEqual(newValue, getValue()))
                return; // Do nothing if no changes to prevent infinte loop in some cases

            context.setValue(name, newValue);

            if (validateOnChange) {
                try {
                    context.validateField(name, newValue);
                } catch {
                    // validateField throws an error if validation fails. The error is captured by the FormContext
                    // and can be ignored at this point.
                }
            }

            if (onChange)
                onChange(newValue, context); // Note that context has not been updated yet at this point
        }

        function handleBlur(e) {
            context.blur(name);

            if (onBlur)
                onBlur(e, context);
        }

        function handleSetValidation(validation) {
            context.setValidation(name, validation);
        }

        function handleGetValidation(validation) {
            return context.getValidation(name, validation);
        }

        return (
            <WrappedComponent
                groupProps={{ label, mandatory, name, help, helpWrapXs, className, noLabels }}
                formProps={{
                    value: getValue(),
                    error: context.getError(name),
                    setValue,
                    setValidation: handleSetValidation,
                    getValidation: handleGetValidation,
                }}
                {...fieldProps}
                name={name}
                onBlur={handleBlur}
            ></WrappedComponent>
        );
    };

    FieldComponent.propTypes = {
        id: PropTypes.string,
        /** The field name, eg. 'firstName' */
        name: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /** Overrides the label text used by errors in the form.
         * Must be supplied if label is an object.
         * It should also be supplied if label is not supplied.
         */
        labelText: requiredIf(PropTypes.string, (props) => props.label && !_.isString(props.label)),
        /** Adds an asterisk next to the label. This will be automatically added when a field has a required validation. */
        mandatory: PropTypes.bool,
        /** Whether or not to use labels and error messages */
        noLabels: PropTypes.bool,
        /** The text to show on the right of the label */
        help: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
        /** The className for the FormGroup element */
        className: PropTypes.string,
        /** When true, the field will validate itself on change based on the
         * associated validator in the enclosing Form */
        validateOnChange: PropTypes.bool,
        mode: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
        plaintext: PropTypes.bool,
        readonly: PropTypes.bool,
        maxLength: PropTypes.number,
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
        placeholders: PropTypes.arrayOf(PropTypes.string),
        placeholder: PropTypes.string,
        inlineUpSm: PropTypes.bool,
        disabled: PropTypes.bool,
        inline: PropTypes.bool,
        selectedValue: PropTypes.any,
        digitsOnly: PropTypes.bool,
        required: PropTypes.bool,
        compact: PropTypes.bool,
        type: PropTypes.string,

        minDate: PropTypes.object,
        maxDate: PropTypes.object,

        onChange: PropTypes.func,
        onBlur: PropTypes.func,

        children: PropTypes.node,

        groupProps: PropTypes.object,

        tabIndex: PropTypes.number,

        // hCaptcha
        onVerify: PropTypes.func,

        // Deprecated
        value: deprecated(PropTypes.any, "Please set the form values instead of this directly"),
    };

    return FieldComponent;
};

export default withField;
