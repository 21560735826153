import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Modal, ModalProps } from '..';


type Props = ModalProps & {
    /** Do not print the outer Modal wrapper (for switching modal content dynamically) */
    noWrapper?: boolean,
    /** The icon above the title */
    icon?: ReactNode,
    title?: ReactNode,
    /** Adds a divider (horizontal line) on top of the footer, to separate the content from the footer */
    footerDivider?: boolean,
    /** The buttons to show on the footer */
    footerButtons?: ReactNode,
    /** Aditional, custom, className for the dialog */
    dialogClassName?: string,
};

// Note: Similar code in FormDialog.js (Make sure to check the other file if you're making changes)
/** A Modal with buttons. You can't close a Dialog by clicking outside it. */
const Dialog = ({
    show, closeButton, onClose, dismissable,  // Modal props
    noWrapper, icon, title, children, dialogClassName,
    footerDivider, footerButtons,
    ...otherProps
}: Props) => {

    const modalContent = (<>
        <Modal.Header closeButton={closeButton}>
            <Modal.Title>
                {icon}
                {title}
            </Modal.Title>
        </Modal.Header>

        {children && <Modal.Body>{children}</Modal.Body>}

        {footerButtons && (
            <Modal.Footer
                buttons
                divider={footerDivider}
            >
                {footerButtons}
            </Modal.Footer>
        )}
    </>);

    return noWrapper
        ? modalContent
        : (
            <Modal show={show} onHide={onClose} dialogClassName={classNames('dialog', dialogClassName)} dismissable={dismissable} {...otherProps}>
                {modalContent}
            </Modal>
        );
};

export default Dialog;
