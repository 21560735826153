import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, FormGroup } from '@premier/ui';
import { MerchantDropdown } from 'components/Common'

import './MerchantPageCountDropdowns.scss';

/**
 * Merchant dropdown & Page Count drop down for the manage pages
 */
const MerchantPageCountDropdowns = ({
    resultsPerPage, resultsPerPageOptions,
    onChildMerchantChanged, onResultsPerPageChanged // handles
}) => {
    return (
        <div className="row merchant-page-count-dropdowns">
            <MerchantDropdown 
                className="col-lg-6 col-xl-5 merchant-dropdown inline-label"
                name='childMerchantNumber'
                onChange={onChildMerchantChanged}
                allOption 
            />

            {!!resultsPerPage &&
                <div className="col-lg-6 results-dropdown inline-label">
                    <FormGroup fieldId='pageSize' label="Items per page">
                        <Dropdown id='pageSize' name="pageSize" aria-labelledby='pageSize_label'
                            onChange={onResultsPerPageChanged}
                            options={resultsPerPageOptions.map(result => ({ value: result, label: result }))}
                            defaultValue={resultsPerPage} />
                    </FormGroup>
                </div>
            }
        </div>
    )
}

MerchantPageCountDropdowns.propTypes = {
    resultsPerPage: PropTypes.number,
    resultsPerPageOptions: PropTypes.array,
    onChildMerchantChanged: PropTypes.func,
    onResultsPerPageChanged: PropTypes.func
};


export default MerchantPageCountDropdowns;