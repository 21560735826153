import _ from 'lodash';

export type CardNetwork = {
    code: string,
    serverCode: string,
    name: string,
    shortName: string,
    cardSubTypeDisabled?: boolean,
    cardCvnEnteredDisabled?: boolean,
    hideForInput?: boolean
}

type CardNetworks = {
    visa: CardNetwork;
    mastercard: CardNetwork;
    amex: CardNetwork;
    diners: CardNetwork;
    jcb: CardNetwork;
    unionPay: CardNetwork;
    paypal: CardNetwork;
    klarna: CardNetwork;
}

type CardInfo = {
    network: CardNetwork | null | undefined;
    cardLength: number;
    cvcLength: number;
    cvcLabel: string;
    expiryDateRequired: boolean;
}

export const cardNetwork: CardNetworks = {
    visa: {
        code: 'visa',
        serverCode: 'VC',
        name: 'VISA',
        shortName: 'Visa'
    },
    mastercard: {
        code: 'mastercard',
        serverCode: 'MC',
        name: 'Mastercard',
        shortName: 'Mastercard'
    },
    amex: {
        code: 'amex',
        serverCode: 'AX',
        name: 'American Express',
        shortName: 'Amex'
    },
    diners: {
        code: 'diners',
        serverCode: 'DC',
        name: 'Diners Club',
        shortName: 'Diners'
    },
    jcb: {
        code: 'jcb',
        serverCode: 'JC',
        name: 'JCB',
        shortName: 'JCB'
    },
    unionPay: {
        code: 'unionpay',
        serverCode: 'UP',
        name: 'UnionPay',
        shortName: 'UnionPay',
        cardSubTypeDisabled: true,
        hideForInput: true
    },
    paypal: {
        code: 'paypal',
        serverCode: 'PP',
        name: 'PayPal',
        shortName: 'PayPal',
        cardSubTypeDisabled: true,
        cardCvnEnteredDisabled: true,
        hideForInput: true
    },
    klarna: {
        code: 'klarna',
        serverCode: 'KN',
        name: 'Klarna',
        shortName: 'Klarna',
        cardSubTypeDisabled: true,
        cardCvnEnteredDisabled: true,
        hideForInput: true
    },
}

export const cardNetworks = Object.values(cardNetwork); // Array: [{code, serverCode, name, shortName}]

const cardRegex = {
    token: new RegExp("^599999"),
    visa: new RegExp("^4"),
    mastercard2: new RegExp("^2"),
    mastercard5: new RegExp("^5"),
    amex: new RegExp("^((34)|(37))"),
    diners: new RegExp("^((36)|(38)|(39)|(300)|(301)|(302)|(303)|(304)|(305)|(309))"),
    jcb16: new RegExp("^(35)"),
    jcb15: new RegExp("^((1800)|(2131))")
};

function isMasterCard(number: string) {
    if (~number.search(cardRegex.mastercard5)) {
        return true;
    }

    if (~number.search(cardRegex.mastercard2) && number.length >= 6) {
        const bin = number.substr(0, 6);
        if (Number(bin) >= 222100 && Number(bin) <= 272099) {
            return true;
        }
    }

    return false;
}

export function isToken(number: string): boolean {
    return !!~number.search(cardRegex.token);
}

/**
 * Given a card number, and a cardTypeCode returns details about the card.  If cardNumber and cardTypeCode are
 * undefined, then returns an object with default values.
 * @param {number} number The card number you are trying to search for card info on
 * @param {*} cardTypeCode The card type code matching the server code.
 */
export const getCardInfo = function (number: string, cardTypeCode?: string): CardInfo {
    const network: CardNetwork | undefined | null = cardTypeCode ? _.find(cardNetworks, x => x.serverCode === cardTypeCode) : null;

    if (number) {
        if (isToken(number)) {
            return {
                network: network,
                cardLength: 16,
                cvcLength: 4,
                cvcLabel: 'CVC',
                expiryDateRequired: false
            };
        }

        if (~number.search(cardRegex.visa)) {
            return {
                network: network || cardNetwork.visa,
                cardLength: 16,
                cvcLength: 3,
                cvcLabel: 'CVV',
                expiryDateRequired: true
            };
        }

        if (isMasterCard(number)) {
            return {
                network: network || cardNetwork.mastercard,
                cardLength: 16,
                cvcLength: 3,
                cvcLabel: 'CVC',
                expiryDateRequired: true
            };
        }

        if (~number.search(cardRegex.amex)) {
            return {
                network: network || cardNetwork.amex,
                cardLength: 15,
                cvcLength: 4,
                cvcLabel: 'CID',
                expiryDateRequired: true
            };
        }

        if (~number.search(cardRegex.diners)) {
            return {
                network: network || cardNetwork.diners,
                cardLength: 14,
                cvcLength: 3,
                cvcLabel: 'CVV',
                expiryDateRequired: true
            };
        }

        if (~number.search(cardRegex.jcb16)) {
            return {
                network: network || cardNetwork.jcb,
                cardLength: 16,
                cvcLength: 3,
                cvcLabel: 'CVV',
                expiryDateRequired: true
            }
        }
        if (~number.search(cardRegex.jcb15)) {
            return {
                network: network || cardNetwork.jcb,
                cardLength: 15,
                cvcLength: 3,
                cvcLabel: 'CVV',
                expiryDateRequired: true
            }
        }
    }

    return {
        network: network,
        cardLength: 19,
        cvcLength: 4,
        cvcLabel: 'CVN',
        expiryDateRequired: true
    };
};

// /utils
//     /cards
//         constants.js
//         converters.js
//         index.js
//
// //index js
// import './constants.js'
// import './converters.js'
// export constants.cardNetworkCodes;
// export constants.cardNetwork;
// export converters.whatever
//
// import {cardNetwork, cardNetworkCodes, whatever} from 'utils/cards'