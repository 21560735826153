import { ReactNode } from "react";
import classNames from "classnames";

import { NameValue } from "models";
import Divider from "./Divider";
import DescriptionListItem from "./DescriptionListItem";
import "./DescriptionList.scss";

type Props = {
    /** Either pass items as an array [{name, value}], or dt+dd as children */
    items?: NameValue<any>[];

    /** Show the row even if there is no name/label */
    showBlankName?: boolean;
    /** Hide the row if there is no value */
    hideBlankValue?: boolean;

    /** dd is right-aligned */
    spaceBetween?: boolean;
    /** Stay inline on XS, ie. dt on the left, dd on the right. (The default is to stack on XS) */
    inlineXs?: boolean;
    /** Have an alternating row background, making it look like table rows */
    striped?: boolean;
    /** Print a divider at the bottom of the list */
    divider?: boolean;
    /** Display name column in non-bolded grey font */
    greyLabel?: boolean;
    /** Split content into two columns. Each label (dt) will have independent width. */
    twoColumn?: boolean;
    /** Display name column won't have break line */
    noBreakLine?: boolean;

    className?: string;
    children?: ReactNode;
};

/**
 * A list of label+description (dl > dt+dd), shown inline (side by side) except on XS where they become stacked.
 */
const DescriptionList = ({
    items, showBlankName, hideBlankValue, className,  // items
    spaceBetween, inlineXs, striped, divider, greyLabel, twoColumn, noBreakLine, // layout
    children
}: Props) => {

    const cssClasses = classNames("description-list", {
        "space-between": spaceBetween,
        "inline-xs": inlineXs,
        "striped": striped,
        "has-divider": divider,
        "grey-label": greyLabel,
        "two-column": twoColumn,
        "label-no-break-line": noBreakLine,
    }, className);

    const listItems = items?.map((item, index) => {
        return (
            (showBlankName || item.name) &&
            (!hideBlankValue || item.value != null) && (

                <DescriptionListItem key={item.id ? item.id : index}
                    item={item}
                    className={item.className}
                    wrapLine={twoColumn}
                />
            )
        );
    });

    const hasItems = (listItems && listItems.length && listItems.some(x => x)) || children;

    return (<>
        { hasItems && (<>
            <dl className={cssClasses}>
                { items ? listItems
                    : children
                }
            </dl>
            { divider && <Divider /> }
        </>)}
    </>);
};

export default DescriptionList;