import React from 'react';
import classNames from 'classnames';
import moment, { MomentInput } from 'moment';

import Flatpickr from 'react-flatpickr';
import { BaseOptions, DateLimit, DateOption } from 'flatpickr/dist/types/options';
import { Instance } from 'flatpickr/dist/types/instance';
import 'flatpickr/dist/themes/material_green.css';

import dateUtil from '@premier/utils/date';
import './DatePicker.scss';

// See https://github.com/haoxins/react-flatpickr
export type DatePickerProps = {
    // Flatpickr
    mode?: 'single' | 'range',
    /** For single this can be a Date/Array. For range/multi this is an Array. */
    defaultDate?: DateOption | DateOption[],
    defaultHour?: number,
    defaultMinute?: number,
    enableTime?: boolean,
    /** Date format from the server (in .NET). Defaults to merchant's date format. */
    dotnetDateFormat?: string,
    /** Time format from the server (in .NET). Defaults to merchant's time format. */
    dotnetTimeFormat?: string,
    /** Disable some dates. See the docs (https://flatpickr.js.org/examples/#disabling-specific-dates) */
    disable?: DateLimit<DateOption>[],
    /** The earliest selectable date. Note that dynamic time might cause infinite refreshes */
    minDate?: DateOption,
    /** The latest selectable date. Note that dynamic time might cause infinite refreshes */
    maxDate?: DateOption,
    /** By default, the placeholder comes from merchant date/time format. Set to '' to disable placeholder, or overwrite the placeholder manually. Note: Do not use this to specify the format. */
    placeholder?: string,
    /* Greys out the widget and stop access to the user */
    disabled?: boolean,
    // Form
    /** Style the textbox accordingly when truthy (eg. red border) */
    error?: string | boolean,
    /** Disable direct input to enter date, user can still use backspace/delete to clear the date */
    disableKeyboardInput?: boolean,
    // Events
    onChange?: (dates: Date[], currentDateString?: string, self?: Instance, data?: any) => void,
    /** Called when the picker is closed, use instead of onBlur */
    onClose?: () => void,
    onReady?: (dates: Date[], currentDateString?: string, self?: Instance, data?: any) => void;
};

const DatePicker = ({
    mode,
    dotnetDateFormat,
    dotnetTimeFormat,
    defaultDate,
    defaultHour,
    defaultMinute,
    enableTime,
    minDate,
    maxDate,
    disable,
    placeholder,
    onChange,
    onReady,
    error,
    disableKeyboardInput,
    disabled,
     ...otherProps
}: DatePickerProps) => {
    mode = mode || 'single';

    // Auto placeholder text if not supplied
    if (placeholder == null) {
        placeholder = dateUtil.datePlaceholder + (enableTime ? ` ${dateUtil.timePlaceholder}` : '');
        if(mode === 'range')
            placeholder += ` – ${placeholder}`;
    }

    function parseDate(dateString: string, format: string) {
        return moment(dateString, format).toDate();
    }
    function formatDate(date: MomentInput, format: string) {
        return moment(date).format(format);
    }

    const options: Partial<BaseOptions> = {
        mode,
        dateFormat: dateUtil.getDateFormat(dotnetDateFormat) + (enableTime ? ' ' + dateUtil.getTimeFormat(dotnetTimeFormat) : ''),
        //rangeSeparator: ' – ',
        defaultDate,
        defaultHour: defaultHour || 0,
        defaultMinute,

        enableTime,
        time_24hr: false,
        allowInput: disableKeyboardInput ? false : true,
        minDate,
        maxDate,
        disable: disable || [],
        disableMobile: true, // This prevents flatpickr from using a native OS interface to pick the dates. Unfortunately some of the native interfaces
        // provide date input in a format that doesn't play well with our UI component,
        parseDate: parseDate,
        formatDate: formatDate,
    };

    return (
        <Flatpickr
            options={options}
            onChange={onChange}
            onReady={onReady}
            className={classNames('date-picker', {'has-error': !!error})}
            placeholder={placeholder}
            disabled={disabled}
            {...otherProps}
        />
    );
};

export default DatePicker;