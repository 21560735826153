import { userRoles } from 'components/Routing';
import { PaymentPlanDetailsRequest } from '@premier/webapi-client'
import { RouteConfigurationMap } from 'components/Routing';

import BatchPayments from 'components/Batch/pages/BatchPayments';
import BatchJob from 'components/Batch/pages/BatchJob';
import BatchLibrary from 'components/Batch/pages/BatchLibrary';
import BatchLibraryFile from 'components/Batch/pages/BatchLibraryFile';
import BatchAddRecords from 'components/Batch/pages/BatchAddRecords';
import BatchAppendRecords from 'components/Batch/pages/BatchAppendRecords';
import BatchHowToAdd from 'components/Batch/pages/BatchHowToAdd';

import { ManagePaymentPlanPage, ViewPaymentPlanDetailsPage,
    NewPaymentPage, ManageTransactionsPage, TransactionDetails, TransactionDetailsPrint,
    ManageSchedulesPage, ViewSchedulePage, NewEditSchedulePage, ScheduledPaymentsHistoryPage, ScheduledPaymentTransactionsPage,
} from 'components/Transactions';

export const transactionRoute : RouteConfigurationMap = {
    root: {
        path:               '/transactions',
        generatePath: () => '/transactions',
        roles:              []
    },

    // Single Payments
    newPayment: {
        path:               '/transactions/new-payment',
        generatePath: () => '/transactions/new-payment',
        element: <NewPaymentPage />,
        title: 'New payment',
        roles:              [userRoles.singlePayment]
    },

    // Transactions
    manageTransactions: {
        path:               '/transactions/manage',
        generatePath: () => '/transactions/manage',
        element: <ManageTransactionsPage />,
        title: 'Manage transactions',
        roles:              [userRoles.transactionSearch, userRoles.securePhone]
    },
    transactionDetails: {
        path:                 '/transactions/manage/:id',
        generatePath: (id: number) => `/transactions/manage/${id}`,
        element: <TransactionDetails />,
        title: 'Transaction details',
        roles:                [userRoles.transactionSearch, userRoles.securePhone]
    },
    transactionDetailsPrint: {
        path:                 '/transactions/manage/:id/print',
        generatePath: (id: number) => `/transactions/manage/${id}/print`,
        element: <TransactionDetailsPrint />,
        title: 'Transaction details',
        roles:                [userRoles.transactionSearch, userRoles.securePhone]
    },

    // Subscriptions
    manageSchedules: {
        path:               '/transactions/subscriptions',
        generatePath: () => '/transactions/subscriptions',
        element: <ManageSchedulesPage />,
        title: 'Subscriptions',
        roles:              [userRoles.recurringSchedule]
    },
    newSchedule: {
        path:               '/transactions/subscriptions/new',
        generatePath: () => '/transactions/subscriptions/new',
        element: <NewEditSchedulePage />,
        title: 'New subscription',
        roles:              [userRoles.recurringSchedule]
    },
    scheduleDetails: {
        path:               '/transactions/subscriptions/:id',
        generatePath: (id: number) => `/transactions/subscriptions/${id}`,
        element: <ViewSchedulePage />,
        title: 'Subscription details',
        roles:              [userRoles.recurringSchedule]
    },
    editSchedule: {
        path:               '/transactions/subscriptions/:id/edit',
        generatePath: (id: number) => `/transactions/subscriptions/${id}/edit`,
        element: <NewEditSchedulePage />,
        title: 'Edit subscription',
        roles:              [userRoles.recurringSchedule]
    },

    // Scheduled Payments
    subscriptionScheduledPayments: {
        path:               '/transactions/subscriptions/:id/scheduled-payments',
        generatePath: (id: number) => `/transactions/subscriptions/${id}/scheduled-payments`,
        element: <ScheduledPaymentsHistoryPage />,
        title: 'Scheduled payments history',
        roles:              [userRoles.recurringSchedule]
    },
    scheduledPaymentTransactions: {
        path:               '/transactions/scheduled-payments/:id/transactions',
        generatePath: (id: number) => `/transactions/scheduled-payments/${id}/transactions`,
        element: <ScheduledPaymentTransactionsPage />,
        title: 'Scheduled payment transactions',
        roles:              [userRoles.recurringSchedule]
    },

     // Batch Payments
    batchPayments: {
        path:               '/transactions/batch',
        generatePath: () => '/transactions/batch',
        element: <BatchPayments />,
        title: 'Batch payments',
        roles:              [userRoles.batchPayment, userRoles.batchManager]
    },
    batchFile: {
        path:                 '/transactions/batch/file/:id',
        generatePath: (id: number) => `/transactions/batch/file/${id}`,
        element: <BatchJob />,
        title: 'Batch file details',
        roles:                [userRoles.batchPayment]
    },
    batchLibrary: {
        path:               '/transactions/batch/library',
        generatePath: () => '/transactions/batch/library',
        element: <BatchLibrary />,
        title: 'Batch library',
        roles:              [userRoles.batchManager]
    },
    batchLibraryFile: {
        path:                 '/transactions/batch/library-file/:id',
        generatePath: (id: number) => `/transactions/batch/library-file/${id}`,
        element: <BatchLibraryFile />,
        title: 'Batch library file details',
        roles:                [userRoles.batchManager]
    },
    batchAddRecords: {
        path:               '/transactions/batch/add-records',
        generatePath: () => '/transactions/batch/add-records',
        element: <BatchAddRecords />,
        title: 'New batch file',
        roles:              [userRoles.batchManager]
    },
    batchAppendRecords: {
        path:                 '/transactions/batch/append-records/:id',
        generatePath: (id: number) => `/transactions/batch/append-records/${id}`,
        element: <BatchAppendRecords />,
        title: 'Edit batch file',
        roles:                [userRoles.batchManager]
    },
    batchHowTo: {
        path:               '/transactions/batch/how-to',
        generatePath: () => '/transactions/batch/how-to',
        element: <BatchHowToAdd />,
        title: 'How to create a BPOINT batch file',
        roles:              [userRoles.batchPayment]
    },

    // Payment plans
    paymentPlan: {
        path:               '/transactions/paymentplan',
        generatePath: () => '/transactions/paymentplan',
        element: <ManagePaymentPlanPage />,
        title: 'Payment plan',
        roles:              [userRoles.paymentPlan]
    },
    landingPaymentPlanPageBillerCodeSelected: {
        path:               '/transactions/paymentplan/:billerCode',
        generatePath: (billerCode: string) => `/transactions/paymentplan/${billerCode}`,
        element: <ManagePaymentPlanPage />,
        title: 'Payment plan',
        roles:              [userRoles.paymentPlan]
    },
    paymentPlanDetails: {
        path:               '/transactions/payment-plan-details',
        // @ts-ignore
        generatePath: (ppdr : PaymentPlanDetailsRequest) => `/transactions/payment-plan-details?${new URLSearchParams(ppdr).toString()}`,
        roles:              [userRoles.paymentPlan],
        element: <ViewPaymentPlanDetailsPage />,
        title: 'View Payment Plan',
    },
};
