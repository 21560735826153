import { useContext } from "react";
import { connect } from "react-redux";

import SecurityUtil from "@premier/utils/security";
import { userRoles } from "components/Routing";

import { Tabs, Tab } from "@premier/ui";
import { FormContext } from "@premier/form";
import { PaymentTypeInputForm } from "components/Transactions";
import { PaymentRequestForm } from "components/PaymentRequests";

// @ts-ignore
import { PaymentMethodDetailsTabKeys } from "constants/billpay";
import { Token } from "models/Request";
import { RootState } from "store/store";
import { Merchant } from "models";

type Props = {
    merchantNumber: string;
    billerCode?: string;
    /** Default = 'paymentMethodDetailsTab' */
    tabName?: string;
    /** The current token, if in edit/update mode */
    token?: Token;
    authenticatedUser: any;
    /** Allow subforms to show the biller selector & CRN fields */
    allowBillerSelection?: boolean;
    hideCrnFields?: boolean;
    merchant: Merchant;
}

/** A component for displaying either a PaymentTypeInputForm or PaymentRequestForm in a tabbed layout.
 * You have to set the Form's initialValues to include {paymentMethodDetailsTab: PaymentMethodDetailsTabKeys.CREATE}
 */
const PaymentMethodDetailsForms = ({
    merchantNumber, billerCode,  // data
    tabName = "paymentMethodDetailsTab", // form
    token, authenticatedUser, // state
    allowBillerSelection, hideCrnFields, merchant // logic render
}: Props) => {
    const { getValue, setValue } = useContext(FormContext);  // The parent Form
    const selectedBillerCode: string | undefined = allowBillerSelection ? getValue("paymentRequest.billerCodeForm.billerCode") : billerCode;
    const showBankAccount = SecurityUtil.hasAccess([userRoles.directDebit], authenticatedUser) && (SecurityUtil.hasAccess([userRoles.dataVault], authenticatedUser) || SecurityUtil.hasAccess([userRoles.recurringSchedule], authenticatedUser));

    function handleChange(tabKey: string | null) {
        setValue(tabName, tabKey);
    }

    const tokenInputTabContent = <PaymentTypeInputForm token={token} showBankAccount={showBankAccount} />;

    if (!SecurityUtil.hasAccess([userRoles.paymentRequest], authenticatedUser)) {
        return tokenInputTabContent;
    }
    if (merchantNumber === merchant.merchantNumber && !merchant.isPaymentRequestAllowed) {
        return tokenInputTabContent;
    }
    if (merchantNumber !== merchant.merchantNumber) {
        const childMerchant = merchant.childMerchants?.find(m => m.merchantNumber === merchantNumber);
        if (!childMerchant?.isPaymentRequestAllowed) {
            return tokenInputTabContent;
        }
    }
        
    return (
        <Tabs activeKey={getValue(tabName)} onSelect={handleChange} >
            <Tab eventKey={PaymentMethodDetailsTabKeys.CREATE} title="Enter payment details">
                {getValue(tabName) === PaymentMethodDetailsTabKeys.CREATE &&
                    tokenInputTabContent
                }
            </Tab>
            <Tab eventKey={PaymentMethodDetailsTabKeys.REQUEST} title="Request payment details">
                {getValue(tabName) === PaymentMethodDetailsTabKeys.REQUEST &&
                    <PaymentRequestForm allowBillerSelection={allowBillerSelection} hideCrnFields={hideCrnFields} merchantNumber={merchantNumber} billerCode={selectedBillerCode} />
                }
            </Tab>
        </Tabs>
    );
};
function mapStateToProps(state: RootState) {
    return {
        authenticatedUser: state.accounts.users.authenticatedUser,
    };
}

export default connect(mapStateToProps)(PaymentMethodDetailsForms);
