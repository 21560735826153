import currencyUtil from '@premier/utils/currency';

import mapErrors, { ApiResult, mapResultErrors } from './mapErrors';
import {
    config,
    resultHandler,
    emptyResultHandler,
    mapFromResult,
} from './util';
import {
    AcceptedAmounts,
    ApiResponse,
    ApiResponseUploadBillerCRNLookupRulesFileResponse,
    Biller,
    BillerApiFactory,
    CreateBillerRequest,
    SurchargeRule,
    SurchargeRuleSet,
    UpdateBillerRequest,
    UpdateBillerDetailsRequest,
    UpdateBillerCRNDetailsRequest,
    CustomerReferenceNumberModel,
    BillerAdditionalPayment,
} from '@premier/webapi-client';
import { Wallet } from 'models';
import httpRequest from './httpRequest';
import { AxiosPromise } from 'axios';

const billerApi = BillerApiFactory(config);
/** TODO: Refactor out Redux related functions. */
//#region ----- Parameter types -----
export type CreateBillerParam = {
    billerName: string;
    acceptedAmounts: {
        min: number;
        max: number;
    };
};

export type UpdateBillerParam = {
    billerCode: string;
    txnTokenisationMode: 'None' | 'OptIn' | 'All';
    tokeniseApprovedOnlyTxns: boolean;
};

export type UpdateBillerDetailsParam = {
    billerCode: string;
    description: string;
    billerCodeEnabled: boolean;
};

export type UpdateCustomerReferenceParam = {
    billerCode: string;
    billerCRNList: Array<CustomerReferenceNumberModel>;
};

export type SurchargeRuleParam = Omit<
    SurchargeRule,
    'fixedSurchargeAmount' | 'surchargeLimits'
> & {
    fixedSurchargeAmount: string | number;
    surchargeLimits: {
        minimum: string | number;
        maximum: string | number;
    };
};

export type AmountParam = {
    minAmount: string | number;
    maxAmount: string | number;
};
//#endregion

class BillerApi {
    /** Get all billers for the logged-in merchant. Legacy version for Redux. DON'T USE THIS */
    static getBillers(): Promise<ApiResult<Biller[]>> {
        return billerApi
            .billerGetBillers(true)
            .then(...resultHandler)
            .then((r) => mapResultErrors(r))
            .then((r) =>
                mapFromResult(r, (billers) => billers.map(mapBillerFromDto))
            );
    }

    /* useApiCall compatible */
    static getBillersV2(includeChildMerchants: boolean): any {
        return billerApi.billerGetBillers(includeChildMerchants).then((o: any) => {
            o.data.data = o.data.data.filter((b: Biller) => b.billerType !== "INVOICE")
            return o;
        });
    }

    /**
     * Get a biller details. (Note that you may be able to get this from the biller list in Redux)
     * @param {string} billerCode The billerCode of the biller to retrieve
     */
    static getBiller(billerCode: string): Promise<ApiResult<Biller>> {
        return billerApi
            .billerGetBiller(billerCode)
            .then(...resultHandler)
            .then((r) => mapResultErrors(r))
            .then((r) => mapFromResult(r, mapBillerFromDto));
    }

    static getBillerDetails(billerCode: string): Promise<ApiResult<Biller>> {
        // @ts-ignore
        return billerApi.billerGetBiller(billerCode);
    }

    /**
     * Creates a new biller under the current logged-in user's merchant, returning the new biller's details.
     * @param {object} billerDetails An object with billerName, and an amounts object with Min/Max values
     */
    static createBiller(
        billerDetails: CreateBillerParam
    ): Promise<ApiResult<Biller>> {
        let newBiller: CreateBillerRequest = {
            billerName: billerDetails.billerName,
            amounts: {
                minimum:
                    currencyUtil.convertToApiValue(
                        billerDetails.acceptedAmounts.min
                    ) ?? undefined,
                maximum:
                    currencyUtil.convertToApiValue(
                        billerDetails.acceptedAmounts.max
                    ) ?? undefined,
            },
        };

        return billerApi
            .billerCreateBiller(newBiller)
            .then(...resultHandler)
            .then((r) => mapResultErrors(r))
            .then((r) => mapFromResult(r, mapBillerFromDto));
    }

    /**
     * Updates an existing biller.
     * @param {object} billerDetails an object with the updated billerDetails to send to the server
     */
    static updateBiller(
        billerDetails: UpdateBillerParam
    ): Promise<ApiResult<void>> {
        let updatedBiller: UpdateBillerRequest = {
            txnTokenisationMode: billerDetails.txnTokenisationMode,
            tokeniseApprovedOnlyTxns: billerDetails.tokeniseApprovedOnlyTxns,
        };

        return billerApi
            .billerUpdateBiller(billerDetails.billerCode, updatedBiller)
            .then(...emptyResultHandler)
            .then((r) => mapResultErrors(r));
    }

    /** Update a biller's description */
    static updateDetails(
        params: UpdateBillerDetailsParam,
    ): AxiosPromise<ApiResponse> {
        const request: UpdateBillerDetailsRequest = {
            billerDescription: params.description,
            billerCodeEnabled: params.billerCodeEnabled,
        };

        return billerApi
            .billerUpdateDetails(params.billerCode, request);
    }

    static updateCustomerReference(
        params: UpdateCustomerReferenceParam,
    ): AxiosPromise<ApiResponse> {
        const request: UpdateBillerCRNDetailsRequest = {
            billerId: parseInt(params.billerCode),
            billerCRNList: params.billerCRNList
        };

        return billerApi
            .billerUpdateCustomerReference(params.billerCode, request);
    }

    /** Get a biller's surcharge rules */
    static getSurchargeRules(billerCode: string) {
        function mapSurchargeRuleFromDto(rule: SurchargeRule) {
            return {
                ...rule,
                fixedSurchargeAmount: currencyUtil.fromApiValue(
                    rule.fixedSurchargeAmount ?? 0
                ),
                surchargeLimits: {
                    minimum: currencyUtil.fromApiValue(
                        rule.surchargeLimits?.minimum ?? 0
                    ),
                    maximum: rule.surchargeLimits?.maximum
                        ? currencyUtil.fromApiValue(
                            rule.surchargeLimits.maximum
                        )
                        : undefined,
                },
            };
        }

        return billerApi
            .billerGetSurchargeRules(billerCode)
            .then(...resultHandler)
            .then((r) => mapResultErrors(r))
            .then((r) =>
                mapFromResult(r, (set) =>
                    set.surcharges?.map(mapSurchargeRuleFromDto)
                )
            );
    }
    /** Get a biller's surcharge rules */
    static getFullSurchargeRules(billerCode: string) {
        return billerApi
            .billerGetFullSurchargeRules(billerCode);
    }

    /** Update a biller's surcharge rules (and accepted cards deduced from the rules) */
    static updateSurchargeRules(
        billerCode: string,
        rules: SurchargeRuleParam[]
    ): Promise<ApiResult<void>> {
        function mapSurchargeToDto(rule: SurchargeRuleParam): SurchargeRule {
            return {
                ...rule,
                fixedSurchargeAmount:
                    currencyUtil.convertToApiValue(rule.fixedSurchargeAmount) ??
                    0,
                surchargeLimits: rule.surchargeLimits && {
                    minimum:
                        currencyUtil.convertToApiValue(
                            rule.surchargeLimits.minimum
                        ) ?? 0,
                    maximum:
                        currencyUtil.convertToApiValue(
                            rule.surchargeLimits.maximum
                        ) ?? undefined,
                },
            };
        }

        var dto: SurchargeRuleSet = {
            surcharges: rules.map(mapSurchargeToDto),
        };
        return billerApi
            .billerUpdateSurchargeRules(billerCode, dto)
            .then(...emptyResultHandler)
            .then((r) => mapResultErrors(r));
    }

    static getWalletsRedux(
        billerCode: string
    ): Promise<ApiResult<Wallet[] | undefined>> {
        return billerApi.billerGetWallets(billerCode)
            .then(...resultHandler)
            .then((r : any) => mapResultErrors(r))
            .then((r : any) => mapFromResult(r, (set : any) => set.wallets));
    }

    /** Get biller's available wallets that they can enable/disable */
    static getWallets(billerCode: string) {
        return billerApi.billerGetWallets(billerCode)
    }

    /** Gets the enable status of the Apple Pay wallet for a specific biller code */
    static getApplePayWallet(billerCode: string): any {
        return billerApi.billerGetApplePayWallet(billerCode);
    }

    static updateWalletsRedux(
        billerCode: string,
        wallets: Wallet[]
    ): Promise<ApiResult<void>> {
        return billerApi.billerUpdateWallets(billerCode, { wallets })
            .then(...emptyResultHandler)
            .then((r : any) => mapResultErrors(r));
    }

    /** Update a biller's accepted wallets */
    static updateWallets(
        billerCode: string,
        wallets: Wallet[]
    ): any {
        return billerApi.billerUpdateWallets(billerCode, { wallets })
    }

    /** Updates the payment limit values for a specific biller
     * @param {string} billerCode The billerCode of the amounts to update for
     * @param {string} acceptedAmounts Object consisting of 2 values of a min / max amount to set
     */
    static updatePaymentLimits(
        billerCode: string,
        acceptedAmounts: AmountParam
    ): Promise<ApiResult<void>> {
        let dto: AcceptedAmounts = {
            minimum:
                currencyUtil.convertToApiValue(acceptedAmounts.minAmount) ??
                undefined,
            maximum:
                currencyUtil.convertToApiValue(acceptedAmounts.maxAmount) ??
                undefined,
        };

        return billerApi
            .billerUpdatePaymentLimits(billerCode, dto)
            .then(...emptyResultHandler)
            .then((r) => mapResultErrors(r));
    }

    static billerUploadBillerCRNLookupRulesFile(
        files: FileList,
        emails: string
    ): Promise<ApiResult<ApiResponseUploadBillerCRNLookupRulesFileResponse>> {
        return new Promise((resolve, reject) => {
            httpRequest
                .postFiles('/Biller/UploadBillerCRNLookupRulesFile', files, {
                    emails,
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(mapErrors(err));
                });
        });
    }

    static updateIsTopUpsEnabled(billerCode: string, enabled: boolean) {
        return billerApi.billerUpdateIsTopUpsEnabled(billerCode, { enabled });
    }

    /** Get a biller's additional payments */
    static getAdditionalPayments(billerCode: string) {
        return billerApi.billerGetAdditionalPayments(billerCode);
    }

    /** Save a biller's additional payments */
    static saveAdditionalPayments(billerCode: string, additionalPayments: BillerAdditionalPayment[]) {
        return billerApi.billerSaveAdditionalPayments(billerCode, additionalPayments)
    }
}

/**Biller DTO Mapping */
/** TODO: Refactor this out because I have no idea why are we modifying API derived objects */
export function mapBillerFromDto(biller: Biller): Biller {
    return {
        ...biller,
        paymentLimits: {
            minimum: currencyUtil.fromApiValue(
                biller.paymentLimits?.minimum ?? 0
            ),
            maximum: biller.paymentLimits?.maximum
                ? currencyUtil.fromApiValue(biller.paymentLimits.maximum)
                : undefined,
        },
    };
}



export default BillerApi;
