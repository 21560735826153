import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BackButton } from '@premier/ui';
// CSS defined in 'css/styles/header.scss'

/** Consists of a H1, an optional BackButton, optional sub-title and optional Buttons/Links passed as
 *  Note that back-button-container acts as a padding so the title of each page isn't at the very
 *  top of the page. Use hideTopDiv to remove it.
 *  Use this class if you also wish to have a header and buttons on the same row.
 */
const PageHeader = ({
    backButton, icon, title, subtitle, useH2, hideTopDiv, children //data
}) => {
    return (
        <div className='page-header-container'>
            {!hideTopDiv ?
                <div className='back-button-container'>
                    {backButton && (
                        <BackButton {...backButton} />
                    )}
                </div> : null
            }
            <div className="page-header">
                <div className={classNames("header-left", { 'has-subtitle': subtitle })}>
                    {icon && <div className="header-icon">{icon}</div>}

                    <div className='header-text'>
                        {useH2 ? <h2>{title}</h2> : <h1>{title}</h1>}

                        {typeof (subtitle) === 'string'
                            ? <p className="header-subtitle">{subtitle}</p>
                            : <div className="header-subtitle">{subtitle}</div>
                        }
                    </div>
                </div>

                <div className="header-right">
                    {children}
                </div>
            </div>
        </div>
    );
}

PageHeader.propTypes = {
    backButton: PropTypes.any,
    /** eg. <Icon tick /> */
    icon: PropTypes.node,
    /** The H1 element, eg. 'Welcome' */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Buttons/Links that appear on the right on L+ */
    children: PropTypes.any,
    useH2: PropTypes.any,
    hideTopDiv: PropTypes.any
};

export default PageHeader;
