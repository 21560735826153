import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from 'components/Common';
// @ts-ignore
import { userRoles, PlatformRoutesConfiguration } from 'components/Routing';
import { Button, Dialog, Icon, IconText, Link, LoadingIndicator, PageSection } from '@premier/ui';
import SecurityUtil from '@premier/utils/security';
import InvoiceForm from '../components/InvoiceForms/InvoiceForm';
import { useInvoiceSettings } from 'components/Settings/_hooks/useInvoicesSettings';
import useCustomer from '../_hooks/useCustomer';
import NoBillerCodeErrorPage from 'components/Common/pages/NoBillerCodeErrorPage';

export const newInvoicePageCopy = {
    pageTitle: 'New invoice',
    pageExplanation: 'Create an invoice for your customer',
    updateSectionTitle: 'Update settings for invoice',
    invoiceSettingsSectionExplanation:
        'To set rules for invoice no., add options for payment terms and tax options, change default customer note and customise labels, please go to',
    invoiceSettingsSectionLink: 'Invoice Settings',
    customerNotFound: 'Customer not found or an error occurred.',

    confirmDialogTitle: 'This invoice is not sent',
    leaveWithoutSending: 'Leaving this page without sending this invoice will discard all your inputs.',
    continueButton: 'Continue editing',
    discardButton: 'Discard inputs',

    successfulSendTitle: 'Invoice sent successfully!',
    errorSendTitle: 'Error with sending invoice',
    errorSendBody: 'We are unable to send this invoice now. Please try again.',
    closeButton: 'Close',
};

interface Props {}

const NewInvoicePage: React.FC<Props> = () => {
    const authenticatedUser = useSelector((state: any) => state.accounts.users.authenticatedUser);
    const { hasLoaded: invoiceSettingsHasLoaded, settings: invoiceSettings } = useInvoiceSettings();
    const { errors: customerErrors, details: customerDetails } = useCustomer();
    const [isInvoiceDirty, setIsInvoiceDirty] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const navigate = useNavigate();

    // If either the invoice settings or customer endpoints haven't loaded yet, display a spinner
    if (!invoiceSettingsHasLoaded || (!customerDetails && customerErrors && customerErrors.length === 0)) {
        return <LoadingIndicator />;
    }

    // If either the invoice settings or customer endpoints fail, display a generic error
    if (!invoiceSettings || (!customerDetails && customerErrors && customerErrors.length > 0)) {
        return (
            <PageSection>
                <PageHeader backButton subtitle={<IconText alert>{newInvoicePageCopy.customerNotFound}</IconText>} />
            </PageSection>
        );
    }

    // If the use has no invoice biller, display a specific error
    if (!invoiceSettings.hasInvoiceBiller) {
        return <NoBillerCodeErrorPage />;
    }

    return (
        <PageSection>
            <PageSection>
                <PageHeader
                    backButton={{ ...{ onClick: isInvoiceDirty && (() => setShowDialog(true)) } }}
                    title={newInvoicePageCopy.pageTitle}
                />
                <p>{newInvoicePageCopy.pageExplanation}</p>
            </PageSection>
            {SecurityUtil.hasAccess([userRoles.invoiceSettings], authenticatedUser) && (
                <PageSection>
                    <h3>{newInvoicePageCopy.updateSectionTitle}</h3>
                    <p>
                        {newInvoicePageCopy.invoiceSettingsSectionExplanation}&nbsp;
                        <Link to={PlatformRoutesConfiguration.invoiceSettingsRoute?.root.path}>{newInvoicePageCopy.invoiceSettingsSectionLink}</Link>
                    </p>
                </PageSection>
            )}
            <PageSection>
                <InvoiceForm
                    setIsInvoiceDirty={setIsInvoiceDirty}
                    showSuccess={() => setShowSuccess(true)}
                    showError={() => setShowError(true)}
                />
            </PageSection>

            <Dialog
                show={showDialog}
                closeButton
                onClose={() => setShowDialog(false)}
                icon={<Icon alert />}
                title={newInvoicePageCopy.confirmDialogTitle}
                footerButtons={
                    <>
                        <Button primary onClick={() => setShowDialog(false)}>
                            {newInvoicePageCopy.continueButton}
                        </Button>
                        <Button onClick={() => { navigate(-1); }}>{newInvoicePageCopy.discardButton}</Button>
                    </>
                }
            >
                {newInvoicePageCopy.leaveWithoutSending}
            </Dialog>

            <Dialog
                show={showSuccess}
                closeButton
                onClose={() => {
                    setShowSuccess(false);
                    navigate(-1);
                }}
                icon={<Icon tick />}
                title={newInvoicePageCopy.successfulSendTitle}
            />

            <Dialog
                show={showError}
                closeButton
                onClose={() => setShowError(false)}
                icon={<Icon alert />}
                title={newInvoicePageCopy.errorSendTitle}
                footerButtons={<Button onClick={() => setShowError(false)}>{newInvoicePageCopy.closeButton}</Button>}
            >
                {newInvoicePageCopy.errorSendBody}
            </Dialog>
        </PageSection>
    );
};

export default NewInvoicePage;
