import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Form, validate, FormContext } from "@premier/form";
import { BillerDetailsInput } from "components/Transactions";
import { MerchantDropdown, useApiCall, APICallRequestState } from "components/Common";
import * as accountActions from "components/Account/_actions/accountActions";
import currencyUtil from "@premier/utils/currency";
// @ts-ignore
import { BillerDefaultAcceptedAmounts } from "constants/billpay";
import { customerApi } from "api";
import { RootState } from "store/store";
import { MerchantModel } from "packages/webapi-client";
import { Token } from "models/Token";
import { ExpiryDate } from "models/ExpiryDate";
import { Biller } from "packages/webapi-client";
import { isAmountRequired } from "util/paymentRequestValidation";
import { userRoles } from "components/Routing";

type Props = {
    /** The subform name. Default = 'billerCodeForm'. Try not to override this unless you have multiple instances on a page. */
    name?: string;

    /** Determines whether the merchant refernces fields default to 'Token Reference 1' or 'Customer Reference 1' */
    tokenDisplay?: boolean;

    /** Determines whether CRN fields are visible or not (hidden in some modes) */
    hideCrnFields?: boolean;

    /** Determines whether the Merchant drop down is visible or not (hidden in some modes) */
    hideMerchantField?: boolean;

    /** Renders show payment method button to lookup tokens */
    showPaymentMethodButton?: boolean;

    /** Renders payment request action drop down. Used on payment request forms/screens. */
    showPaymentRequestAction?: boolean;

    /** Renders token lookup field */
    showTokenNumberLookup?: boolean;

    /** Determines if the 'All' value in the merchant drop-down is supplied */
    AllMerchantOption?: boolean;

    /** Function to run if merchant change returns anything  */
    onMerchantChange?: (merchant: Merchant | undefined) => void;

    /** Used when you want to restrict token lookups/searches to a particular customer */
    customerUniqueId?: string;

    initialValuesTokens?: Token;

    preSelectedToken?: Token;

    billers: Biller[];

    merchant: MerchantModel;

    tokenRequired?: boolean;

    tokenLocked?: boolean;

    tokenTriggerValidation?: boolean;

    tokenPage?: boolean;

    feature?: userRoles;

    onBillerChange?: (biller: Biller) => void;

    onPaymentRequestActionChange?: (action: number) => void;
}

type Merchant = {
    merchantNumber: string | undefined;
    merchantName:   string | undefined;
    isParent:       boolean;
}

/** A (sub)form that contains ChildMerchant dropdown + BillerDetailsInput (ie. BillerCode + CRNs) */
const MerchantBillerDetailsForm = ({
    name = "billerCodeForm",
    AllMerchantOption, tokenDisplay, hideCrnFields, hideMerchantField, showPaymentMethodButton, showPaymentRequestAction, showTokenNumberLookup = false, tokenRequired = false, tokenLocked = false, tokenTriggerValidation = false, tokenPage = false, feature, //logic renders
    billers, merchant, customerUniqueId, initialValuesTokens, preSelectedToken, //states
    onMerchantChange, onBillerChange, onPaymentRequestActionChange //functions
}: Props) => {
    const parentMerchant: Merchant = {
        merchantNumber: merchant.merchantNumber,
        merchantName: merchant.merchantName,
        isParent: true,
    };

    const [merchantOptions, setMerchantOptions] = useState<Merchant[]>([]);

    const parentContext = React.useContext(FormContext);
    const merchantNumber: string | undefined = parentContext.getValue(`${name}.merchantNumber`) || parentMerchant.merchantNumber;
    const merchantBillers = billers.filter(b => b.merchantNumber ===  merchantNumber);
    const { setValidation } = useContext(FormContext);
    const [customerV2Id, setCustomerV2Id] = useState<number>();
    const [selectedToken, setSelectedToken] = useState<Token | undefined>(preSelectedToken);

    const [customer, customerStatus] = useApiCall(async () => {
        if (customerV2Id) {
            const dto = await customerApi.getCustomer(customerV2Id);
            return { data: { data: dto }, status: 200 };
        }
    }, [customerV2Id]);

    useEffect(() => {
        parentContext.setValue("customer", customerStatus === APICallRequestState.SUCCESSFUL ? customer : null);
    }, [customerStatus]);

    useEffect(() => {
        if (preSelectedToken) {
            selectDataVault([preSelectedToken]);
        }
    }, [preSelectedToken]);

    useEffect(() => {
        const childMercs =
            merchant.childMerchants &&
            merchant.childMerchants.map((cm: MerchantModel) => ({
                merchantNumber: cm.merchantNumber,
                merchantName: cm.merchantName,
                isParent: false,
                isPaymentRequestAllowed: cm.isPaymentRequestAllowed,
            }));

        setMerchantOptions([parentMerchant].concat(childMercs || []));
    }, []);

    const billerCode = useRef<string>();

    const populateCrnList = (token: Token)  => {
        parentContext.setValue("billerCodeForm.billerCrnList.crn1", token.crn1);
        parentContext.setValue("billerCodeForm.billerCrnList.crn2", token.crn2);
        parentContext.setValue("billerCodeForm.billerCrnList.crn3", token.crn3);
    };

    const selectDataVault = (items: Token[]) => {
        // item passed in is an array contains only one data vault object, data vault is selected from SearchPaymentMethodsDialog
        if (items.length === 1) {
            setSelectedToken(items[0]);
        }

        // if data vault is linked to customer
        if (items[0].customerV2Id) {
            setCustomerV2Id(items[0].customerV2Id);
        } else {
            parentContext.setValue("customer", null);
        }

        const expiryDate: ExpiryDate = items[0].expiryDate as ExpiryDate;

        populateCrnList(items[0]);
        parentContext.setValue("card.cardNumber", items[0].token);
        parentContext.setValue("card.accountNumber", items[0].cardTypeCode === "BA" ? `${items[0].deBsbNumber} - ${items[0].deAccountNumber}` : items[0].maskedCardNumber);
        parentContext.setValue("card.expiryDate.month", expiryDate?.month);
        parentContext.setValue("card.expiryDate.year", expiryDate?.year);
        parentContext.setValue("paymentRequest.dataVaultId", items[0].dataVaultId);
    };

    const clearDataVault = () => {
        setSelectedToken(undefined);
        parentContext.setValue("customer", null);
        parentContext.setValue("billerCodeForm.billerCrnList.crn1", null);
        parentContext.setValue("billerCodeForm.billerCrnList.crn2", null);
        parentContext.setValue("billerCodeForm.billerCrnList.crn3", null);
        parentContext.setValue("card.cardNumber", null);
        parentContext.setValue("card.accountNumber", null);
        parentContext.setValue("card.expiryDate.month", null);
        parentContext.setValue("card.expiryDate.year", null);
        parentContext.setValue("paymentRequest.dataVaultId", null);
    };

    function handleMerchantChanged(newValue: string) {
        clearDataVault(); // Clear form whenever merchant changes
        const newMerchant = merchantOptions.find(mo => mo.merchantNumber === newValue);
        onMerchantChange && onMerchantChange(newMerchant);
    }

    function handleBillerChange(biller?: Biller) {
        if (!biller) {
            return;
        }

        billerCode.current = biller.billerCode;

        resetAmountValidation(Number(parentContext.getValue("billerCodeForm.action")));

        // reset initial value of merchant number
        parentContext.setValue("billerCodeForm.merchantNumber", biller.merchantNumber ?? "");

        // reset initial value for crn2 and crn3 once biller is changed if initial values have been provided
        if (selectedToken || initialValuesTokens) {
            parentContext.setValue("billerCodeForm.billerCrnList.crn2", selectedToken?.crn2 ?? initialValuesTokens?.crn2 ?? "");
            parentContext.setValue("billerCodeForm.billerCrnList.crn3", selectedToken?.crn3 ?? initialValuesTokens?.crn3 ?? "");
        }

        if (onBillerChange) {
            onBillerChange(biller);
        }
    }

    function resetAmountValidation(action: number) {
        onPaymentRequestActionChange && onPaymentRequestActionChange(action);

        const biller = billers.find(b => b.billerCode === billerCode.current);

        // set amount validation only when parentContext has amount validation
        if (biller && "amount" in parentContext.validations) {
            const maxAmount = biller.paymentLimits?.maximum ?? BillerDefaultAcceptedAmounts.MAX;
            const minAmount = biller.paymentLimits?.minimum ?? BillerDefaultAcceptedAmounts.MIN;
            setValidation(
                "amount",
                validate()
                    .requiredIf(() => isAmountRequired(action))
                    .when(val => !isAmountRequired(action) || currencyUtil.parseAmount(val)! >= minAmount,
                        `Minimum amount cannot be less than ${currencyUtil.formatAmount(minAmount)}`
                    )
                    .when(val => !isAmountRequired(action) || currencyUtil.parseAmount(val)! <= maxAmount,
                        `Maximum amount cannot exceed ${currencyUtil.formatAmount(maxAmount)}`
                    ),
            );
        }
    }

    return (
        <Form
            name={name}
            initialValues={{
                merchantNumber: merchantNumber,
            }}
            initialValidation={{
                merchantNumber: !AllMerchantOption && validate().required(),
                billerCode: validate().required(),
            }}
        >
            {!hideMerchantField && <MerchantDropdown allOption={AllMerchantOption} onChange={handleMerchantChanged} feature={feature} />}
            <BillerDetailsInput
                parentMerchantNumber={merchant.merchantNumber}
                merchantNumber={merchantNumber}
                merchantBillers={merchantBillers}
                onBillerCodeChange={handleBillerChange}
                tokenDisplay={tokenDisplay}
                hideCrnFields={hideCrnFields}
                selectDataVault={selectDataVault}
                onClearDataVault={clearDataVault}
                showPaymentMethodButton={showPaymentMethodButton}
                showPaymentRequestAction={showPaymentRequestAction}
                showTokenNumberLookup={showTokenNumberLookup}
                selectedToken={selectedToken}
                customerUniqueId={customerUniqueId}
                tokenRequired={tokenRequired}
                tokenLocked={tokenLocked}
                tokenTriggerValidation={tokenTriggerValidation}
                tokenPage={tokenPage}
                onPaymentRequestActionChange={resetAmountValidation}
            />
        </Form>
    );
};

function mapStateToProps(state: RootState) {
    return {
        billers: state.accounts.users.activeBillers,
        merchant: state.accounts.users.merchant,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        accountActions: bindActionCreators(accountActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantBillerDetailsForm);
