/* tslint:disable */
/* eslint-disable */
/**
 * Premier.Billpay.BackOffice.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptedAmounts
 */
export interface AcceptedAmounts {
    /**
     * 
     * @type {number}
     * @memberof AcceptedAmounts
     */
    minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptedAmounts
     */
    maximum?: number;
}
/**
 * 
 * @export
 * @interface AccountModel
 */
export interface AccountModel {
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    countryCode?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    supportCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    lastSuccessfulLogOnTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountModel
     */
    lastLogOnIpAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountModel
     */
    features?: Array<AccountModelFeaturesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountModel
     */
    logOnActions?: Array<AccountModelLogOnActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof AccountModel
     */
    userId?: number;
}

/**
    * @export
    * @enum {string}
    */
export type AccountModelFeaturesEnum = 'SINGLE_PAYMENT' |
    'BATCH_PAYMENT' |
    'BATCH_MANAGER' |
    'DATA_VAULT' |
    'INTERNET' |
    'ADMIN_MENU' |
    'TRANSACTION_SEARCH' |
    'USER_MANAGEMENT' |
    'SETTLEMENT' |
    'REPORTS' |
    'IDB_UPLOAD' |
    'API_ACCESS' |
    'DELETE_BATCH_MANAGER_BATCH' |
    'PROCESS_REFUND' |
    'DIRECT_DEBIT' |
    'RECURRING_SCHEDULE' |
    'EFORMS' |
    'DECLINE_MANAGER' |
    'DECLINE_MANAGER_ADMIN' |
    'NOTIFICATIONS' |
    'MESSAGING' |
    'WEB_SHOP' |
    'INVOICES' |
    'CUSTOMERS' |
    'FRAUD_CONTROL' |
    'FRAUD_BASIC' |
    'API_CONFIGURATION' |
    'PAYMENT_OPTIONS' |
    'PHONE_PROMPTS' |
    'PHONE_PROMPTS_CREATE_NEW' |
    'PHONE_PROMPTS_LOGS' |
    'PLUGIN_CONFIGURATION' |
    'IVR_BLOCKED_CALLERS' |
    'IVR_CUSTOM_SETTINGS' |
    'IVR_CUSTOM_SETTINGS_ACTIONS' |
    'IVR_LOGS' |
    'IVR_OPERATOR' |
    'IVR_QUEUES' |
    'BILLER_CONFIG' |
    'PAYMENT_REQUEST' |
    'PAYMENT_REQUEST_ADMIN' |
    'PAYMENT_REQUEST_BATCH' |
    'SECURE_PHONE' |
    'LEGACY_XML' |
    'CONSUMER_PORTAL_ADMIN' |
    'EXTERNAL_REPORTING' |
    'STATIONERY_SHOP' |
    'NOT_STATIONERY_SHOP' |
    'QR_CODE' |
    'INVOICE_SETTINGS' |
    'PAYMENT_PLAN' |
    'PAYMENT_PLAN_SETTINGS' |
    'INVOICE_PAYMENTS' |
    'LEAST_COST_ROUTING' |
    'LCR_ALLOW_AMOUNT_THRESHOLD_CONFIGURATION' |
    'ADDITIONAL_PAYMENTS' |
    'MULTI_FACTOR_AUTHENTICATION' |
    'ALLOW_DISABLING_MFA';
/**
    * @export
    * @enum {string}
    */
export type AccountModelLogOnActionsEnum = 'UPDATE_PASSWORD' |
    'ACCEPT_TERMS_AND_CONDITIONS' |
    'MULTI_FACTOR_AUTHENTICATION';

/**
 * 
 * @export
 * @interface AddBatchRecordsRequest
 */
export interface AddBatchRecordsRequest {
    /**
     * 
     * @type {number}
     * @memberof AddBatchRecordsRequest
     */
    batchFileId?: number;
    /**
     * 
     * @type {Array<BatchRecord>}
     * @memberof AddBatchRecordsRequest
     */
    batchRecords?: Array<BatchRecord>;
}
/**
 * 
 * @export
 * @interface AddUserRequest
 */
export interface AddUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserRequest
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof AddUserRequest
     */
    roleId?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserRequest
     */
    dailyRefundLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserRequest
     */
    dailyRefundAmountLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    currentPassword?: string;
}
/**
 * 
 * @export
 * @interface AddressModel
 */
export interface AddressModel {
    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    typeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    suburb?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    stateId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    postcode?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressModel
     */
    countryCode?: number;
}
/**
 * 
 * @export
 * @interface AmountBreakdownModel
 */
export interface AmountBreakdownModel {
    /**
     * 
     * @type {number}
     * @memberof AmountBreakdownModel
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof AmountBreakdownModel
     */
    amountOriginal?: number;
    /**
     * 
     * @type {number}
     * @memberof AmountBreakdownModel
     */
    amountSurcharge?: number;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    parameter?: string;
}
/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseBatchProcessResponse
 */
export interface ApiResponseBatchProcessResponse {
    /**
     * 
     * @type {BatchProcessResponse}
     * @memberof ApiResponseBatchProcessResponse
     */
    data?: BatchProcessResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseBatchProcessResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseBiller
 */
export interface ApiResponseBiller {
    /**
     * 
     * @type {Biller}
     * @memberof ApiResponseBiller
     */
    data?: Biller;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseBiller
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseBillerAdditionalPayment
 */
export interface ApiResponseBillerAdditionalPayment {
    /**
     * 
     * @type {Array<BillerAdditionalPayment>}
     * @memberof ApiResponseBillerAdditionalPayment
     */
    data?: Array<BillerAdditionalPayment>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseBillerAdditionalPayment
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseBoolean
 */
export interface ApiResponseBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseBoolean
     */
    data?: boolean;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseBoolean
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseBranding
 */
export interface ApiResponseBranding {
    /**
     * 
     * @type {Branding}
     * @memberof ApiResponseBranding
     */
    data?: Branding;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseBranding
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseBusinessDetails
 */
export interface ApiResponseBusinessDetails {
    /**
     * 
     * @type {BusinessDetails}
     * @memberof ApiResponseBusinessDetails
     */
    data?: BusinessDetails;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseBusinessDetails
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseCardSurchargeDetails
 */
export interface ApiResponseCardSurchargeDetails {
    /**
     * 
     * @type {CardSurchargeDetails}
     * @memberof ApiResponseCardSurchargeDetails
     */
    data?: CardSurchargeDetails;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseCardSurchargeDetails
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseCardTypesData
 */
export interface ApiResponseCardTypesData {
    /**
     * 
     * @type {CardTypesData}
     * @memberof ApiResponseCardTypesData
     */
    data?: CardTypesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseCardTypesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseClientPlatformConfiguration
 */
export interface ApiResponseClientPlatformConfiguration {
    /**
     * 
     * @type {ClientPlatformConfiguration}
     * @memberof ApiResponseClientPlatformConfiguration
     */
    data?: ClientPlatformConfiguration;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseClientPlatformConfiguration
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseCompanyIdLabels
 */
export interface ApiResponseCompanyIdLabels {
    /**
     * 
     * @type {CompanyIdLabels}
     * @memberof ApiResponseCompanyIdLabels
     */
    data?: CompanyIdLabels;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseCompanyIdLabels
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseCredentialOnFileModel
 */
export interface ApiResponseCredentialOnFileModel {
    /**
     * 
     * @type {CredentialOnFileModel}
     * @memberof ApiResponseCredentialOnFileModel
     */
    data?: CredentialOnFileModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseCredentialOnFileModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseCredentials
 */
export interface ApiResponseCredentials {
    /**
     * 
     * @type {Credentials}
     * @memberof ApiResponseCredentials
     */
    data?: Credentials;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseCredentials
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseCustomerModel
 */
export interface ApiResponseCustomerModel {
    /**
     * 
     * @type {CustomerModel}
     * @memberof ApiResponseCustomerModel
     */
    data?: CustomerModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseCustomerModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseDailyReportsResponse
 */
export interface ApiResponseDailyReportsResponse {
    /**
     * 
     * @type {DailyReportsResponse}
     * @memberof ApiResponseDailyReportsResponse
     */
    data?: DailyReportsResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseDailyReportsResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseDataVaultModel
 */
export interface ApiResponseDataVaultModel {
    /**
     * 
     * @type {DataVaultModel}
     * @memberof ApiResponseDataVaultModel
     */
    data?: DataVaultModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseDataVaultModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseDataVaultSummaryModel
 */
export interface ApiResponseDataVaultSummaryModel {
    /**
     * 
     * @type {DataVaultSummaryModel}
     * @memberof ApiResponseDataVaultSummaryModel
     */
    data?: DataVaultSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseDataVaultSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseDownloadBatchFileResultResponse
 */
export interface ApiResponseDownloadBatchFileResultResponse {
    /**
     * 
     * @type {DownloadBatchFileResultResponse}
     * @memberof ApiResponseDownloadBatchFileResultResponse
     */
    data?: DownloadBatchFileResultResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseDownloadBatchFileResultResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseExportTypesData
 */
export interface ApiResponseExportTypesData {
    /**
     * 
     * @type {ExportTypesData}
     * @memberof ApiResponseExportTypesData
     */
    data?: ExportTypesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseExportTypesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseFraudBasicExclusionsModel
 */
export interface ApiResponseFraudBasicExclusionsModel {
    /**
     * 
     * @type {FraudBasicExclusionsModel}
     * @memberof ApiResponseFraudBasicExclusionsModel
     */
    data?: FraudBasicExclusionsModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseFraudBasicExclusionsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseFraudBasicSettingsModel
 */
export interface ApiResponseFraudBasicSettingsModel {
    /**
     * 
     * @type {FraudBasicSettingsModel}
     * @memberof ApiResponseFraudBasicSettingsModel
     */
    data?: FraudBasicSettingsModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseFraudBasicSettingsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseGetBackofficeUrlResponse
 */
export interface ApiResponseGetBackofficeUrlResponse {
    /**
     * 
     * @type {GetBackofficeUrlResponse}
     * @memberof ApiResponseGetBackofficeUrlResponse
     */
    data?: GetBackofficeUrlResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseGetBackofficeUrlResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseGetBatchFileContentsResponse
 */
export interface ApiResponseGetBatchFileContentsResponse {
    /**
     * 
     * @type {GetBatchFileContentsResponse}
     * @memberof ApiResponseGetBatchFileContentsResponse
     */
    data?: GetBatchFileContentsResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseGetBatchFileContentsResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseGetBatchFilesResponse
 */
export interface ApiResponseGetBatchFilesResponse {
    /**
     * 
     * @type {GetBatchFilesResponse}
     * @memberof ApiResponseGetBatchFilesResponse
     */
    data?: GetBatchFilesResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseGetBatchFilesResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserRolesResponse
 */
export interface ApiResponseGetUserRolesResponse {
    /**
     * 
     * @type {GetUserRolesResponse}
     * @memberof ApiResponseGetUserRolesResponse
     */
    data?: GetUserRolesResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseGetUserRolesResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseHCaptchaSettingsModel
 */
export interface ApiResponseHCaptchaSettingsModel {
    /**
     * 
     * @type {HCaptchaSettingsModel}
     * @memberof ApiResponseHCaptchaSettingsModel
     */
    data?: HCaptchaSettingsModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseHCaptchaSettingsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseIEnumerableBiller
 */
export interface ApiResponseIEnumerableBiller {
    /**
     * 
     * @type {Array<Biller>}
     * @memberof ApiResponseIEnumerableBiller
     */
    data?: Array<Biller>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseIEnumerableBiller
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseIEnumerablePaymentMethod
 */
export interface ApiResponseIEnumerablePaymentMethod {
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof ApiResponseIEnumerablePaymentMethod
     */
    data?: Array<PaymentMethod>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseIEnumerablePaymentMethod
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseIEnumerableWallet
 */
export interface ApiResponseIEnumerableWallet {
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof ApiResponseIEnumerableWallet
     */
    data?: Array<Wallet>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseIEnumerableWallet
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseInt32
 */
export interface ApiResponseInt32 {
    /**
     * 
     * @type {number}
     * @memberof ApiResponseInt32
     */
    data?: number;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseInt32
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseInvoiceConfigModel
 */
export interface ApiResponseInvoiceConfigModel {
    /**
     * 
     * @type {InvoiceConfigModel}
     * @memberof ApiResponseInvoiceConfigModel
     */
    data?: InvoiceConfigModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseInvoiceConfigModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseInvoiceModel
 */
export interface ApiResponseInvoiceModel {
    /**
     * 
     * @type {InvoiceModel}
     * @memberof ApiResponseInvoiceModel
     */
    data?: InvoiceModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseInvoiceModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseIssueCodeResponseModel
 */
export interface ApiResponseIssueCodeResponseModel {
    /**
     * 
     * @type {IssueCodeResponseModel}
     * @memberof ApiResponseIssueCodeResponseModel
     */
    data?: IssueCodeResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseIssueCodeResponseModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseKeyDescriptionListAntiFraudAction
 */
export interface ApiResponseKeyDescriptionListAntiFraudAction {
    /**
     * 
     * @type {KeyDescriptionListAntiFraudAction}
     * @memberof ApiResponseKeyDescriptionListAntiFraudAction
     */
    data?: KeyDescriptionListAntiFraudAction;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseKeyDescriptionListAntiFraudAction
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseKeyDescriptionListAntiFraudType
 */
export interface ApiResponseKeyDescriptionListAntiFraudType {
    /**
     * 
     * @type {KeyDescriptionListAntiFraudType}
     * @memberof ApiResponseKeyDescriptionListAntiFraudType
     */
    data?: KeyDescriptionListAntiFraudType;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseKeyDescriptionListAntiFraudType
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseKeyDescriptionListInvoiceStatusFilter
 */
export interface ApiResponseKeyDescriptionListInvoiceStatusFilter {
    /**
     * 
     * @type {KeyDescriptionListInvoiceStatusFilter}
     * @memberof ApiResponseKeyDescriptionListInvoiceStatusFilter
     */
    data?: KeyDescriptionListInvoiceStatusFilter;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseKeyDescriptionListInvoiceStatusFilter
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseKeyDescriptionListTxnSource
 */
export interface ApiResponseKeyDescriptionListTxnSource {
    /**
     * 
     * @type {KeyDescriptionListTxnSource}
     * @memberof ApiResponseKeyDescriptionListTxnSource
     */
    data?: KeyDescriptionListTxnSource;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseKeyDescriptionListTxnSource
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseKeyDescriptionListTxnSubSource
 */
export interface ApiResponseKeyDescriptionListTxnSubSource {
    /**
     * 
     * @type {KeyDescriptionListTxnSubSource}
     * @memberof ApiResponseKeyDescriptionListTxnSubSource
     */
    data?: KeyDescriptionListTxnSubSource;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseKeyDescriptionListTxnSubSource
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseKeyDescriptionListTxnSubType
 */
export interface ApiResponseKeyDescriptionListTxnSubType {
    /**
     * 
     * @type {KeyDescriptionListTxnSubType}
     * @memberof ApiResponseKeyDescriptionListTxnSubType
     */
    data?: KeyDescriptionListTxnSubType;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseKeyDescriptionListTxnSubType
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseKeyDescriptionListTxnType
 */
export interface ApiResponseKeyDescriptionListTxnType {
    /**
     * 
     * @type {KeyDescriptionListTxnType}
     * @memberof ApiResponseKeyDescriptionListTxnType
     */
    data?: KeyDescriptionListTxnType;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseKeyDescriptionListTxnType
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseLCRConfigurationModel
 */
export interface ApiResponseLCRConfigurationModel {
    /**
     * 
     * @type {LCRConfigurationModel}
     * @memberof ApiResponseLCRConfigurationModel
     */
    data?: LCRConfigurationModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseLCRConfigurationModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListBillerActionModel
 */
export interface ApiResponseListBillerActionModel {
    /**
     * 
     * @type {Array<BillerActionModel>}
     * @memberof ApiResponseListBillerActionModel
     */
    data?: Array<BillerActionModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListBillerActionModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListBillerFraudBasicSettingsModel
 */
export interface ApiResponseListBillerFraudBasicSettingsModel {
    /**
     * 
     * @type {Array<BillerFraudBasicSettingsModel>}
     * @memberof ApiResponseListBillerFraudBasicSettingsModel
     */
    data?: Array<BillerFraudBasicSettingsModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListBillerFraudBasicSettingsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListBillerModel
 */
export interface ApiResponseListBillerModel {
    /**
     * 
     * @type {Array<BillerModel>}
     * @memberof ApiResponseListBillerModel
     */
    data?: Array<BillerModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListBillerModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListCountryModel
 */
export interface ApiResponseListCountryModel {
    /**
     * 
     * @type {Array<CountryModel>}
     * @memberof ApiResponseListCountryModel
     */
    data?: Array<CountryModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListCountryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListCountryStateModel
 */
export interface ApiResponseListCountryStateModel {
    /**
     * 
     * @type {Array<CountryStateModel>}
     * @memberof ApiResponseListCountryStateModel
     */
    data?: Array<CountryStateModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListCountryStateModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListCurrencyModel
 */
export interface ApiResponseListCurrencyModel {
    /**
     * 
     * @type {Array<CurrencyModel>}
     * @memberof ApiResponseListCurrencyModel
     */
    data?: Array<CurrencyModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListCurrencyModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListFeedbackAreaModel
 */
export interface ApiResponseListFeedbackAreaModel {
    /**
     * 
     * @type {Array<FeedbackAreaModel>}
     * @memberof ApiResponseListFeedbackAreaModel
     */
    data?: Array<FeedbackAreaModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListFeedbackAreaModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListGetExportColumnsResponse
 */
export interface ApiResponseListGetExportColumnsResponse {
    /**
     * 
     * @type {Array<GetExportColumnsResponse>}
     * @memberof ApiResponseListGetExportColumnsResponse
     */
    data?: Array<GetExportColumnsResponse>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListGetExportColumnsResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListGetExportOptionsResponse
 */
export interface ApiResponseListGetExportOptionsResponse {
    /**
     * 
     * @type {Array<GetExportOptionsResponse>}
     * @memberof ApiResponseListGetExportOptionsResponse
     */
    data?: Array<GetExportOptionsResponse>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListGetExportOptionsResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListInvoicePaymentModel
 */
export interface ApiResponseListInvoicePaymentModel {
    /**
     * 
     * @type {Array<InvoicePaymentModel>}
     * @memberof ApiResponseListInvoicePaymentModel
     */
    data?: Array<InvoicePaymentModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListInvoicePaymentModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListMerchantModel
 */
export interface ApiResponseListMerchantModel {
    /**
     * 
     * @type {Array<MerchantModel>}
     * @memberof ApiResponseListMerchantModel
     */
    data?: Array<MerchantModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListMerchantModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListNoticeModel
 */
export interface ApiResponseListNoticeModel {
    /**
     * 
     * @type {Array<NoticeModel>}
     * @memberof ApiResponseListNoticeModel
     */
    data?: Array<NoticeModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListNoticeModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListPaymentPlanCommitmentModel
 */
export interface ApiResponseListPaymentPlanCommitmentModel {
    /**
     * 
     * @type {Array<PaymentPlanCommitmentModel>}
     * @memberof ApiResponseListPaymentPlanCommitmentModel
     */
    data?: Array<PaymentPlanCommitmentModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListPaymentPlanCommitmentModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListPaymentPlanOptionModel
 */
export interface ApiResponseListPaymentPlanOptionModel {
    /**
     * 
     * @type {Array<PaymentPlanOptionModel>}
     * @memberof ApiResponseListPaymentPlanOptionModel
     */
    data?: Array<PaymentPlanOptionModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListPaymentPlanOptionModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListStationeryShopAddressModel
 */
export interface ApiResponseListStationeryShopAddressModel {
    /**
     * 
     * @type {Array<StationeryShopAddressModel>}
     * @memberof ApiResponseListStationeryShopAddressModel
     */
    data?: Array<StationeryShopAddressModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListStationeryShopAddressModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListStationeryShopCategoryModel
 */
export interface ApiResponseListStationeryShopCategoryModel {
    /**
     * 
     * @type {Array<StationeryShopCategoryModel>}
     * @memberof ApiResponseListStationeryShopCategoryModel
     */
    data?: Array<StationeryShopCategoryModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListStationeryShopCategoryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseListStationeryShopProductModel
 */
export interface ApiResponseListStationeryShopProductModel {
    /**
     * 
     * @type {Array<StationeryShopProductModel>}
     * @memberof ApiResponseListStationeryShopProductModel
     */
    data?: Array<StationeryShopProductModel>;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseListStationeryShopProductModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseLoggedOnDetails
 */
export interface ApiResponseLoggedOnDetails {
    /**
     * 
     * @type {LoggedOnDetails}
     * @memberof ApiResponseLoggedOnDetails
     */
    data?: LoggedOnDetails;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseLoggedOnDetails
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseMerchantAddress
 */
export interface ApiResponseMerchantAddress {
    /**
     * 
     * @type {MerchantAddress}
     * @memberof ApiResponseMerchantAddress
     */
    data?: MerchantAddress;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseMerchantAddress
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseMessageEmailConfigurationModel
 */
export interface ApiResponseMessageEmailConfigurationModel {
    /**
     * 
     * @type {MessageEmailConfigurationModel}
     * @memberof ApiResponseMessageEmailConfigurationModel
     */
    data?: MessageEmailConfigurationModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseMessageEmailConfigurationModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseMessagingSettingsModel
 */
export interface ApiResponseMessagingSettingsModel {
    /**
     * 
     * @type {MessagingSettingsModel}
     * @memberof ApiResponseMessagingSettingsModel
     */
    data?: MessagingSettingsModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseMessagingSettingsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseMultiFactorAuthenticationSettings
 */
export interface ApiResponseMultiFactorAuthenticationSettings {
    /**
     * 
     * @type {MultiFactorAuthenticationSettings}
     * @memberof ApiResponseMultiFactorAuthenticationSettings
     */
    data?: MultiFactorAuthenticationSettings;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseMultiFactorAuthenticationSettings
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseNullableInt32
 */
export interface ApiResponseNullableInt32 {
    /**
     * 
     * @type {number}
     * @memberof ApiResponseNullableInt32
     */
    data?: number;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseNullableInt32
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseOrderTypesData
 */
export interface ApiResponseOrderTypesData {
    /**
     * 
     * @type {OrderTypesData}
     * @memberof ApiResponseOrderTypesData
     */
    data?: OrderTypesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseOrderTypesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListBatchFileInfo
 */
export interface ApiResponsePaginatedListBatchFileInfo {
    /**
     * 
     * @type {PaginatedListBatchFileInfo}
     * @memberof ApiResponsePaginatedListBatchFileInfo
     */
    data?: PaginatedListBatchFileInfo;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListBatchFileInfo
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListCustomerModel
 */
export interface ApiResponsePaginatedListCustomerModel {
    /**
     * 
     * @type {PaginatedListCustomerModel}
     * @memberof ApiResponsePaginatedListCustomerModel
     */
    data?: PaginatedListCustomerModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListCustomerModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListDataVaultSummaryModel
 */
export interface ApiResponsePaginatedListDataVaultSummaryModel {
    /**
     * 
     * @type {PaginatedListDataVaultSummaryModel}
     * @memberof ApiResponsePaginatedListDataVaultSummaryModel
     */
    data?: PaginatedListDataVaultSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListDataVaultSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListFraudRuleModel
 */
export interface ApiResponsePaginatedListFraudRuleModel {
    /**
     * 
     * @type {PaginatedListFraudRuleModel}
     * @memberof ApiResponsePaginatedListFraudRuleModel
     */
    data?: PaginatedListFraudRuleModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListFraudRuleModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListInvoiceSearchResponseModel
 */
export interface ApiResponsePaginatedListInvoiceSearchResponseModel {
    /**
     * 
     * @type {PaginatedListInvoiceSearchResponseModel}
     * @memberof ApiResponsePaginatedListInvoiceSearchResponseModel
     */
    data?: PaginatedListInvoiceSearchResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListInvoiceSearchResponseModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListPaymentPlanViewModel
 */
export interface ApiResponsePaginatedListPaymentPlanViewModel {
    /**
     * 
     * @type {PaginatedListPaymentPlanViewModel}
     * @memberof ApiResponsePaginatedListPaymentPlanViewModel
     */
    data?: PaginatedListPaymentPlanViewModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListPaymentPlanViewModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListRequestSummaryModel
 */
export interface ApiResponsePaginatedListRequestSummaryModel {
    /**
     * 
     * @type {PaginatedListRequestSummaryModel}
     * @memberof ApiResponsePaginatedListRequestSummaryModel
     */
    data?: PaginatedListRequestSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListRequestSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListScheduledPaymentModel
 */
export interface ApiResponsePaginatedListScheduledPaymentModel {
    /**
     * 
     * @type {PaginatedListScheduledPaymentModel}
     * @memberof ApiResponsePaginatedListScheduledPaymentModel
     */
    data?: PaginatedListScheduledPaymentModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListScheduledPaymentModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListStationeryShopOrderSummaryModel
 */
export interface ApiResponsePaginatedListStationeryShopOrderSummaryModel {
    /**
     * 
     * @type {PaginatedListStationeryShopOrderSummaryModel}
     * @memberof ApiResponsePaginatedListStationeryShopOrderSummaryModel
     */
    data?: PaginatedListStationeryShopOrderSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListStationeryShopOrderSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListSubscriptionSummaryModel
 */
export interface ApiResponsePaginatedListSubscriptionSummaryModel {
    /**
     * 
     * @type {PaginatedListSubscriptionSummaryModel}
     * @memberof ApiResponsePaginatedListSubscriptionSummaryModel
     */
    data?: PaginatedListSubscriptionSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListSubscriptionSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListTransactionSummaryModel
 */
export interface ApiResponsePaginatedListTransactionSummaryModel {
    /**
     * 
     * @type {PaginatedListTransactionSummaryModel}
     * @memberof ApiResponsePaginatedListTransactionSummaryModel
     */
    data?: PaginatedListTransactionSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListTransactionSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaginatedListUserSummaryModel
 */
export interface ApiResponsePaginatedListUserSummaryModel {
    /**
     * 
     * @type {PaginatedListUserSummaryModel}
     * @memberof ApiResponsePaginatedListUserSummaryModel
     */
    data?: PaginatedListUserSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaginatedListUserSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentBreakdown
 */
export interface ApiResponsePaymentBreakdown {
    /**
     * 
     * @type {PaymentBreakdown}
     * @memberof ApiResponsePaymentBreakdown
     */
    data?: PaymentBreakdown;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentBreakdown
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentPlanCustomerSummary
 */
export interface ApiResponsePaymentPlanCustomerSummary {
    /**
     * 
     * @type {PaymentPlanCustomerSummary}
     * @memberof ApiResponsePaymentPlanCustomerSummary
     */
    data?: PaymentPlanCustomerSummary;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentPlanCustomerSummary
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentPlanDetailsModel
 */
export interface ApiResponsePaymentPlanDetailsModel {
    /**
     * 
     * @type {PaymentPlanDetailsModel}
     * @memberof ApiResponsePaymentPlanDetailsModel
     */
    data?: PaymentPlanDetailsModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentPlanDetailsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentPlanModel
 */
export interface ApiResponsePaymentPlanModel {
    /**
     * 
     * @type {PaymentPlanModel}
     * @memberof ApiResponsePaymentPlanModel
     */
    data?: PaymentPlanModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentPlanModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentPlanOptionModel
 */
export interface ApiResponsePaymentPlanOptionModel {
    /**
     * 
     * @type {PaymentPlanOptionModel}
     * @memberof ApiResponsePaymentPlanOptionModel
     */
    data?: PaymentPlanOptionModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentPlanOptionModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentProcessedTxnModel
 */
export interface ApiResponsePaymentProcessedTxnModel {
    /**
     * 
     * @type {PaymentProcessedTxnModel}
     * @memberof ApiResponsePaymentProcessedTxnModel
     */
    data?: PaymentProcessedTxnModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentProcessedTxnModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentRequestSettings
 */
export interface ApiResponsePaymentRequestSettings {
    /**
     * 
     * @type {PaymentRequestSettings}
     * @memberof ApiResponsePaymentRequestSettings
     */
    data?: PaymentRequestSettings;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentRequestSettings
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponsePaymentRequestStatus
 */
export interface ApiResponsePaymentRequestStatus {
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePaymentRequestStatus
     */
    data?: ApiResponsePaymentRequestStatusDataEnum;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponsePaymentRequestStatus
     */
    errors?: Array<ApiError>;
}

/**
    * @export
    * @enum {string}
    */
export type ApiResponsePaymentRequestStatusDataEnum = 'Saved' |
    'Sent' |
    'Unpaid' |
    'Registered' |
    'Cancelled' |
    'PaidInFull' |
    'PaidPartially' |
    'Refunded' |
    'RefundedPartially' |
    'OverDue' |
    'Expired' |
    'AuthOnly';

/**
 * 
 * @export
 * @interface ApiResponseProductTooltipModel
 */
export interface ApiResponseProductTooltipModel {
    /**
     * 
     * @type {ProductTooltipModel}
     * @memberof ApiResponseProductTooltipModel
     */
    data?: ProductTooltipModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseProductTooltipModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseReceiptDetailsModel
 */
export interface ApiResponseReceiptDetailsModel {
    /**
     * 
     * @type {ReceiptDetailsModel}
     * @memberof ApiResponseReceiptDetailsModel
     */
    data?: ReceiptDetailsModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseReceiptDetailsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseRequestActionsData
 */
export interface ApiResponseRequestActionsData {
    /**
     * 
     * @type {RequestActionsData}
     * @memberof ApiResponseRequestActionsData
     */
    data?: RequestActionsData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseRequestActionsData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseRequestModel
 */
export interface ApiResponseRequestModel {
    /**
     * 
     * @type {RequestModel}
     * @memberof ApiResponseRequestModel
     */
    data?: RequestModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseRequestModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseRequestResponse
 */
export interface ApiResponseRequestResponse {
    /**
     * 
     * @type {RequestResponse}
     * @memberof ApiResponseRequestResponse
     */
    data?: RequestResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseRequestResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseRequestStatusesData
 */
export interface ApiResponseRequestStatusesData {
    /**
     * 
     * @type {RequestStatusesData}
     * @memberof ApiResponseRequestStatusesData
     */
    data?: RequestStatusesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseRequestStatusesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseScheduledPaymentModel
 */
export interface ApiResponseScheduledPaymentModel {
    /**
     * 
     * @type {ScheduledPaymentModel}
     * @memberof ApiResponseScheduledPaymentModel
     */
    data?: ScheduledPaymentModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseScheduledPaymentModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseSettlementReportModel
 */
export interface ApiResponseSettlementReportModel {
    /**
     * 
     * @type {SettlementReportModel}
     * @memberof ApiResponseSettlementReportModel
     */
    data?: SettlementReportModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseSettlementReportModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseStationeryShopOrderModel
 */
export interface ApiResponseStationeryShopOrderModel {
    /**
     * 
     * @type {StationeryShopOrderModel}
     * @memberof ApiResponseStationeryShopOrderModel
     */
    data?: StationeryShopOrderModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseStationeryShopOrderModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseStationeryShopOrderSummaryModel
 */
export interface ApiResponseStationeryShopOrderSummaryModel {
    /**
     * 
     * @type {StationeryShopOrderSummaryModel}
     * @memberof ApiResponseStationeryShopOrderSummaryModel
     */
    data?: StationeryShopOrderSummaryModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseStationeryShopOrderSummaryModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseStationeryShopProductModel
 */
export interface ApiResponseStationeryShopProductModel {
    /**
     * 
     * @type {StationeryShopProductModel}
     * @memberof ApiResponseStationeryShopProductModel
     */
    data?: StationeryShopProductModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseStationeryShopProductModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseString
 */
export interface ApiResponseString {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    data?: string;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseString
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseSubscriptionFrequenciesData
 */
export interface ApiResponseSubscriptionFrequenciesData {
    /**
     * 
     * @type {SubscriptionFrequenciesData}
     * @memberof ApiResponseSubscriptionFrequenciesData
     */
    data?: SubscriptionFrequenciesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseSubscriptionFrequenciesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseSubscriptionModel
 */
export interface ApiResponseSubscriptionModel {
    /**
     * 
     * @type {SubscriptionModel}
     * @memberof ApiResponseSubscriptionModel
     */
    data?: SubscriptionModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseSubscriptionModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseSubscriptionPaymentStatusData
 */
export interface ApiResponseSubscriptionPaymentStatusData {
    /**
     * 
     * @type {SubscriptionPaymentStatusData}
     * @memberof ApiResponseSubscriptionPaymentStatusData
     */
    data?: SubscriptionPaymentStatusData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseSubscriptionPaymentStatusData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseSubscriptionStatusesData
 */
export interface ApiResponseSubscriptionStatusesData {
    /**
     * 
     * @type {SubscriptionStatusesData}
     * @memberof ApiResponseSubscriptionStatusesData
     */
    data?: SubscriptionStatusesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseSubscriptionStatusesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseSurchargeRuleSet
 */
export interface ApiResponseSurchargeRuleSet {
    /**
     * 
     * @type {SurchargeRuleSet}
     * @memberof ApiResponseSurchargeRuleSet
     */
    data?: SurchargeRuleSet;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseSurchargeRuleSet
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseTermsModel
 */
export interface ApiResponseTermsModel {
    /**
     * 
     * @type {TermsModel}
     * @memberof ApiResponseTermsModel
     */
    data?: TermsModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseTermsModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseTransactionDetailModel
 */
export interface ApiResponseTransactionDetailModel {
    /**
     * 
     * @type {TransactionDetailModel}
     * @memberof ApiResponseTransactionDetailModel
     */
    data?: TransactionDetailModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseTransactionDetailModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseTransactionSourcesData
 */
export interface ApiResponseTransactionSourcesData {
    /**
     * 
     * @type {TransactionSourcesData}
     * @memberof ApiResponseTransactionSourcesData
     */
    data?: TransactionSourcesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseTransactionSourcesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseTransactionTypesData
 */
export interface ApiResponseTransactionTypesData {
    /**
     * 
     * @type {TransactionTypesData}
     * @memberof ApiResponseTransactionTypesData
     */
    data?: TransactionTypesData;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseTransactionTypesData
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseUploadAntiFraudRulesFileResponse
 */
export interface ApiResponseUploadAntiFraudRulesFileResponse {
    /**
     * 
     * @type {UploadAntiFraudRulesFileResponse}
     * @memberof ApiResponseUploadAntiFraudRulesFileResponse
     */
    data?: UploadAntiFraudRulesFileResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseUploadAntiFraudRulesFileResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseUploadBatchResponse
 */
export interface ApiResponseUploadBatchResponse {
    /**
     * 
     * @type {UploadBatchResponse}
     * @memberof ApiResponseUploadBatchResponse
     */
    data?: UploadBatchResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseUploadBatchResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseUploadBillerCRNLookupRulesFileResponse
 */
export interface ApiResponseUploadBillerCRNLookupRulesFileResponse {
    /**
     * 
     * @type {UploadBillerCRNLookupRulesFileResponse}
     * @memberof ApiResponseUploadBillerCRNLookupRulesFileResponse
     */
    data?: UploadBillerCRNLookupRulesFileResponse;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseUploadBillerCRNLookupRulesFileResponse
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseUserModel
 */
export interface ApiResponseUserModel {
    /**
     * 
     * @type {UserModel}
     * @memberof ApiResponseUserModel
     */
    data?: UserModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseUserModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseUserProfileModel
 */
export interface ApiResponseUserProfileModel {
    /**
     * 
     * @type {UserProfileModel}
     * @memberof ApiResponseUserProfileModel
     */
    data?: UserProfileModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseUserProfileModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseValidateCodeResponseModel
 */
export interface ApiResponseValidateCodeResponseModel {
    /**
     * 
     * @type {ValidateCodeResponseModel}
     * @memberof ApiResponseValidateCodeResponseModel
     */
    data?: ValidateCodeResponseModel;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseValidateCodeResponseModel
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseWallet
 */
export interface ApiResponseWallet {
    /**
     * 
     * @type {Wallet}
     * @memberof ApiResponseWallet
     */
    data?: Wallet;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseWallet
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface ApiResponseWalletSet
 */
export interface ApiResponseWalletSet {
    /**
     * 
     * @type {WalletSet}
     * @memberof ApiResponseWalletSet
     */
    data?: WalletSet;
    /**
     * 
     * @type {Array<ApiError>}
     * @memberof ApiResponseWalletSet
     */
    errors?: Array<ApiError>;
}
/**
 * 
 * @export
 * @interface AppendBatchFileRequest
 */
export interface AppendBatchFileRequest {
    /**
     * 
     * @type {number}
     * @memberof AppendBatchFileRequest
     */
    batchFileId?: number;
}
/**
 * 
 * @export
 * @interface BankAccountDetails
 */
export interface BankAccountDetails {
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetails
     */
    bsb?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetails
     */
    accountNumber?: string;
}
/**
 * 
 * @export
 * @interface Batch
 */
export interface Batch {
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    batchPriority?: BatchBatchPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    batchState?: BatchBatchStateEnum;
    /**
     * 
     * @type {Array<BatchRecord>}
     * @memberof Batch
     */
    records?: Array<BatchRecord>;
}

/**
    * @export
    * @enum {string}
    */
export type BatchBatchPriorityEnum = 'UNSPECIFIED' |
    'LOWEST' |
    'BELOWNORMAL' |
    'NORMAL' |
    'ABOVENORMAL' |
    'HIGHEST';
/**
    * @export
    * @enum {string}
    */
export type BatchBatchStateEnum = 'UNSPECIFIED' |
    'LOADING' |
    'PENDING' |
    'INPROGRESS' |
    'COMPLETE' |
    'ERROR' |
    'DELETED' |
    'BATCHMANAGER' |
    'DONOTPROCESS' |
    'LOADING_BATCHMANAGER' |
    'LOADING_DV' |
    'COMPLETE_DV' |
    'LOADING_PAYMENTREQUEST' |
    'COMPLETE_PAYMENTREQUEST';

/**
 * 
 * @export
 * @interface BatchFileContents
 */
export interface BatchFileContents {
    /**
     * 
     * @type {number}
     * @memberof BatchFileContents
     */
    recordId?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    merchantReference?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    emailAddress?: string;
    /**
     * 
     * @type {CardDetails}
     * @memberof BatchFileContents
     */
    card?: CardDetails;
    /**
     * 
     * @type {number}
     * @memberof BatchFileContents
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    authType?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    txnType?: BatchFileContentsTxnTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    txnSubType?: BatchFileContentsTxnSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchFileContents
     */
    orderType?: BatchFileContentsOrderTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type BatchFileContentsTxnTypeEnum = 'UNSPECIFIED' |
    'MAIL_ORDER' |
    'TELEPHONE_ORDER' |
    'ECOMMERCE' |
    'CARD_PRESENT' |
    'CALL_CENTRE' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';
/**
    * @export
    * @enum {string}
    */
export type BatchFileContentsTxnSubTypeEnum = 'UNSPECIFIED' |
    'SINGLE' |
    'RECURRING';
/**
    * @export
    * @enum {string}
    */
export type BatchFileContentsOrderTypeEnum = 'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'TELEPHONE' |
    'RECURRING' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface BatchFileInfo
 */
export interface BatchFileInfo {
    /**
     * 
     * @type {string}
     * @memberof BatchFileInfo
     */
    filename?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchFileInfo
     */
    fileId?: number;
    /**
     * 
     * @type {BatchFileStatus}
     * @memberof BatchFileInfo
     */
    status?: BatchFileStatus;
    /**
     * 
     * @type {string}
     * @memberof BatchFileInfo
     */
    batchType?: BatchFileInfoBatchTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BatchFileInfo
     */
    isFirstAttemptCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BatchFileInfo
     */
    isDeclineMgr?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BatchFileInfo
     */
    uploadedRecordCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchFileInfo
     */
    approvedRecordCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchFileInfo
     */
    uploadedValue?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchFileInfo
     */
    approvedValue?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchFileInfo
     */
    submittedDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileInfo
     */
    processDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileInfo
     */
    scheduledDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchFileInfo
     */
    updatedDateTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export type BatchFileInfoBatchTypeEnum = 'UNSPECIFIED' |
    'BPOINT' |
    'VIRTUALPOS' |
    'VIRTUALPOSPLUS' |
    'MICROMERCHANT' |
    'BECS' |
    'PAYMENTREQUEST' |
    'KENANCC' |
    'KENANDD' |
    'TDE' |
    'BPOINT_GROUPHEADERS' |
    'SECUREPAY' |
    'QUICKSTREAMPAY' |
    'PAYRIX' |
    'CSV';

/**
 * 
 * @export
 * @interface BatchFileStatus
 */
export interface BatchFileStatus {
    /**
     * 
     * @type {string}
     * @memberof BatchFileStatus
     */
    key?: BatchFileStatusKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchFileStatus
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type BatchFileStatusKeyEnum = 'UNSPECIFIED' |
    'LOADING' |
    'PENDING' |
    'INPROGRESS' |
    'COMPLETE' |
    'ERROR' |
    'DELETED' |
    'BATCHMANAGER' |
    'DONOTPROCESS' |
    'LOADING_BATCHMANAGER' |
    'LOADING_DV' |
    'COMPLETE_DV' |
    'LOADING_PAYMENTREQUEST' |
    'COMPLETE_PAYMENTREQUEST';

/**
 * 
 * @export
 * @interface BatchProcessResponse
 */
export interface BatchProcessResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchProcessResponse
     */
    batchFileId?: number;
    /**
     * 
     * @type {Array<BatchValidationResult>}
     * @memberof BatchProcessResponse
     */
    batchFileValidationResult?: Array<BatchValidationResult>;
    /**
     * 
     * @type {boolean}
     * @memberof BatchProcessResponse
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BatchProcessResponse
     */
    hasWarnings?: boolean;
}
/**
 * 
 * @export
 * @interface BatchRecord
 */
export interface BatchRecord {
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    merchantReference?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    emailAddress?: string;
    /**
     * 
     * @type {CardDetails}
     * @memberof BatchRecord
     */
    card?: CardDetails;
    /**
     * 
     * @type {number}
     * @memberof BatchRecord
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    authType?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    txnType?: BatchRecordTxnTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    txnSubType?: BatchRecordTxnSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BatchRecord
     */
    orderType?: BatchRecordOrderTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type BatchRecordTxnTypeEnum = 'UNSPECIFIED' |
    'MAIL_ORDER' |
    'TELEPHONE_ORDER' |
    'ECOMMERCE' |
    'CARD_PRESENT' |
    'CALL_CENTRE' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';
/**
    * @export
    * @enum {string}
    */
export type BatchRecordTxnSubTypeEnum = 'UNSPECIFIED' |
    'SINGLE' |
    'RECURRING';
/**
    * @export
    * @enum {string}
    */
export type BatchRecordOrderTypeEnum = 'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'TELEPHONE' |
    'RECURRING' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface BatchValidationResult
 */
export interface BatchValidationResult {
    /**
     * 
     * @type {string}
     * @memberof BatchValidationResult
     */
    filename?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchValidationResult
     */
    errors?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchValidationResult
     */
    warnings?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BatchValidationResult
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BatchValidationResult
     */
    hasWarnings?: boolean;
}
/**
 * 
 * @export
 * @interface Biller
 */
export interface Biller {
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    merchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Biller
     */
    menuSequence?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Biller
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    billerType?: string;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    billerCodeLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    billName?: string;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    billerDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    billerPrompt?: string;
    /**
     * 
     * @type {LimitsInt32}
     * @memberof Biller
     */
    paymentLimits?: LimitsInt32;
    /**
     * 
     * @type {number}
     * @memberof Biller
     */
    fixedAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    tokeniseTxnLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    tokeniseTxnDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Biller
     */
    tokeniseApprovedOnlyTxns?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    helpText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Biller
     */
    deferredPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Biller
     */
    captchaBankEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Biller
     */
    captchaEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Biller
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Biller
     */
    billerCodeWithName?: string;
    /**
     * 
     * @type {Array<CRNLibraryModel>}
     * @memberof Biller
     */
    crnNameList?: Array<CRNLibraryModel>;
    /**
     * 
     * @type {CustomerReferenceNumberModel}
     * @memberof Biller
     */
    acceptedCrn1?: CustomerReferenceNumberModel;
    /**
     * 
     * @type {CustomerReferenceNumberModel}
     * @memberof Biller
     */
    acceptedCrn2?: CustomerReferenceNumberModel;
    /**
     * 
     * @type {CustomerReferenceNumberModel}
     * @memberof Biller
     */
    acceptedCrn3?: CustomerReferenceNumberModel;
    /**
     * 
     * @type {TransactionTokenisationMode}
     * @memberof Biller
     */
    txnTokenisationMode?: TransactionTokenisationMode;
    /**
     * 
     * @type {boolean}
     * @memberof Biller
     */
    isTopUpsEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface BillerActionModel
 */
export interface BillerActionModel {
    /**
     * 
     * @type {number}
     * @memberof BillerActionModel
     */
    billerId?: number;
    /**
     * 
     * @type {number}
     * @memberof BillerActionModel
     */
    action?: number;
    /**
     * 
     * @type {number}
     * @memberof BillerActionModel
     */
    merchantId?: number;
    /**
     * 
     * @type {string}
     * @memberof BillerActionModel
     */
    actionName?: string;
}
/**
 * 
 * @export
 * @interface BillerAdditionalPayment
 */
export interface BillerAdditionalPayment {
    /**
     * 
     * @type {string}
     * @memberof BillerAdditionalPayment
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerAdditionalPayment
     */
    reportableDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerAdditionalPayment
     */
    amountSetting?: BillerAdditionalPaymentAmountSettingEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BillerAdditionalPayment
     */
    optedInByDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillerAdditionalPayment
     */
    optOutAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BillerAdditionalPayment
     */
    prePopulatedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof BillerAdditionalPayment
     */
    listOrder?: number;
}

/**
    * @export
    * @enum {string}
    */
export type BillerAdditionalPaymentAmountSettingEnum = 'Variable' |
    'Fixed';

/**
 * 
 * @export
 * @interface BillerCardModel
 */
export interface BillerCardModel {
    /**
     * 
     * @type {string}
     * @memberof BillerCardModel
     */
    cardTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerCardModel
     */
    displayName?: string;
    /**
     * 
     * @type {number}
     * @memberof BillerCardModel
     */
    surchargePercentagePoints?: number;
    /**
     * 
     * @type {number}
     * @memberof BillerCardModel
     */
    surchargeFixed?: number;
    /**
     * 
     * @type {number}
     * @memberof BillerCardModel
     */
    surchargeMinimum?: number;
    /**
     * 
     * @type {string}
     * @memberof BillerCardModel
     */
    cardSubType?: BillerCardModelCardSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BillerCardModel
     */
    cardLocalisation?: BillerCardModelCardLocalisationEnum;
}

/**
    * @export
    * @enum {string}
    */
export type BillerCardModelCardSubTypeEnum = 'UNSPECIFIED' |
    'DEBIT' |
    'CREDIT' |
    'CHARGE_CARD';
/**
    * @export
    * @enum {string}
    */
export type BillerCardModelCardLocalisationEnum = 'UNSPECIFIED' |
    'LOCAL' |
    'INTERNATIONAL';

/**
 * 
 * @export
 * @interface BillerFraudBasicSettingsModel
 */
export interface BillerFraudBasicSettingsModel {
    /**
     * 
     * @type {string}
     * @memberof BillerFraudBasicSettingsModel
     */
    billerCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillerFraudBasicSettingsModel
     */
    allowHppAnonymousAccess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillerFraudBasicSettingsModel
     */
    billerDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillerFraudBasicSettingsModel
     */
    allowCaptcha?: boolean;
}
/**
 * 
 * @export
 * @interface BillerModel
 */
export interface BillerModel {
    /**
     * 
     * @type {number}
     * @memberof BillerModel
     */
    billerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BillerModel
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillerModel
     */
    isSurchargeEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    billerType?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    billerCodeLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    billName?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    billerDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    billerPrompt?: string;
    /**
     * 
     * @type {Array<BillerCardModel>}
     * @memberof BillerModel
     */
    acceptedCards?: Array<BillerCardModel>;
    /**
     * 
     * @type {CustomerReferenceNumberModel}
     * @memberof BillerModel
     */
    acceptedCrn1?: CustomerReferenceNumberModel;
    /**
     * 
     * @type {CustomerReferenceNumberModel}
     * @memberof BillerModel
     */
    acceptedCrn2?: CustomerReferenceNumberModel;
    /**
     * 
     * @type {CustomerReferenceNumberModel}
     * @memberof BillerModel
     */
    acceptedCrn3?: CustomerReferenceNumberModel;
    /**
     * 
     * @type {number}
     * @memberof BillerModel
     */
    amountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof BillerModel
     */
    amountMax?: number;
    /**
     * 
     * @type {number}
     * @memberof BillerModel
     */
    fixedAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    tokeniseTxnLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    tokeniseTxnDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillerModel
     */
    tokeniseApprovedOnlyTxns?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillerModel
     */
    helpText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillerModel
     */
    deferredPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillerModel
     */
    captchaBankEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillerModel
     */
    captchaEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface Branding
 */
export interface Branding {
    /**
     * 
     * @type {boolean}
     * @memberof Branding
     */
    isDraft?: boolean;
    /**
     * 
     * @type {BrandingReturnBar}
     * @memberof Branding
     */
    returnBar?: BrandingReturnBar;
    /**
     * 
     * @type {BrandingBanner}
     * @memberof Branding
     */
    banner?: BrandingBanner;
    /**
     * 
     * @type {BrandingMenu}
     * @memberof Branding
     */
    menu?: BrandingMenu;
    /**
     * 
     * @type {BrandingPage}
     * @memberof Branding
     */
    page?: BrandingPage;
}
/**
 * 
 * @export
 * @interface BrandingBanner
 */
export interface BrandingBanner {
    /**
     * 
     * @type {string}
     * @memberof BrandingBanner
     */
    fontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingBanner
     */
    backColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingBanner
     */
    logoFilename?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingBanner
     */
    logoUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandingBanner
     */
    logoFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandingBanner
     */
    logoPosition?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingBanner
     */
    height?: string;
}
/**
 * 
 * @export
 * @interface BrandingMenu
 */
export interface BrandingMenu {
    /**
     * 
     * @type {boolean}
     * @memberof BrandingMenu
     */
    showTerms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingMenu
     */
    showPrivacy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingMenu
     */
    showExtraInfo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandingMenu
     */
    fontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingMenu
     */
    backColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingMenu
     */
    underlineColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingMenu
     */
    mobileColour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingMenu
     */
    showTopBorder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrandingMenu
     */
    showBottomBorder?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandingMenu
     */
    borderColour?: string;
}
/**
 * 
 * @export
 * @interface BrandingPage
 */
export interface BrandingPage {
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    fontFamily?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    fontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    backColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    sectionColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    primaryButtonFontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    primaryButtonColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    secondaryButtonFontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingPage
     */
    secondaryButtonColour?: string;
}
/**
 * 
 * @export
 * @interface BrandingReturnBar
 */
export interface BrandingReturnBar {
    /**
     * 
     * @type {string}
     * @memberof BrandingReturnBar
     */
    fontColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingReturnBar
     */
    backColour?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandingReturnBar
     */
    height?: string;
}
/**
 * 
 * @export
 * @interface Breakdown
 */
export interface Breakdown {
    /**
     * 
     * @type {number}
     * @memberof Breakdown
     */
    paymentPlanOptionId?: number;
    /**
     * 
     * @type {string}
     * @memberof Breakdown
     */
    paymentPlanOptionKey?: string;
    /**
     * 
     * @type {number}
     * @memberof Breakdown
     */
    firstPaymentAmount?: number;
    /**
     * 
     * @type {SchedulerSettings}
     * @memberof Breakdown
     */
    schedulerSettings?: SchedulerSettings;
}
/**
 * 
 * @export
 * @interface BreakdownRequest
 */
export interface BreakdownRequest {
    /**
     * 
     * @type {string}
     * @memberof BreakdownRequest
     */
    merchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BreakdownRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BreakdownRequest
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {number}
     * @memberof BreakdownRequest
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface BusinessDetails
 */
export interface BusinessDetails {
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    tradingName?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    companyId1?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    companyId2?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessDetails
     */
    countryCode?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessDetails
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface CRNLibraryModel
 */
export interface CRNLibraryModel {
    /**
     * 
     * @type {number}
     * @memberof CRNLibraryModel
     */
    crnLibraryId?: number;
    /**
     * 
     * @type {string}
     * @memberof CRNLibraryModel
     */
    crnName?: string;
    /**
     * 
     * @type {string}
     * @memberof CRNLibraryModel
     */
    crnFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof CRNLibraryModel
     */
    type?: CRNLibraryModelTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type CRNLibraryModelTypeEnum = 'UNSPECIFIED' |
    'REFERENCE' |
    'MEDIUM';

/**
 * 
 * @export
 * @interface CancelInvoiceRequest
 */
export interface CancelInvoiceRequest {
    /**
     * 
     * @type {number}
     * @memberof CancelInvoiceRequest
     */
    invoiceId?: number;
}
/**
 * 
 * @export
 * @interface CardAmountBreakdownModel
 */
export interface CardAmountBreakdownModel {
    /**
     * 
     * @type {CardTypeDetails}
     * @memberof CardAmountBreakdownModel
     */
    card?: CardTypeDetails;
    /**
     * 
     * @type {number}
     * @memberof CardAmountBreakdownModel
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CardAmountBreakdownModel
     */
    amountOriginal?: number;
    /**
     * 
     * @type {number}
     * @memberof CardAmountBreakdownModel
     */
    amountSurcharge?: number;
}
/**
 * 
 * @export
 * @interface CardDetails
 */
export interface CardDetails {
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    detokenizedNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof CardDetails
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    cvc?: string;
}
/**
 * 
 * @export
 * @interface CardSurchargeDetails
 */
export interface CardSurchargeDetails {
    /**
     * 
     * @type {string}
     * @memberof CardSurchargeDetails
     */
    type?: CardSurchargeDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CardSurchargeDetails
     */
    subType?: CardSurchargeDetailsSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CardSurchargeDetails
     */
    localisation?: CardSurchargeDetailsLocalisationEnum;
    /**
     * 
     * @type {number}
     * @memberof CardSurchargeDetails
     */
    originalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CardSurchargeDetails
     */
    surchargeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CardSurchargeDetails
     */
    totalAmount?: number;
}

/**
    * @export
    * @enum {string}
    */
export type CardSurchargeDetailsTypeEnum = 'UNSPECIFIED' |
    'AMEX_LOCAL' |
    'MASTERCARD_LOCAL' |
    'VISA_LOCAL' |
    'DINERS_LOCAL' |
    'AMEX_INTL' |
    'MASTERCARD_INTL' |
    'VISA_INTL' |
    'DINERS_INTL' |
    'JCB_INTL' |
    'PAYPAL' |
    'DIRECT_ENTRY' |
    'CASH' |
    'CHEQUE' |
    'NONE' |
    'CHINA_UNION_PAY' |
    'MONEY_ORDER' |
    'INDUSTRY_CARD' |
    'KLARNA' |
    'EFTPOS';
/**
    * @export
    * @enum {string}
    */
export type CardSurchargeDetailsSubTypeEnum = 'UNSPECIFIED' |
    'DEBIT' |
    'CREDIT' |
    'CHARGE_CARD';
/**
    * @export
    * @enum {string}
    */
export type CardSurchargeDetailsLocalisationEnum = 'UNSPECIFIED' |
    'LOCAL' |
    'INTERNATIONAL';

/**
 * 
 * @export
 * @interface CardSurchargeInput
 */
export interface CardSurchargeInput {
    /**
     * 
     * @type {string}
     * @memberof CardSurchargeInput
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CardSurchargeInput
     */
    billerCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CardSurchargeInput
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CardSurchargeInput
     */
    cardBin?: string;
}
/**
 * 
 * @export
 * @interface CardTypeDetails
 */
export interface CardTypeDetails {
    /**
     * 
     * @type {string}
     * @memberof CardTypeDetails
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CardTypeDetails
     */
    key?: CardTypeDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof CardTypeDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type CardTypeDetailsKeyEnum = 'UNSPECIFIED' |
    'AMEX_LOCAL' |
    'MASTERCARD_LOCAL' |
    'VISA_LOCAL' |
    'DINERS_LOCAL' |
    'AMEX_INTL' |
    'MASTERCARD_INTL' |
    'VISA_INTL' |
    'DINERS_INTL' |
    'JCB_INTL' |
    'PAYPAL' |
    'DIRECT_ENTRY' |
    'CASH' |
    'CHEQUE' |
    'NONE' |
    'CHINA_UNION_PAY' |
    'MONEY_ORDER' |
    'INDUSTRY_CARD' |
    'KLARNA' |
    'EFTPOS';

/**
 * 
 * @export
 * @interface CardTypeModel
 */
export interface CardTypeModel {
    /**
     * 
     * @type {string}
     * @memberof CardTypeModel
     */
    key?: CardTypeModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof CardTypeModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type CardTypeModelKeyEnum = 'UNSPECIFIED' |
    'AMEX_LOCAL' |
    'MASTERCARD_LOCAL' |
    'VISA_LOCAL' |
    'DINERS_LOCAL' |
    'AMEX_INTL' |
    'MASTERCARD_INTL' |
    'VISA_INTL' |
    'DINERS_INTL' |
    'JCB_INTL' |
    'PAYPAL' |
    'DIRECT_ENTRY' |
    'CASH' |
    'CHEQUE' |
    'NONE' |
    'CHINA_UNION_PAY' |
    'MONEY_ORDER' |
    'INDUSTRY_CARD' |
    'KLARNA' |
    'EFTPOS';

/**
 * 
 * @export
 * @interface CardTypesData
 */
export interface CardTypesData {
    /**
     * 
     * @type {Array<CardTypeModel>}
     * @memberof CardTypesData
     */
    items?: Array<CardTypeModel>;
    /**
     * 
     * @type {string}
     * @memberof CardTypesData
     */
    defaultKey?: CardTypesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type CardTypesDataDefaultKeyEnum = 'UNSPECIFIED' |
    'AMEX_LOCAL' |
    'MASTERCARD_LOCAL' |
    'VISA_LOCAL' |
    'DINERS_LOCAL' |
    'AMEX_INTL' |
    'MASTERCARD_INTL' |
    'VISA_INTL' |
    'DINERS_INTL' |
    'JCB_INTL' |
    'PAYPAL' |
    'DIRECT_ENTRY' |
    'CASH' |
    'CHEQUE' |
    'NONE' |
    'CHINA_UNION_PAY' |
    'MONEY_ORDER' |
    'INDUSTRY_CARD' |
    'KLARNA' |
    'EFTPOS';

/**
 * 
 * @export
 * @interface CheckSumModel
 */
export interface CheckSumModel {
    /**
     * 
     * @type {number}
     * @memberof CheckSumModel
     */
    checkSumId?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckSumModel
     */
    checkSumName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckSumModel
     */
    visible?: boolean;
}
/**
 * 
 * @export
 * @interface ChildMerchantModel
 */
export interface ChildMerchantModel {
    /**
     * 
     * @type {string}
     * @memberof ChildMerchantModel
     */
    merchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildMerchantModel
     */
    merchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildMerchantModel
     */
    merchantAlias?: string;
    /**
     * 
     * @type {MessagingConfigurationModel}
     * @memberof ChildMerchantModel
     */
    messaging?: MessagingConfigurationModel;
    /**
     * 
     * @type {boolean}
     * @memberof ChildMerchantModel
     */
    isPaymentRequestAllowed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChildMerchantModel
     */
    features?: Array<ChildMerchantModelFeaturesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export type ChildMerchantModelFeaturesEnum = 'SINGLE_PAYMENT' |
    'BATCH_PAYMENT' |
    'BATCH_MANAGER' |
    'DATA_VAULT' |
    'INTERNET' |
    'ADMIN_MENU' |
    'TRANSACTION_SEARCH' |
    'USER_MANAGEMENT' |
    'SETTLEMENT' |
    'REPORTS' |
    'IDB_UPLOAD' |
    'API_ACCESS' |
    'DELETE_BATCH_MANAGER_BATCH' |
    'PROCESS_REFUND' |
    'DIRECT_DEBIT' |
    'RECURRING_SCHEDULE' |
    'EFORMS' |
    'DECLINE_MANAGER' |
    'DECLINE_MANAGER_ADMIN' |
    'NOTIFICATIONS' |
    'MESSAGING' |
    'WEB_SHOP' |
    'INVOICES' |
    'CUSTOMERS' |
    'FRAUD_CONTROL' |
    'FRAUD_BASIC' |
    'API_CONFIGURATION' |
    'PAYMENT_OPTIONS' |
    'PHONE_PROMPTS' |
    'PHONE_PROMPTS_CREATE_NEW' |
    'PHONE_PROMPTS_LOGS' |
    'PLUGIN_CONFIGURATION' |
    'IVR_BLOCKED_CALLERS' |
    'IVR_CUSTOM_SETTINGS' |
    'IVR_CUSTOM_SETTINGS_ACTIONS' |
    'IVR_LOGS' |
    'IVR_OPERATOR' |
    'IVR_QUEUES' |
    'BILLER_CONFIG' |
    'PAYMENT_REQUEST' |
    'PAYMENT_REQUEST_ADMIN' |
    'PAYMENT_REQUEST_BATCH' |
    'SECURE_PHONE' |
    'LEGACY_XML' |
    'CONSUMER_PORTAL_ADMIN' |
    'EXTERNAL_REPORTING' |
    'STATIONERY_SHOP' |
    'NOT_STATIONERY_SHOP' |
    'QR_CODE' |
    'INVOICE_SETTINGS' |
    'PAYMENT_PLAN' |
    'PAYMENT_PLAN_SETTINGS' |
    'INVOICE_PAYMENTS' |
    'LEAST_COST_ROUTING' |
    'LCR_ALLOW_AMOUNT_THRESHOLD_CONFIGURATION' |
    'ADDITIONAL_PAYMENTS' |
    'MULTI_FACTOR_AUTHENTICATION' |
    'ALLOW_DISABLING_MFA';

/**
 * 
 * @export
 * @interface ClientPlatformConfiguration
 */
export interface ClientPlatformConfiguration {
    /**
     * 
     * @type {number}
     * @memberof ClientPlatformConfiguration
     */
    defaultCountryCode?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientPlatformConfiguration
     */
    postcodeLength?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientPlatformConfiguration
     */
    legacyBackOfficeUrl?: string;
}
/**
 * 
 * @export
 * @interface ClientsideErrorModel
 */
export interface ClientsideErrorModel {
    /**
     * 
     * @type {string}
     * @memberof ClientsideErrorModel
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientsideErrorModel
     */
    stackTrace?: string;
}
/**
 * 
 * @export
 * @interface CompanyIdLabels
 */
export interface CompanyIdLabels {
    /**
     * 
     * @type {string}
     * @memberof CompanyIdLabels
     */
    label1?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyIdLabels
     */
    label2?: string;
}
/**
 * 
 * @export
 * @interface ContactUsModel
 */
export interface ContactUsModel {
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    contactType?: ContactUsModelContactTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    iddCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    preferredContactDay?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    preferredContactAmPm?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    postalAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    postalAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    postalSuburb?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    postalState?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    postalPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    siteAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    siteAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    siteSuburb?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    siteState?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    sitePostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    phone1?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    phone2?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    caseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    dateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    noOfTransactions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactUsModel
     */
    isMastercard?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactUsModel
     */
    isVisa?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    bsb?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    feeType?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsModel
     */
    comment?: string;
}

/**
    * @export
    * @enum {string}
    */
export type ContactUsModelContactTypeEnum = 'UNSELECTED' |
    'CHANGE_CONTACT_DETAILS' |
    'CHANGE_BANK_DETAILS' |
    'CHARGEBACK' |
    'FUND_SETTLEMENT' |
    'FEES_CHARGED' |
    'TECHNICAL' |
    'OTHER';

/**
 * 
 * @export
 * @interface CountryModel
 */
export interface CountryModel {
    /**
     * 
     * @type {number}
     * @memberof CountryModel
     */
    countryCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    logoImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    alpha2Code?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    alpha3Code?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    stateFieldLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    companyId1Label?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    companyId2Label?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryModel
     */
    iddCode?: string;
    /**
     * 
     * @type {CurrencyModel}
     * @memberof CountryModel
     */
    currency?: CurrencyModel;
    /**
     * 
     * @type {Array<CountryTaxModel>}
     * @memberof CountryModel
     */
    taxRates?: Array<CountryTaxModel>;
    /**
     * 
     * @type {Array<CountryStateModel>}
     * @memberof CountryModel
     */
    states?: Array<CountryStateModel>;
}
/**
 * 
 * @export
 * @interface CountryStateModel
 */
export interface CountryStateModel {
    /**
     * 
     * @type {number}
     * @memberof CountryStateModel
     */
    stateId?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryStateModel
     */
    countryCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryStateModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryStateModel
     */
    stateFullName?: string;
}
/**
 * 
 * @export
 * @interface CountryTax
 */
export interface CountryTax {
    /**
     * 
     * @type {string}
     * @memberof CountryTax
     */
    taxName?: string;
    /**
     * 
     * @type {number}
     * @memberof CountryTax
     */
    taxRate?: number;
}
/**
 * 
 * @export
 * @interface CountryTaxModel
 */
export interface CountryTaxModel {
    /**
     * 
     * @type {string}
     * @memberof CountryTaxModel
     */
    taxName?: string;
    /**
     * 
     * @type {number}
     * @memberof CountryTaxModel
     */
    taxRate?: number;
}
/**
 * 
 * @export
 * @interface CreateBatchFileRequest
 */
export interface CreateBatchFileRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBatchFileRequest
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBatchFileRequest
     */
    batchPriority?: CreateBatchFileRequestBatchPriorityEnum;
    /**
     * 
     * @type {Array<Batch>}
     * @memberof CreateBatchFileRequest
     */
    batches?: Array<Batch>;
}

/**
    * @export
    * @enum {string}
    */
export type CreateBatchFileRequestBatchPriorityEnum = 'UNSPECIFIED' |
    'LOWEST' |
    'BELOWNORMAL' |
    'NORMAL' |
    'ABOVENORMAL' |
    'HIGHEST';

/**
 * 
 * @export
 * @interface CreateBillerRequest
 */
export interface CreateBillerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBillerRequest
     */
    billerName?: string;
    /**
     * 
     * @type {AcceptedAmounts}
     * @memberof CreateBillerRequest
     */
    amounts?: AcceptedAmounts;
}
/**
 * 
 * @export
 * @interface CredentialOnFileModel
 */
export interface CredentialOnFileModel {
    /**
     * 
     * @type {string}
     * @memberof CredentialOnFileModel
     */
    mode?: CredentialOnFileModelModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type CredentialOnFileModelModeEnum = 'UNKNOWN' |
    'CRN1' |
    'DATAVAULT';

/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface CurrencyModel
 */
export interface CurrencyModel {
    /**
     * 
     * @type {number}
     * @memberof CurrencyModel
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyModel
     */
    symbol?: string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyModel
     */
    decimalPlaces?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyModel
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CustomerModel
 */
export interface CustomerModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    uniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    dob?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    currencyCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    note?: string;
    /**
     * 
     * @type {Array<AddressModel>}
     * @memberof CustomerModel
     */
    addresses?: Array<AddressModel>;
    /**
     * 
     * @type {Array<PhoneNumberModel>}
     * @memberof CustomerModel
     */
    phoneNumbers?: Array<PhoneNumberModel>;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    lastUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    created?: string;
    /**
     * 
     * @type {Array<DataVaultSummaryModel>}
     * @memberof CustomerModel
     */
    tokens?: Array<DataVaultSummaryModel>;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    childMerchantNumber?: string;
}
/**
 * 
 * @export
 * @interface CustomerReferenceNumberModel
 */
export interface CustomerReferenceNumberModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    helpText?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    minLength?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    maxLength?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerReferenceNumberModel
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    crnName?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    crnReferenceId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerReferenceNumberModel
     */
    isPrefixActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerReferenceNumberModel
     */
    isPrefixRangeEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    prefixRangeStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    prefixRangeEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    prefixSpecify?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerReferenceNumberModel
     */
    isSuffixActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerReferenceNumberModel
     */
    isSuffixRangeEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    suffixRangeStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    suffixRangeEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    suffixSpecify?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerReferenceNumberModel
     */
    isChecksumAlgoActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    xAddDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    xFirstDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceNumberModel
     */
    xLastDisplay?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    xAdd?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    xFirst?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    xLast?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerReferenceNumberModel
     */
    crnCheckSum?: number;
    /**
     * 
     * @type {Array<CheckSumModel>}
     * @memberof CustomerReferenceNumberModel
     */
    checkSumList?: Array<CheckSumModel>;
}
/**
 * 
 * @export
 * @interface CustomerTokenRequest
 */
export interface CustomerTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerTokenRequest
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerTokenRequest
     */
    tokenType?: CustomerTokenRequestTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerTokenRequest
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTokenRequest
     */
    cardNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof CustomerTokenRequest
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof CustomerTokenRequest
     */
    deBsbNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTokenRequest
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerTokenRequest
     */
    customerIdentified?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type CustomerTokenRequestTokenTypeEnum = 'CARD' |
    'BANKACCOUNT';

/**
 * 
 * @export
 * @interface DailyReportFileRequest
 */
export interface DailyReportFileRequest {
    /**
     * 
     * @type {string}
     * @memberof DailyReportFileRequest
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyReportFileRequest
     */
    childMerchantNumber?: string;
}
/**
 * 
 * @export
 * @interface DailyReportsRequest
 */
export interface DailyReportsRequest {
    /**
     * 
     * @type {string}
     * @memberof DailyReportsRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyReportsRequest
     */
    toDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyReportsRequest
     */
    childMerchantNumber?: string;
}
/**
 * 
 * @export
 * @interface DailyReportsResponse
 */
export interface DailyReportsResponse {
    /**
     * 
     * @type {number}
     * @memberof DailyReportsResponse
     */
    totalRowCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyReportsResponse
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyReportsResponse
     */
    resultsPerPage?: number;
    /**
     * 
     * @type {PaginatedListReportsFile}
     * @memberof DailyReportsResponse
     */
    reportsList?: PaginatedListReportsFile;
}
/**
 * 
 * @export
 * @interface DataVaultModel
 */
export interface DataVaultModel {
    /**
     * 
     * @type {number}
     * @memberof DataVaultModel
     */
    dataVaultId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    accountNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof DataVaultModel
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    cardTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    cardType?: DataVaultModelCardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    cardSubType?: DataVaultModelCardSubTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DataVaultModel
     */
    cardIssuerCountryCode?: number;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    txnSource?: DataVaultModelTxnSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    deBsbNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof DataVaultModel
     */
    customerV2Id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    updatedTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    customerUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultModel
     */
    customerEmail?: string;
}

/**
    * @export
    * @enum {string}
    */
export type DataVaultModelCardTypeEnum = 'UNSPECIFIED' |
    'AMEX_LOCAL' |
    'MASTERCARD_LOCAL' |
    'VISA_LOCAL' |
    'DINERS_LOCAL' |
    'AMEX_INTL' |
    'MASTERCARD_INTL' |
    'VISA_INTL' |
    'DINERS_INTL' |
    'JCB_INTL' |
    'PAYPAL' |
    'DIRECT_ENTRY' |
    'CASH' |
    'CHEQUE' |
    'NONE' |
    'CHINA_UNION_PAY' |
    'MONEY_ORDER' |
    'INDUSTRY_CARD' |
    'KLARNA' |
    'EFTPOS';
/**
    * @export
    * @enum {string}
    */
export type DataVaultModelCardSubTypeEnum = 'UNSPECIFIED' |
    'DEBIT' |
    'CREDIT' |
    'CHARGE_CARD';
/**
    * @export
    * @enum {string}
    */
export type DataVaultModelTxnSourceEnum = 'UNSPECIFIED' |
    'IVR' |
    'INTERNETANNONYMOUS' |
    'MERCHANTBACKOFFICE' |
    'CALLCENTER' |
    'API' |
    'ISHOP' |
    'MOBILEBACKOFFICE' |
    'CUSTOMERPORTAL' |
    'SFTP' |
    'MERCHANTSUITE' |
    'INVOICE' |
    'VICROADS_BOOKINGS' |
    'VICROADS_REPLACEMENTS' |
    'VICROADS_ONLINEWEBPAY' |
    'VICROADS_HISTORYREPORT';

/**
 * 
 * @export
 * @interface DataVaultSummaryModel
 */
export interface DataVaultSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof DataVaultSummaryModel
     */
    dataVaultId?: number;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    token?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof DataVaultSummaryModel
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    deBsbNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    crn3?: string;
    /**
     * 
     * @type {number}
     * @memberof DataVaultSummaryModel
     */
    customerV2Id?: number;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    customerUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    type?: DataVaultSummaryModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataVaultSummaryModel
     */
    cardTypeCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export type DataVaultSummaryModelTypeEnum = 'CARD' |
    'BANKACCOUNT';

/**
 * 
 * @export
 * @interface DataVaultTokenRequest
 */
export interface DataVaultTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    crN1?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    crN2?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    crN3?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    customerUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    tokenType?: DataVaultTokenRequestTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    cardNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof DataVaultTokenRequest
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    deBsbNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DataVaultTokenRequest
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataVaultTokenRequest
     */
    customerIdentified?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type DataVaultTokenRequestTokenTypeEnum = 'CARD' |
    'BANKACCOUNT';

/**
 * 
 * @export
 * @interface DateFormatModel
 */
export interface DateFormatModel {
    /**
     * 
     * @type {number}
     * @memberof DateFormatModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DateFormatModel
     */
    display?: string;
    /**
     * 
     * @type {string}
     * @memberof DateFormatModel
     */
    specifier?: string;
}
/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface DeleteBatchJobRequest
 */
export interface DeleteBatchJobRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteBatchJobRequest
     */
    batchFileId?: number;
    /**
     * 
     * @type {string}
     * @memberof DeleteBatchJobRequest
     */
    batchStatus?: DeleteBatchJobRequestBatchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export type DeleteBatchJobRequestBatchStatusEnum = 'UNSPECIFIED' |
    'LOADING' |
    'PENDING' |
    'INPROGRESS' |
    'COMPLETE' |
    'ERROR' |
    'DELETED' |
    'BATCHMANAGER' |
    'DONOTPROCESS' |
    'LOADING_BATCHMANAGER' |
    'LOADING_DV' |
    'COMPLETE_DV' |
    'LOADING_PAYMENTREQUEST' |
    'COMPLETE_PAYMENTREQUEST';

/**
 * 
 * @export
 * @interface DeleteBatchLibraryFileRequest
 */
export interface DeleteBatchLibraryFileRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteBatchLibraryFileRequest
     */
    batchFileId?: number;
}
/**
 * 
 * @export
 * @interface DeleteBatchRecordRequest
 */
export interface DeleteBatchRecordRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteBatchRecordRequest
     */
    batchFileId?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteBatchRecordRequest
     */
    recordId?: number;
}
/**
 * 
 * @export
 * @interface DeleteCustomerRequest
 */
export interface DeleteCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteCustomerRequest
     */
    customerId?: number;
}
/**
 * 
 * @export
 * @interface DeleteTokenRequest
 */
export interface DeleteTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteTokenRequest
     */
    dataVaultId?: number;
}
/**
 * 
 * @export
 * @interface DeleteUserRequest
 */
export interface DeleteUserRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteUserRequest
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof DeleteUserRequest
     */
    childMerchantNumber?: string;
}
/**
 * 
 * @export
 * @interface DownloadBatchFileResultRequest
 */
export interface DownloadBatchFileResultRequest {
    /**
     * 
     * @type {number}
     * @memberof DownloadBatchFileResultRequest
     */
    downloadFileId?: number;
    /**
     * 
     * @type {string}
     * @memberof DownloadBatchFileResultRequest
     */
    downloadFormat?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadBatchFileResultRequest
     */
    returnAll?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadBatchFileResultRequest
     */
    zipContents?: boolean;
}
/**
 * 
 * @export
 * @interface DownloadBatchFileResultResponse
 */
export interface DownloadBatchFileResultResponse {
    /**
     * 
     * @type {object}
     * @memberof DownloadBatchFileResultResponse
     */
    fileData?: object;
    /**
     * 
     * @type {string}
     * @memberof DownloadBatchFileResultResponse
     */
    fileName?: string;
}
/**
 * 
 * @export
 * @interface DownloadCustomersExportRequest
 */
export interface DownloadCustomersExportRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadCustomersExportRequest
     */
    columnCodesToInclude?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DownloadCustomersExportRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadCustomersExportRequest
     */
    reportCode?: DownloadCustomersExportRequestReportCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type DownloadCustomersExportRequestReportCodeEnum = 'SettlementDetailed' |
    'DeReject' |
    'CustomersExtract';

/**
 * 
 * @export
 * @interface DownloadDataVaultReportRequest
 */
export interface DownloadDataVaultReportRequest {
    /**
     * 
     * @type {PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields}
     * @memberof DownloadDataVaultReportRequest
     */
    search?: PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields;
    /**
     * 
     * @type {string}
     * @memberof DownloadDataVaultReportRequest
     */
    format?: DownloadDataVaultReportRequestFormatEnum;
}

/**
    * @export
    * @enum {string}
    */
export type DownloadDataVaultReportRequestFormatEnum = 'UNSPECIFIED' |
    'BPOINT' |
    'VIRTUALPOS' |
    'VIRTUALPOSPLUS' |
    'MICROMERCHANT' |
    'BECS' |
    'PAYMENTREQUEST' |
    'KENANCC' |
    'KENANDD' |
    'TDE' |
    'BPOINT_GROUPHEADERS' |
    'SECUREPAY' |
    'QUICKSTREAMPAY' |
    'PAYRIX' |
    'CSV';

/**
 * 
 * @export
 * @interface DownloadSettlementExportRequest
 */
export interface DownloadSettlementExportRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadSettlementExportRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadSettlementExportRequest
     */
    toDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadSettlementExportRequest
     */
    columnCodesToInclude?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DownloadSettlementExportRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadSettlementExportRequest
     */
    reportCode?: DownloadSettlementExportRequestReportCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type DownloadSettlementExportRequestReportCodeEnum = 'SettlementDetailed' |
    'DeReject' |
    'CustomersExtract';

/**
 * 
 * @export
 * @interface DownloadSettlementSummaryRequest
 */
export interface DownloadSettlementSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadSettlementSummaryRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadSettlementSummaryRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadSettlementSummaryRequest
     */
    toDate?: string;
}
/**
 * 
 * @export
 * @interface DownloadTransactionsReportInput
 */
export interface DownloadTransactionsReportInput {
    /**
     * 
     * @type {SearchTransactionsInput}
     * @memberof DownloadTransactionsReportInput
     */
    search?: SearchTransactionsInput;
    /**
     * 
     * @type {string}
     * @memberof DownloadTransactionsReportInput
     */
    format?: DownloadTransactionsReportInputFormatEnum;
}

/**
    * @export
    * @enum {string}
    */
export type DownloadTransactionsReportInputFormatEnum = 'Standard' |
    'Extended';

/**
 * 
 * @export
 * @interface EditBatchRecordRequest
 */
export interface EditBatchRecordRequest {
    /**
     * 
     * @type {number}
     * @memberof EditBatchRecordRequest
     */
    batchFileId?: number;
    /**
     * 
     * @type {number}
     * @memberof EditBatchRecordRequest
     */
    recordId?: number;
    /**
     * 
     * @type {BatchRecord}
     * @memberof EditBatchRecordRequest
     */
    batchRecord?: BatchRecord;
}
/**
 * 
 * @export
 * @interface ExpiryDate
 */
export interface ExpiryDate {
    /**
     * 
     * @type {number}
     * @memberof ExpiryDate
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpiryDate
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface ExportTypeModel
 */
export interface ExportTypeModel {
    /**
     * 
     * @type {string}
     * @memberof ExportTypeModel
     */
    key?: ExportTypeModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof ExportTypeModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type ExportTypeModelKeyEnum = 'SettlementDetailed' |
    'DeReject' |
    'CustomersExtract';

/**
 * 
 * @export
 * @interface ExportTypesData
 */
export interface ExportTypesData {
    /**
     * 
     * @type {Array<ExportTypeModel>}
     * @memberof ExportTypesData
     */
    items?: Array<ExportTypeModel>;
    /**
     * 
     * @type {string}
     * @memberof ExportTypesData
     */
    defaultKey?: ExportTypesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type ExportTypesDataDefaultKeyEnum = 'SettlementDetailed' |
    'DeReject' |
    'CustomersExtract';

/**
 * 
 * @export
 * @interface FeedbackAreaModel
 */
export interface FeedbackAreaModel {
    /**
     * 
     * @type {number}
     * @memberof FeedbackAreaModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAreaModel
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface FeedbackModel
 */
export interface FeedbackModel {
    /**
     * 
     * @type {number}
     * @memberof FeedbackModel
     */
    feedbackAreaId?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackModel
     */
    type?: FeedbackModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeedbackModel
     */
    comment?: string;
}

/**
    * @export
    * @enum {string}
    */
export type FeedbackModelTypeEnum = 'Defect' |
    'Improvement';

/**
 * 
 * @export
 * @interface FraudActionDetails
 */
export interface FraudActionDetails {
    /**
     * 
     * @type {string}
     * @memberof FraudActionDetails
     */
    key?: FraudActionDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof FraudActionDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type FraudActionDetailsKeyEnum = 'ALLOW' |
    'BLOCK';

/**
 * 
 * @export
 * @interface FraudBasicExclusionsModel
 */
export interface FraudBasicExclusionsModel {
    /**
     * 
     * @type {Array<FraudConfigurationCountryExclusionModel>}
     * @memberof FraudBasicExclusionsModel
     */
    fraudConfigurationCountryExclusions?: Array<FraudConfigurationCountryExclusionModel>;
}
/**
 * 
 * @export
 * @interface FraudBasicSettingsModel
 */
export interface FraudBasicSettingsModel {
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockIntlCards?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockIntlIps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockPaymentsFromProxy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockPaymentsFromVpn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockPaymentsWithIntlCardAndIntlIp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockPaymentsWhereCardAndIpCountryDiffer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockHppAnonymousAccess?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    enableCaptchaBank?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudBasicSettingsModel
     */
    blockAnonymousPaymentFromFeatures?: boolean;
}
/**
 * 
 * @export
 * @interface FraudConfigurationCountryExclusionModel
 */
export interface FraudConfigurationCountryExclusionModel {
    /**
     * 
     * @type {number}
     * @memberof FraudConfigurationCountryExclusionModel
     */
    countryCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FraudConfigurationCountryExclusionModel
     */
    excludedFromBlockIntlCards?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FraudConfigurationCountryExclusionModel
     */
    excludedFromBlockIntlIps?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FraudConfigurationCountryExclusionModel
     */
    dateUpdated?: string;
    /**
     * 
     * @type {number}
     * @memberof FraudConfigurationCountryExclusionModel
     */
    userIdUpdated?: number;
}
/**
 * 
 * @export
 * @interface FraudDetectionResponseModel
 */
export interface FraudDetectionResponseModel {
    /**
     * 
     * @type {string}
     * @memberof FraudDetectionResponseModel
     */
    responseMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof FraudDetectionResponseModel
     */
    responseCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FraudDetectionResponseModel
     */
    txnRejected?: boolean;
    /**
     * 
     * @type {RedResponseModel}
     * @memberof FraudDetectionResponseModel
     */
    redResponse?: RedResponseModel;
}
/**
 * 
 * @export
 * @interface FraudRuleModel
 */
export interface FraudRuleModel {
    /**
     * 
     * @type {number}
     * @memberof FraudRuleModel
     */
    merchantId?: number;
    /**
     * 
     * @type {FraudActionDetails}
     * @memberof FraudRuleModel
     */
    action?: FraudActionDetails;
    /**
     * 
     * @type {FraudTypeDetails}
     * @memberof FraudRuleModel
     */
    type?: FraudTypeDetails;
    /**
     * 
     * @type {string}
     * @memberof FraudRuleModel
     */
    lowField?: string;
    /**
     * 
     * @type {string}
     * @memberof FraudRuleModel
     */
    highField?: string;
    /**
     * 
     * @type {string}
     * @memberof FraudRuleModel
     */
    truncatedCard?: string;
    /**
     * 
     * @type {number}
     * @memberof FraudRuleModel
     */
    userIdLoaded?: number;
    /**
     * 
     * @type {string}
     * @memberof FraudRuleModel
     */
    loaded?: string;
}
/**
 * 
 * @export
 * @interface FraudTypeDetails
 */
export interface FraudTypeDetails {
    /**
     * 
     * @type {string}
     * @memberof FraudTypeDetails
     */
    key?: FraudTypeDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof FraudTypeDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type FraudTypeDetailsKeyEnum = 'IP' |
    'CARD' |
    'BIN' |
    'CRN' |
    'PHONE' |
    'ISSUER_COUNTRY' |
    'IP_COUNTRY' |
    'PROXY' |
    'VPN';

/**
 * 
 * @export
 * @interface GetBackofficeUrlRequest
 */
export interface GetBackofficeUrlRequest {
    /**
     * 
     * @type {number}
     * @memberof GetBackofficeUrlRequest
     */
    billerCode?: number;
}
/**
 * 
 * @export
 * @interface GetBackofficeUrlResponse
 */
export interface GetBackofficeUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GetBackofficeUrlResponse
     */
    backofficeBaseUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBackofficeUrlResponse
     */
    editBillerUrl?: string;
}
/**
 * 
 * @export
 * @interface GetBatchFileContentsRequest
 */
export interface GetBatchFileContentsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetBatchFileContentsRequest
     */
    fileId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBatchFileContentsRequest
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchFileContentsRequest
     */
    accountNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof GetBatchFileContentsRequest
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {number}
     * @memberof GetBatchFileContentsRequest
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBatchFileContentsRequest
     */
    orderTypeKey?: GetBatchFileContentsRequestOrderTypeKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type GetBatchFileContentsRequestOrderTypeKeyEnum = 'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'TELEPHONE' |
    'RECURRING' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface GetBatchFileContentsResponse
 */
export interface GetBatchFileContentsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetBatchFileContentsResponse
     */
    fileId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBatchFileContentsResponse
     */
    filename?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBatchFileContentsResponse
     */
    uploadedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchFileContentsResponse
     */
    uploadedValue?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBatchFileContentsResponse
     */
    submittedDateTime?: string;
    /**
     * 
     * @type {PaginatedListBatchFileContents}
     * @memberof GetBatchFileContentsResponse
     */
    fileRecords?: PaginatedListBatchFileContents;
}
/**
 * 
 * @export
 * @interface GetBatchFilesResponse
 */
export interface GetBatchFilesResponse {
    /**
     * 
     * @type {PaginatedListBatchFileInfo}
     * @memberof GetBatchFilesResponse
     */
    batchFiles?: PaginatedListBatchFileInfo;
    /**
     * 
     * @type {number}
     * @memberof GetBatchFilesResponse
     */
    completedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchFilesResponse
     */
    progressCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBatchFilesResponse
     */
    scheduledCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetBatchFilesResponse
     */
    progressErrors?: boolean;
}
/**
 * 
 * @export
 * @interface GetBatchJobsRequest
 */
export interface GetBatchJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetBatchJobsRequest
     */
    fileStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchJobsRequest
     */
    filename?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBatchJobsRequest
     */
    timeSpanDays?: number;
}
/**
 * 
 * @export
 * @interface GetBatchLibraryFilesRequest
 */
export interface GetBatchLibraryFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof GetBatchLibraryFilesRequest
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatchLibraryFilesRequest
     */
    updatedDate?: string;
}
/**
 * 
 * @export
 * @interface GetBatchRequestsRequest
 */
export interface GetBatchRequestsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetBatchRequestsRequest
     */
    filename?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBatchRequestsRequest
     */
    timeSpanDays?: number;
}
/**
 * 
 * @export
 * @interface GetBillersInput
 */
export interface GetBillersInput {
    /**
     * 
     * @type {string}
     * @memberof GetBillersInput
     */
    childMerchantNumber?: string;
}
/**
 * 
 * @export
 * @interface GetCustomerRequest
 */
export interface GetCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof GetCustomerRequest
     */
    customerId?: number;
}
/**
 * 
 * @export
 * @interface GetCustomersRequest
 */
export interface GetCustomersRequest {
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    uniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    doB?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCustomersRequest
     */
    preferredCurrency?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCustomersRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCustomersRequest
     */
    withoutDataVaultOnly?: boolean;
}
/**
 * 
 * @export
 * @interface GetExportColumnsResponse
 */
export interface GetExportColumnsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetExportColumnsResponse
     */
    columnCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetExportColumnsResponse
     */
    columnDesc?: string;
}
/**
 * 
 * @export
 * @interface GetExportDetailsRequest
 */
export interface GetExportDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetExportDetailsRequest
     */
    reportCode?: GetExportDetailsRequestReportCodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type GetExportDetailsRequestReportCodeEnum = 'SettlementDetailed' |
    'DeReject' |
    'CustomersExtract';

/**
 * 
 * @export
 * @interface GetExportOptionsResponse
 */
export interface GetExportOptionsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetExportOptionsResponse
     */
    optionDesc?: string;
    /**
     * 
     * @type {Array<GetExportColumnsResponse>}
     * @memberof GetExportOptionsResponse
     */
    defaultColumns?: Array<GetExportColumnsResponse>;
}
/**
 * 
 * @export
 * @interface GetInvoicePaymentsRequest
 */
export interface GetInvoicePaymentsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetInvoicePaymentsRequest
     */
    invoiceId?: number;
}
/**
 * 
 * @export
 * @interface GetInvoiceRequest
 */
export interface GetInvoiceRequest {
    /**
     * 
     * @type {number}
     * @memberof GetInvoiceRequest
     */
    invoiceId?: number;
    /**
     * 
     * @type {string}
     * @memberof GetInvoiceRequest
     */
    guid?: string;
}
/**
 * 
 * @export
 * @interface GetTokenRequest
 */
export interface GetTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof GetTokenRequest
     */
    dataVaultId?: number;
}
/**
 * 
 * @export
 * @interface GetUserRequest
 */
export interface GetUserRequest {
    /**
     * 
     * @type {number}
     * @memberof GetUserRequest
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface GetUserRolesResponse
 */
export interface GetUserRolesResponse {
    /**
     * 
     * @type {Array<MerchantUserRolesModel>}
     * @memberof GetUserRolesResponse
     */
    merchants?: Array<MerchantUserRolesModel>;
}
/**
 * 
 * @export
 * @interface GetUsersRequest
 */
export interface GetUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUsersRequest
     */
    dailyRefundCountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUsersRequest
     */
    dailyRefundCountMax?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUsersRequest
     */
    dailyRefundAmountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUsersRequest
     */
    dailyRefundAmountMax?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    roleName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUsersRequest
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersRequest
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @interface HCaptchaSettingsModel
 */
export interface HCaptchaSettingsModel {
    /**
     * 
     * @type {string}
     * @memberof HCaptchaSettingsModel
     */
    siteKey?: string;
}
/**
 * 
 * @export
 * @interface HppPathStringFieldModel
 */
export interface HppPathStringFieldModel {
    /**
     * 
     * @type {string}
     * @memberof HppPathStringFieldModel
     */
    hppPathType?: HppPathStringFieldModelHppPathTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HppPathStringFieldModel
     */
    pathValue?: string;
}

/**
    * @export
    * @enum {string}
    */
export type HppPathStringFieldModelHppPathTypeEnum = 'EFormId';

/**
 * 
 * @export
 * @interface HppQueryStringModel
 */
export interface HppQueryStringModel {
    /**
     * 
     * @type {string}
     * @memberof HppQueryStringModel
     */
    hppQueryType?: HppQueryStringModelHppQueryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HppQueryStringModel
     */
    queryValue?: string;
}

/**
    * @export
    * @enum {string}
    */
export type HppQueryStringModelHppQueryTypeEnum = 'BillerCode' |
    'RequestId' |
    'InvoiceId' |
    'AuthKey';

/**
 * 
 * @export
 * @interface InvoiceAddressModel
 */
export interface InvoiceAddressModel {
    /**
     * 
     * @type {number}
     * @memberof InvoiceAddressModel
     */
    typeId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressModel
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressModel
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressModel
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressModel
     */
    suburb?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceAddressModel
     */
    stateId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressModel
     */
    postcode?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceAddressModel
     */
    countryCode?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressModel
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface InvoiceConfigModel
 */
export interface InvoiceConfigModel {
    /**
     * 
     * @type {Array<CountryTax>}
     * @memberof InvoiceConfigModel
     */
    taxRates?: Array<CountryTax>;
    /**
     * 
     * @type {Array<TermOfPayment>}
     * @memberof InvoiceConfigModel
     */
    termOfPayments?: Array<TermOfPayment>;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceConfigModel
     */
    unitPriceInclusiveOfTax?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceConfigModel
     */
    displayItemsWithZeroAmount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceConfigModel
     */
    customerNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceConfigModel
     */
    hasInvoiceBiller?: boolean;
}
/**
 * 
 * @export
 * @interface InvoiceCountryTaxModel
 */
export interface InvoiceCountryTaxModel {
    /**
     * 
     * @type {string}
     * @memberof InvoiceCountryTaxModel
     */
    taxName?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCountryTaxModel
     */
    taxRate?: number;
}
/**
 * 
 * @export
 * @interface InvoiceCustomerModel
 */
export interface InvoiceCustomerModel {
    /**
     * 
     * @type {number}
     * @memberof InvoiceCustomerModel
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerModel
     */
    salutation?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerModel
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerModel
     */
    email?: string;
    /**
     * 
     * @type {InvoiceAddressModel}
     * @memberof InvoiceCustomerModel
     */
    address?: InvoiceAddressModel;
}
/**
 * 
 * @export
 * @interface InvoiceModel
 */
export interface InvoiceModel {
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    merchantId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    billerId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    invoiceId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    guid?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    invoiceStatusId?: number;
    /**
     * 
     * @type {InvoiceCustomerModel}
     * @memberof InvoiceModel
     */
    customer?: InvoiceCustomerModel;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    customerNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    orderNumber?: string;
    /**
     * 
     * @type {TermOfPaymentModel}
     * @memberof InvoiceModel
     */
    selectedTermOfPayment?: TermOfPaymentModel;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    invoiceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    dueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    currencyCode?: number;
    /**
     * 
     * @type {Array<InvoiceProductItemModel>}
     * @memberof InvoiceModel
     */
    invoiceProductItems?: Array<InvoiceProductItemModel>;
    /**
     * 
     * @type {Array<InvoiceSubTotalItemModel>}
     * @memberof InvoiceModel
     */
    invoiceSubTotalItems?: Array<InvoiceSubTotalItemModel>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    productItemsSubTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    productItemsSubTotalExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    subTotalItemsSubTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    subTotalItemsSubTotalExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    amountsPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    subTotalExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    taxTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    customerViewUrl?: string;
}
/**
 * 
 * @export
 * @interface InvoicePaymentModel
 */
export interface InvoicePaymentModel {
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentModel
     */
    txnHistoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentModel
     */
    typeLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentModel
     */
    receiptNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentModel
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentModel
     */
    processedDateUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentModel
     */
    processedDateMerchantTime?: string;
}
/**
 * 
 * @export
 * @interface InvoiceProductItemModel
 */
export interface InvoiceProductItemModel {
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductItemModel
     */
    unitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductItemModel
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductItemModel
     */
    invoiceItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProductItemModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductItemModel
     */
    taxTotal?: number;
    /**
     * 
     * @type {InvoiceCountryTaxModel}
     * @memberof InvoiceProductItemModel
     */
    selectedTaxRate?: InvoiceCountryTaxModel;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductItemModel
     */
    amountTotal?: number;
}
/**
 * 
 * @export
 * @interface InvoiceSearchInputModel
 */
export interface InvoiceSearchInputModel {
    /**
     * 
     * @type {InvoiceStatusDetails}
     * @memberof InvoiceSearchInputModel
     */
    status?: InvoiceStatusDetails;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSearchInputModel
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchInputModel
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchInputModel
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchInputModel
     */
    invoiceDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSearchInputModel
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface InvoiceSearchResponseModel
 */
export interface InvoiceSearchResponseModel {
    /**
     * 
     * @type {InvoiceStatusDetails}
     * @memberof InvoiceSearchResponseModel
     */
    status?: InvoiceStatusDetails;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchResponseModel
     */
    invoiceGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSearchResponseModel
     */
    invoiceId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchResponseModel
     */
    invoiceUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchResponseModel
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchResponseModel
     */
    orderNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSearchResponseModel
     */
    currencyCode?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSearchResponseModel
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSearchResponseModel
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSearchResponseModel
     */
    invoiceDate?: string;
}
/**
 * 
 * @export
 * @interface InvoiceStatusDetails
 */
export interface InvoiceStatusDetails {
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatusDetails
     */
    key?: InvoiceStatusDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatusDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type InvoiceStatusDetailsKeyEnum = 'All' |
    'Unpaid' |
    'Paid' |
    'Refunded' |
    'Cancelled' |
    'Archived' |
    'Overdue' |
    'Expired';

/**
 * 
 * @export
 * @interface InvoiceSubTotalItemModel
 */
export interface InvoiceSubTotalItemModel {
    /**
     * 
     * @type {InvoiceSubtotalItemOptionModel}
     * @memberof InvoiceSubTotalItemModel
     */
    selectedOption?: InvoiceSubtotalItemOptionModel;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSubTotalItemModel
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSubTotalItemModel
     */
    invoiceItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSubTotalItemModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSubTotalItemModel
     */
    taxTotal?: number;
    /**
     * 
     * @type {InvoiceCountryTaxModel}
     * @memberof InvoiceSubTotalItemModel
     */
    selectedTaxRate?: InvoiceCountryTaxModel;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSubTotalItemModel
     */
    amountTotal?: number;
}
/**
 * 
 * @export
 * @interface InvoiceSubtotalItemOptionModel
 */
export interface InvoiceSubtotalItemOptionModel {
    /**
     * 
     * @type {number}
     * @memberof InvoiceSubtotalItemOptionModel
     */
    optionId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSubtotalItemOptionModel
     */
    optionName?: string;
}
/**
 * 
 * @export
 * @interface IssueCodeResponseModel
 */
export interface IssueCodeResponseModel {
    /**
     * 
     * @type {string}
     * @memberof IssueCodeResponseModel
     */
    responseCode?: IssueCodeResponseModelResponseCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof IssueCodeResponseModel
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueCodeResponseModel
     */
    issuedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof IssueCodeResponseModel
     */
    expiresOn?: string;
}

/**
    * @export
    * @enum {string}
    */
export type IssueCodeResponseModelResponseCodeEnum = 'MfaRequired' |
    'MfaNotRequired' |
    'UserDoesNotHaveDeliveryMethod' |
    'SessionNotFound';

/**
 * 
 * @export
 * @interface KeyDescriptionBaseAntiFraudAction
 */
export interface KeyDescriptionBaseAntiFraudAction {
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseAntiFraudAction
     */
    key?: KeyDescriptionBaseAntiFraudActionKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseAntiFraudAction
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionBaseAntiFraudActionKeyEnum = 'ALLOW' |
    'BLOCK';

/**
 * 
 * @export
 * @interface KeyDescriptionBaseAntiFraudType
 */
export interface KeyDescriptionBaseAntiFraudType {
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseAntiFraudType
     */
    key?: KeyDescriptionBaseAntiFraudTypeKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseAntiFraudType
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionBaseAntiFraudTypeKeyEnum = 'IP' |
    'CARD' |
    'BIN' |
    'CRN' |
    'PHONE' |
    'ISSUER_COUNTRY' |
    'IP_COUNTRY' |
    'PROXY' |
    'VPN';

/**
 * 
 * @export
 * @interface KeyDescriptionBaseInvoiceStatusFilter
 */
export interface KeyDescriptionBaseInvoiceStatusFilter {
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseInvoiceStatusFilter
     */
    key?: KeyDescriptionBaseInvoiceStatusFilterKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseInvoiceStatusFilter
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionBaseInvoiceStatusFilterKeyEnum = 'All' |
    'Unpaid' |
    'Paid' |
    'Refunded' |
    'Cancelled' |
    'Archived' |
    'Overdue' |
    'Expired';

/**
 * 
 * @export
 * @interface KeyDescriptionBaseTxnSource
 */
export interface KeyDescriptionBaseTxnSource {
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnSource
     */
    key?: KeyDescriptionBaseTxnSourceKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnSource
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionBaseTxnSourceKeyEnum = 'UNSPECIFIED' |
    'IVR' |
    'INTERNETANNONYMOUS' |
    'MERCHANTBACKOFFICE' |
    'CALLCENTER' |
    'API' |
    'ISHOP' |
    'MOBILEBACKOFFICE' |
    'CUSTOMERPORTAL' |
    'SFTP' |
    'MERCHANTSUITE' |
    'INVOICE' |
    'VICROADS_BOOKINGS' |
    'VICROADS_REPLACEMENTS' |
    'VICROADS_ONLINEWEBPAY' |
    'VICROADS_HISTORYREPORT';

/**
 * 
 * @export
 * @interface KeyDescriptionBaseTxnSubSource
 */
export interface KeyDescriptionBaseTxnSubSource {
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnSubSource
     */
    key?: KeyDescriptionBaseTxnSubSourceKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnSubSource
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionBaseTxnSubSourceKeyEnum = 'PAYMENT_REQUEST' |
    'OTHER' |
    'AUSTRALIA_POST' |
    'LOCKBOX' |
    'DUN_BRADSTREET' |
    'BPAY' |
    'APF' |
    'ELOCKBOX' |
    'SCAN_AND_PAY' |
    'QR_CODE_OTHER';

/**
 * 
 * @export
 * @interface KeyDescriptionBaseTxnSubType
 */
export interface KeyDescriptionBaseTxnSubType {
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnSubType
     */
    key?: KeyDescriptionBaseTxnSubTypeKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnSubType
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionBaseTxnSubTypeKeyEnum = 'UNSPECIFIED' |
    'SINGLE' |
    'RECURRING';

/**
 * 
 * @export
 * @interface KeyDescriptionBaseTxnType
 */
export interface KeyDescriptionBaseTxnType {
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnType
     */
    key?: KeyDescriptionBaseTxnTypeKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionBaseTxnType
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionBaseTxnTypeKeyEnum = 'UNSPECIFIED' |
    'MAIL_ORDER' |
    'TELEPHONE_ORDER' |
    'ECOMMERCE' |
    'CARD_PRESENT' |
    'CALL_CENTRE' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface KeyDescriptionListAntiFraudAction
 */
export interface KeyDescriptionListAntiFraudAction {
    /**
     * 
     * @type {Array<KeyDescriptionBaseAntiFraudAction>}
     * @memberof KeyDescriptionListAntiFraudAction
     */
    items?: Array<KeyDescriptionBaseAntiFraudAction>;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionListAntiFraudAction
     */
    defaultKey?: KeyDescriptionListAntiFraudActionDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionListAntiFraudActionDefaultKeyEnum = 'ALLOW' |
    'BLOCK';

/**
 * 
 * @export
 * @interface KeyDescriptionListAntiFraudType
 */
export interface KeyDescriptionListAntiFraudType {
    /**
     * 
     * @type {Array<KeyDescriptionBaseAntiFraudType>}
     * @memberof KeyDescriptionListAntiFraudType
     */
    items?: Array<KeyDescriptionBaseAntiFraudType>;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionListAntiFraudType
     */
    defaultKey?: KeyDescriptionListAntiFraudTypeDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionListAntiFraudTypeDefaultKeyEnum = 'IP' |
    'CARD' |
    'BIN' |
    'CRN' |
    'PHONE' |
    'ISSUER_COUNTRY' |
    'IP_COUNTRY' |
    'PROXY' |
    'VPN';

/**
 * 
 * @export
 * @interface KeyDescriptionListInvoiceStatusFilter
 */
export interface KeyDescriptionListInvoiceStatusFilter {
    /**
     * 
     * @type {Array<KeyDescriptionBaseInvoiceStatusFilter>}
     * @memberof KeyDescriptionListInvoiceStatusFilter
     */
    items?: Array<KeyDescriptionBaseInvoiceStatusFilter>;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionListInvoiceStatusFilter
     */
    defaultKey?: KeyDescriptionListInvoiceStatusFilterDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionListInvoiceStatusFilterDefaultKeyEnum = 'All' |
    'Unpaid' |
    'Paid' |
    'Refunded' |
    'Cancelled' |
    'Archived' |
    'Overdue' |
    'Expired';

/**
 * 
 * @export
 * @interface KeyDescriptionListTxnSource
 */
export interface KeyDescriptionListTxnSource {
    /**
     * 
     * @type {Array<KeyDescriptionBaseTxnSource>}
     * @memberof KeyDescriptionListTxnSource
     */
    items?: Array<KeyDescriptionBaseTxnSource>;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionListTxnSource
     */
    defaultKey?: KeyDescriptionListTxnSourceDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionListTxnSourceDefaultKeyEnum = 'UNSPECIFIED' |
    'IVR' |
    'INTERNETANNONYMOUS' |
    'MERCHANTBACKOFFICE' |
    'CALLCENTER' |
    'API' |
    'ISHOP' |
    'MOBILEBACKOFFICE' |
    'CUSTOMERPORTAL' |
    'SFTP' |
    'MERCHANTSUITE' |
    'INVOICE' |
    'VICROADS_BOOKINGS' |
    'VICROADS_REPLACEMENTS' |
    'VICROADS_ONLINEWEBPAY' |
    'VICROADS_HISTORYREPORT';

/**
 * 
 * @export
 * @interface KeyDescriptionListTxnSubSource
 */
export interface KeyDescriptionListTxnSubSource {
    /**
     * 
     * @type {Array<KeyDescriptionBaseTxnSubSource>}
     * @memberof KeyDescriptionListTxnSubSource
     */
    items?: Array<KeyDescriptionBaseTxnSubSource>;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionListTxnSubSource
     */
    defaultKey?: KeyDescriptionListTxnSubSourceDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionListTxnSubSourceDefaultKeyEnum = 'PAYMENT_REQUEST' |
    'OTHER' |
    'AUSTRALIA_POST' |
    'LOCKBOX' |
    'DUN_BRADSTREET' |
    'BPAY' |
    'APF' |
    'ELOCKBOX' |
    'SCAN_AND_PAY' |
    'QR_CODE_OTHER';

/**
 * 
 * @export
 * @interface KeyDescriptionListTxnSubType
 */
export interface KeyDescriptionListTxnSubType {
    /**
     * 
     * @type {Array<KeyDescriptionBaseTxnSubType>}
     * @memberof KeyDescriptionListTxnSubType
     */
    items?: Array<KeyDescriptionBaseTxnSubType>;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionListTxnSubType
     */
    defaultKey?: KeyDescriptionListTxnSubTypeDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionListTxnSubTypeDefaultKeyEnum = 'UNSPECIFIED' |
    'SINGLE' |
    'RECURRING';

/**
 * 
 * @export
 * @interface KeyDescriptionListTxnType
 */
export interface KeyDescriptionListTxnType {
    /**
     * 
     * @type {Array<KeyDescriptionBaseTxnType>}
     * @memberof KeyDescriptionListTxnType
     */
    items?: Array<KeyDescriptionBaseTxnType>;
    /**
     * 
     * @type {string}
     * @memberof KeyDescriptionListTxnType
     */
    defaultKey?: KeyDescriptionListTxnTypeDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type KeyDescriptionListTxnTypeDefaultKeyEnum = 'UNSPECIFIED' |
    'MAIL_ORDER' |
    'TELEPHONE_ORDER' |
    'ECOMMERCE' |
    'CARD_PRESENT' |
    'CALL_CENTRE' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface LCRConfigurationModel
 */
export interface LCRConfigurationModel {
    /**
     * 
     * @type {string}
     * @memberof LCRConfigurationModel
     */
    vcPrimaryNetwork?: LCRConfigurationModelVcPrimaryNetworkEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LCRConfigurationModel
     */
    vcMaxAmountSet?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LCRConfigurationModel
     */
    vcMaxAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LCRConfigurationModel
     */
    mcPrimaryNetwork?: LCRConfigurationModelMcPrimaryNetworkEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LCRConfigurationModel
     */
    mcMaxAmountSet?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LCRConfigurationModel
     */
    mcMaxAmount?: number;
}

/**
    * @export
    * @enum {string}
    */
export type LCRConfigurationModelVcPrimaryNetworkEnum = 'Scheme' |
    'Eftpos';
/**
    * @export
    * @enum {string}
    */
export type LCRConfigurationModelMcPrimaryNetworkEnum = 'Scheme' |
    'Eftpos';

/**
 * 
 * @export
 * @interface LimitsInt32
 */
export interface LimitsInt32 {
    /**
     * 
     * @type {number}
     * @memberof LimitsInt32
     */
    minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsInt32
     */
    maximum?: number;
}
/**
 * 
 * @export
 * @interface LimitsNullableInt32
 */
export interface LimitsNullableInt32 {
    /**
     * 
     * @type {number}
     * @memberof LimitsNullableInt32
     */
    minimum?: number;
    /**
     * 
     * @type {number}
     * @memberof LimitsNullableInt32
     */
    maximum?: number;
}
/**
 * 
 * @export
 * @interface LinkCustomerTokenRequest
 */
export interface LinkCustomerTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkCustomerTokenRequest
     */
    customerId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkCustomerTokenRequest
     */
    dataVaultIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface LoggedOnDetails
 */
export interface LoggedOnDetails {
    /**
     * 
     * @type {string}
     * @memberof LoggedOnDetails
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggedOnDetails
     */
    merchantNumber?: string;
    /**
     * 
     * @type {AccountModel}
     * @memberof LoggedOnDetails
     */
    user?: AccountModel;
    /**
     * 
     * @type {MerchantModel}
     * @memberof LoggedOnDetails
     */
    merchant?: MerchantModel;
    /**
     * 
     * @type {string}
     * @memberof LoggedOnDetails
     */
    requiredUserAction?: LoggedOnDetailsRequiredUserActionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LoggedOnDetails
     */
    extendedTimeout?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type LoggedOnDetailsRequiredUserActionEnum = 'UPDATE_PASSWORD' |
    'ACCEPT_TERMS_AND_CONDITIONS' |
    'MULTI_FACTOR_AUTHENTICATION';

/**
 * 
 * @export
 * @interface MaskedCardDetails
 */
export interface MaskedCardDetails {
    /**
     * 
     * @type {string}
     * @memberof MaskedCardDetails
     */
    cardNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof MaskedCardDetails
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {boolean}
     * @memberof MaskedCardDetails
     */
    cvcPresent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaskedCardDetails
     */
    cardTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedCardDetails
     */
    cardSubType?: MaskedCardDetailsCardSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MaskedCardDetails
     */
    cardIssuingCountryName?: string;
}

/**
    * @export
    * @enum {string}
    */
export type MaskedCardDetailsCardSubTypeEnum = 'UNSPECIFIED' |
    'DEBIT' |
    'CREDIT' |
    'CHARGE_CARD';

/**
 * 
 * @export
 * @interface MerchantAddress
 */
export interface MerchantAddress {
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    tradingName?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    faxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    id1?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    id1Label?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    id2?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantAddress
     */
    id2Label?: string;
}
/**
 * 
 * @export
 * @interface MerchantModel
 */
export interface MerchantModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantModel
     */
    merchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantModel
     */
    merchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantModel
     */
    merchantAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantModel
     */
    domainName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    isCvcRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    is3DSecure?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    isRefundAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    isScheduleWithoutCustomer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    isPaymentRequestAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MerchantModel
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantModel
     */
    supportEmailAddress?: string;
    /**
     * 
     * @type {DateFormatModel}
     * @memberof MerchantModel
     */
    dateFormat?: DateFormatModel;
    /**
     * 
     * @type {TimeFormatModel}
     * @memberof MerchantModel
     */
    timeFormat?: TimeFormatModel;
    /**
     * 
     * @type {NumberFormatModel}
     * @memberof MerchantModel
     */
    numberFormat?: NumberFormatModel;
    /**
     * 
     * @type {TimeZoneModel}
     * @memberof MerchantModel
     */
    timeZone?: TimeZoneModel;
    /**
     * 
     * @type {CurrencyModel}
     * @memberof MerchantModel
     */
    currency?: CurrencyModel;
    /**
     * 
     * @type {number}
     * @memberof MerchantModel
     */
    countryCode?: number;
    /**
     * 
     * @type {MessagingConfigurationModel}
     * @memberof MerchantModel
     */
    messaging?: MessagingConfigurationModel;
    /**
     * 
     * @type {Array<ChildMerchantModel>}
     * @memberof MerchantModel
     */
    childMerchants?: Array<ChildMerchantModel>;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    brandingByBiller?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    hasAnyReports?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantModel
     */
    applyRecurringSurcharge?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantModel
     */
    features?: Array<MerchantModelFeaturesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export type MerchantModelFeaturesEnum = 'SINGLE_PAYMENT' |
    'BATCH_PAYMENT' |
    'BATCH_MANAGER' |
    'DATA_VAULT' |
    'INTERNET' |
    'ADMIN_MENU' |
    'TRANSACTION_SEARCH' |
    'USER_MANAGEMENT' |
    'SETTLEMENT' |
    'REPORTS' |
    'IDB_UPLOAD' |
    'API_ACCESS' |
    'DELETE_BATCH_MANAGER_BATCH' |
    'PROCESS_REFUND' |
    'DIRECT_DEBIT' |
    'RECURRING_SCHEDULE' |
    'EFORMS' |
    'DECLINE_MANAGER' |
    'DECLINE_MANAGER_ADMIN' |
    'NOTIFICATIONS' |
    'MESSAGING' |
    'WEB_SHOP' |
    'INVOICES' |
    'CUSTOMERS' |
    'FRAUD_CONTROL' |
    'FRAUD_BASIC' |
    'API_CONFIGURATION' |
    'PAYMENT_OPTIONS' |
    'PHONE_PROMPTS' |
    'PHONE_PROMPTS_CREATE_NEW' |
    'PHONE_PROMPTS_LOGS' |
    'PLUGIN_CONFIGURATION' |
    'IVR_BLOCKED_CALLERS' |
    'IVR_CUSTOM_SETTINGS' |
    'IVR_CUSTOM_SETTINGS_ACTIONS' |
    'IVR_LOGS' |
    'IVR_OPERATOR' |
    'IVR_QUEUES' |
    'BILLER_CONFIG' |
    'PAYMENT_REQUEST' |
    'PAYMENT_REQUEST_ADMIN' |
    'PAYMENT_REQUEST_BATCH' |
    'SECURE_PHONE' |
    'LEGACY_XML' |
    'CONSUMER_PORTAL_ADMIN' |
    'EXTERNAL_REPORTING' |
    'STATIONERY_SHOP' |
    'NOT_STATIONERY_SHOP' |
    'QR_CODE' |
    'INVOICE_SETTINGS' |
    'PAYMENT_PLAN' |
    'PAYMENT_PLAN_SETTINGS' |
    'INVOICE_PAYMENTS' |
    'LEAST_COST_ROUTING' |
    'LCR_ALLOW_AMOUNT_THRESHOLD_CONFIGURATION' |
    'ADDITIONAL_PAYMENTS' |
    'MULTI_FACTOR_AUTHENTICATION' |
    'ALLOW_DISABLING_MFA';

/**
 * 
 * @export
 * @interface MerchantUserRolesModel
 */
export interface MerchantUserRolesModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantUserRolesModel
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {Array<UserRoleModel>}
     * @memberof MerchantUserRolesModel
     */
    roles?: Array<UserRoleModel>;
}
/**
 * 
 * @export
 * @interface MessageConfigurationModel
 */
export interface MessageConfigurationModel {
    /**
     * 
     * @type {boolean}
     * @memberof MessageConfigurationModel
     */
    emailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageConfigurationModel
     */
    smsEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface MessageEmailConfigurationModel
 */
export interface MessageEmailConfigurationModel {
    /**
     * 
     * @type {number}
     * @memberof MessageEmailConfigurationModel
     */
    merchantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageEmailConfigurationModel
     */
    messagingEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageEmailConfigurationModel
     */
    emailEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageEmailConfigurationModel
     */
    smsEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageEmailConfigurationModel
     */
    bccEmailAddress?: string;
}
/**
 * 
 * @export
 * @interface MessagingConfigurationModel
 */
export interface MessagingConfigurationModel {
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingConfigurationModel
     */
    singlePaymentReceipt?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingConfigurationModel
     */
    singlePaymentDecline?: MessageConfigurationModel;
}
/**
 * 
 * @export
 * @interface MessagingSettingsModel
 */
export interface MessagingSettingsModel {
    /**
     * 
     * @type {boolean}
     * @memberof MessagingSettingsModel
     */
    smsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessagingSettingsModel
     */
    emailEnabled?: boolean;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    singlePaymentReceipt?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    singlePaymentDecline?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    scheduledPaymentReminder?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    scheduledBatchReminder?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    eddrScheduleAcknowledgement?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    payLaterConfirmation?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    paymentMethodCreation?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    batchPaymentReceipt?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    batchPaymentDecline?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    internetPaymentReceipt?: MessageConfigurationModel;
    /**
     * 
     * @type {MessageConfigurationModel}
     * @memberof MessagingSettingsModel
     */
    internetPaymentDecline?: MessageConfigurationModel;
}
/**
 * 
 * @export
 * @interface MultiFactorAuthenticationSettings
 */
export interface MultiFactorAuthenticationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof MultiFactorAuthenticationSettings
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MultiFactorAuthenticationSettings
     */
    timeoutMins?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MultiFactorAuthenticationSettings
     */
    allowDisablingMFA?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MultiFactorAuthenticationSettings
     */
    timeoutMinsSelection?: Array<number>;
}
/**
 * 
 * @export
 * @interface MultiFactorAuthenticationValidationModel
 */
export interface MultiFactorAuthenticationValidationModel {
    /**
     * 
     * @type {string}
     * @memberof MultiFactorAuthenticationValidationModel
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MultiFactorAuthenticationValidationModel
     */
    remember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MultiFactorAuthenticationValidationModel
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiFactorAuthenticationValidationModel
     */
    merchantNumber?: string;
}
/**
 * 
 * @export
 * @interface NoticeModel
 */
export interface NoticeModel {
    /**
     * 
     * @type {number}
     * @memberof NoticeModel
     */
    noticeId?: number;
    /**
     * 
     * @type {string}
     * @memberof NoticeModel
     */
    noticeType?: NoticeModelNoticeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NoticeModel
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeModel
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeModel
     */
    updated?: string;
}

/**
    * @export
    * @enum {string}
    */
export type NoticeModelNoticeTypeEnum = 'Maintenance' |
    'Marketing' |
    'Compliance';

/**
 * 
 * @export
 * @interface NumberFormatModel
 */
export interface NumberFormatModel {
    /**
     * 
     * @type {number}
     * @memberof NumberFormatModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatModel
     */
    displayFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatModel
     */
    decimalSeparator?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatModel
     */
    thousandsSeparator?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberFormatModel
     */
    grouping?: string;
}
/**
 * 
 * @export
 * @interface OrderTypeModel
 */
export interface OrderTypeModel {
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeModel
     */
    cvcDisabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeModel
     */
    key?: OrderTypeModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type OrderTypeModelKeyEnum = 'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'TELEPHONE' |
    'RECURRING' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface OrderTypesData
 */
export interface OrderTypesData {
    /**
     * 
     * @type {Array<OrderTypeModel>}
     * @memberof OrderTypesData
     */
    items?: Array<OrderTypeModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderTypesData
     */
    defaultKey?: OrderTypesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type OrderTypesDataDefaultKeyEnum = 'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'TELEPHONE' |
    'RECURRING' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields
 */
export interface PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields {
    /**
     * 
     * @type {GetBatchFileContentsRequest}
     * @memberof PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields
     */
    request?: GetBatchFileContentsRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields
     */
    sortField?: PaginateRequestGetBatchFileContentsRequestBatchRecordSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields
     */
    order?: PaginateRequestGetBatchFileContentsRequestBatchRecordSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchFileContentsRequestBatchRecordSortFieldsSortFieldEnum = 'Reference' |
    'AccountNumber' |
    'ExpiryDate' |
    'Amount' |
    'AuthType';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchFileContentsRequestBatchRecordSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields
 */
export interface PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields {
    /**
     * 
     * @type {GetBatchJobsRequest}
     * @memberof PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields
     */
    request?: GetBatchJobsRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields
     */
    sortField?: PaginateRequestGetBatchJobsRequestBatchFileStatusSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields
     */
    order?: PaginateRequestGetBatchJobsRequestBatchFileStatusSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchJobsRequestBatchFileStatusSortFieldsSortFieldEnum = 'Filename' |
    'Status' |
    'UploadedRecordCount' |
    'ApprovedCount' |
    'UploadedValue' |
    'ApprovedValue' |
    'SubmittedDateTime' |
    'ProcessDateTime' |
    'ScheduledDateTime' |
    'UpdatedDateTime';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchJobsRequestBatchFileStatusSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields
 */
export interface PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields {
    /**
     * 
     * @type {GetBatchLibraryFilesRequest}
     * @memberof PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields
     */
    request?: GetBatchLibraryFilesRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields
     */
    sortField?: PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields
     */
    order?: PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFieldsSortFieldEnum = 'Filename' |
    'Status' |
    'UploadedRecordCount' |
    'ApprovedCount' |
    'UploadedValue' |
    'ApprovedValue' |
    'SubmittedDateTime' |
    'ProcessDateTime' |
    'ScheduledDateTime' |
    'UpdatedDateTime';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields
 */
export interface PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields {
    /**
     * 
     * @type {GetBatchRequestsRequest}
     * @memberof PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields
     */
    request?: GetBatchRequestsRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields
     */
    sortField?: PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields
     */
    order?: PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFieldsSortFieldEnum = 'Filename' |
    'Status' |
    'UploadedRecordCount' |
    'ApprovedCount' |
    'UploadedValue' |
    'ApprovedValue' |
    'SubmittedDateTime' |
    'ProcessDateTime' |
    'ScheduledDateTime' |
    'UpdatedDateTime';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestGetCustomersRequestCustomerModelSortFields
 */
export interface PaginateRequestGetCustomersRequestCustomerModelSortFields {
    /**
     * 
     * @type {GetCustomersRequest}
     * @memberof PaginateRequestGetCustomersRequestCustomerModelSortFields
     */
    request?: GetCustomersRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetCustomersRequestCustomerModelSortFields
     */
    sortField?: PaginateRequestGetCustomersRequestCustomerModelSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetCustomersRequestCustomerModelSortFields
     */
    order?: PaginateRequestGetCustomersRequestCustomerModelSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetCustomersRequestCustomerModelSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetCustomersRequestCustomerModelSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetCustomersRequestCustomerModelSortFieldsSortFieldEnum = 'LastUpdated' |
    'UniqueId' |
    'Name' |
    'EmailAddress' |
    'Crn1' |
    'Crn2' |
    'Crn3';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetCustomersRequestCustomerModelSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestGetUsersRequestUserModelSortFields
 */
export interface PaginateRequestGetUsersRequestUserModelSortFields {
    /**
     * 
     * @type {GetUsersRequest}
     * @memberof PaginateRequestGetUsersRequestUserModelSortFields
     */
    request?: GetUsersRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetUsersRequestUserModelSortFields
     */
    sortField?: PaginateRequestGetUsersRequestUserModelSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestGetUsersRequestUserModelSortFields
     */
    order?: PaginateRequestGetUsersRequestUserModelSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetUsersRequestUserModelSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestGetUsersRequestUserModelSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetUsersRequestUserModelSortFieldsSortFieldEnum = 'Status' |
    'FirstName' |
    'LastName' |
    'UserName' |
    'EmailAddress' |
    'RoleName' |
    'FullName' |
    'LastUpdated';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestGetUsersRequestUserModelSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields
 */
export interface PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields {
    /**
     * 
     * @type {InvoiceSearchInputModel}
     * @memberof PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields
     */
    request?: InvoiceSearchInputModel;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields
     */
    sortField?: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields
     */
    order?: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFieldsSortFieldEnum = 'Status' |
    'InvoiceNumber' |
    'OrderNumber' |
    'InvoiceDate' |
    'Amount' |
    'CurrencyCode';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields
 */
export interface PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields {
    /**
     * 
     * @type {PaymentPlanViewFilter}
     * @memberof PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields
     */
    request?: PaymentPlanViewFilter;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields
     */
    sortField?: PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields
     */
    order?: PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFieldsSortFieldEnum = 'Status' |
    'Reference' |
    'PaymentPlanOptionName' |
    'CardNumber' |
    'AccountNumber';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields
 */
export interface PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields {
    /**
     * 
     * @type {ScheduledPaymentsRequest}
     * @memberof PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields
     */
    request?: ScheduledPaymentsRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields
     */
    sortField?: PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields
     */
    order?: PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFieldsSortFieldEnum = 'ScheduledPaymentDate' |
    'AmountPaid' |
    'AttemptCount' |
    'ScheduledPaymentStatus';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields
 */
export interface PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields {
    /**
     * 
     * @type {SearchDataVaultRequest}
     * @memberof PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields
     */
    request?: SearchDataVaultRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields
     */
    sortField?: PaginateRequestSearchDataVaultRequestSearchDataVaultSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields
     */
    order?: PaginateRequestSearchDataVaultRequestSearchDataVaultSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchDataVaultRequestSearchDataVaultSortFieldsSortFieldEnum = 'Updated' |
    'Token' |
    'CustomerUniqueId' |
    'MaskedCardNumber' |
    'ExpiryDate' |
    'Crn1' |
    'Crn2' |
    'Crn3' |
    'DeBsbNumber' |
    'DeAccountNumber';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchDataVaultRequestSearchDataVaultSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestSearchFraudRulesInputFraudRuleSortFields
 */
export interface PaginateRequestSearchFraudRulesInputFraudRuleSortFields {
    /**
     * 
     * @type {SearchFraudRulesInput}
     * @memberof PaginateRequestSearchFraudRulesInputFraudRuleSortFields
     */
    request?: SearchFraudRulesInput;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchFraudRulesInputFraudRuleSortFields
     */
    sortField?: PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchFraudRulesInputFraudRuleSortFields
     */
    order?: PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchFraudRulesInputFraudRuleSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchFraudRulesInputFraudRuleSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsSortFieldEnum = 'Action' |
    'Type' |
    'LowField' |
    'HighField';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchFraudRulesInputFraudRuleSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestSearchRequestRequestSearchRequestSortFields
 */
export interface PaginateRequestSearchRequestRequestSearchRequestSortFields {
    /**
     * 
     * @type {SearchRequestRequest}
     * @memberof PaginateRequestSearchRequestRequestSearchRequestSortFields
     */
    request?: SearchRequestRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchRequestRequestSearchRequestSortFields
     */
    sortField?: PaginateRequestSearchRequestRequestSearchRequestSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchRequestRequestSearchRequestSortFields
     */
    order?: PaginateRequestSearchRequestRequestSearchRequestSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchRequestRequestSearchRequestSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchRequestRequestSearchRequestSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchRequestRequestSearchRequestSortFieldsSortFieldEnum = 'Created' |
    'Action' |
    'Status' |
    'Reference1' |
    'Amount' |
    'DeliveryReference' |
    'CustomerUniqueCode';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchRequestRequestSearchRequestSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields
 */
export interface PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields {
    /**
     * 
     * @type {SearchSubscriptionRequest}
     * @memberof PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields
     */
    request?: SearchSubscriptionRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields
     */
    sortField?: PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields
     */
    order?: PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFieldsSortFieldEnum = 'Created' |
    'ScheduleStatus' |
    'Reference1' |
    'TokenReference1' |
    'MaskedCardNumber' |
    'DeAccountNumber' |
    'ScheduleReference' |
    'RecurringAmount' |
    'NextPaymentDate' |
    'CustomerUniqueCode';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestSearchTransactionsInputTransactionSummarySortFields
 */
export interface PaginateRequestSearchTransactionsInputTransactionSummarySortFields {
    /**
     * 
     * @type {SearchTransactionsInput}
     * @memberof PaginateRequestSearchTransactionsInputTransactionSummarySortFields
     */
    request?: SearchTransactionsInput;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchTransactionsInputTransactionSummarySortFields
     */
    sortField?: PaginateRequestSearchTransactionsInputTransactionSummarySortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestSearchTransactionsInputTransactionSummarySortFields
     */
    order?: PaginateRequestSearchTransactionsInputTransactionSummarySortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchTransactionsInputTransactionSummarySortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestSearchTransactionsInputTransactionSummarySortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchTransactionsInputTransactionSummarySortFieldsSortFieldEnum = 'ProcessedDate' |
    'ResponseCode' |
    'TransactionType' |
    'Crn1' |
    'ReceiptNumber' |
    'MaskedCardNumber' |
    'Amount' |
    'AccountNumber';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestSearchTransactionsInputTransactionSummarySortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields
 */
export interface PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields {
    /**
     * 
     * @type {StationeryShopOrderListRequest}
     * @memberof PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields
     */
    request?: StationeryShopOrderListRequest;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields
     */
    sortField?: PaginateRequestStationeryShopOrderListRequestOrderSummarySortFieldsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields
     */
    order?: PaginateRequestStationeryShopOrderListRequestOrderSummarySortFieldsOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields
     */
    pageSize?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaginateRequestStationeryShopOrderListRequestOrderSummarySortFieldsSortFieldEnum = 'OrderStatus' |
    'DateCreated' |
    'OrderReference' |
    'TotalAmount';
/**
    * @export
    * @enum {string}
    */
export type PaginateRequestStationeryShopOrderListRequestOrderSummarySortFieldsOrderEnum = 'ASCENDING' |
    'DESCENDING';

/**
 * 
 * @export
 * @interface PaginatedListBatchFileContents
 */
export interface PaginatedListBatchFileContents {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileContents
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileContents
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileContents
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileContents
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<BatchFileContents>}
     * @memberof PaginatedListBatchFileContents
     */
    list?: Array<BatchFileContents>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListBatchFileContents
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListBatchFileContents
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListBatchFileInfo
 */
export interface PaginatedListBatchFileInfo {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileInfo
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileInfo
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileInfo
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListBatchFileInfo
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<BatchFileInfo>}
     * @memberof PaginatedListBatchFileInfo
     */
    list?: Array<BatchFileInfo>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListBatchFileInfo
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListBatchFileInfo
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListCustomerModel
 */
export interface PaginatedListCustomerModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListCustomerModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListCustomerModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListCustomerModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListCustomerModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<CustomerModel>}
     * @memberof PaginatedListCustomerModel
     */
    list?: Array<CustomerModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListCustomerModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListCustomerModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListDataVaultSummaryModel
 */
export interface PaginatedListDataVaultSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListDataVaultSummaryModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListDataVaultSummaryModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListDataVaultSummaryModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListDataVaultSummaryModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<DataVaultSummaryModel>}
     * @memberof PaginatedListDataVaultSummaryModel
     */
    list?: Array<DataVaultSummaryModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListDataVaultSummaryModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListDataVaultSummaryModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListFraudRuleModel
 */
export interface PaginatedListFraudRuleModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListFraudRuleModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListFraudRuleModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListFraudRuleModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListFraudRuleModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<FraudRuleModel>}
     * @memberof PaginatedListFraudRuleModel
     */
    list?: Array<FraudRuleModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListFraudRuleModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListFraudRuleModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListInvoiceSearchResponseModel
 */
export interface PaginatedListInvoiceSearchResponseModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListInvoiceSearchResponseModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListInvoiceSearchResponseModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListInvoiceSearchResponseModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListInvoiceSearchResponseModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<InvoiceSearchResponseModel>}
     * @memberof PaginatedListInvoiceSearchResponseModel
     */
    list?: Array<InvoiceSearchResponseModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListInvoiceSearchResponseModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListInvoiceSearchResponseModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListPaymentPlanViewModel
 */
export interface PaginatedListPaymentPlanViewModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListPaymentPlanViewModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListPaymentPlanViewModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListPaymentPlanViewModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListPaymentPlanViewModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<PaymentPlanViewModel>}
     * @memberof PaginatedListPaymentPlanViewModel
     */
    list?: Array<PaymentPlanViewModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListPaymentPlanViewModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListPaymentPlanViewModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListReportsFile
 */
export interface PaginatedListReportsFile {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListReportsFile
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListReportsFile
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListReportsFile
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListReportsFile
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<ReportsFile>}
     * @memberof PaginatedListReportsFile
     */
    list?: Array<ReportsFile>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListReportsFile
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListReportsFile
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListRequestSummaryModel
 */
export interface PaginatedListRequestSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListRequestSummaryModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListRequestSummaryModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListRequestSummaryModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListRequestSummaryModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<RequestSummaryModel>}
     * @memberof PaginatedListRequestSummaryModel
     */
    list?: Array<RequestSummaryModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListRequestSummaryModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListRequestSummaryModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListScheduledPaymentModel
 */
export interface PaginatedListScheduledPaymentModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListScheduledPaymentModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListScheduledPaymentModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListScheduledPaymentModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListScheduledPaymentModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<ScheduledPaymentModel>}
     * @memberof PaginatedListScheduledPaymentModel
     */
    list?: Array<ScheduledPaymentModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListScheduledPaymentModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListScheduledPaymentModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListStationeryShopOrderSummaryModel
 */
export interface PaginatedListStationeryShopOrderSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListStationeryShopOrderSummaryModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListStationeryShopOrderSummaryModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListStationeryShopOrderSummaryModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListStationeryShopOrderSummaryModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<StationeryShopOrderSummaryModel>}
     * @memberof PaginatedListStationeryShopOrderSummaryModel
     */
    list?: Array<StationeryShopOrderSummaryModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListStationeryShopOrderSummaryModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListStationeryShopOrderSummaryModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListSubscriptionSummaryModel
 */
export interface PaginatedListSubscriptionSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListSubscriptionSummaryModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListSubscriptionSummaryModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListSubscriptionSummaryModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListSubscriptionSummaryModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<SubscriptionSummaryModel>}
     * @memberof PaginatedListSubscriptionSummaryModel
     */
    list?: Array<SubscriptionSummaryModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListSubscriptionSummaryModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListSubscriptionSummaryModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListTransactionSummaryModel
 */
export interface PaginatedListTransactionSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListTransactionSummaryModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListTransactionSummaryModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListTransactionSummaryModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListTransactionSummaryModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<TransactionSummaryModel>}
     * @memberof PaginatedListTransactionSummaryModel
     */
    list?: Array<TransactionSummaryModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListTransactionSummaryModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListTransactionSummaryModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedListUserSummaryModel
 */
export interface PaginatedListUserSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof PaginatedListUserSummaryModel
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListUserSummaryModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListUserSummaryModel
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListUserSummaryModel
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<UserSummaryModel>}
     * @memberof PaginatedListUserSummaryModel
     */
    list?: Array<UserSummaryModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListUserSummaryModel
     */
    hasPreviousPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedListUserSummaryModel
     */
    hasNextPage?: boolean;
}
/**
 * 
 * @export
 * @interface PasswordInput
 */
export interface PasswordInput {
    /**
     * 
     * @type {string}
     * @memberof PasswordInput
     */
    currentPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordInput
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface PaymentBreakdown
 */
export interface PaymentBreakdown {
    /**
     * 
     * @type {string}
     * @memberof PaymentBreakdown
     */
    merchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBreakdown
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentBreakdown
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentBreakdown
     */
    originalAmount?: number;
    /**
     * 
     * @type {Breakdown}
     * @memberof PaymentBreakdown
     */
    breakdown?: Breakdown;
}
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    cardType?: PaymentMethodCardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    cardTypeCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    optional?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    allowSurcharge?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    differentiateSurchargeByCardSubType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    differentiateSurchargeByCardRegion?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentMethodCardTypeEnum = 'UNSPECIFIED' |
    'AMEX_LOCAL' |
    'MASTERCARD_LOCAL' |
    'VISA_LOCAL' |
    'DINERS_LOCAL' |
    'AMEX_INTL' |
    'MASTERCARD_INTL' |
    'VISA_INTL' |
    'DINERS_INTL' |
    'JCB_INTL' |
    'PAYPAL' |
    'DIRECT_ENTRY' |
    'CASH' |
    'CHEQUE' |
    'NONE' |
    'CHINA_UNION_PAY' |
    'MONEY_ORDER' |
    'INDUSTRY_CARD' |
    'KLARNA' |
    'EFTPOS';

/**
 * 
 * @export
 * @interface PaymentPlanCommitmentModel
 */
export interface PaymentPlanCommitmentModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCommitmentModel
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanCommitmentModel
     */
    frequency?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCommitmentModel
     */
    interval?: PaymentPlanCommitmentModelIntervalEnum;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentPlanCommitmentModelIntervalEnum = 'DAY' |
    'WEEK' |
    'MONTH' |
    'YEAR';

/**
 * 
 * @export
 * @interface PaymentPlanCustomerSummary
 */
export interface PaymentPlanCustomerSummary {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanCustomerSummary
     */
    paymentPlanId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummary
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummary
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummary
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummary
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummary
     */
    crn3?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanCustomerSummary
     */
    originalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanCustomerSummary
     */
    totalPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanCustomerSummary
     */
    remainingOwed?: number;
}
/**
 * 
 * @export
 * @interface PaymentPlanCustomerSummaryRequest
 */
export interface PaymentPlanCustomerSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummaryRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummaryRequest
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummaryRequest
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanCustomerSummaryRequest
     */
    crn3?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanCustomerSummaryRequest
     */
    paymentPlanId?: number;
}
/**
 * 
 * @export
 * @interface PaymentPlanDetailsModel
 */
export interface PaymentPlanDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsModel
     */
    status?: PaymentPlanDetailsModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsModel
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsModel
     */
    lastUpdatedOn?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanDetailsModel
     */
    totalAmountToPay?: number;
    /**
     * 
     * @type {PaymentPlanCommitmentModel}
     * @memberof PaymentPlanDetailsModel
     */
    paymentPlanCommitment?: PaymentPlanCommitmentModel;
    /**
     * 
     * @type {SubscriptionModel}
     * @memberof PaymentPlanDetailsModel
     */
    subscription?: SubscriptionModel;
    /**
     * 
     * @type {ScheduledPaymentModel}
     * @memberof PaymentPlanDetailsModel
     */
    initialPayment?: ScheduledPaymentModel;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentPlanDetailsModelStatusEnum = 'NONE' |
    'ACTIVE' |
    'CANCELLED' |
    'COMPLETED' |
    'PENDING';

/**
 * 
 * @export
 * @interface PaymentPlanDetailsRequest
 */
export interface PaymentPlanDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanDetailsRequest
     */
    paymentPlanId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsRequest
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsRequest
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanDetailsRequest
     */
    crn3?: string;
}
/**
 * 
 * @export
 * @interface PaymentPlanModel
 */
export interface PaymentPlanModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanModel
     */
    status?: PaymentPlanModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanModel
     */
    merchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanModel
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanModel
     */
    originalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanModel
     */
    startDate?: string;
    /**
     * 
     * @type {Breakdown}
     * @memberof PaymentPlanModel
     */
    breakdown?: Breakdown;
    /**
     * 
     * @type {PaymentProcessedTxnModel}
     * @memberof PaymentPlanModel
     */
    paymentProcessedTxnModel?: PaymentProcessedTxnModel;
    /**
     * 
     * @type {SubscriptionModel}
     * @memberof PaymentPlanModel
     */
    subscriptionModel?: SubscriptionModel;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanModel
     */
    dataVaultId?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentPlanModelStatusEnum = 'NONE' |
    'ACTIVE' |
    'CANCELLED' |
    'COMPLETED' |
    'PENDING';

/**
 * 
 * @export
 * @interface PaymentPlanOptionModel
 */
export interface PaymentPlanOptionModel {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionModel
     */
    paymentPlanOptionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    availableFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    availableTo?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    lastUpdated?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionModel
     */
    frequency?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    interval?: PaymentPlanOptionModelIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionModel
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionModel
     */
    discountPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    discountType?: PaymentPlanOptionModelDiscountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionModel
     */
    lastPaymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionModel
     */
    numOfPayment?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentPlanOptionModelIntervalEnum = 'DAY' |
    'WEEK' |
    'MONTH' |
    'YEAR';
/**
    * @export
    * @enum {string}
    */
export type PaymentPlanOptionModelDiscountTypeEnum = 'None' |
    'Percentage' |
    'Amount';

/**
 * 
 * @export
 * @interface PaymentPlanOptionRequest
 */
export interface PaymentPlanOptionRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionRequest
     */
    paymentPlanOptionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionRequest
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionRequest
     */
    availableFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionRequest
     */
    availableTo?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionRequest
     */
    frequency?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionRequest
     */
    interval?: PaymentPlanOptionRequestIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionRequest
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionRequest
     */
    discountPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionRequest
     */
    discountType?: PaymentPlanOptionRequestDiscountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanOptionRequest
     */
    lastPaymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanOptionRequest
     */
    numOfPayment?: number;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentPlanOptionRequestIntervalEnum = 'DAY' |
    'WEEK' |
    'MONTH' |
    'YEAR';
/**
    * @export
    * @enum {string}
    */
export type PaymentPlanOptionRequestDiscountTypeEnum = 'None' |
    'Percentage' |
    'Amount';

/**
 * 
 * @export
 * @interface PaymentPlanRequest
 */
export interface PaymentPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    merchant?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanRequest
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    accountName?: string;
    /**
     * 
     * @type {CardDetails}
     * @memberof PaymentPlanRequest
     */
    cardDetails?: CardDetails;
    /**
     * 
     * @type {BankAccountDetails}
     * @memberof PaymentPlanRequest
     */
    bankAccountDetails?: BankAccountDetails;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    tokenType?: PaymentPlanRequestTokenTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlanRequest
     */
    customerIdentified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanRequest
     */
    orderType?: PaymentPlanRequestOrderTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentPlanRequestTokenTypeEnum = 'CARD' |
    'BANKACCOUNT';
/**
    * @export
    * @enum {string}
    */
export type PaymentPlanRequestOrderTypeEnum = 'TELEPHONE' |
    'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'RECURRING';

/**
 * 
 * @export
 * @interface PaymentPlanViewFilter
 */
export interface PaymentPlanViewFilter {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    cardNumberFirst6?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    cardNumberLast3?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewFilter
     */
    accountNum?: string;
}
/**
 * 
 * @export
 * @interface PaymentPlanViewModel
 */
export interface PaymentPlanViewModel {
    /**
     * 
     * @type {number}
     * @memberof PaymentPlanViewModel
     */
    paymentPlanId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    paymentPlanOptionName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlanViewModel
     */
    accountNumber?: string;
}
/**
 * 
 * @export
 * @interface PaymentProcessedTxnModel
 */
export interface PaymentProcessedTxnModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    txnFinancialType?: PaymentProcessedTxnModelTxnFinancialTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    responseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    acqResponseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    authorizationResult?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentProcessedTxnModel
     */
    txnHistoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    receiptNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    rrn?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    processedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    settlementDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessedTxnModel
     */
    merchantReference?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentProcessedTxnModel
     */
    originalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentProcessedTxnModel
     */
    surchargeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentProcessedTxnModel
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentProcessedTxnModel
     */
    currencyCode?: number;
    /**
     * 
     * @type {MaskedCardDetails}
     * @memberof PaymentProcessedTxnModel
     */
    cardDetails?: MaskedCardDetails;
    /**
     * 
     * @type {BankAccountDetails}
     * @memberof PaymentProcessedTxnModel
     */
    bankDetails?: BankAccountDetails;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentProcessedTxnModel
     */
    isExternal?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentProcessedTxnModelTxnFinancialTypeEnum = 'UNSPECIFIED' |
    'PAYMENT' |
    'REFUND' |
    'CAPTURE' |
    'UNMATCHED_REFUND' |
    'PREAUTH' |
    'REVERSAL' |
    'MANUAL_REVERSAL' |
    'DE_REJECTION' |
    'ZERO_VALUE' |
    'VERIFY' |
    'CHEQUE_DISHONOUR' |
    'AUTHENTICATE';

/**
 * 
 * @export
 * @interface PaymentRequestSettings
 */
export interface PaymentRequestSettings {
    /**
     * 
     * @type {number}
     * @memberof PaymentRequestSettings
     */
    deliveryMethod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentRequestSettings
     */
    expiryDateMandatory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentRequestSettings
     */
    dueDateMandatory?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequestSettings
     */
    acceptablePaymentAmountType?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequestSettings
     */
    expirePaymentLink?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentRequestSettings
     */
    barSMSLinks?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentSource
 */
export interface PaymentSource {
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    txnSourceDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    userNameProcessed?: string;
    /**
     * 
     * @type {TxnTypeDetails}
     * @memberof PaymentSource
     */
    txnTypeDetails?: TxnTypeDetails;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    txnSubType?: PaymentSourceTxnSubTypeEnum;
    /**
     * 
     * @type {TransactionTypeDetails}
     * @memberof PaymentSource
     */
    transactionTypeDetails?: TransactionTypeDetails;
    /**
     * 
     * @type {number}
     * @memberof PaymentSource
     */
    merchantId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    merchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentSource
     */
    recurringScheduleId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    acqResponseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    authorisationResult?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    crn1Name?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    crn2Name?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    crn3Name?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    refundReason?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentSource
     */
    originalTransactionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSource
     */
    originalTransactionId?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSource
     */
    capturedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSource
     */
    refundedAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    partiallyCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    fullyCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    partiallyRefunded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    completelyRefunded?: boolean;
    /**
     * 
     * @type {WalletTypeDetails}
     * @memberof PaymentSource
     */
    walletType?: WalletTypeDetails;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    preAuthSentAsVerify?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    chequeNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    transactionNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    authoriseId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorCity?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorCompany?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorCountry?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentSource
     */
    statementDescriptorCountryCode?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorMerchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    statementDescriptorState?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    isQrCodeTxn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentSource
     */
    txnSubSourceDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    lcrEligible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentSource
     */
    eftposRouted?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentSourceTxnSubTypeEnum = 'UNSPECIFIED' |
    'SINGLE' |
    'RECURRING';

/**
 * 
 * @export
 * @interface PaymentUrlRequestModel
 */
export interface PaymentUrlRequestModel {
    /**
     * 
     * @type {number}
     * @memberof PaymentUrlRequestModel
     */
    merchantId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentUrlRequestModel
     */
    paymentFlowType?: PaymentUrlRequestModelPaymentFlowTypeEnum;
    /**
     * 
     * @type {Array<HppQueryStringModel>}
     * @memberof PaymentUrlRequestModel
     */
    _queryParameters?: Array<HppQueryStringModel>;
    /**
     * 
     * @type {Array<HppPathStringFieldModel>}
     * @memberof PaymentUrlRequestModel
     */
    pathParameters?: Array<HppPathStringFieldModel>;
}

/**
    * @export
    * @enum {string}
    */
export type PaymentUrlRequestModelPaymentFlowTypeEnum = 'Default' |
    'Register' |
    'PaymentPlan' |
    'EForms' |
    'AddDv' |
    'UpdateDv' |
    'Invoice';

/**
 * 
 * @export
 * @interface PhoneNumberModel
 */
export interface PhoneNumberModel {
    /**
     * 
     * @type {number}
     * @memberof PhoneNumberModel
     */
    typeId?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberModel
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @interface ProcessBatchFileRequest
 */
export interface ProcessBatchFileRequest {
    /**
     * 
     * @type {number}
     * @memberof ProcessBatchFileRequest
     */
    batchFileId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessBatchFileRequest
     */
    checkUploadHistory?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessBatchFileRequest
     */
    processAfter?: string;
}
/**
 * 
 * @export
 * @interface ProcessCaptureInput
 */
export interface ProcessCaptureInput {
    /**
     * 
     * @type {string}
     * @memberof ProcessCaptureInput
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessCaptureInput
     */
    txnHistoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessCaptureInput
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ProcessDuplicateBatchJobRequest
 */
export interface ProcessDuplicateBatchJobRequest {
    /**
     * 
     * @type {number}
     * @memberof ProcessDuplicateBatchJobRequest
     */
    batchFileId?: number;
}
/**
 * 
 * @export
 * @interface ProcessRefundInput
 */
export interface ProcessRefundInput {
    /**
     * 
     * @type {string}
     * @memberof ProcessRefundInput
     */
    refundReason?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessRefundInput
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessRefundInput
     */
    txnHistoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessRefundInput
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ProcessTransactionInput
 */
export interface ProcessTransactionInput {
    /**
     * 
     * @type {string}
     * @memberof ProcessTransactionInput
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransactionInput
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransactionInput
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransactionInput
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransactionInput
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransactionInput
     */
    merchantReference?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessTransactionInput
     */
    orderType?: ProcessTransactionInputOrderTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProcessTransactionInput
     */
    amount?: number;
    /**
     * 
     * @type {CardDetails}
     * @memberof ProcessTransactionInput
     */
    card?: CardDetails;
    /**
     * 
     * @type {number}
     * @memberof ProcessTransactionInput
     */
    customerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessTransactionInput
     */
    saveCustomerPaymentMethod?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProcessTransactionInput
     */
    walletTypeId?: number;
}

/**
    * @export
    * @enum {string}
    */
export type ProcessTransactionInputOrderTypeEnum = 'TELEPHONE' |
    'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'RECURRING';

/**
 * 
 * @export
 * @interface ProductTooltipModel
 */
export interface ProductTooltipModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTooltipModel
     */
    productModule?: ProductTooltipModelProductModuleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTooltipModel
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTooltipModel
     */
    linkText?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTooltipModel
     */
    link?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTooltipModel
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTooltipModel
     */
    youtubeLink?: string;
}

/**
    * @export
    * @enum {string}
    */
export type ProductTooltipModelProductModuleEnum = 'NEW_PAYMENT_TRANSACTIONS' |
    'MANAGE_TRANSACTIONS' |
    'SUBSCRIPTION_TRANSACTIONS' |
    'PAYMENT_PLAN_TRANSACTIONS' |
    'BATCH_PAYMENTS_TRANSACTIONS' |
    'CUSTOMERS_DATAVAULT' |
    'PAYMENT_METHODS_DATAVAULT' |
    'MANAGE_REQUESTS' |
    'BATCH_REQUESTS' |
    'SETTLEMENTS_REPORTS' |
    'CUSTOM_REPORTS' |
    'PAYMENT_PAGE_DESIGNER' |
    'PAYMENT_SETTINGS' |
    'FRAUD_MANAGEMENT_SETTINGS' |
    'INVOICE_SETTINGS' |
    'ISERVICES_SETTINGS' |
    'MANAGE_USERS_SETTINGS' |
    'MESSAGING_SETTINGS' |
    'PAYMENT_PLAN_SETTINGS' |
    'BUSINESS_DETAILS_SETTINGS' |
    'LEAST_COST_ROUTING_SETTINGS' |
    'LEAST_COST_ROUTING_SURCHARGE' |
    'NEW_PAYMENT_REQUEST' |
    'ADDITIONAL_PAYMENTS' |
    'MULTI_FACTOR_AUTHENTICATION_ENABLE' |
    'MULTI_FACTOR_AUTHENTICATION_TIMEOUT';

/**
 * 
 * @export
 * @interface ProductTooltipRequestModel
 */
export interface ProductTooltipRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ProductTooltipRequestModel
     */
    productTooltipModule?: ProductTooltipRequestModelProductTooltipModuleEnum;
}

/**
    * @export
    * @enum {string}
    */
export type ProductTooltipRequestModelProductTooltipModuleEnum = 'NEW_PAYMENT_TRANSACTIONS' |
    'MANAGE_TRANSACTIONS' |
    'SUBSCRIPTION_TRANSACTIONS' |
    'PAYMENT_PLAN_TRANSACTIONS' |
    'BATCH_PAYMENTS_TRANSACTIONS' |
    'CUSTOMERS_DATAVAULT' |
    'PAYMENT_METHODS_DATAVAULT' |
    'MANAGE_REQUESTS' |
    'BATCH_REQUESTS' |
    'SETTLEMENTS_REPORTS' |
    'CUSTOM_REPORTS' |
    'PAYMENT_PAGE_DESIGNER' |
    'PAYMENT_SETTINGS' |
    'FRAUD_MANAGEMENT_SETTINGS' |
    'INVOICE_SETTINGS' |
    'ISERVICES_SETTINGS' |
    'MANAGE_USERS_SETTINGS' |
    'MESSAGING_SETTINGS' |
    'PAYMENT_PLAN_SETTINGS' |
    'BUSINESS_DETAILS_SETTINGS' |
    'LEAST_COST_ROUTING_SETTINGS' |
    'LEAST_COST_ROUTING_SURCHARGE' |
    'NEW_PAYMENT_REQUEST' |
    'ADDITIONAL_PAYMENTS' |
    'MULTI_FACTOR_AUTHENTICATION_ENABLE' |
    'MULTI_FACTOR_AUTHENTICATION_TIMEOUT';

/**
 * 
 * @export
 * @interface ReceiptDetailsFileModel
 */
export interface ReceiptDetailsFileModel {
    /**
     * 
     * @type {ReceiptDetailsModel}
     * @memberof ReceiptDetailsFileModel
     */
    receiptDetailsModel?: ReceiptDetailsModel;
}
/**
 * 
 * @export
 * @interface ReceiptDetailsModel
 */
export interface ReceiptDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    tradingName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    abn?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    acn?: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof ReceiptDetailsModel
     */
    address?: AddressModel;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    faxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    receiptMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    id1?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    id1Label?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    id2?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    id2Label?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDetailsModel
     */
    logoFilename?: string;
    /**
     * 
     * @type {number}
     * @memberof ReceiptDetailsModel
     */
    logoFileSize?: number;
}
/**
 * 
 * @export
 * @interface RedResponseModel
 */
export interface RedResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RedResponseModel
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof RedResponseModel
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof RedResponseModel
     */
    statusCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RedResponseModel
     */
    responseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RedResponseModel
     */
    recordId?: string;
    /**
     * 
     * @type {string}
     * @memberof RedResponseModel
     */
    fraudNeural?: string;
}
/**
 * 
 * @export
 * @interface RenameBatchFileRequest
 */
export interface RenameBatchFileRequest {
    /**
     * 
     * @type {number}
     * @memberof RenameBatchFileRequest
     */
    batchFileId?: number;
    /**
     * 
     * @type {string}
     * @memberof RenameBatchFileRequest
     */
    fileName?: string;
}
/**
 * 
 * @export
 * @interface ReportsFile
 */
export interface ReportsFile {
    /**
     * 
     * @type {string}
     * @memberof ReportsFile
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFile
     */
    timeCreated?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFile
     */
    lastModified?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFile
     */
    lastAccessed?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFile
     */
    childMerchantNumber?: string;
}
/**
 * 
 * @export
 * @interface RequestActionDetails
 */
export interface RequestActionDetails {
    /**
     * 
     * @type {string}
     * @memberof RequestActionDetails
     */
    key?: RequestActionDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestActionDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type RequestActionDetailsKeyEnum = 'None' |
    'PaymentOnly' |
    'PreAuthOnly' |
    'PaymentAndTokenise' |
    'PreAuthAndTokenise' |
    'TokeniseOnly' |
    'UpdateToken' |
    'PaymentAndUpdateToken' |
    'PreAuthAndUpdateToken' |
    'UpdateAndRetryMissedPayment';

/**
 * 
 * @export
 * @interface RequestActionModel
 */
export interface RequestActionModel {
    /**
     * 
     * @type {string}
     * @memberof RequestActionModel
     */
    key?: RequestActionModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestActionModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type RequestActionModelKeyEnum = 'None' |
    'PaymentOnly' |
    'PreAuthOnly' |
    'PaymentAndTokenise' |
    'PreAuthAndTokenise' |
    'TokeniseOnly' |
    'UpdateToken' |
    'PaymentAndUpdateToken' |
    'PreAuthAndUpdateToken' |
    'UpdateAndRetryMissedPayment';

/**
 * 
 * @export
 * @interface RequestActionsData
 */
export interface RequestActionsData {
    /**
     * 
     * @type {Array<RequestActionModel>}
     * @memberof RequestActionsData
     */
    items?: Array<RequestActionModel>;
    /**
     * 
     * @type {string}
     * @memberof RequestActionsData
     */
    defaultKey?: RequestActionsDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type RequestActionsDataDefaultKeyEnum = 'None' |
    'PaymentOnly' |
    'PreAuthOnly' |
    'PaymentAndTokenise' |
    'PreAuthAndTokenise' |
    'TokeniseOnly' |
    'UpdateToken' |
    'PaymentAndUpdateToken' |
    'PreAuthAndUpdateToken' |
    'UpdateAndRetryMissedPayment';

/**
 * 
 * @export
 * @interface RequestModel
 */
export interface RequestModel {
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    guid?: string;
    /**
     * 
     * @type {RequestActionDetails}
     * @memberof RequestModel
     */
    actionDetails?: RequestActionDetails;
    /**
     * 
     * @type {RequestStatusDetails}
     * @memberof RequestModel
     */
    status?: RequestStatusDetails;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    action?: RequestModelActionEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    userNameUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    merchantReference?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestModel
     */
    currencyCode?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestModel
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    expiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    mobileNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestModel
     */
    customerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RequestModel
     */
    sendRequest?: boolean;
    /**
     * 
     * @type {CustomerModel}
     * @memberof RequestModel
     */
    customer?: CustomerModel;
    /**
     * 
     * @type {number}
     * @memberof RequestModel
     */
    dataVaultId?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestModel
     */
    requestingMerchantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RequestModel
     */
    qrCode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestModel
     */
    orderType?: RequestModelOrderTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type RequestModelActionEnum = 'None' |
    'PaymentOnly' |
    'PreAuthOnly' |
    'PaymentAndTokenise' |
    'PreAuthAndTokenise' |
    'TokeniseOnly' |
    'UpdateToken' |
    'PaymentAndUpdateToken' |
    'PreAuthAndUpdateToken' |
    'UpdateAndRetryMissedPayment';
/**
    * @export
    * @enum {string}
    */
export type RequestModelOrderTypeEnum = 'TELEPHONE' |
    'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'RECURRING';

/**
 * 
 * @export
 * @interface RequestResponse
 */
export interface RequestResponse {
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    guid?: string;
}
/**
 * 
 * @export
 * @interface RequestStatusDetails
 */
export interface RequestStatusDetails {
    /**
     * 
     * @type {string}
     * @memberof RequestStatusDetails
     */
    key?: RequestStatusDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestStatusDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type RequestStatusDetailsKeyEnum = 'Saved' |
    'Sent' |
    'Unpaid' |
    'Registered' |
    'Cancelled' |
    'PaidInFull' |
    'PaidPartially' |
    'Refunded' |
    'RefundedPartially' |
    'OverDue' |
    'Expired' |
    'AuthOnly';

/**
 * 
 * @export
 * @interface RequestStatusModel
 */
export interface RequestStatusModel {
    /**
     * 
     * @type {string}
     * @memberof RequestStatusModel
     */
    key?: RequestStatusModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestStatusModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type RequestStatusModelKeyEnum = 'Saved' |
    'Sent' |
    'Unpaid' |
    'Registered' |
    'Cancelled' |
    'PaidInFull' |
    'PaidPartially' |
    'Refunded' |
    'RefundedPartially' |
    'OverDue' |
    'Expired' |
    'AuthOnly';

/**
 * 
 * @export
 * @interface RequestStatusesData
 */
export interface RequestStatusesData {
    /**
     * 
     * @type {Array<RequestStatusModel>}
     * @memberof RequestStatusesData
     */
    items?: Array<RequestStatusModel>;
    /**
     * 
     * @type {string}
     * @memberof RequestStatusesData
     */
    defaultKey?: RequestStatusesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type RequestStatusesDataDefaultKeyEnum = 'Saved' |
    'Sent' |
    'Unpaid' |
    'Registered' |
    'Cancelled' |
    'PaidInFull' |
    'PaidPartially' |
    'Refunded' |
    'RefundedPartially' |
    'OverDue' |
    'Expired' |
    'AuthOnly';

/**
 * 
 * @export
 * @interface RequestSummaryModel
 */
export interface RequestSummaryModel {
    /**
     * 
     * @type {string}
     * @memberof RequestSummaryModel
     */
    guid?: string;
    /**
     * 
     * @type {RequestActionDetails}
     * @memberof RequestSummaryModel
     */
    action?: RequestActionDetails;
    /**
     * 
     * @type {RequestStatusDetails}
     * @memberof RequestSummaryModel
     */
    status?: RequestStatusDetails;
    /**
     * 
     * @type {string}
     * @memberof RequestSummaryModel
     */
    reference1?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestSummaryModel
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestSummaryModel
     */
    deliveryEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestSummaryModel
     */
    deliveryPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestSummaryModel
     */
    deliveryIddCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestSummaryModel
     */
    customerV2UniqueCode?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestSummaryModel
     */
    lastTxnHistoryId?: number;
}
/**
 * 
 * @export
 * @interface ResendInvoiceRequest
 */
export interface ResendInvoiceRequest {
    /**
     * 
     * @type {number}
     * @memberof ResendInvoiceRequest
     */
    invoiceId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResendInvoiceRequest
     */
    recipientEmail?: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordInput
 */
export interface ResetPasswordInput {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    merchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    captchaResponse?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordInput
     */
    platform?: string;
}
/**
 * 
 * @export
 * @interface ResetUserPasswordRequest
 */
export interface ResetUserPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ResetUserPasswordRequest
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface SampleBreakdownRequest
 */
export interface SampleBreakdownRequest {
    /**
     * 
     * @type {number}
     * @memberof SampleBreakdownRequest
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SampleBreakdownRequest
     */
    frequency?: number;
    /**
     * 
     * @type {string}
     * @memberof SampleBreakdownRequest
     */
    interval?: SampleBreakdownRequestIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof SampleBreakdownRequest
     */
    numOfPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof SampleBreakdownRequest
     */
    lastPaymentDate?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SampleBreakdownRequestIntervalEnum = 'DAY' |
    'WEEK' |
    'MONTH' |
    'YEAR';

/**
 * 
 * @export
 * @interface ScheduledPaymentModel
 */
export interface ScheduledPaymentModel {
    /**
     * 
     * @type {number}
     * @memberof ScheduledPaymentModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledPaymentModel
     */
    scheduledPaymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledPaymentModel
     */
    amountPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledPaymentModel
     */
    attemptCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledPaymentModel
     */
    successfulPayments?: number;
    /**
     * 
     * @type {ScheduledPaymentStatusDetails}
     * @memberof ScheduledPaymentModel
     */
    scheduledPaymentStatus?: ScheduledPaymentStatusDetails;
}
/**
 * 
 * @export
 * @interface ScheduledPaymentStatusDetails
 */
export interface ScheduledPaymentStatusDetails {
    /**
     * 
     * @type {string}
     * @memberof ScheduledPaymentStatusDetails
     */
    key?: ScheduledPaymentStatusDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof ScheduledPaymentStatusDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type ScheduledPaymentStatusDetailsKeyEnum = 'CANCELLED' |
    'OVERDUE' |
    'PROCESSING' |
    'RECEIVED';

/**
 * 
 * @export
 * @interface ScheduledPaymentsRequest
 */
export interface ScheduledPaymentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledPaymentsRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledPaymentsRequest
     */
    subscriptionId?: number;
}
/**
 * 
 * @export
 * @interface SchedulerSettings
 */
export interface SchedulerSettings {
    /**
     * 
     * @type {number}
     * @memberof SchedulerSettings
     */
    recurringAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof SchedulerSettings
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerSettings
     */
    interval?: SchedulerSettingsIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof SchedulerSettings
     */
    frequency?: number;
    /**
     * 
     * @type {number}
     * @memberof SchedulerSettings
     */
    numberOfPayments?: number;
}

/**
    * @export
    * @enum {string}
    */
export type SchedulerSettingsIntervalEnum = 'DAY' |
    'WEEK' |
    'MONTH' |
    'YEAR';

/**
 * 
 * @export
 * @interface SearchDataVaultRequest
 */
export interface SearchDataVaultRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof SearchDataVaultRequest
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    crn2?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    crn3?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    createdFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    createdTo?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    deBsbNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    deAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    accountName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDataVaultRequest
     */
    expiredOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    customerUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    customerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    customerLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    customerEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDataVaultRequest
     */
    customerPhoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDataVaultRequest
     */
    withoutCustomerOnly?: boolean;
}
/**
 * 
 * @export
 * @interface SearchFraudRulesInput
 */
export interface SearchFraudRulesInput {
    /**
     * 
     * @type {string}
     * @memberof SearchFraudRulesInput
     */
    action?: SearchFraudRulesInputActionEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchFraudRulesInput
     */
    type?: SearchFraudRulesInputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchFraudRulesInput
     */
    lowField?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFraudRulesInput
     */
    highField?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SearchFraudRulesInputActionEnum = 'ALLOW' |
    'BLOCK';
/**
    * @export
    * @enum {string}
    */
export type SearchFraudRulesInputTypeEnum = 'IP' |
    'CARD' |
    'BIN' |
    'CRN' |
    'PHONE' |
    'ISSUER_COUNTRY' |
    'IP_COUNTRY' |
    'PROXY' |
    'VPN';

/**
 * 
 * @export
 * @interface SearchRequestRequest
 */
export interface SearchRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    action?: SearchRequestRequestActionEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    status?: SearchRequestRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    reference1?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    reference2?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    reference3?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchRequestRequest
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchRequestRequest
     */
    amountFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchRequestRequest
     */
    amountTo?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    dueDateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    dueDateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    deliveryReference?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    customerUniqueCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    customerEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    customerPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    customerIdentifier1?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    customerIdentifier2?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    customerIdentifier3?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    createdFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestRequest
     */
    createdTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchRequestRequest
     */
    qrCode?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type SearchRequestRequestActionEnum = 'None' |
    'PaymentOnly' |
    'PreAuthOnly' |
    'PaymentAndTokenise' |
    'PreAuthAndTokenise' |
    'TokeniseOnly' |
    'UpdateToken' |
    'PaymentAndUpdateToken' |
    'PreAuthAndUpdateToken' |
    'UpdateAndRetryMissedPayment';
/**
    * @export
    * @enum {string}
    */
export type SearchRequestRequestStatusEnum = 'Saved' |
    'Sent' |
    'Unpaid' |
    'Registered' |
    'Cancelled' |
    'PaidInFull' |
    'PaidPartially' |
    'Refunded' |
    'RefundedPartially' |
    'OverDue' |
    'Expired' |
    'AuthOnly';

/**
 * 
 * @export
 * @interface SearchSubscriptionRequest
 */
export interface SearchSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    scheduleStatus?: SearchSubscriptionRequestScheduleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    reference1?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    reference2?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    reference3?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    scheduleReference?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchSubscriptionRequest
     */
    recurringAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    frequency?: SearchSubscriptionRequestFrequencyEnum;
    /**
     * 
     * @type {DateRange}
     * @memberof SearchSubscriptionRequest
     */
    created?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    scheduleEnd?: SearchSubscriptionRequestScheduleEndEnum;
    /**
     * 
     * @type {number}
     * @memberof SearchSubscriptionRequest
     */
    numberOfPayments?: number;
    /**
     * 
     * @type {DateRange}
     * @memberof SearchSubscriptionRequest
     */
    end?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof SearchSubscriptionRequest
     */
    nextPayment?: DateRange;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    schedulePaymentStatus?: SearchSubscriptionRequestSchedulePaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    tokenReference1?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    tokenReference2?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    tokenReference3?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof SearchSubscriptionRequest
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    deAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    deBsbNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchSubscriptionRequest
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchSubscriptionRequest
     */
    customerUniqueCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SearchSubscriptionRequestScheduleStatusEnum = 'SCHEDULED' |
    'FINISHED' |
    'PAUSED' |
    'DELETED' |
    'ERROR';
/**
    * @export
    * @enum {string}
    */
export type SearchSubscriptionRequestFrequencyEnum = 'WEEKLY' |
    'FORTNIGHTLY' |
    'MONTHLY' |
    'LAST_DAY_OF_MONTH' |
    'BI_MONTHLY' |
    'QUARTERLY' |
    'HALF_YEARLY' |
    'ANNUALLY';
/**
    * @export
    * @enum {string}
    */
export type SearchSubscriptionRequestScheduleEndEnum = 'NO_END' |
    'END_AFTER' |
    'END_DATE';
/**
    * @export
    * @enum {string}
    */
export type SearchSubscriptionRequestSchedulePaymentStatusEnum = 'UP_TO_DATE' |
    'OVERDUE';

/**
 * 
 * @export
 * @interface SearchTransactionsInput
 */
export interface SearchTransactionsInput {
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    transactionFromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    transactionToDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    settlementDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionsInput
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionsInput
     */
    amountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionsInput
     */
    amountMax?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    merchantReference?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    receiptNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    searchTransactionsResponseType?: SearchTransactionsInputSearchTransactionsResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    rrn?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    cardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    chequeNo?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    transactionType?: SearchTransactionsInputTransactionTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTransactionsInput
     */
    transactionTypes?: Array<SearchTransactionsInputTransactionTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    transactionSource?: SearchTransactionsInputTransactionSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    orderType?: SearchTransactionsInputOrderTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTransactionsInput
     */
    txnSources?: Array<SearchTransactionsInputTxnSourcesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTransactionsInput
     */
    txnSubSources?: Array<SearchTransactionsInputTxnSubSourcesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTransactionsInput
     */
    txnTypes?: Array<SearchTransactionsInputTxnTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTransactionsInput
     */
    txnSubTypes?: Array<SearchTransactionsInputTxnSubTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTransactionsInput
     */
    cardTypes?: Array<SearchTransactionsInputCardTypesEnum>;
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionsInput
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    customerUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    customerEmailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransactionsInput
     */
    customerPhoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchTransactionsInput
     */
    txnRequestId?: number;
}

/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputSearchTransactionsResponseTypeEnum = 'APPROVED' |
    'DECLINED';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputTransactionTypeEnum = 'UNSPECIFIED' |
    'PAYMENT' |
    'REFUND' |
    'CAPTURE' |
    'UNMATCHED_REFUND' |
    'PREAUTH' |
    'REVERSAL' |
    'MANUAL_REVERSAL' |
    'DE_REJECTION' |
    'ZERO_VALUE' |
    'VERIFY' |
    'CHEQUE_DISHONOUR' |
    'AUTHENTICATE';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputTransactionTypesEnum = 'UNSPECIFIED' |
    'PAYMENT' |
    'REFUND' |
    'CAPTURE' |
    'UNMATCHED_REFUND' |
    'PREAUTH' |
    'REVERSAL' |
    'MANUAL_REVERSAL' |
    'DE_REJECTION' |
    'ZERO_VALUE' |
    'VERIFY' |
    'CHEQUE_DISHONOUR' |
    'AUTHENTICATE';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputTransactionSourceEnum = 'BACKOFFICE' |
    'INTERNET' |
    'PHONE' |
    'API' |
    'APF' |
    'SFTP' |
    'LINK_GENERATOR' |
    'E_LOCKBOX' |
    'ALL_BPOINT' |
    'AUSTRALIA_POST' |
    'LOCKBOX' |
    'BPAY' |
    'DUN_BRADSTREET';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputOrderTypeEnum = 'ECOMMERCE' |
    'IN_PERSON' |
    'MAIL_FAX' |
    'TELEPHONE' |
    'RECURRING' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputTxnSourcesEnum = 'UNSPECIFIED' |
    'IVR' |
    'INTERNETANNONYMOUS' |
    'MERCHANTBACKOFFICE' |
    'CALLCENTER' |
    'API' |
    'ISHOP' |
    'MOBILEBACKOFFICE' |
    'CUSTOMERPORTAL' |
    'SFTP' |
    'MERCHANTSUITE' |
    'INVOICE' |
    'VICROADS_BOOKINGS' |
    'VICROADS_REPLACEMENTS' |
    'VICROADS_ONLINEWEBPAY' |
    'VICROADS_HISTORYREPORT';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputTxnSubSourcesEnum = 'PAYMENT_REQUEST' |
    'OTHER' |
    'AUSTRALIA_POST' |
    'LOCKBOX' |
    'DUN_BRADSTREET' |
    'BPAY' |
    'APF' |
    'ELOCKBOX' |
    'SCAN_AND_PAY' |
    'QR_CODE_OTHER';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputTxnTypesEnum = 'UNSPECIFIED' |
    'MAIL_ORDER' |
    'TELEPHONE_ORDER' |
    'ECOMMERCE' |
    'CARD_PRESENT' |
    'CALL_CENTRE' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputTxnSubTypesEnum = 'UNSPECIFIED' |
    'SINGLE' |
    'RECURRING';
/**
    * @export
    * @enum {string}
    */
export type SearchTransactionsInputCardTypesEnum = 'UNSPECIFIED' |
    'AMEX_LOCAL' |
    'MASTERCARD_LOCAL' |
    'VISA_LOCAL' |
    'DINERS_LOCAL' |
    'AMEX_INTL' |
    'MASTERCARD_INTL' |
    'VISA_INTL' |
    'DINERS_INTL' |
    'JCB_INTL' |
    'PAYPAL' |
    'DIRECT_ENTRY' |
    'CASH' |
    'CHEQUE' |
    'NONE' |
    'CHINA_UNION_PAY' |
    'MONEY_ORDER' |
    'INDUSTRY_CARD' |
    'KLARNA' |
    'EFTPOS';

/**
 * 
 * @export
 * @interface SetIsTopUpsEnabledRequest
 */
export interface SetIsTopUpsEnabledRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SetIsTopUpsEnabledRequest
     */
    enabled?: boolean;
}
/**
 * 
 * @export
 * @interface SettlementReportModel
 */
export interface SettlementReportModel {
    /**
     * 
     * @type {SummaryReportModel}
     * @memberof SettlementReportModel
     */
    summaryReport?: SummaryReportModel;
    /**
     * 
     * @type {TransactionsBreakdownModel}
     * @memberof SettlementReportModel
     */
    transactionsBreakdown?: TransactionsBreakdownModel;
}
/**
 * 
 * @export
 * @interface SettlementReportRequest
 */
export interface SettlementReportRequest {
    /**
     * 
     * @type {string}
     * @memberof SettlementReportRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementReportRequest
     */
    toDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementReportRequest
     */
    childMerchantNumber?: string;
}
/**
 * 
 * @export
 * @interface SinglePaymentReceiptInput
 */
export interface SinglePaymentReceiptInput {
    /**
     * 
     * @type {string}
     * @memberof SinglePaymentReceiptInput
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SinglePaymentReceiptInput
     */
    txnHistoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof SinglePaymentReceiptInput
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof SinglePaymentReceiptInput
     */
    mobileNumber?: string;
}
/**
 * 
 * @export
 * @interface StationeryShopAddressModel
 */
export interface StationeryShopAddressModel {
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    addressType?: StationeryShopAddressModelAddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    street1?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    street2?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopAddressModel
     */
    faxNumber?: string;
}

/**
    * @export
    * @enum {string}
    */
export type StationeryShopAddressModelAddressTypeEnum = 'UNSPECIFIED' |
    'BILLING' |
    'DELIVERY';

/**
 * 
 * @export
 * @interface StationeryShopCategoryModel
 */
export interface StationeryShopCategoryModel {
    /**
     * 
     * @type {number}
     * @memberof StationeryShopCategoryModel
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopCategoryModel
     */
    categoryTitle?: string;
}
/**
 * 
 * @export
 * @interface StationeryShopOrderItemModel
 */
export interface StationeryShopOrderItemModel {
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderItemModel
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderItemModel
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderItemModel
     */
    productDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderItemModel
     */
    productImage?: string;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    quantityPerPack?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StationeryShopOrderItemModel
     */
    isAvailable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StationeryShopOrderItemModel
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StationeryShopOrderItemModel
     */
    isFreightFee?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    unitCost?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    amountPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    amountBilled?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    gstAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    discount?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderItemModel
     */
    status?: StationeryShopOrderItemModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderItemModel
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderItemModel
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderItemModel
     */
    userIdUpdated?: number;
}

/**
    * @export
    * @enum {string}
    */
export type StationeryShopOrderItemModelStatusEnum = 'UNSPECIFIED' |
    'PENDING' |
    'RECEIVED_BY_TOLL' |
    'TOLL_WAREHOUSE' |
    'FULFILLED' |
    'BILLED' |
    'TOLL_BACK_ORDER' |
    'ABANDONED' |
    'CANCELLED' |
    'DELETED_BY_TOLL';

/**
 * 
 * @export
 * @interface StationeryShopOrderListRequest
 */
export interface StationeryShopOrderListRequest {
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderListRequest
     */
    orderReference?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderListRequest
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderListRequest
     */
    toDate?: string;
}
/**
 * 
 * @export
 * @interface StationeryShopOrderModel
 */
export interface StationeryShopOrderModel {
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    orderReference?: string;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    customerNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StationeryShopOrderModel
     */
    isFreeOrder?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    freeOrderReason?: string;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    amountPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    discount?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    penalty?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    amountBilled?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    orderCost?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    gstAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    street1?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    street2?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    lastName?: string;
    /**
     * 
     * @type {StationeryShopOrderStatus}
     * @memberof StationeryShopOrderModel
     */
    status?: StationeryShopOrderStatus;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderModel
     */
    userIdUpdated?: number;
    /**
     * 
     * @type {Array<StationeryShopOrderItemModel>}
     * @memberof StationeryShopOrderModel
     */
    items?: Array<StationeryShopOrderItemModel>;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    processed?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    dispatched?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    billed?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderModel
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface StationeryShopOrderStatus
 */
export interface StationeryShopOrderStatus {
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderStatus
     */
    key?: StationeryShopOrderStatusKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderStatus
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type StationeryShopOrderStatusKeyEnum = 'UNSPECIFIED' |
    'PENDING' |
    'ABANDONED' |
    'CANCELLED' |
    'PROCESSED' |
    'DISPATCHED' |
    'BILLED';

/**
 * 
 * @export
 * @interface StationeryShopOrderSummaryModel
 */
export interface StationeryShopOrderSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderSummaryModel
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderSummaryModel
     */
    orderReference?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopOrderSummaryModel
     */
    dateCreated?: string;
    /**
     * 
     * @type {StationeryShopOrderStatus}
     * @memberof StationeryShopOrderSummaryModel
     */
    orderStatus?: StationeryShopOrderStatus;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopOrderSummaryModel
     */
    totalAmount?: number;
}
/**
 * 
 * @export
 * @interface StationeryShopProductModel
 */
export interface StationeryShopProductModel {
    /**
     * 
     * @type {number}
     * @memberof StationeryShopProductModel
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopProductModel
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopProductModel
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopProductModel
     */
    productDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopProductModel
     */
    productImage?: string;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopProductModel
     */
    quantityPerPack?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopProductModel
     */
    minOrderQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopProductModel
     */
    maxOrderQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopProductModel
     */
    itemCost?: number;
    /**
     * 
     * @type {number}
     * @memberof StationeryShopProductModel
     */
    itemStock?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StationeryShopProductModel
     */
    categories?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof StationeryShopProductModel
     */
    isAvailable?: boolean;
}
/**
 * 
 * @export
 * @interface StationeryShopProductRequest
 */
export interface StationeryShopProductRequest {
    /**
     * 
     * @type {number}
     * @memberof StationeryShopProductRequest
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopProductRequest
     */
    order?: StationeryShopProductRequestOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof StationeryShopProductRequest
     */
    sortField?: StationeryShopProductRequestSortFieldEnum;
}

/**
    * @export
    * @enum {string}
    */
export type StationeryShopProductRequestOrderEnum = 'ASCENDING' |
    'DESCENDING';
/**
    * @export
    * @enum {string}
    */
export type StationeryShopProductRequestSortFieldEnum = 'ProductCode' |
    'ProductName' |
    'ItemCost';

/**
 * 
 * @export
 * @interface StopRecurringManagerRequest
 */
export interface StopRecurringManagerRequest {
    /**
     * 
     * @type {number}
     * @memberof StopRecurringManagerRequest
     */
    batchFileId?: number;
}
/**
 * 
 * @export
 * @interface SubscriptionFrequenciesData
 */
export interface SubscriptionFrequenciesData {
    /**
     * 
     * @type {Array<SubscriptionFrequencyModel>}
     * @memberof SubscriptionFrequenciesData
     */
    items?: Array<SubscriptionFrequencyModel>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionFrequenciesData
     */
    defaultKey?: SubscriptionFrequenciesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionFrequenciesDataDefaultKeyEnum = 'WEEKLY' |
    'FORTNIGHTLY' |
    'MONTHLY' |
    'LAST_DAY_OF_MONTH' |
    'BI_MONTHLY' |
    'QUARTERLY' |
    'HALF_YEARLY' |
    'ANNUALLY';

/**
 * 
 * @export
 * @interface SubscriptionFrequencyDetails
 */
export interface SubscriptionFrequencyDetails {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionFrequencyDetails
     */
    key?: SubscriptionFrequencyDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionFrequencyDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionFrequencyDetailsKeyEnum = 'WEEKLY' |
    'FORTNIGHTLY' |
    'MONTHLY' |
    'LAST_DAY_OF_MONTH' |
    'BI_MONTHLY' |
    'QUARTERLY' |
    'HALF_YEARLY' |
    'ANNUALLY';

/**
 * 
 * @export
 * @interface SubscriptionFrequencyModel
 */
export interface SubscriptionFrequencyModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionFrequencyModel
     */
    key?: SubscriptionFrequencyModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionFrequencyModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionFrequencyModelKeyEnum = 'WEEKLY' |
    'FORTNIGHTLY' |
    'MONTHLY' |
    'LAST_DAY_OF_MONTH' |
    'BI_MONTHLY' |
    'QUARTERLY' |
    'HALF_YEARLY' |
    'ANNUALLY';

/**
 * 
 * @export
 * @interface SubscriptionModel
 */
export interface SubscriptionModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    recurringScheduleId?: number;
    /**
     * 
     * @type {SubscriptionStatusDetails}
     * @memberof SubscriptionModel
     */
    subscriptionStatus?: SubscriptionStatusDetails;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    reference1?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    reference2?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    reference3?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    scheduleReference?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    recurringAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    surchargeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    frequency?: SubscriptionModelFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    nextPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    scheduleEnd?: SubscriptionModelScheduleEndEnum;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    numberOfPayments?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    dataVaultId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    createdDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    updatedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    originalStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    userNameUpdated?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    amountCollected?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    remainingPayments?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    remainingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionModel
     */
    customerUniqueCode?: string;
    /**
     * 
     * @type {CustomerModel}
     * @memberof SubscriptionModel
     */
    customer?: CustomerModel;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    numberOfOverduePayments?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    numberOfSuccessfulPayments?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    amountOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    remainingAmountScheduled?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    amountOriginalCollected?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionModel
     */
    amountSurchargeCollected?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionModel
     */
    surchargeApplied?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionModelFrequencyEnum = 'WEEKLY' |
    'FORTNIGHTLY' |
    'MONTHLY' |
    'LAST_DAY_OF_MONTH' |
    'BI_MONTHLY' |
    'QUARTERLY' |
    'HALF_YEARLY' |
    'ANNUALLY';
/**
    * @export
    * @enum {string}
    */
export type SubscriptionModelScheduleEndEnum = 'NO_END' |
    'END_AFTER' |
    'END_DATE';

/**
 * 
 * @export
 * @interface SubscriptionPaymentStatusData
 */
export interface SubscriptionPaymentStatusData {
    /**
     * 
     * @type {Array<SubscriptionPaymentStatusModel>}
     * @memberof SubscriptionPaymentStatusData
     */
    items?: Array<SubscriptionPaymentStatusModel>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPaymentStatusData
     */
    defaultKey?: SubscriptionPaymentStatusDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionPaymentStatusDataDefaultKeyEnum = 'UP_TO_DATE' |
    'OVERDUE';

/**
 * 
 * @export
 * @interface SubscriptionPaymentStatusModel
 */
export interface SubscriptionPaymentStatusModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPaymentStatusModel
     */
    key?: SubscriptionPaymentStatusModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPaymentStatusModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionPaymentStatusModelKeyEnum = 'UP_TO_DATE' |
    'OVERDUE';

/**
 * 
 * @export
 * @interface SubscriptionRequest
 */
export interface SubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    reference1?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    reference2?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    reference3?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    scheduleReference?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRequest
     */
    recurringAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRequest
     */
    surchargeAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    frequency?: SubscriptionRequestFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    scheduleEnd?: SubscriptionRequestScheduleEndEnum;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRequest
     */
    numberOfPayments?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRequest
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRequest
     */
    dataVaultId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRequest
     */
    customerId?: number;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionRequestFrequencyEnum = 'WEEKLY' |
    'FORTNIGHTLY' |
    'MONTHLY' |
    'LAST_DAY_OF_MONTH' |
    'BI_MONTHLY' |
    'QUARTERLY' |
    'HALF_YEARLY' |
    'ANNUALLY';
/**
    * @export
    * @enum {string}
    */
export type SubscriptionRequestScheduleEndEnum = 'NO_END' |
    'END_AFTER' |
    'END_DATE';

/**
 * 
 * @export
 * @interface SubscriptionStatusDetails
 */
export interface SubscriptionStatusDetails {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusDetails
     */
    key?: SubscriptionStatusDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionStatusDetailsKeyEnum = 'SCHEDULED' |
    'FINISHED' |
    'PAUSED' |
    'DELETED' |
    'ERROR';

/**
 * 
 * @export
 * @interface SubscriptionStatusModel
 */
export interface SubscriptionStatusModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusModel
     */
    key?: SubscriptionStatusModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionStatusModelKeyEnum = 'SCHEDULED' |
    'FINISHED' |
    'PAUSED' |
    'DELETED' |
    'ERROR';

/**
 * 
 * @export
 * @interface SubscriptionStatusesData
 */
export interface SubscriptionStatusesData {
    /**
     * 
     * @type {Array<SubscriptionStatusModel>}
     * @memberof SubscriptionStatusesData
     */
    items?: Array<SubscriptionStatusModel>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusesData
     */
    defaultKey?: SubscriptionStatusesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type SubscriptionStatusesDataDefaultKeyEnum = 'SCHEDULED' |
    'FINISHED' |
    'PAUSED' |
    'DELETED' |
    'ERROR';

/**
 * 
 * @export
 * @interface SubscriptionSummaryModel
 */
export interface SubscriptionSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSummaryModel
     */
    recurringScheduleId?: number;
    /**
     * 
     * @type {SubscriptionStatusDetails}
     * @memberof SubscriptionSummaryModel
     */
    subscriptionStatus?: SubscriptionStatusDetails;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryModel
     */
    reference1?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryModel
     */
    tokenReference1?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryModel
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryModel
     */
    scheduleReference?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSummaryModel
     */
    recurringAmount?: number;
    /**
     * 
     * @type {SubscriptionFrequencyDetails}
     * @memberof SubscriptionSummaryModel
     */
    frequency?: SubscriptionFrequencyDetails;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryModel
     */
    nextPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSummaryModel
     */
    customerUniqueCode?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionSuspendRequest
 */
export interface SubscriptionSuspendRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSuspendRequest
     */
    numberOfPayments?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSuspendRequest
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @interface SummaryReportAmountsModel
 */
export interface SummaryReportAmountsModel {
    /**
     * 
     * @type {number}
     * @memberof SummaryReportAmountsModel
     */
    authorised?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryReportAmountsModel
     */
    rejected?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryReportAmountsModel
     */
    refunded?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryReportAmountsModel
     */
    net?: number;
}
/**
 * 
 * @export
 * @interface SummaryReportModel
 */
export interface SummaryReportModel {
    /**
     * 
     * @type {SummaryReportAmountsModel}
     * @memberof SummaryReportModel
     */
    cards?: SummaryReportAmountsModel;
    /**
     * 
     * @type {SummaryReportAmountsModel}
     * @memberof SummaryReportModel
     */
    bankAccounts?: SummaryReportAmountsModel;
    /**
     * 
     * @type {SummaryReportAmountsModel}
     * @memberof SummaryReportModel
     */
    digitalWallets?: SummaryReportAmountsModel;
    /**
     * 
     * @type {SummaryReportAmountsModel}
     * @memberof SummaryReportModel
     */
    totals?: SummaryReportAmountsModel;
}
/**
 * 
 * @export
 * @interface SurchargeRule
 */
export interface SurchargeRule {
    /**
     * 
     * @type {string}
     * @memberof SurchargeRule
     */
    cardTypeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SurchargeRule
     */
    cardSubType?: SurchargeRuleCardSubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SurchargeRule
     */
    cardLocalisation?: SurchargeRuleCardLocalisationEnum;
    /**
     * 
     * @type {number}
     * @memberof SurchargeRule
     */
    fixedSurchargeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SurchargeRule
     */
    fixedSurchargePercent?: number;
    /**
     * 
     * @type {LimitsNullableInt32}
     * @memberof SurchargeRule
     */
    surchargeLimits?: LimitsNullableInt32;
}

/**
    * @export
    * @enum {string}
    */
export type SurchargeRuleCardSubTypeEnum = 'UNSPECIFIED' |
    'DEBIT' |
    'CREDIT' |
    'CHARGE_CARD';
/**
    * @export
    * @enum {string}
    */
export type SurchargeRuleCardLocalisationEnum = 'UNSPECIFIED' |
    'LOCAL' |
    'INTERNATIONAL';

/**
 * 
 * @export
 * @interface SurchargeRuleSet
 */
export interface SurchargeRuleSet {
    /**
     * 
     * @type {Array<SurchargeRule>}
     * @memberof SurchargeRuleSet
     */
    surcharges?: Array<SurchargeRule>;
}
/**
 * 
 * @export
 * @interface TermOfPayment
 */
export interface TermOfPayment {
    /**
     * 
     * @type {number}
     * @memberof TermOfPayment
     */
    daysToPay?: number;
    /**
     * 
     * @type {string}
     * @memberof TermOfPayment
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface TermOfPaymentModel
 */
export interface TermOfPaymentModel {
    /**
     * 
     * @type {number}
     * @memberof TermOfPaymentModel
     */
    daysToPay?: number;
    /**
     * 
     * @type {string}
     * @memberof TermOfPaymentModel
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface TermsModel
 */
export interface TermsModel {
    /**
     * 
     * @type {number}
     * @memberof TermsModel
     */
    termsId?: number;
    /**
     * 
     * @type {string}
     * @memberof TermsModel
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsModel
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsModel
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TermsModel
     */
    isCompulsory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TermsModel
     */
    isUserTypeTermsAndConditions?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TermsModel
     */
    merchantId?: number;
    /**
     * 
     * @type {number}
     * @memberof TermsModel
     */
    billerId?: number;
    /**
     * 
     * @type {string}
     * @memberof TermsModel
     */
    walletType?: TermsModelWalletTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TermsModel
     */
    walletDisplayName?: string;
}

/**
    * @export
    * @enum {string}
    */
export type TermsModelWalletTypeEnum = 'Unspecified' |
    'MasterPass' |
    'AmexWallet' |
    'ApplePay';

/**
 * 
 * @export
 * @interface ThreeDsAuthenticationModel
 */
export interface ThreeDsAuthenticationModel {
    /**
     * 
     * @type {string}
     * @memberof ThreeDsAuthenticationModel
     */
    threeDsEci?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDsAuthenticationModel
     */
    threeDsEnrolled?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDsAuthenticationModel
     */
    threeDsStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDsAuthenticationModel
     */
    threeDsVerifySecurityLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDsAuthenticationModel
     */
    threeDsVerifyStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDsAuthenticationModel
     */
    threeDsVerifyToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDsAuthenticationModel
     */
    threeDsXid?: string;
}
/**
 * 
 * @export
 * @interface TimeFormatModel
 */
export interface TimeFormatModel {
    /**
     * 
     * @type {number}
     * @memberof TimeFormatModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeFormatModel
     */
    display?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeFormatModel
     */
    specifier?: string;
}
/**
 * 
 * @export
 * @interface TimeZoneModel
 */
export interface TimeZoneModel {
    /**
     * 
     * @type {number}
     * @memberof TimeZoneModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneModel
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeZoneModel
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface TransactionDetailModel
 */
export interface TransactionDetailModel {
    /**
     * 
     * @type {PaymentProcessedTxnModel}
     * @memberof TransactionDetailModel
     */
    paymentProcessedTxnModel?: PaymentProcessedTxnModel;
    /**
     * 
     * @type {PaymentSource}
     * @memberof TransactionDetailModel
     */
    paymentSource?: PaymentSource;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDetailModel
     */
    isThreeDs?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionDetailModel
     */
    isFraudScreened?: boolean;
    /**
     * 
     * @type {FraudDetectionResponseModel}
     * @memberof TransactionDetailModel
     */
    fraudDetectionResponseModel?: FraudDetectionResponseModel;
    /**
     * 
     * @type {ThreeDsAuthenticationModel}
     * @memberof TransactionDetailModel
     */
    threeDsAuthenticationModel?: ThreeDsAuthenticationModel;
    /**
     * 
     * @type {Array<TxnAdditionalPaymentModel>}
     * @memberof TransactionDetailModel
     */
    txnAdditionalPaymentModel?: Array<TxnAdditionalPaymentModel>;
}
/**
 * 
 * @export
 * @interface TransactionSourceModel
 */
export interface TransactionSourceModel {
    /**
     * 
     * @type {string}
     * @memberof TransactionSourceModel
     */
    key?: TransactionSourceModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionSourceModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type TransactionSourceModelKeyEnum = 'BACKOFFICE' |
    'INTERNET' |
    'PHONE' |
    'API' |
    'APF' |
    'SFTP' |
    'LINK_GENERATOR' |
    'E_LOCKBOX' |
    'ALL_BPOINT' |
    'AUSTRALIA_POST' |
    'LOCKBOX' |
    'BPAY' |
    'DUN_BRADSTREET';

/**
 * 
 * @export
 * @interface TransactionSourcesData
 */
export interface TransactionSourcesData {
    /**
     * 
     * @type {Array<TransactionSourceModel>}
     * @memberof TransactionSourcesData
     */
    items?: Array<TransactionSourceModel>;
    /**
     * 
     * @type {string}
     * @memberof TransactionSourcesData
     */
    defaultKey?: TransactionSourcesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type TransactionSourcesDataDefaultKeyEnum = 'BACKOFFICE' |
    'INTERNET' |
    'PHONE' |
    'API' |
    'APF' |
    'SFTP' |
    'LINK_GENERATOR' |
    'E_LOCKBOX' |
    'ALL_BPOINT' |
    'AUSTRALIA_POST' |
    'LOCKBOX' |
    'BPAY' |
    'DUN_BRADSTREET';

/**
 * 
 * @export
 * @interface TransactionSummaryModel
 */
export interface TransactionSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof TransactionSummaryModel
     */
    txnHistoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionSummaryModel
     */
    responseCode?: string;
    /**
     * 
     * @type {TransactionTypeDetails}
     * @memberof TransactionSummaryModel
     */
    transactionTypeDetails?: TransactionTypeDetails;
    /**
     * 
     * @type {string}
     * @memberof TransactionSummaryModel
     */
    crn1?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSummaryModel
     */
    receiptNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSummaryModel
     */
    processedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionSummaryModel
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionSummaryModel
     */
    currencyCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionSummaryModel
     */
    isExternal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionSummaryModel
     */
    maskedCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSummaryModel
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionSummaryModel
     */
    accountNumber?: string;
}
/**
 * 
 * @export
 * @interface TransactionTokenisationMode
 */
export interface TransactionTokenisationMode {
    /**
     * 
     * @type {string}
     * @memberof TransactionTokenisationMode
     */
    key?: TransactionTokenisationModeKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionTokenisationMode
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type TransactionTokenisationModeKeyEnum = 'None' |
    'OptIn' |
    'All';

/**
 * 
 * @export
 * @interface TransactionTypeDetails
 */
export interface TransactionTypeDetails {
    /**
     * 
     * @type {string}
     * @memberof TransactionTypeDetails
     */
    key?: TransactionTypeDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionTypeDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type TransactionTypeDetailsKeyEnum = 'UNSPECIFIED' |
    'PAYMENT' |
    'REFUND' |
    'CAPTURE' |
    'UNMATCHED_REFUND' |
    'PREAUTH' |
    'REVERSAL' |
    'MANUAL_REVERSAL' |
    'DE_REJECTION' |
    'ZERO_VALUE' |
    'VERIFY' |
    'CHEQUE_DISHONOUR' |
    'AUTHENTICATE';

/**
 * 
 * @export
 * @interface TransactionTypeModel
 */
export interface TransactionTypeModel {
    /**
     * 
     * @type {string}
     * @memberof TransactionTypeModel
     */
    key?: TransactionTypeModelKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionTypeModel
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type TransactionTypeModelKeyEnum = 'UNSPECIFIED' |
    'PAYMENT' |
    'REFUND' |
    'CAPTURE' |
    'UNMATCHED_REFUND' |
    'PREAUTH' |
    'REVERSAL' |
    'MANUAL_REVERSAL' |
    'DE_REJECTION' |
    'ZERO_VALUE' |
    'VERIFY' |
    'CHEQUE_DISHONOUR' |
    'AUTHENTICATE';

/**
 * 
 * @export
 * @interface TransactionTypesData
 */
export interface TransactionTypesData {
    /**
     * 
     * @type {Array<TransactionTypeModel>}
     * @memberof TransactionTypesData
     */
    items?: Array<TransactionTypeModel>;
    /**
     * 
     * @type {string}
     * @memberof TransactionTypesData
     */
    defaultKey?: TransactionTypesDataDefaultKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export type TransactionTypesDataDefaultKeyEnum = 'UNSPECIFIED' |
    'PAYMENT' |
    'REFUND' |
    'CAPTURE' |
    'UNMATCHED_REFUND' |
    'PREAUTH' |
    'REVERSAL' |
    'MANUAL_REVERSAL' |
    'DE_REJECTION' |
    'ZERO_VALUE' |
    'VERIFY' |
    'CHEQUE_DISHONOUR' |
    'AUTHENTICATE';

/**
 * 
 * @export
 * @interface TransactionsBreakdownModel
 */
export interface TransactionsBreakdownModel {
    /**
     * 
     * @type {Array<CardAmountBreakdownModel>}
     * @memberof TransactionsBreakdownModel
     */
    subTotalCards?: Array<CardAmountBreakdownModel>;
    /**
     * 
     * @type {AmountBreakdownModel}
     * @memberof TransactionsBreakdownModel
     */
    subTotals?: AmountBreakdownModel;
    /**
     * 
     * @type {Array<CardAmountBreakdownModel>}
     * @memberof TransactionsBreakdownModel
     */
    cardsExclSubTotal?: Array<CardAmountBreakdownModel>;
    /**
     * 
     * @type {AmountBreakdownModel}
     * @memberof TransactionsBreakdownModel
     */
    totals?: AmountBreakdownModel;
}
/**
 * 
 * @export
 * @interface TxnAdditionalPaymentModel
 */
export interface TxnAdditionalPaymentModel {
    /**
     * 
     * @type {number}
     * @memberof TxnAdditionalPaymentModel
     */
    txnHistoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof TxnAdditionalPaymentModel
     */
    recordNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof TxnAdditionalPaymentModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TxnAdditionalPaymentModel
     */
    reportDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof TxnAdditionalPaymentModel
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface TxnTypeDetails
 */
export interface TxnTypeDetails {
    /**
     * 
     * @type {string}
     * @memberof TxnTypeDetails
     */
    key?: TxnTypeDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof TxnTypeDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type TxnTypeDetailsKeyEnum = 'UNSPECIFIED' |
    'MAIL_ORDER' |
    'TELEPHONE_ORDER' |
    'ECOMMERCE' |
    'CARD_PRESENT' |
    'CALL_CENTRE' |
    'IVR' |
    'INTERNET' |
    'ONLINE_BANKING';

/**
 * 
 * @export
 * @interface UpdateBatchFileScheduleRequest
 */
export interface UpdateBatchFileScheduleRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateBatchFileScheduleRequest
     */
    batchFileId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBatchFileScheduleRequest
     */
    processAfterDate?: string;
}
/**
 * 
 * @export
 * @interface UpdateBillerCRNDetailsRequest
 */
export interface UpdateBillerCRNDetailsRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateBillerCRNDetailsRequest
     */
    billerId?: number;
    /**
     * 
     * @type {Array<CustomerReferenceNumberModel>}
     * @memberof UpdateBillerCRNDetailsRequest
     */
    billerCRNList?: Array<CustomerReferenceNumberModel>;
}
/**
 * 
 * @export
 * @interface UpdateBillerDetailsRequest
 */
export interface UpdateBillerDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBillerDetailsRequest
     */
    billerDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBillerDetailsRequest
     */
    billerCodeEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateBillerRequest
 */
export interface UpdateBillerRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBillerRequest
     */
    tokeniseApprovedOnlyTxns?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillerRequest
     */
    txnTokenisationMode?: UpdateBillerRequestTxnTokenisationModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export type UpdateBillerRequestTxnTokenisationModeEnum = 'None' |
    'OptIn' |
    'All';

/**
 * 
 * @export
 * @interface UpdateDataVaultTokenRequest
 */
export interface UpdateDataVaultTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateDataVaultTokenRequest
     */
    dataVaultId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    billerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    crN1?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    crN2?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    crN3?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    customerUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    tokenType?: UpdateDataVaultTokenRequestTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    cardNumber?: string;
    /**
     * 
     * @type {ExpiryDate}
     * @memberof UpdateDataVaultTokenRequest
     */
    expiryDate?: ExpiryDate;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    deBsbNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataVaultTokenRequest
     */
    deAccountNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDataVaultTokenRequest
     */
    customerIdentified?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type UpdateDataVaultTokenRequestTokenTypeEnum = 'CARD' |
    'BANKACCOUNT';

/**
 * 
 * @export
 * @interface UpdateUserProfileRequest
 */
export interface UpdateUserProfileRequest {
    /**
     * 
     * @type {UserProfileModel}
     * @memberof UpdateUserProfileRequest
     */
    userProfile?: UserProfileModel;
    /**
     * 
     * @type {PasswordInput}
     * @memberof UpdateUserProfileRequest
     */
    password?: PasswordInput;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    childMerchantNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    roleId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    dailyRefundLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRequest
     */
    dailyRefundAmountLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    currentPassword?: string;
}
/**
 * 
 * @export
 * @interface UploadAntiFraudRulesFileResponse
 */
export interface UploadAntiFraudRulesFileResponse {
    /**
     * 
     * @type {Array<UploadAntiFraudRulesFileValidationResult>}
     * @memberof UploadAntiFraudRulesFileResponse
     */
    uploadAntiFraudRulesValidationResult?: Array<UploadAntiFraudRulesFileValidationResult>;
    /**
     * 
     * @type {boolean}
     * @memberof UploadAntiFraudRulesFileResponse
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadAntiFraudRulesFileResponse
     */
    hasWarnings?: boolean;
}
/**
 * 
 * @export
 * @interface UploadAntiFraudRulesFileValidationResult
 */
export interface UploadAntiFraudRulesFileValidationResult {
    /**
     * 
     * @type {string}
     * @memberof UploadAntiFraudRulesFileValidationResult
     */
    filename?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadAntiFraudRulesFileValidationResult
     */
    errors?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadAntiFraudRulesFileValidationResult
     */
    warnings?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UploadAntiFraudRulesFileValidationResult
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadAntiFraudRulesFileValidationResult
     */
    hasWarnings?: boolean;
}
/**
 * 
 * @export
 * @interface UploadBatchJobRequest
 */
export interface UploadBatchJobRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadBatchJobRequest
     */
    scheduledDateTime?: string;
}
/**
 * 
 * @export
 * @interface UploadBatchLibraryFileRequest
 */
export interface UploadBatchLibraryFileRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UploadBatchLibraryFileRequest
     */
    scheduleBatchJob?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UploadBatchLibraryFileRequest
     */
    scheduledDateTime?: string;
}
/**
 * 
 * @export
 * @interface UploadBatchResponse
 */
export interface UploadBatchResponse {
    /**
     * 
     * @type {Array<BatchValidationResult>}
     * @memberof UploadBatchResponse
     */
    batchFileValidationResult?: Array<BatchValidationResult>;
    /**
     * 
     * @type {boolean}
     * @memberof UploadBatchResponse
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadBatchResponse
     */
    hasWarnings?: boolean;
}
/**
 * 
 * @export
 * @interface UploadBillerCRNLookupRulesFileRequest
 */
export interface UploadBillerCRNLookupRulesFileRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadBillerCRNLookupRulesFileRequest
     */
    emails?: string;
}
/**
 * 
 * @export
 * @interface UploadBillerCRNLookupRulesFileResponse
 */
export interface UploadBillerCRNLookupRulesFileResponse {
    /**
     * 
     * @type {Array<UploadBillerCRNLookupRulesFileValidationResult>}
     * @memberof UploadBillerCRNLookupRulesFileResponse
     */
    uploadBillerCRNLookupRulesValidationResult?: Array<UploadBillerCRNLookupRulesFileValidationResult>;
    /**
     * 
     * @type {boolean}
     * @memberof UploadBillerCRNLookupRulesFileResponse
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadBillerCRNLookupRulesFileResponse
     */
    hasWarnings?: boolean;
}
/**
 * 
 * @export
 * @interface UploadBillerCRNLookupRulesFileValidationResult
 */
export interface UploadBillerCRNLookupRulesFileValidationResult {
    /**
     * 
     * @type {string}
     * @memberof UploadBillerCRNLookupRulesFileValidationResult
     */
    filename?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadBillerCRNLookupRulesFileValidationResult
     */
    errors?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadBillerCRNLookupRulesFileValidationResult
     */
    warnings?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UploadBillerCRNLookupRulesFileValidationResult
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadBillerCRNLookupRulesFileValidationResult
     */
    hasWarnings?: boolean;
}
/**
 * 
 * @export
 * @interface UploadBrandingFileRequest
 */
export interface UploadBrandingFileRequest {
    /**
     * 
     * @type {Branding}
     * @memberof UploadBrandingFileRequest
     */
    branding?: Branding;
}
/**
 * 
 * @export
 * @interface UserCredentials
 */
export interface UserCredentials {
    /**
     * 
     * @type {string}
     * @memberof UserCredentials
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredentials
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredentials
     */
    merchantNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredentials
     */
    captchaResponse?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredentials
     */
    platform?: string;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    merchantNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    dailyRefundCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    dailyRefundAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    roleName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    isApiUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    apiUserPassword?: string;
}
/**
 * 
 * @export
 * @interface UserProfileModel
 */
export interface UserProfileModel {
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof UserProfileModel
     */
    countryCode?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    mobileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @interface UserRoleModel
 */
export interface UserRoleModel {
    /**
     * 
     * @type {string}
     * @memberof UserRoleModel
     */
    roleName?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRoleModel
     */
    roleId?: number;
}
/**
 * 
 * @export
 * @interface UserSummaryModel
 */
export interface UserSummaryModel {
    /**
     * 
     * @type {number}
     * @memberof UserSummaryModel
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSummaryModel
     */
    merchantNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserSummaryModel
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSummaryModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSummaryModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSummaryModel
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSummaryModel
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSummaryModel
     */
    roleName?: string;
}
/**
 * 
 * @export
 * @interface ValidateCodeResponseModel
 */
export interface ValidateCodeResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateCodeResponseModel
     */
    codeIsValid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ValidateCodeResponseModel
     */
    rememberMeToken?: string;
}
/**
 * 
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    walletType?: WalletWalletTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Wallet
     */
    enabled?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export type WalletWalletTypeEnum = 'Unspecified' |
    'MasterPass' |
    'AmexWallet' |
    'ApplePay';

/**
 * 
 * @export
 * @interface WalletSet
 */
export interface WalletSet {
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof WalletSet
     */
    wallets?: Array<Wallet>;
}
/**
 * 
 * @export
 * @interface WalletTypeDetails
 */
export interface WalletTypeDetails {
    /**
     * 
     * @type {string}
     * @memberof WalletTypeDetails
     */
    key?: WalletTypeDetailsKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof WalletTypeDetails
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export type WalletTypeDetailsKeyEnum = 'Unspecified' |
    'MasterPass' |
    'AmexWallet' |
    'ApplePay';


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} termsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAcceptTerms: async (termsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termsId' is not null or undefined
            assertParamExists('accountAcceptTerms', 'termsId', termsId)
            const localVarPath = `/Account/AcceptTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (termsId !== undefined) {
                localVarQueryParameter['termsId'] = termsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submits a Contact Us request into the system.
         * @param {ContactUsModel} contactUsRequest Contact Us form details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountContactUs: async (contactUsRequest: ContactUsModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactUsRequest' is not null or undefined
            assertParamExists('accountContactUs', 'contactUsRequest', contactUsRequest)
            const localVarPath = `/Account/ContactUs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactUsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the hCaptcha Site key
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetHCaptchaSiteKey: async (platform: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'platform' is not null or undefined
            assertParamExists('accountGetHCaptchaSiteKey', 'platform', platform)
            const localVarPath = `/Account/GetHCaptchaSiteKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get logged on user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetLoggedOn: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetLoggedOn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the MFA configuration
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetMultiFactorAuthenticationSettings: async (merchantNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('accountGetMultiFactorAuthenticationSettings', 'merchantNumber', merchantNumber)
            const localVarPath = `/Account/GetMultiFactorAuthenticationSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantNumber !== undefined) {
                localVarQueryParameter['merchantNumber'] = merchantNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTerms: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs off the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogOff: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/LogOff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs on the user.
         * @param {UserCredentials} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogOn: async (request: UserCredentials, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('accountLogOn', 'request', request)
            const localVarPath = `/Account/LogOn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend MFA Code
         * @param {string} userName 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResendMfaCode: async (userName: string, merchantNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('accountResendMfaCode', 'userName', userName)
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('accountResendMfaCode', 'merchantNumber', merchantNumber)
            const localVarPath = `/Account/ResendMfaCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (merchantNumber !== undefined) {
                localVarQueryParameter['merchantNumber'] = merchantNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset the user\'s password, based on user\'s username and merchant number. Throw error if any issues.
         * @param {ResetPasswordInput} resetPasswordInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword: async (resetPasswordInput: ResetPasswordInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordInput' is not null or undefined
            assertParamExists('accountResetPassword', 'resetPasswordInput', resetPasswordInput)
            const localVarPath = `/Account/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the MFA configuration
         * @param {MultiFactorAuthenticationSettings} multiFactorAuthenticationSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateMultiFactorAuthenticationSettings: async (multiFactorAuthenticationSettings: MultiFactorAuthenticationSettings, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'multiFactorAuthenticationSettings' is not null or undefined
            assertParamExists('accountUpdateMultiFactorAuthenticationSettings', 'multiFactorAuthenticationSettings', multiFactorAuthenticationSettings)
            const localVarPath = `/Account/UpdateMultiFactorAuthenticationSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multiFactorAuthenticationSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user\'s password
         * @param {PasswordInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword: async (input: PasswordInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('accountUpdatePassword', 'input', input)
            const localVarPath = `/Account/UpdatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Multi-factor Authentication
         * @param {MultiFactorAuthenticationValidationModel} multiFactorAuthenticationValidationModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountValidateMfaCode: async (multiFactorAuthenticationValidationModel: MultiFactorAuthenticationValidationModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'multiFactorAuthenticationValidationModel' is not null or undefined
            assertParamExists('accountValidateMfaCode', 'multiFactorAuthenticationValidationModel', multiFactorAuthenticationValidationModel)
            const localVarPath = `/Account/ValidateMfaCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multiFactorAuthenticationValidationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} termsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountAcceptTerms(termsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountAcceptTerms(termsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submits a Contact Us request into the system.
         * @param {ContactUsModel} contactUsRequest Contact Us form details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountContactUs(contactUsRequest: ContactUsModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountContactUs(contactUsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the hCaptcha Site key
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetHCaptchaSiteKey(platform: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseHCaptchaSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetHCaptchaSiteKey(platform, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get logged on user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetLoggedOn(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseLoggedOnDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetLoggedOn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the MFA configuration
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetMultiFactorAuthenticationSettings(merchantNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseMultiFactorAuthenticationSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetMultiFactorAuthenticationSettings(merchantNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetTerms(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseTermsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetTerms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logs off the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLogOff(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLogOff(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logs on the user.
         * @param {UserCredentials} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLogOn(request: UserCredentials, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseLoggedOnDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLogOn(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend MFA Code
         * @param {string} userName 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountResendMfaCode(userName: string, merchantNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIssueCodeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountResendMfaCode(userName, merchantNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset the user\'s password, based on user\'s username and merchant number. Throw error if any issues.
         * @param {ResetPasswordInput} resetPasswordInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountResetPassword(resetPasswordInput: ResetPasswordInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountResetPassword(resetPasswordInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the MFA configuration
         * @param {MultiFactorAuthenticationSettings} multiFactorAuthenticationSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdateMultiFactorAuthenticationSettings(multiFactorAuthenticationSettings: MultiFactorAuthenticationSettings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdateMultiFactorAuthenticationSettings(multiFactorAuthenticationSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user\'s password
         * @param {PasswordInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdatePassword(input: PasswordInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdatePassword(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify Multi-factor Authentication
         * @param {MultiFactorAuthenticationValidationModel} multiFactorAuthenticationValidationModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountValidateMfaCode(multiFactorAuthenticationValidationModel: MultiFactorAuthenticationValidationModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseValidateCodeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountValidateMfaCode(multiFactorAuthenticationValidationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {number} termsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAcceptTerms(termsId: number, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.accountAcceptTerms(termsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submits a Contact Us request into the system.
         * @param {ContactUsModel} contactUsRequest Contact Us form details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountContactUs(contactUsRequest: ContactUsModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.accountContactUs(contactUsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the hCaptcha Site key
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetHCaptchaSiteKey(platform: string, options?: any): AxiosPromise<ApiResponseHCaptchaSettingsModel> {
            return localVarFp.accountGetHCaptchaSiteKey(platform, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get logged on user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetLoggedOn(options?: any): AxiosPromise<ApiResponseLoggedOnDetails> {
            return localVarFp.accountGetLoggedOn(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the MFA configuration
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetMultiFactorAuthenticationSettings(merchantNumber: string, options?: any): AxiosPromise<ApiResponseMultiFactorAuthenticationSettings> {
            return localVarFp.accountGetMultiFactorAuthenticationSettings(merchantNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTerms(options?: any): AxiosPromise<ApiResponseTermsModel> {
            return localVarFp.accountGetTerms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs off the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogOff(options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.accountLogOff(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs on the user.
         * @param {UserCredentials} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogOn(request: UserCredentials, options?: any): AxiosPromise<ApiResponseLoggedOnDetails> {
            return localVarFp.accountLogOn(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend MFA Code
         * @param {string} userName 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResendMfaCode(userName: string, merchantNumber: string, options?: any): AxiosPromise<ApiResponseIssueCodeResponseModel> {
            return localVarFp.accountResendMfaCode(userName, merchantNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset the user\'s password, based on user\'s username and merchant number. Throw error if any issues.
         * @param {ResetPasswordInput} resetPasswordInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword(resetPasswordInput: ResetPasswordInput, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.accountResetPassword(resetPasswordInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the MFA configuration
         * @param {MultiFactorAuthenticationSettings} multiFactorAuthenticationSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateMultiFactorAuthenticationSettings(multiFactorAuthenticationSettings: MultiFactorAuthenticationSettings, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.accountUpdateMultiFactorAuthenticationSettings(multiFactorAuthenticationSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user\'s password
         * @param {PasswordInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword(input: PasswordInput, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.accountUpdatePassword(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Multi-factor Authentication
         * @param {MultiFactorAuthenticationValidationModel} multiFactorAuthenticationValidationModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountValidateMfaCode(multiFactorAuthenticationValidationModel: MultiFactorAuthenticationValidationModel, options?: any): AxiosPromise<ApiResponseValidateCodeResponseModel> {
            return localVarFp.accountValidateMfaCode(multiFactorAuthenticationValidationModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {number} termsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountAcceptTerms(termsId: number, options?: any) {
        return AccountApiFp(this.configuration).accountAcceptTerms(termsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submits a Contact Us request into the system.
     * @param {ContactUsModel} contactUsRequest Contact Us form details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountContactUs(contactUsRequest: ContactUsModel, options?: any) {
        return AccountApiFp(this.configuration).accountContactUs(contactUsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the hCaptcha Site key
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetHCaptchaSiteKey(platform: string, options?: any) {
        return AccountApiFp(this.configuration).accountGetHCaptchaSiteKey(platform, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get logged on user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetLoggedOn(options?: any) {
        return AccountApiFp(this.configuration).accountGetLoggedOn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the MFA configuration
     * @param {string} merchantNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetMultiFactorAuthenticationSettings(merchantNumber: string, options?: any) {
        return AccountApiFp(this.configuration).accountGetMultiFactorAuthenticationSettings(merchantNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetTerms(options?: any) {
        return AccountApiFp(this.configuration).accountGetTerms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs off the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLogOff(options?: any) {
        return AccountApiFp(this.configuration).accountLogOff(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs on the user.
     * @param {UserCredentials} request The request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLogOn(request: UserCredentials, options?: any) {
        return AccountApiFp(this.configuration).accountLogOn(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend MFA Code
     * @param {string} userName 
     * @param {string} merchantNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResendMfaCode(userName: string, merchantNumber: string, options?: any) {
        return AccountApiFp(this.configuration).accountResendMfaCode(userName, merchantNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset the user\'s password, based on user\'s username and merchant number. Throw error if any issues.
     * @param {ResetPasswordInput} resetPasswordInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResetPassword(resetPasswordInput: ResetPasswordInput, options?: any) {
        return AccountApiFp(this.configuration).accountResetPassword(resetPasswordInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the MFA configuration
     * @param {MultiFactorAuthenticationSettings} multiFactorAuthenticationSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdateMultiFactorAuthenticationSettings(multiFactorAuthenticationSettings: MultiFactorAuthenticationSettings, options?: any) {
        return AccountApiFp(this.configuration).accountUpdateMultiFactorAuthenticationSettings(multiFactorAuthenticationSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user\'s password
     * @param {PasswordInput} input 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdatePassword(input: PasswordInput, options?: any) {
        return AccountApiFp(this.configuration).accountUpdatePassword(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Multi-factor Authentication
     * @param {MultiFactorAuthenticationValidationModel} multiFactorAuthenticationValidationModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountValidateMfaCode(multiFactorAuthenticationValidationModel: MultiFactorAuthenticationValidationModel, options?: any) {
        return AccountApiFp(this.configuration).accountValidateMfaCode(multiFactorAuthenticationValidationModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchJobApi - axios parameter creator
 * @export
 */
export const BatchJobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteBatchJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobDeleteBatchFile: async (request: DeleteBatchJobRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchJobDeleteBatchFile', 'request', request)
            const localVarPath = `/BatchJob/DeleteBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadBatchFileResultRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobDownloadBatchFileResult: async (request: DownloadBatchFileResultRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchJobDownloadBatchFileResult', 'request', request)
            const localVarPath = `/BatchJob/DownloadBatchFileResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBatchFileScheduleRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobEditBatchFileSchedule: async (request: UpdateBatchFileScheduleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchJobEditBatchFileSchedule', 'request', request)
            const localVarPath = `/BatchJob/EditBatchFileSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobGetBatchFiles: async (request: PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchJobGetBatchFiles', 'request', request)
            const localVarPath = `/BatchJob/GetBatchFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process a batch payment file, overriding warnings
         * @param {ProcessDuplicateBatchJobRequest} request Batch file ID to be processed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobProcessDuplicateBatchFile: async (request: ProcessDuplicateBatchJobRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchJobProcessDuplicateBatchFile', 'request', request)
            const localVarPath = `/BatchJob/ProcessDuplicateBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StopRecurringManagerRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobStopRecurringManager: async (request: StopRecurringManagerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchJobStopRecurringManager', 'request', request)
            const localVarPath = `/BatchJob/StopRecurringManager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [requestScheduledDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobUploadBatchFile: async (requestScheduledDateTime?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/BatchJob/UploadBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestScheduledDateTime !== undefined) {
                localVarQueryParameter['request.scheduledDateTime'] = (requestScheduledDateTime as any instanceof Date) ?
                    (requestScheduledDateTime as any).toISOString() :
                    requestScheduledDateTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchJobApi - functional programming interface
 * @export
 */
export const BatchJobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchJobApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteBatchJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchJobDeleteBatchFile(request: DeleteBatchJobRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchJobDeleteBatchFile(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadBatchFileResultRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchJobDownloadBatchFileResult(request: DownloadBatchFileResultRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDownloadBatchFileResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchJobDownloadBatchFileResult(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBatchFileScheduleRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchJobEditBatchFileSchedule(request: UpdateBatchFileScheduleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchJobEditBatchFileSchedule(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchJobGetBatchFiles(request: PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetBatchFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchJobGetBatchFiles(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Process a batch payment file, overriding warnings
         * @param {ProcessDuplicateBatchJobRequest} request Batch file ID to be processed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchJobProcessDuplicateBatchFile(request: ProcessDuplicateBatchJobRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchJobProcessDuplicateBatchFile(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StopRecurringManagerRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchJobStopRecurringManager(request: StopRecurringManagerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchJobStopRecurringManager(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [requestScheduledDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchJobUploadBatchFile(requestScheduledDateTime?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUploadBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchJobUploadBatchFile(requestScheduledDateTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchJobApi - factory interface
 * @export
 */
export const BatchJobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchJobApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteBatchJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobDeleteBatchFile(request: DeleteBatchJobRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchJobDeleteBatchFile(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadBatchFileResultRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobDownloadBatchFileResult(request: DownloadBatchFileResultRequest, options?: any): AxiosPromise<ApiResponseDownloadBatchFileResultResponse> {
            return localVarFp.batchJobDownloadBatchFileResult(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBatchFileScheduleRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobEditBatchFileSchedule(request: UpdateBatchFileScheduleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchJobEditBatchFileSchedule(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobGetBatchFiles(request: PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields, options?: any): AxiosPromise<ApiResponseGetBatchFilesResponse> {
            return localVarFp.batchJobGetBatchFiles(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process a batch payment file, overriding warnings
         * @param {ProcessDuplicateBatchJobRequest} request Batch file ID to be processed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobProcessDuplicateBatchFile(request: ProcessDuplicateBatchJobRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchJobProcessDuplicateBatchFile(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StopRecurringManagerRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobStopRecurringManager(request: StopRecurringManagerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchJobStopRecurringManager(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [requestScheduledDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchJobUploadBatchFile(requestScheduledDateTime?: string, options?: any): AxiosPromise<ApiResponseUploadBatchResponse> {
            return localVarFp.batchJobUploadBatchFile(requestScheduledDateTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchJobApi - object-oriented interface
 * @export
 * @class BatchJobApi
 * @extends {BaseAPI}
 */
export class BatchJobApi extends BaseAPI {
    /**
     * 
     * @param {DeleteBatchJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchJobApi
     */
    public batchJobDeleteBatchFile(request: DeleteBatchJobRequest, options?: any) {
        return BatchJobApiFp(this.configuration).batchJobDeleteBatchFile(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadBatchFileResultRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchJobApi
     */
    public batchJobDownloadBatchFileResult(request: DownloadBatchFileResultRequest, options?: any) {
        return BatchJobApiFp(this.configuration).batchJobDownloadBatchFileResult(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBatchFileScheduleRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchJobApi
     */
    public batchJobEditBatchFileSchedule(request: UpdateBatchFileScheduleRequest, options?: any) {
        return BatchJobApiFp(this.configuration).batchJobEditBatchFileSchedule(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchJobApi
     */
    public batchJobGetBatchFiles(request: PaginateRequestGetBatchJobsRequestBatchFileStatusSortFields, options?: any) {
        return BatchJobApiFp(this.configuration).batchJobGetBatchFiles(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process a batch payment file, overriding warnings
     * @param {ProcessDuplicateBatchJobRequest} request Batch file ID to be processed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchJobApi
     */
    public batchJobProcessDuplicateBatchFile(request: ProcessDuplicateBatchJobRequest, options?: any) {
        return BatchJobApiFp(this.configuration).batchJobProcessDuplicateBatchFile(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StopRecurringManagerRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchJobApi
     */
    public batchJobStopRecurringManager(request: StopRecurringManagerRequest, options?: any) {
        return BatchJobApiFp(this.configuration).batchJobStopRecurringManager(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [requestScheduledDateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchJobApi
     */
    public batchJobUploadBatchFile(requestScheduledDateTime?: string, options?: any) {
        return BatchJobApiFp(this.configuration).batchJobUploadBatchFile(requestScheduledDateTime, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchLibraryApi - axios parameter creator
 * @export
 */
export const BatchLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddBatchRecordsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryAddBatchRecords: async (request: AddBatchRecordsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryAddBatchRecords', 'request', request)
            const localVarPath = `/BatchLibrary/AddBatchRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [requestBatchFileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryAppendBatchFile: async (requestBatchFileId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/BatchLibrary/AppendBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestBatchFileId !== undefined) {
                localVarQueryParameter['request.batchFileId'] = requestBatchFileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryCreateBatchFile: async (request: CreateBatchFileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryCreateBatchFile', 'request', request)
            const localVarPath = `/BatchLibrary/CreateBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Batch Library file.
         * @param {DeleteBatchLibraryFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryDeleteBatchFile: async (request: DeleteBatchLibraryFileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryDeleteBatchFile', 'request', request)
            const localVarPath = `/BatchLibrary/DeleteBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific record from a Batch Library file
         * @param {DeleteBatchRecordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryDeleteBatchRecord: async (request: DeleteBatchRecordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryDeleteBatchRecord', 'request', request)
            const localVarPath = `/BatchLibrary/DeleteBatchRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a specific record from a Batch Library file
         * @param {EditBatchRecordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryEditBatchRecord: async (request: EditBatchRecordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryEditBatchRecord', 'request', request)
            const localVarPath = `/BatchLibrary/EditBatchRecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryGetBatchFileContents: async (request: PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryGetBatchFileContents', 'request', request)
            const localVarPath = `/BatchLibrary/GetBatchFileContents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of Batch Library files for the current merchant. Optional filename and updated date matching.
         * @param {PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryGetBatchFiles: async (pagedRequest: PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('batchLibraryGetBatchFiles', 'pagedRequest', pagedRequest)
            const localVarPath = `/BatchLibrary/GetBatchFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryProcessFile: async (request: ProcessBatchFileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryProcessFile', 'request', request)
            const localVarPath = `/BatchLibrary/ProcessFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RenameBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryRenameBatchFile: async (request: RenameBatchFileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchLibraryRenameBatchFile', 'request', request)
            const localVarPath = `/BatchLibrary/RenameBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [requestScheduleBatchJob] 
         * @param {string} [requestScheduledDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryUploadBatchFile: async (requestScheduleBatchJob?: boolean, requestScheduledDateTime?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/BatchLibrary/UploadBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestScheduleBatchJob !== undefined) {
                localVarQueryParameter['request.scheduleBatchJob'] = requestScheduleBatchJob;
            }

            if (requestScheduledDateTime !== undefined) {
                localVarQueryParameter['request.scheduledDateTime'] = (requestScheduledDateTime as any instanceof Date) ?
                    (requestScheduledDateTime as any).toISOString() :
                    requestScheduledDateTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchLibraryApi - functional programming interface
 * @export
 */
export const BatchLibraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchLibraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddBatchRecordsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryAddBatchRecords(request: AddBatchRecordsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryAddBatchRecords(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [requestBatchFileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryAppendBatchFile(requestBatchFileId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUploadBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryAppendBatchFile(requestBatchFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryCreateBatchFile(request: CreateBatchFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseInt32>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryCreateBatchFile(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Batch Library file.
         * @param {DeleteBatchLibraryFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryDeleteBatchFile(request: DeleteBatchLibraryFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryDeleteBatchFile(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a specific record from a Batch Library file
         * @param {DeleteBatchRecordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryDeleteBatchRecord(request: DeleteBatchRecordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryDeleteBatchRecord(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit a specific record from a Batch Library file
         * @param {EditBatchRecordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryEditBatchRecord(request: EditBatchRecordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryEditBatchRecord(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryGetBatchFileContents(request: PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetBatchFileContentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryGetBatchFileContents(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of Batch Library files for the current merchant. Optional filename and updated date matching.
         * @param {PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryGetBatchFiles(pagedRequest: PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListBatchFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryGetBatchFiles(pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProcessBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryProcessFile(request: ProcessBatchFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBatchProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryProcessFile(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RenameBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryRenameBatchFile(request: RenameBatchFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryRenameBatchFile(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [requestScheduleBatchJob] 
         * @param {string} [requestScheduledDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchLibraryUploadBatchFile(requestScheduleBatchJob?: boolean, requestScheduledDateTime?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUploadBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchLibraryUploadBatchFile(requestScheduleBatchJob, requestScheduledDateTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchLibraryApi - factory interface
 * @export
 */
export const BatchLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchLibraryApiFp(configuration)
    return {
        /**
         * 
         * @param {AddBatchRecordsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryAddBatchRecords(request: AddBatchRecordsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchLibraryAddBatchRecords(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [requestBatchFileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryAppendBatchFile(requestBatchFileId?: number, options?: any): AxiosPromise<ApiResponseUploadBatchResponse> {
            return localVarFp.batchLibraryAppendBatchFile(requestBatchFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryCreateBatchFile(request: CreateBatchFileRequest, options?: any): AxiosPromise<ApiResponseInt32> {
            return localVarFp.batchLibraryCreateBatchFile(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Batch Library file.
         * @param {DeleteBatchLibraryFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryDeleteBatchFile(request: DeleteBatchLibraryFileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchLibraryDeleteBatchFile(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a specific record from a Batch Library file
         * @param {DeleteBatchRecordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryDeleteBatchRecord(request: DeleteBatchRecordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchLibraryDeleteBatchRecord(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a specific record from a Batch Library file
         * @param {EditBatchRecordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryEditBatchRecord(request: EditBatchRecordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchLibraryEditBatchRecord(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryGetBatchFileContents(request: PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields, options?: any): AxiosPromise<ApiResponseGetBatchFileContentsResponse> {
            return localVarFp.batchLibraryGetBatchFileContents(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of Batch Library files for the current merchant. Optional filename and updated date matching.
         * @param {PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryGetBatchFiles(pagedRequest: PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListBatchFileInfo> {
            return localVarFp.batchLibraryGetBatchFiles(pagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryProcessFile(request: ProcessBatchFileRequest, options?: any): AxiosPromise<ApiResponseBatchProcessResponse> {
            return localVarFp.batchLibraryProcessFile(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RenameBatchFileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryRenameBatchFile(request: RenameBatchFileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.batchLibraryRenameBatchFile(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [requestScheduleBatchJob] 
         * @param {string} [requestScheduledDateTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchLibraryUploadBatchFile(requestScheduleBatchJob?: boolean, requestScheduledDateTime?: string, options?: any): AxiosPromise<ApiResponseUploadBatchResponse> {
            return localVarFp.batchLibraryUploadBatchFile(requestScheduleBatchJob, requestScheduledDateTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchLibraryApi - object-oriented interface
 * @export
 * @class BatchLibraryApi
 * @extends {BaseAPI}
 */
export class BatchLibraryApi extends BaseAPI {
    /**
     * 
     * @param {AddBatchRecordsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryAddBatchRecords(request: AddBatchRecordsRequest, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryAddBatchRecords(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [requestBatchFileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryAppendBatchFile(requestBatchFileId?: number, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryAppendBatchFile(requestBatchFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBatchFileRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryCreateBatchFile(request: CreateBatchFileRequest, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryCreateBatchFile(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Batch Library file.
     * @param {DeleteBatchLibraryFileRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryDeleteBatchFile(request: DeleteBatchLibraryFileRequest, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryDeleteBatchFile(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a specific record from a Batch Library file
     * @param {DeleteBatchRecordRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryDeleteBatchRecord(request: DeleteBatchRecordRequest, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryDeleteBatchRecord(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a specific record from a Batch Library file
     * @param {EditBatchRecordRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryEditBatchRecord(request: EditBatchRecordRequest, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryEditBatchRecord(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryGetBatchFileContents(request: PaginateRequestGetBatchFileContentsRequestBatchRecordSortFields, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryGetBatchFileContents(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of Batch Library files for the current merchant. Optional filename and updated date matching.
     * @param {PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields} pagedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryGetBatchFiles(pagedRequest: PaginateRequestGetBatchLibraryFilesRequestBatchFileStatusSortFields, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryGetBatchFiles(pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessBatchFileRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryProcessFile(request: ProcessBatchFileRequest, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryProcessFile(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RenameBatchFileRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryRenameBatchFile(request: RenameBatchFileRequest, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryRenameBatchFile(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [requestScheduleBatchJob] 
     * @param {string} [requestScheduledDateTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchLibraryApi
     */
    public batchLibraryUploadBatchFile(requestScheduleBatchJob?: boolean, requestScheduledDateTime?: string, options?: any) {
        return BatchLibraryApiFp(this.configuration).batchLibraryUploadBatchFile(requestScheduleBatchJob, requestScheduledDateTime, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchRequestsApi - axios parameter creator
 * @export
 */
export const BatchRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search (payment) requests
         * @param {PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchRequestsGetBatchFiles: async (request: PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('batchRequestsGetBatchFiles', 'request', request)
            const localVarPath = `/request/batch/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload (payment) request batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchRequestsUploadBatchFile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/request/batch/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchRequestsApi - functional programming interface
 * @export
 */
export const BatchRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search (payment) requests
         * @param {PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchRequestsGetBatchFiles(request: PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListBatchFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchRequestsGetBatchFiles(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload (payment) request batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchRequestsUploadBatchFile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUploadBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchRequestsUploadBatchFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchRequestsApi - factory interface
 * @export
 */
export const BatchRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Search (payment) requests
         * @param {PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchRequestsGetBatchFiles(request: PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListBatchFileInfo> {
            return localVarFp.batchRequestsGetBatchFiles(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload (payment) request batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchRequestsUploadBatchFile(options?: any): AxiosPromise<ApiResponseUploadBatchResponse> {
            return localVarFp.batchRequestsUploadBatchFile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchRequestsApi - object-oriented interface
 * @export
 * @class BatchRequestsApi
 * @extends {BaseAPI}
 */
export class BatchRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Search (payment) requests
     * @param {PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchRequestsApi
     */
    public batchRequestsGetBatchFiles(request: PaginateRequestGetBatchRequestsRequestBatchFileStatusSortFields, options?: any) {
        return BatchRequestsApiFp(this.configuration).batchRequestsGetBatchFiles(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload (payment) request batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchRequestsApi
     */
    public batchRequestsUploadBatchFile(options?: any) {
        return BatchRequestsApiFp(this.configuration).batchRequestsUploadBatchFile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BillerApi - axios parameter creator
 * @export
 */
export const BillerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a biller for a merchant, if they have less than 5 billers
         * @param {CreateBillerRequest} newBiller A CreateBillerRequest object used for some of the new biller\&#39;s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerCreateBiller: async (newBiller: CreateBillerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newBiller' is not null or undefined
            assertParamExists('billerCreateBiller', 'newBiller', newBiller)
            const localVarPath = `/billers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBiller, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetAdditionalPayments: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerGetAdditionalPayments', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/additional-payments`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetApplePayWallet: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerGetApplePayWallet', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/applepaywallet`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetBiller: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerGetBiller', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} includeChildMerchants 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetBillers: async (includeChildMerchants: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'includeChildMerchants' is not null or undefined
            assertParamExists('billerGetBillers', 'includeChildMerchants', includeChildMerchants)
            const localVarPath = `/billers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeChildMerchants !== undefined) {
                localVarQueryParameter['includeChildMerchants'] = includeChildMerchants;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetFullSurchargeRules: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerGetFullSurchargeRules', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/full-surcharge-rules`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetSurchargeRules: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerGetSurchargeRules', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/surcharge-rules`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetWallets: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerGetWallets', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/wallets`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {Array<BillerAdditionalPayment>} additionalPayments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerSaveAdditionalPayments: async (billerCode: string, additionalPayments: Array<BillerAdditionalPayment>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerSaveAdditionalPayments', 'billerCode', billerCode)
            // verify required parameter 'additionalPayments' is not null or undefined
            assertParamExists('billerSaveAdditionalPayments', 'additionalPayments', additionalPayments)
            const localVarPath = `/billers/{billerCode}/additional-payments`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionalPayments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a biller\'s details
         * @param {string} billerCode The biller\&#39;s unique identifier
         * @param {UpdateBillerRequest} updatedBiller The updated fields to apply to the biller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateBiller: async (billerCode: string, updatedBiller: UpdateBillerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerUpdateBiller', 'billerCode', billerCode)
            // verify required parameter 'updatedBiller' is not null or undefined
            assertParamExists('billerUpdateBiller', 'updatedBiller', updatedBiller)
            const localVarPath = `/billers/{billerCode}`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedBiller, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {UpdateBillerCRNDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateCustomerReference: async (billerCode: string, request: UpdateBillerCRNDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerUpdateCustomerReference', 'billerCode', billerCode)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('billerUpdateCustomerReference', 'request', request)
            const localVarPath = `/billers/{billerCode}/edit`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {UpdateBillerDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateDetails: async (billerCode: string, request: UpdateBillerDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerUpdateDetails', 'billerCode', billerCode)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('billerUpdateDetails', 'request', request)
            const localVarPath = `/billers/{billerCode}/details`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {SetIsTopUpsEnabledRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateIsTopUpsEnabled: async (billerCode: string, request: SetIsTopUpsEnabledRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerUpdateIsTopUpsEnabled', 'billerCode', billerCode)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('billerUpdateIsTopUpsEnabled', 'request', request)
            const localVarPath = `/billers/{billerCode}/top-ups`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {AcceptedAmounts} amounts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdatePaymentLimits: async (billerCode: string, amounts: AcceptedAmounts, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerUpdatePaymentLimits', 'billerCode', billerCode)
            // verify required parameter 'amounts' is not null or undefined
            assertParamExists('billerUpdatePaymentLimits', 'amounts', amounts)
            const localVarPath = `/billers/{billerCode}/payment-limits`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(amounts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {SurchargeRuleSet} ruleSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateSurchargeRules: async (billerCode: string, ruleSet: SurchargeRuleSet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerUpdateSurchargeRules', 'billerCode', billerCode)
            // verify required parameter 'ruleSet' is not null or undefined
            assertParamExists('billerUpdateSurchargeRules', 'ruleSet', ruleSet)
            const localVarPath = `/billers/{billerCode}/surcharge-rules`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ruleSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {WalletSet} walletSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateWallets: async (billerCode: string, walletSet: WalletSet, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('billerUpdateWallets', 'billerCode', billerCode)
            // verify required parameter 'walletSet' is not null or undefined
            assertParamExists('billerUpdateWallets', 'walletSet', walletSet)
            const localVarPath = `/billers/{billerCode}/wallets`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(walletSet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the CRN Payment Rules via file upload
         * @param {string} [requestEmails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUploadBillerCRNLookupRulesFile: async (requestEmails?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Biller/UploadBillerCRNLookupRulesFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestEmails !== undefined) {
                localVarQueryParameter['request.emails'] = requestEmails;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillerApi - functional programming interface
 * @export
 */
export const BillerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a biller for a merchant, if they have less than 5 billers
         * @param {CreateBillerRequest} newBiller A CreateBillerRequest object used for some of the new biller\&#39;s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerCreateBiller(newBiller: CreateBillerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBiller>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerCreateBiller(newBiller, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerGetAdditionalPayments(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBillerAdditionalPayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerGetAdditionalPayments(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerGetApplePayWallet(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerGetApplePayWallet(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerGetBiller(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBiller>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerGetBiller(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} includeChildMerchants 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerGetBillers(includeChildMerchants: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIEnumerableBiller>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerGetBillers(includeChildMerchants, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerGetFullSurchargeRules(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSurchargeRuleSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerGetFullSurchargeRules(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerGetSurchargeRules(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSurchargeRuleSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerGetSurchargeRules(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerGetWallets(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseWalletSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerGetWallets(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {Array<BillerAdditionalPayment>} additionalPayments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerSaveAdditionalPayments(billerCode: string, additionalPayments: Array<BillerAdditionalPayment>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerSaveAdditionalPayments(billerCode, additionalPayments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a biller\'s details
         * @param {string} billerCode The biller\&#39;s unique identifier
         * @param {UpdateBillerRequest} updatedBiller The updated fields to apply to the biller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUpdateBiller(billerCode: string, updatedBiller: UpdateBillerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUpdateBiller(billerCode, updatedBiller, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {UpdateBillerCRNDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUpdateCustomerReference(billerCode: string, request: UpdateBillerCRNDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUpdateCustomerReference(billerCode, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {UpdateBillerDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUpdateDetails(billerCode: string, request: UpdateBillerDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUpdateDetails(billerCode, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {SetIsTopUpsEnabledRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUpdateIsTopUpsEnabled(billerCode: string, request: SetIsTopUpsEnabledRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUpdateIsTopUpsEnabled(billerCode, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {AcceptedAmounts} amounts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUpdatePaymentLimits(billerCode: string, amounts: AcceptedAmounts, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUpdatePaymentLimits(billerCode, amounts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {SurchargeRuleSet} ruleSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUpdateSurchargeRules(billerCode: string, ruleSet: SurchargeRuleSet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUpdateSurchargeRules(billerCode, ruleSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {WalletSet} walletSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUpdateWallets(billerCode: string, walletSet: WalletSet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUpdateWallets(billerCode, walletSet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the CRN Payment Rules via file upload
         * @param {string} [requestEmails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billerUploadBillerCRNLookupRulesFile(requestEmails?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUploadBillerCRNLookupRulesFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billerUploadBillerCRNLookupRulesFile(requestEmails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillerApi - factory interface
 * @export
 */
export const BillerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillerApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a biller for a merchant, if they have less than 5 billers
         * @param {CreateBillerRequest} newBiller A CreateBillerRequest object used for some of the new biller\&#39;s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerCreateBiller(newBiller: CreateBillerRequest, options?: any): AxiosPromise<ApiResponseBiller> {
            return localVarFp.billerCreateBiller(newBiller, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetAdditionalPayments(billerCode: string, options?: any): AxiosPromise<ApiResponseBillerAdditionalPayment> {
            return localVarFp.billerGetAdditionalPayments(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetApplePayWallet(billerCode: string, options?: any): AxiosPromise<ApiResponseWallet> {
            return localVarFp.billerGetApplePayWallet(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetBiller(billerCode: string, options?: any): AxiosPromise<ApiResponseBiller> {
            return localVarFp.billerGetBiller(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} includeChildMerchants 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetBillers(includeChildMerchants: boolean, options?: any): AxiosPromise<ApiResponseIEnumerableBiller> {
            return localVarFp.billerGetBillers(includeChildMerchants, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetFullSurchargeRules(billerCode: string, options?: any): AxiosPromise<ApiResponseSurchargeRuleSet> {
            return localVarFp.billerGetFullSurchargeRules(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetSurchargeRules(billerCode: string, options?: any): AxiosPromise<ApiResponseSurchargeRuleSet> {
            return localVarFp.billerGetSurchargeRules(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerGetWallets(billerCode: string, options?: any): AxiosPromise<ApiResponseWalletSet> {
            return localVarFp.billerGetWallets(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {Array<BillerAdditionalPayment>} additionalPayments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerSaveAdditionalPayments(billerCode: string, additionalPayments: Array<BillerAdditionalPayment>, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerSaveAdditionalPayments(billerCode, additionalPayments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a biller\'s details
         * @param {string} billerCode The biller\&#39;s unique identifier
         * @param {UpdateBillerRequest} updatedBiller The updated fields to apply to the biller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateBiller(billerCode: string, updatedBiller: UpdateBillerRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerUpdateBiller(billerCode, updatedBiller, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {UpdateBillerCRNDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateCustomerReference(billerCode: string, request: UpdateBillerCRNDetailsRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerUpdateCustomerReference(billerCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {UpdateBillerDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateDetails(billerCode: string, request: UpdateBillerDetailsRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerUpdateDetails(billerCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {SetIsTopUpsEnabledRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateIsTopUpsEnabled(billerCode: string, request: SetIsTopUpsEnabledRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerUpdateIsTopUpsEnabled(billerCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {AcceptedAmounts} amounts 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdatePaymentLimits(billerCode: string, amounts: AcceptedAmounts, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerUpdatePaymentLimits(billerCode, amounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {SurchargeRuleSet} ruleSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateSurchargeRules(billerCode: string, ruleSet: SurchargeRuleSet, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerUpdateSurchargeRules(billerCode, ruleSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {WalletSet} walletSet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUpdateWallets(billerCode: string, walletSet: WalletSet, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.billerUpdateWallets(billerCode, walletSet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the CRN Payment Rules via file upload
         * @param {string} [requestEmails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billerUploadBillerCRNLookupRulesFile(requestEmails?: string, options?: any): AxiosPromise<ApiResponseUploadBillerCRNLookupRulesFileResponse> {
            return localVarFp.billerUploadBillerCRNLookupRulesFile(requestEmails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillerApi - object-oriented interface
 * @export
 * @class BillerApi
 * @extends {BaseAPI}
 */
export class BillerApi extends BaseAPI {
    /**
     * 
     * @summary Creates a biller for a merchant, if they have less than 5 billers
     * @param {CreateBillerRequest} newBiller A CreateBillerRequest object used for some of the new biller\&#39;s details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerCreateBiller(newBiller: CreateBillerRequest, options?: any) {
        return BillerApiFp(this.configuration).billerCreateBiller(newBiller, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerGetAdditionalPayments(billerCode: string, options?: any) {
        return BillerApiFp(this.configuration).billerGetAdditionalPayments(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerGetApplePayWallet(billerCode: string, options?: any) {
        return BillerApiFp(this.configuration).billerGetApplePayWallet(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerGetBiller(billerCode: string, options?: any) {
        return BillerApiFp(this.configuration).billerGetBiller(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} includeChildMerchants 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerGetBillers(includeChildMerchants: boolean, options?: any) {
        return BillerApiFp(this.configuration).billerGetBillers(includeChildMerchants, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerGetFullSurchargeRules(billerCode: string, options?: any) {
        return BillerApiFp(this.configuration).billerGetFullSurchargeRules(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerGetSurchargeRules(billerCode: string, options?: any) {
        return BillerApiFp(this.configuration).billerGetSurchargeRules(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerGetWallets(billerCode: string, options?: any) {
        return BillerApiFp(this.configuration).billerGetWallets(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {Array<BillerAdditionalPayment>} additionalPayments 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerSaveAdditionalPayments(billerCode: string, additionalPayments: Array<BillerAdditionalPayment>, options?: any) {
        return BillerApiFp(this.configuration).billerSaveAdditionalPayments(billerCode, additionalPayments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a biller\'s details
     * @param {string} billerCode The biller\&#39;s unique identifier
     * @param {UpdateBillerRequest} updatedBiller The updated fields to apply to the biller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUpdateBiller(billerCode: string, updatedBiller: UpdateBillerRequest, options?: any) {
        return BillerApiFp(this.configuration).billerUpdateBiller(billerCode, updatedBiller, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {UpdateBillerCRNDetailsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUpdateCustomerReference(billerCode: string, request: UpdateBillerCRNDetailsRequest, options?: any) {
        return BillerApiFp(this.configuration).billerUpdateCustomerReference(billerCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {UpdateBillerDetailsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUpdateDetails(billerCode: string, request: UpdateBillerDetailsRequest, options?: any) {
        return BillerApiFp(this.configuration).billerUpdateDetails(billerCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {SetIsTopUpsEnabledRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUpdateIsTopUpsEnabled(billerCode: string, request: SetIsTopUpsEnabledRequest, options?: any) {
        return BillerApiFp(this.configuration).billerUpdateIsTopUpsEnabled(billerCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {AcceptedAmounts} amounts 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUpdatePaymentLimits(billerCode: string, amounts: AcceptedAmounts, options?: any) {
        return BillerApiFp(this.configuration).billerUpdatePaymentLimits(billerCode, amounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {SurchargeRuleSet} ruleSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUpdateSurchargeRules(billerCode: string, ruleSet: SurchargeRuleSet, options?: any) {
        return BillerApiFp(this.configuration).billerUpdateSurchargeRules(billerCode, ruleSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {WalletSet} walletSet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUpdateWallets(billerCode: string, walletSet: WalletSet, options?: any) {
        return BillerApiFp(this.configuration).billerUpdateWallets(billerCode, walletSet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the CRN Payment Rules via file upload
     * @param {string} [requestEmails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillerApi
     */
    public billerUploadBillerCRNLookupRulesFile(requestEmails?: string, options?: any) {
        return BillerApiFp(this.configuration).billerUploadBillerCRNLookupRulesFile(requestEmails, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessDetailsApi - axios parameter creator
 * @export
 */
export const BusinessDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessDetailsGetBusinessDetails: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/business-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BusinessDetails} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessDetailsUpdateBusinessDetails: async (request: BusinessDetails, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('businessDetailsUpdateBusinessDetails', 'request', request)
            const localVarPath = `/business-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessDetailsApi - functional programming interface
 * @export
 */
export const BusinessDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessDetailsGetBusinessDetails(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBusinessDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessDetailsGetBusinessDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BusinessDetails} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessDetailsUpdateBusinessDetails(request: BusinessDetails, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessDetailsUpdateBusinessDetails(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessDetailsApi - factory interface
 * @export
 */
export const BusinessDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessDetailsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessDetailsGetBusinessDetails(options?: any): AxiosPromise<ApiResponseBusinessDetails> {
            return localVarFp.businessDetailsGetBusinessDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BusinessDetails} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessDetailsUpdateBusinessDetails(request: BusinessDetails, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.businessDetailsUpdateBusinessDetails(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessDetailsApi - object-oriented interface
 * @export
 * @class BusinessDetailsApi
 * @extends {BaseAPI}
 */
export class BusinessDetailsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessDetailsApi
     */
    public businessDetailsGetBusinessDetails(options?: any) {
        return BusinessDetailsApiFp(this.configuration).businessDetailsGetBusinessDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BusinessDetails} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessDetailsApi
     */
    public businessDetailsUpdateBusinessDetails(request: BusinessDetails, options?: any) {
        return BusinessDetailsApiFp(this.configuration).businessDetailsUpdateBusinessDetails(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentManagementApi - axios parameter creator
 * @export
 */
export const ContentManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProductTooltipRequestModel} productToolTipModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentManagementGetProductTooltip: async (productToolTipModule: ProductTooltipRequestModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productToolTipModule' is not null or undefined
            assertParamExists('contentManagementGetProductTooltip', 'productToolTipModule', productToolTipModule)
            const localVarPath = `/content/tooltip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productToolTipModule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentManagementApi - functional programming interface
 * @export
 */
export const ContentManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProductTooltipRequestModel} productToolTipModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentManagementGetProductTooltip(productToolTipModule: ProductTooltipRequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseProductTooltipModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentManagementGetProductTooltip(productToolTipModule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentManagementApi - factory interface
 * @export
 */
export const ContentManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductTooltipRequestModel} productToolTipModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentManagementGetProductTooltip(productToolTipModule: ProductTooltipRequestModel, options?: any): AxiosPromise<ApiResponseProductTooltipModel> {
            return localVarFp.contentManagementGetProductTooltip(productToolTipModule, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentManagementApi - object-oriented interface
 * @export
 * @class ContentManagementApi
 * @extends {BaseAPI}
 */
export class ContentManagementApi extends BaseAPI {
    /**
     * 
     * @param {ProductTooltipRequestModel} productToolTipModule 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentManagementApi
     */
    public contentManagementGetProductTooltip(productToolTipModule: ProductTooltipRequestModel, options?: any) {
        return ContentManagementApiFp(this.configuration).contentManagementGetProductTooltip(productToolTipModule, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CredentialOnFileApi - axios parameter creator
 * @export
 */
export const CredentialOnFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialOnFileGetConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/CredentialOnFile/GetConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CredentialOnFileModel} credentialOnFileModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialOnFileUpdateConfiguration: async (credentialOnFileModel: CredentialOnFileModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentialOnFileModel' is not null or undefined
            assertParamExists('credentialOnFileUpdateConfiguration', 'credentialOnFileModel', credentialOnFileModel)
            const localVarPath = `/CredentialOnFile/UpdateConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentialOnFileModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CredentialOnFileApi - functional programming interface
 * @export
 */
export const CredentialOnFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CredentialOnFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialOnFileGetConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCredentialOnFileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialOnFileGetConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CredentialOnFileModel} credentialOnFileModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialOnFileUpdateConfiguration(credentialOnFileModel: CredentialOnFileModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialOnFileUpdateConfiguration(credentialOnFileModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CredentialOnFileApi - factory interface
 * @export
 */
export const CredentialOnFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CredentialOnFileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialOnFileGetConfiguration(options?: any): AxiosPromise<ApiResponseCredentialOnFileModel> {
            return localVarFp.credentialOnFileGetConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CredentialOnFileModel} credentialOnFileModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialOnFileUpdateConfiguration(credentialOnFileModel: CredentialOnFileModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.credentialOnFileUpdateConfiguration(credentialOnFileModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CredentialOnFileApi - object-oriented interface
 * @export
 * @class CredentialOnFileApi
 * @extends {BaseAPI}
 */
export class CredentialOnFileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialOnFileApi
     */
    public credentialOnFileGetConfiguration(options?: any) {
        return CredentialOnFileApiFp(this.configuration).credentialOnFileGetConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CredentialOnFileModel} credentialOnFileModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialOnFileApi
     */
    public credentialOnFileUpdateConfiguration(credentialOnFileModel: CredentialOnFileModel, options?: any) {
        return CredentialOnFileApiFp(this.configuration).credentialOnFileUpdateConfiguration(credentialOnFileModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Inserts a new Customer to the database
         * @param {CustomerModel} customer The Customer to add, including Addresses and PhoneNumbers, without UniqueId / MerchantId (they\&#39;re ignored)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAddCustomer: async (customer: CustomerModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('customerAddCustomer', 'customer', customer)
            const localVarPath = `/Customer/AddCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a Data Vault token for a customer
         * @param {CustomerTokenRequest} request Customer and card/bank details to be tokenised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAddToken: async (request: CustomerTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerAddToken', 'request', request)
            const localVarPath = `/Customer/AddToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a customer from the database (setting bDeleted flag)
         * @param {DeleteCustomerRequest} request A DeleteCustomerRequest object, consisting of a customerId number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteCustomer: async (request: DeleteCustomerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerDeleteCustomer', 'request', request)
            const localVarPath = `/Customer/DeleteCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadCustomersExportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDownloadCustomersExport: async (request: DownloadCustomersExportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerDownloadCustomersExport', 'request', request)
            const localVarPath = `/Customer/DownloadCustomersExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a customer\'s details including saved tokenised payment methods.
         * @param {GetCustomerRequest} request CustomerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomer: async (request: GetCustomerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerGetCustomer', 'request', request)
            const localVarPath = `/Customer/GetCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of Customers matching the provided filters
         * @param {PaginateRequestGetCustomersRequestCustomerModelSortFields} request A PaginatedList object of type GetCustomersRequest. Request contains Name, Email Address, Phone number, CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomers: async (request: PaginateRequestGetCustomersRequestCustomerModelSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerGetCustomers', 'request', request)
            const localVarPath = `/Customer/GetCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersExportColumns: async (request: GetExportDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerGetCustomersExportColumns', 'request', request)
            const localVarPath = `/Customer/GetCustomersExportColumns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersExportOptions: async (request: GetExportDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerGetCustomersExportOptions', 'request', request)
            const localVarPath = `/Customer/GetCustomersExportOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersExportTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Customer/GetCustomersExportTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link a Customer to Tokens
         * @param {LinkCustomerTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerLinkToken: async (request: LinkCustomerTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerLinkToken', 'request', request)
            const localVarPath = `/Customer/LinkToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink a Customer from Tokens
         * @param {LinkCustomerTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUnlinkToken: async (request: LinkCustomerTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('customerUnlinkToken', 'request', request)
            const localVarPath = `/Customer/UnlinkToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates Customer details (incl. Addresses + Phones) into the database
         * @param {CustomerModel} customer The Customer to update, including Addresses and PhoneNumbers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateCustomer: async (customer: CustomerModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('customerUpdateCustomer', 'customer', customer)
            const localVarPath = `/Customer/UpdateCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Inserts a new Customer to the database
         * @param {CustomerModel} customer The Customer to add, including Addresses and PhoneNumbers, without UniqueId / MerchantId (they\&#39;re ignored)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAddCustomer(customer: CustomerModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCustomerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAddCustomer(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a Data Vault token for a customer
         * @param {CustomerTokenRequest} request Customer and card/bank details to be tokenised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAddToken(request: CustomerTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDataVaultSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAddToken(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a customer from the database (setting bDeleted flag)
         * @param {DeleteCustomerRequest} request A DeleteCustomerRequest object, consisting of a customerId number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerDeleteCustomer(request: DeleteCustomerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerDeleteCustomer(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadCustomersExportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerDownloadCustomersExport(request: DownloadCustomersExportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerDownloadCustomersExport(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a customer\'s details including saved tokenised payment methods.
         * @param {GetCustomerRequest} request CustomerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetCustomer(request: GetCustomerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCustomerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetCustomer(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of Customers matching the provided filters
         * @param {PaginateRequestGetCustomersRequestCustomerModelSortFields} request A PaginatedList object of type GetCustomersRequest. Request contains Name, Email Address, Phone number, CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetCustomers(request: PaginateRequestGetCustomersRequestCustomerModelSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListCustomerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetCustomers(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetCustomersExportColumns(request: GetExportDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListGetExportColumnsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetCustomersExportColumns(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetCustomersExportOptions(request: GetExportDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListGetExportOptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetCustomersExportOptions(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetCustomersExportTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseExportTypesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetCustomersExportTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Link a Customer to Tokens
         * @param {LinkCustomerTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerLinkToken(request: LinkCustomerTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerLinkToken(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlink a Customer from Tokens
         * @param {LinkCustomerTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUnlinkToken(request: LinkCustomerTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUnlinkToken(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates Customer details (incl. Addresses + Phones) into the database
         * @param {CustomerModel} customer The Customer to update, including Addresses and PhoneNumbers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateCustomer(customer: CustomerModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCustomerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateCustomer(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Inserts a new Customer to the database
         * @param {CustomerModel} customer The Customer to add, including Addresses and PhoneNumbers, without UniqueId / MerchantId (they\&#39;re ignored)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAddCustomer(customer: CustomerModel, options?: any): AxiosPromise<ApiResponseCustomerModel> {
            return localVarFp.customerAddCustomer(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a Data Vault token for a customer
         * @param {CustomerTokenRequest} request Customer and card/bank details to be tokenised
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAddToken(request: CustomerTokenRequest, options?: any): AxiosPromise<ApiResponseDataVaultSummaryModel> {
            return localVarFp.customerAddToken(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a customer from the database (setting bDeleted flag)
         * @param {DeleteCustomerRequest} request A DeleteCustomerRequest object, consisting of a customerId number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDeleteCustomer(request: DeleteCustomerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerDeleteCustomer(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadCustomersExportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerDownloadCustomersExport(request: DownloadCustomersExportRequest, options?: any): AxiosPromise<object> {
            return localVarFp.customerDownloadCustomersExport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a customer\'s details including saved tokenised payment methods.
         * @param {GetCustomerRequest} request CustomerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomer(request: GetCustomerRequest, options?: any): AxiosPromise<ApiResponseCustomerModel> {
            return localVarFp.customerGetCustomer(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of Customers matching the provided filters
         * @param {PaginateRequestGetCustomersRequestCustomerModelSortFields} request A PaginatedList object of type GetCustomersRequest. Request contains Name, Email Address, Phone number, CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomers(request: PaginateRequestGetCustomersRequestCustomerModelSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListCustomerModel> {
            return localVarFp.customerGetCustomers(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersExportColumns(request: GetExportDetailsRequest, options?: any): AxiosPromise<ApiResponseListGetExportColumnsResponse> {
            return localVarFp.customerGetCustomersExportColumns(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersExportOptions(request: GetExportDetailsRequest, options?: any): AxiosPromise<ApiResponseListGetExportOptionsResponse> {
            return localVarFp.customerGetCustomersExportOptions(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersExportTypes(options?: any): AxiosPromise<ApiResponseExportTypesData> {
            return localVarFp.customerGetCustomersExportTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link a Customer to Tokens
         * @param {LinkCustomerTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerLinkToken(request: LinkCustomerTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerLinkToken(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink a Customer from Tokens
         * @param {LinkCustomerTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUnlinkToken(request: LinkCustomerTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.customerUnlinkToken(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates Customer details (incl. Addresses + Phones) into the database
         * @param {CustomerModel} customer The Customer to update, including Addresses and PhoneNumbers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateCustomer(customer: CustomerModel, options?: any): AxiosPromise<ApiResponseCustomerModel> {
            return localVarFp.customerUpdateCustomer(customer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Inserts a new Customer to the database
     * @param {CustomerModel} customer The Customer to add, including Addresses and PhoneNumbers, without UniqueId / MerchantId (they\&#39;re ignored)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerAddCustomer(customer: CustomerModel, options?: any) {
        return CustomerApiFp(this.configuration).customerAddCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a Data Vault token for a customer
     * @param {CustomerTokenRequest} request Customer and card/bank details to be tokenised
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerAddToken(request: CustomerTokenRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerAddToken(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a customer from the database (setting bDeleted flag)
     * @param {DeleteCustomerRequest} request A DeleteCustomerRequest object, consisting of a customerId number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerDeleteCustomer(request: DeleteCustomerRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerDeleteCustomer(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadCustomersExportRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerDownloadCustomersExport(request: DownloadCustomersExportRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerDownloadCustomersExport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a customer\'s details including saved tokenised payment methods.
     * @param {GetCustomerRequest} request CustomerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetCustomer(request: GetCustomerRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerGetCustomer(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of Customers matching the provided filters
     * @param {PaginateRequestGetCustomersRequestCustomerModelSortFields} request A PaginatedList object of type GetCustomersRequest. Request contains Name, Email Address, Phone number, CRNs etc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetCustomers(request: PaginateRequestGetCustomersRequestCustomerModelSortFields, options?: any) {
        return CustomerApiFp(this.configuration).customerGetCustomers(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetExportDetailsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetCustomersExportColumns(request: GetExportDetailsRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerGetCustomersExportColumns(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetExportDetailsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetCustomersExportOptions(request: GetExportDetailsRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerGetCustomersExportOptions(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetCustomersExportTypes(options?: any) {
        return CustomerApiFp(this.configuration).customerGetCustomersExportTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link a Customer to Tokens
     * @param {LinkCustomerTokenRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerLinkToken(request: LinkCustomerTokenRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerLinkToken(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink a Customer from Tokens
     * @param {LinkCustomerTokenRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerUnlinkToken(request: LinkCustomerTokenRequest, options?: any) {
        return CustomerApiFp(this.configuration).customerUnlinkToken(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates Customer details (incl. Addresses + Phones) into the database
     * @param {CustomerModel} customer The Customer to update, including Addresses and PhoneNumbers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerUpdateCustomer(customer: CustomerModel, options?: any) {
        return CustomerApiFp(this.configuration).customerUpdateCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataVaultApi - axios parameter creator
 * @export
 */
export const DataVaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DataVaultTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultAddToken: async (request: DataVaultTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('dataVaultAddToken', 'request', request)
            const localVarPath = `/DataVault/AddToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultDeleteToken: async (request: DeleteTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('dataVaultDeleteToken', 'request', request)
            const localVarPath = `/DataVault/DeleteToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadDataVaultReportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultDownloadDataVaultReport: async (request: DownloadDataVaultReportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('dataVaultDownloadDataVaultReport', 'request', request)
            const localVarPath = `/DataVault/DownloadDataVaultReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Data Vault token\'s details
         * @param {GetTokenRequest} request ViewTokenRequest consisting of a DataVaultId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultGetToken: async (request: GetTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('dataVaultGetToken', 'request', request)
            const localVarPath = `/DataVault/GetToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of DataVaultSummaryModel matching the provided filters
         * @param {PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields} request A PaginatedList object of type SearchDataVaultRequest. Request contains Token, Dates, CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultSearchTokens: async (request: PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('dataVaultSearchTokens', 'request', request)
            const localVarPath = `/DataVault/SearchTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDataVaultTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultUpdateToken: async (request: UpdateDataVaultTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('dataVaultUpdateToken', 'request', request)
            const localVarPath = `/DataVault/UpdateToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultUploadBatchFile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataVault/UploadBatchFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataVaultApi - functional programming interface
 * @export
 */
export const DataVaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataVaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DataVaultTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataVaultAddToken(request: DataVaultTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDataVaultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataVaultAddToken(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataVaultDeleteToken(request: DeleteTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataVaultDeleteToken(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadDataVaultReportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataVaultDownloadDataVaultReport(request: DownloadDataVaultReportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataVaultDownloadDataVaultReport(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Data Vault token\'s details
         * @param {GetTokenRequest} request ViewTokenRequest consisting of a DataVaultId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataVaultGetToken(request: GetTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDataVaultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataVaultGetToken(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of DataVaultSummaryModel matching the provided filters
         * @param {PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields} request A PaginatedList object of type SearchDataVaultRequest. Request contains Token, Dates, CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataVaultSearchTokens(request: PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListDataVaultSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataVaultSearchTokens(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDataVaultTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataVaultUpdateToken(request: UpdateDataVaultTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDataVaultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataVaultUpdateToken(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataVaultUploadBatchFile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUploadBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataVaultUploadBatchFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataVaultApi - factory interface
 * @export
 */
export const DataVaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataVaultApiFp(configuration)
    return {
        /**
         * 
         * @param {DataVaultTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultAddToken(request: DataVaultTokenRequest, options?: any): AxiosPromise<ApiResponseDataVaultModel> {
            return localVarFp.dataVaultAddToken(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultDeleteToken(request: DeleteTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.dataVaultDeleteToken(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadDataVaultReportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultDownloadDataVaultReport(request: DownloadDataVaultReportRequest, options?: any): AxiosPromise<object> {
            return localVarFp.dataVaultDownloadDataVaultReport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Data Vault token\'s details
         * @param {GetTokenRequest} request ViewTokenRequest consisting of a DataVaultId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultGetToken(request: GetTokenRequest, options?: any): AxiosPromise<ApiResponseDataVaultModel> {
            return localVarFp.dataVaultGetToken(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of DataVaultSummaryModel matching the provided filters
         * @param {PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields} request A PaginatedList object of type SearchDataVaultRequest. Request contains Token, Dates, CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultSearchTokens(request: PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListDataVaultSummaryModel> {
            return localVarFp.dataVaultSearchTokens(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDataVaultTokenRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultUpdateToken(request: UpdateDataVaultTokenRequest, options?: any): AxiosPromise<ApiResponseDataVaultModel> {
            return localVarFp.dataVaultUpdateToken(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataVaultUploadBatchFile(options?: any): AxiosPromise<ApiResponseUploadBatchResponse> {
            return localVarFp.dataVaultUploadBatchFile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataVaultApi - object-oriented interface
 * @export
 * @class DataVaultApi
 * @extends {BaseAPI}
 */
export class DataVaultApi extends BaseAPI {
    /**
     * 
     * @param {DataVaultTokenRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataVaultApi
     */
    public dataVaultAddToken(request: DataVaultTokenRequest, options?: any) {
        return DataVaultApiFp(this.configuration).dataVaultAddToken(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteTokenRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataVaultApi
     */
    public dataVaultDeleteToken(request: DeleteTokenRequest, options?: any) {
        return DataVaultApiFp(this.configuration).dataVaultDeleteToken(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadDataVaultReportRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataVaultApi
     */
    public dataVaultDownloadDataVaultReport(request: DownloadDataVaultReportRequest, options?: any) {
        return DataVaultApiFp(this.configuration).dataVaultDownloadDataVaultReport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Data Vault token\'s details
     * @param {GetTokenRequest} request ViewTokenRequest consisting of a DataVaultId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataVaultApi
     */
    public dataVaultGetToken(request: GetTokenRequest, options?: any) {
        return DataVaultApiFp(this.configuration).dataVaultGetToken(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of DataVaultSummaryModel matching the provided filters
     * @param {PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields} request A PaginatedList object of type SearchDataVaultRequest. Request contains Token, Dates, CRNs etc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataVaultApi
     */
    public dataVaultSearchTokens(request: PaginateRequestSearchDataVaultRequestSearchDataVaultSortFields, options?: any) {
        return DataVaultApiFp(this.configuration).dataVaultSearchTokens(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDataVaultTokenRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataVaultApi
     */
    public dataVaultUpdateToken(request: UpdateDataVaultTokenRequest, options?: any) {
        return DataVaultApiFp(this.configuration).dataVaultUpdateToken(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataVaultApi
     */
    public dataVaultUploadBatchFile(options?: any) {
        return DataVaultApiFp(this.configuration).dataVaultUploadBatchFile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeeplinkingApi - axios parameter creator
 * @export
 */
export const DeeplinkingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the backoffice base url for use with deeplinking
         * @param {GetBackofficeUrlRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deeplinkingGetBackofficeUrl: async (request: GetBackofficeUrlRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('deeplinkingGetBackofficeUrl', 'request', request)
            const localVarPath = `/Deeplinking/GetBackofficeUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeeplinkingApi - functional programming interface
 * @export
 */
export const DeeplinkingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeeplinkingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the backoffice base url for use with deeplinking
         * @param {GetBackofficeUrlRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deeplinkingGetBackofficeUrl(request: GetBackofficeUrlRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetBackofficeUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deeplinkingGetBackofficeUrl(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeeplinkingApi - factory interface
 * @export
 */
export const DeeplinkingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeeplinkingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the backoffice base url for use with deeplinking
         * @param {GetBackofficeUrlRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deeplinkingGetBackofficeUrl(request: GetBackofficeUrlRequest, options?: any): AxiosPromise<ApiResponseGetBackofficeUrlResponse> {
            return localVarFp.deeplinkingGetBackofficeUrl(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeeplinkingApi - object-oriented interface
 * @export
 * @class DeeplinkingApi
 * @extends {BaseAPI}
 */
export class DeeplinkingApi extends BaseAPI {
    /**
     * 
     * @summary Get the backoffice base url for use with deeplinking
     * @param {GetBackofficeUrlRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeeplinkingApi
     */
    public deeplinkingGetBackofficeUrl(request: GetBackofficeUrlRequest, options?: any) {
        return DeeplinkingApiFp(this.configuration).deeplinkingGetBackofficeUrl(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DesignerApi - axios parameter creator
 * @export
 */
export const DesignerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Discards the draft of the biller-level branding style
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerDiscardBrandingDraft: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('designerDiscardBrandingDraft', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/branding/discard-draft`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Discards the draft of the merchant-level branding style
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerDiscardBrandingDraftForMerchant: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/branding/discard-draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the biller-level branding style
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerGetBranding: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('designerGetBranding', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/branding`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the merchant-level branding style
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerGetBrandingForMerchant: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/branding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the biller-level branding style (excluding logo) and publishes it
         * @param {string} billerCode 
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBranding: async (billerCode: string, branding: Branding, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('designerSaveAndPublishBranding', 'billerCode', billerCode)
            // verify required parameter 'branding' is not null or undefined
            assertParamExists('designerSaveAndPublishBranding', 'branding', branding)
            const localVarPath = `/billers/{billerCode}/branding/save-and-publish`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (excluding logo) and publishes it
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBrandingForMerchant: async (branding: Branding, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branding' is not null or undefined
            assertParamExists('designerSaveAndPublishBrandingForMerchant', 'branding', branding)
            const localVarPath = `/branding/save-and-publish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the biller-level branding style (including logo) and publishes it
         * @param {string} billerCode 
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBrandingWithLogo: async (billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('designerSaveAndPublishBrandingWithLogo', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/branding/save-and-publish-with-logo`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestBrandingIsDraft !== undefined) {
                localVarQueryParameter['request.branding.isDraft'] = requestBrandingIsDraft;
            }

            if (requestBrandingReturnBarFontColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.fontColour'] = requestBrandingReturnBarFontColour;
            }

            if (requestBrandingReturnBarBackColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.backColour'] = requestBrandingReturnBarBackColour;
            }

            if (requestBrandingReturnBarHeight !== undefined) {
                localVarQueryParameter['request.branding.returnBar.height'] = requestBrandingReturnBarHeight;
            }

            if (requestBrandingBannerFontColour !== undefined) {
                localVarQueryParameter['request.branding.banner.fontColour'] = requestBrandingBannerFontColour;
            }

            if (requestBrandingBannerBackColour !== undefined) {
                localVarQueryParameter['request.branding.banner.backColour'] = requestBrandingBannerBackColour;
            }

            if (requestBrandingBannerLogoFilename !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFilename'] = requestBrandingBannerLogoFilename;
            }

            if (requestBrandingBannerLogoUrl !== undefined) {
                localVarQueryParameter['request.branding.banner.logoUrl'] = requestBrandingBannerLogoUrl;
            }

            if (requestBrandingBannerLogoFileSize !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFileSize'] = requestBrandingBannerLogoFileSize;
            }

            if (requestBrandingBannerLogoPosition !== undefined) {
                localVarQueryParameter['request.branding.banner.logoPosition'] = requestBrandingBannerLogoPosition;
            }

            if (requestBrandingBannerHeight !== undefined) {
                localVarQueryParameter['request.branding.banner.height'] = requestBrandingBannerHeight;
            }

            if (requestBrandingMenuShowTerms !== undefined) {
                localVarQueryParameter['request.branding.menu.showTerms'] = requestBrandingMenuShowTerms;
            }

            if (requestBrandingMenuShowPrivacy !== undefined) {
                localVarQueryParameter['request.branding.menu.showPrivacy'] = requestBrandingMenuShowPrivacy;
            }

            if (requestBrandingMenuShowExtraInfo !== undefined) {
                localVarQueryParameter['request.branding.menu.showExtraInfo'] = requestBrandingMenuShowExtraInfo;
            }

            if (requestBrandingMenuFontColour !== undefined) {
                localVarQueryParameter['request.branding.menu.fontColour'] = requestBrandingMenuFontColour;
            }

            if (requestBrandingMenuBackColour !== undefined) {
                localVarQueryParameter['request.branding.menu.backColour'] = requestBrandingMenuBackColour;
            }

            if (requestBrandingMenuUnderlineColour !== undefined) {
                localVarQueryParameter['request.branding.menu.underlineColour'] = requestBrandingMenuUnderlineColour;
            }

            if (requestBrandingMenuMobileColour !== undefined) {
                localVarQueryParameter['request.branding.menu.mobileColour'] = requestBrandingMenuMobileColour;
            }

            if (requestBrandingMenuShowTopBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showTopBorder'] = requestBrandingMenuShowTopBorder;
            }

            if (requestBrandingMenuShowBottomBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showBottomBorder'] = requestBrandingMenuShowBottomBorder;
            }

            if (requestBrandingMenuBorderColour !== undefined) {
                localVarQueryParameter['request.branding.menu.borderColour'] = requestBrandingMenuBorderColour;
            }

            if (requestBrandingPageFontFamily !== undefined) {
                localVarQueryParameter['request.branding.page.fontFamily'] = requestBrandingPageFontFamily;
            }

            if (requestBrandingPageFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.fontColour'] = requestBrandingPageFontColour;
            }

            if (requestBrandingPageBackColour !== undefined) {
                localVarQueryParameter['request.branding.page.backColour'] = requestBrandingPageBackColour;
            }

            if (requestBrandingPageSectionColour !== undefined) {
                localVarQueryParameter['request.branding.page.sectionColour'] = requestBrandingPageSectionColour;
            }

            if (requestBrandingPagePrimaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonFontColour'] = requestBrandingPagePrimaryButtonFontColour;
            }

            if (requestBrandingPagePrimaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonColour'] = requestBrandingPagePrimaryButtonColour;
            }

            if (requestBrandingPageSecondaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonFontColour'] = requestBrandingPageSecondaryButtonFontColour;
            }

            if (requestBrandingPageSecondaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonColour'] = requestBrandingPageSecondaryButtonColour;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (including logo) and publishes it
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBrandingWithLogoForMerchant: async (requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/branding/save-and-publish-with-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestBrandingIsDraft !== undefined) {
                localVarQueryParameter['request.branding.isDraft'] = requestBrandingIsDraft;
            }

            if (requestBrandingReturnBarFontColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.fontColour'] = requestBrandingReturnBarFontColour;
            }

            if (requestBrandingReturnBarBackColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.backColour'] = requestBrandingReturnBarBackColour;
            }

            if (requestBrandingReturnBarHeight !== undefined) {
                localVarQueryParameter['request.branding.returnBar.height'] = requestBrandingReturnBarHeight;
            }

            if (requestBrandingBannerFontColour !== undefined) {
                localVarQueryParameter['request.branding.banner.fontColour'] = requestBrandingBannerFontColour;
            }

            if (requestBrandingBannerBackColour !== undefined) {
                localVarQueryParameter['request.branding.banner.backColour'] = requestBrandingBannerBackColour;
            }

            if (requestBrandingBannerLogoFilename !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFilename'] = requestBrandingBannerLogoFilename;
            }

            if (requestBrandingBannerLogoUrl !== undefined) {
                localVarQueryParameter['request.branding.banner.logoUrl'] = requestBrandingBannerLogoUrl;
            }

            if (requestBrandingBannerLogoFileSize !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFileSize'] = requestBrandingBannerLogoFileSize;
            }

            if (requestBrandingBannerLogoPosition !== undefined) {
                localVarQueryParameter['request.branding.banner.logoPosition'] = requestBrandingBannerLogoPosition;
            }

            if (requestBrandingBannerHeight !== undefined) {
                localVarQueryParameter['request.branding.banner.height'] = requestBrandingBannerHeight;
            }

            if (requestBrandingMenuShowTerms !== undefined) {
                localVarQueryParameter['request.branding.menu.showTerms'] = requestBrandingMenuShowTerms;
            }

            if (requestBrandingMenuShowPrivacy !== undefined) {
                localVarQueryParameter['request.branding.menu.showPrivacy'] = requestBrandingMenuShowPrivacy;
            }

            if (requestBrandingMenuShowExtraInfo !== undefined) {
                localVarQueryParameter['request.branding.menu.showExtraInfo'] = requestBrandingMenuShowExtraInfo;
            }

            if (requestBrandingMenuFontColour !== undefined) {
                localVarQueryParameter['request.branding.menu.fontColour'] = requestBrandingMenuFontColour;
            }

            if (requestBrandingMenuBackColour !== undefined) {
                localVarQueryParameter['request.branding.menu.backColour'] = requestBrandingMenuBackColour;
            }

            if (requestBrandingMenuUnderlineColour !== undefined) {
                localVarQueryParameter['request.branding.menu.underlineColour'] = requestBrandingMenuUnderlineColour;
            }

            if (requestBrandingMenuMobileColour !== undefined) {
                localVarQueryParameter['request.branding.menu.mobileColour'] = requestBrandingMenuMobileColour;
            }

            if (requestBrandingMenuShowTopBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showTopBorder'] = requestBrandingMenuShowTopBorder;
            }

            if (requestBrandingMenuShowBottomBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showBottomBorder'] = requestBrandingMenuShowBottomBorder;
            }

            if (requestBrandingMenuBorderColour !== undefined) {
                localVarQueryParameter['request.branding.menu.borderColour'] = requestBrandingMenuBorderColour;
            }

            if (requestBrandingPageFontFamily !== undefined) {
                localVarQueryParameter['request.branding.page.fontFamily'] = requestBrandingPageFontFamily;
            }

            if (requestBrandingPageFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.fontColour'] = requestBrandingPageFontColour;
            }

            if (requestBrandingPageBackColour !== undefined) {
                localVarQueryParameter['request.branding.page.backColour'] = requestBrandingPageBackColour;
            }

            if (requestBrandingPageSectionColour !== undefined) {
                localVarQueryParameter['request.branding.page.sectionColour'] = requestBrandingPageSectionColour;
            }

            if (requestBrandingPagePrimaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonFontColour'] = requestBrandingPagePrimaryButtonFontColour;
            }

            if (requestBrandingPagePrimaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonColour'] = requestBrandingPagePrimaryButtonColour;
            }

            if (requestBrandingPageSecondaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonFontColour'] = requestBrandingPageSecondaryButtonFontColour;
            }

            if (requestBrandingPageSecondaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonColour'] = requestBrandingPageSecondaryButtonColour;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the biller-level branding style (excluding logo) as a draft
         * @param {string} billerCode 
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBranding: async (billerCode: string, branding: Branding, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('designerSaveBranding', 'billerCode', billerCode)
            // verify required parameter 'branding' is not null or undefined
            assertParamExists('designerSaveBranding', 'branding', branding)
            const localVarPath = `/billers/{billerCode}/branding`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (excluding logo) as a draft
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBrandingForMerchant: async (branding: Branding, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'branding' is not null or undefined
            assertParamExists('designerSaveBrandingForMerchant', 'branding', branding)
            const localVarPath = `/branding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the biller-level branding style (including logo) as a draft
         * @param {string} billerCode 
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBrandingWithLogo: async (billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('designerSaveBrandingWithLogo', 'billerCode', billerCode)
            const localVarPath = `/billers/{billerCode}/branding/save-with-logo`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestBrandingIsDraft !== undefined) {
                localVarQueryParameter['request.branding.isDraft'] = requestBrandingIsDraft;
            }

            if (requestBrandingReturnBarFontColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.fontColour'] = requestBrandingReturnBarFontColour;
            }

            if (requestBrandingReturnBarBackColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.backColour'] = requestBrandingReturnBarBackColour;
            }

            if (requestBrandingReturnBarHeight !== undefined) {
                localVarQueryParameter['request.branding.returnBar.height'] = requestBrandingReturnBarHeight;
            }

            if (requestBrandingBannerFontColour !== undefined) {
                localVarQueryParameter['request.branding.banner.fontColour'] = requestBrandingBannerFontColour;
            }

            if (requestBrandingBannerBackColour !== undefined) {
                localVarQueryParameter['request.branding.banner.backColour'] = requestBrandingBannerBackColour;
            }

            if (requestBrandingBannerLogoFilename !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFilename'] = requestBrandingBannerLogoFilename;
            }

            if (requestBrandingBannerLogoUrl !== undefined) {
                localVarQueryParameter['request.branding.banner.logoUrl'] = requestBrandingBannerLogoUrl;
            }

            if (requestBrandingBannerLogoFileSize !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFileSize'] = requestBrandingBannerLogoFileSize;
            }

            if (requestBrandingBannerLogoPosition !== undefined) {
                localVarQueryParameter['request.branding.banner.logoPosition'] = requestBrandingBannerLogoPosition;
            }

            if (requestBrandingBannerHeight !== undefined) {
                localVarQueryParameter['request.branding.banner.height'] = requestBrandingBannerHeight;
            }

            if (requestBrandingMenuShowTerms !== undefined) {
                localVarQueryParameter['request.branding.menu.showTerms'] = requestBrandingMenuShowTerms;
            }

            if (requestBrandingMenuShowPrivacy !== undefined) {
                localVarQueryParameter['request.branding.menu.showPrivacy'] = requestBrandingMenuShowPrivacy;
            }

            if (requestBrandingMenuShowExtraInfo !== undefined) {
                localVarQueryParameter['request.branding.menu.showExtraInfo'] = requestBrandingMenuShowExtraInfo;
            }

            if (requestBrandingMenuFontColour !== undefined) {
                localVarQueryParameter['request.branding.menu.fontColour'] = requestBrandingMenuFontColour;
            }

            if (requestBrandingMenuBackColour !== undefined) {
                localVarQueryParameter['request.branding.menu.backColour'] = requestBrandingMenuBackColour;
            }

            if (requestBrandingMenuUnderlineColour !== undefined) {
                localVarQueryParameter['request.branding.menu.underlineColour'] = requestBrandingMenuUnderlineColour;
            }

            if (requestBrandingMenuMobileColour !== undefined) {
                localVarQueryParameter['request.branding.menu.mobileColour'] = requestBrandingMenuMobileColour;
            }

            if (requestBrandingMenuShowTopBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showTopBorder'] = requestBrandingMenuShowTopBorder;
            }

            if (requestBrandingMenuShowBottomBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showBottomBorder'] = requestBrandingMenuShowBottomBorder;
            }

            if (requestBrandingMenuBorderColour !== undefined) {
                localVarQueryParameter['request.branding.menu.borderColour'] = requestBrandingMenuBorderColour;
            }

            if (requestBrandingPageFontFamily !== undefined) {
                localVarQueryParameter['request.branding.page.fontFamily'] = requestBrandingPageFontFamily;
            }

            if (requestBrandingPageFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.fontColour'] = requestBrandingPageFontColour;
            }

            if (requestBrandingPageBackColour !== undefined) {
                localVarQueryParameter['request.branding.page.backColour'] = requestBrandingPageBackColour;
            }

            if (requestBrandingPageSectionColour !== undefined) {
                localVarQueryParameter['request.branding.page.sectionColour'] = requestBrandingPageSectionColour;
            }

            if (requestBrandingPagePrimaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonFontColour'] = requestBrandingPagePrimaryButtonFontColour;
            }

            if (requestBrandingPagePrimaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonColour'] = requestBrandingPagePrimaryButtonColour;
            }

            if (requestBrandingPageSecondaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonFontColour'] = requestBrandingPageSecondaryButtonFontColour;
            }

            if (requestBrandingPageSecondaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonColour'] = requestBrandingPageSecondaryButtonColour;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (including logo) as a draft
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBrandingWithLogoForMerchant: async (requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/branding/save-with-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requestBrandingIsDraft !== undefined) {
                localVarQueryParameter['request.branding.isDraft'] = requestBrandingIsDraft;
            }

            if (requestBrandingReturnBarFontColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.fontColour'] = requestBrandingReturnBarFontColour;
            }

            if (requestBrandingReturnBarBackColour !== undefined) {
                localVarQueryParameter['request.branding.returnBar.backColour'] = requestBrandingReturnBarBackColour;
            }

            if (requestBrandingReturnBarHeight !== undefined) {
                localVarQueryParameter['request.branding.returnBar.height'] = requestBrandingReturnBarHeight;
            }

            if (requestBrandingBannerFontColour !== undefined) {
                localVarQueryParameter['request.branding.banner.fontColour'] = requestBrandingBannerFontColour;
            }

            if (requestBrandingBannerBackColour !== undefined) {
                localVarQueryParameter['request.branding.banner.backColour'] = requestBrandingBannerBackColour;
            }

            if (requestBrandingBannerLogoFilename !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFilename'] = requestBrandingBannerLogoFilename;
            }

            if (requestBrandingBannerLogoUrl !== undefined) {
                localVarQueryParameter['request.branding.banner.logoUrl'] = requestBrandingBannerLogoUrl;
            }

            if (requestBrandingBannerLogoFileSize !== undefined) {
                localVarQueryParameter['request.branding.banner.logoFileSize'] = requestBrandingBannerLogoFileSize;
            }

            if (requestBrandingBannerLogoPosition !== undefined) {
                localVarQueryParameter['request.branding.banner.logoPosition'] = requestBrandingBannerLogoPosition;
            }

            if (requestBrandingBannerHeight !== undefined) {
                localVarQueryParameter['request.branding.banner.height'] = requestBrandingBannerHeight;
            }

            if (requestBrandingMenuShowTerms !== undefined) {
                localVarQueryParameter['request.branding.menu.showTerms'] = requestBrandingMenuShowTerms;
            }

            if (requestBrandingMenuShowPrivacy !== undefined) {
                localVarQueryParameter['request.branding.menu.showPrivacy'] = requestBrandingMenuShowPrivacy;
            }

            if (requestBrandingMenuShowExtraInfo !== undefined) {
                localVarQueryParameter['request.branding.menu.showExtraInfo'] = requestBrandingMenuShowExtraInfo;
            }

            if (requestBrandingMenuFontColour !== undefined) {
                localVarQueryParameter['request.branding.menu.fontColour'] = requestBrandingMenuFontColour;
            }

            if (requestBrandingMenuBackColour !== undefined) {
                localVarQueryParameter['request.branding.menu.backColour'] = requestBrandingMenuBackColour;
            }

            if (requestBrandingMenuUnderlineColour !== undefined) {
                localVarQueryParameter['request.branding.menu.underlineColour'] = requestBrandingMenuUnderlineColour;
            }

            if (requestBrandingMenuMobileColour !== undefined) {
                localVarQueryParameter['request.branding.menu.mobileColour'] = requestBrandingMenuMobileColour;
            }

            if (requestBrandingMenuShowTopBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showTopBorder'] = requestBrandingMenuShowTopBorder;
            }

            if (requestBrandingMenuShowBottomBorder !== undefined) {
                localVarQueryParameter['request.branding.menu.showBottomBorder'] = requestBrandingMenuShowBottomBorder;
            }

            if (requestBrandingMenuBorderColour !== undefined) {
                localVarQueryParameter['request.branding.menu.borderColour'] = requestBrandingMenuBorderColour;
            }

            if (requestBrandingPageFontFamily !== undefined) {
                localVarQueryParameter['request.branding.page.fontFamily'] = requestBrandingPageFontFamily;
            }

            if (requestBrandingPageFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.fontColour'] = requestBrandingPageFontColour;
            }

            if (requestBrandingPageBackColour !== undefined) {
                localVarQueryParameter['request.branding.page.backColour'] = requestBrandingPageBackColour;
            }

            if (requestBrandingPageSectionColour !== undefined) {
                localVarQueryParameter['request.branding.page.sectionColour'] = requestBrandingPageSectionColour;
            }

            if (requestBrandingPagePrimaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonFontColour'] = requestBrandingPagePrimaryButtonFontColour;
            }

            if (requestBrandingPagePrimaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.primaryButtonColour'] = requestBrandingPagePrimaryButtonColour;
            }

            if (requestBrandingPageSecondaryButtonFontColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonFontColour'] = requestBrandingPageSecondaryButtonFontColour;
            }

            if (requestBrandingPageSecondaryButtonColour !== undefined) {
                localVarQueryParameter['request.branding.page.secondaryButtonColour'] = requestBrandingPageSecondaryButtonColour;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the merchant preference whether to have unique branding style per biller
         * @param {boolean} byBiller 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSetByBiller: async (byBiller: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'byBiller' is not null or undefined
            assertParamExists('designerSetByBiller', 'byBiller', byBiller)
            const localVarPath = `/branding/by-biller`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (byBiller !== undefined) {
                localVarQueryParameter['byBiller'] = byBiller;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DesignerApi - functional programming interface
 * @export
 */
export const DesignerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DesignerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Discards the draft of the biller-level branding style
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerDiscardBrandingDraft(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerDiscardBrandingDraft(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Discards the draft of the merchant-level branding style
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerDiscardBrandingDraftForMerchant(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerDiscardBrandingDraftForMerchant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the biller-level branding style
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerGetBranding(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBranding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerGetBranding(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the merchant-level branding style
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerGetBrandingForMerchant(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBranding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerGetBrandingForMerchant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the biller-level branding style (excluding logo) and publishes it
         * @param {string} billerCode 
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveAndPublishBranding(billerCode: string, branding: Branding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveAndPublishBranding(billerCode, branding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (excluding logo) and publishes it
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveAndPublishBrandingForMerchant(branding: Branding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveAndPublishBrandingForMerchant(branding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the biller-level branding style (including logo) and publishes it
         * @param {string} billerCode 
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveAndPublishBrandingWithLogo(billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveAndPublishBrandingWithLogo(billerCode, requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (including logo) and publishes it
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveAndPublishBrandingWithLogoForMerchant(requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveAndPublishBrandingWithLogoForMerchant(requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the biller-level branding style (excluding logo) as a draft
         * @param {string} billerCode 
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveBranding(billerCode: string, branding: Branding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveBranding(billerCode, branding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (excluding logo) as a draft
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveBrandingForMerchant(branding: Branding, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveBrandingForMerchant(branding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the biller-level branding style (including logo) as a draft
         * @param {string} billerCode 
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveBrandingWithLogo(billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveBrandingWithLogo(billerCode, requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (including logo) as a draft
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSaveBrandingWithLogoForMerchant(requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSaveBrandingWithLogoForMerchant(requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the merchant preference whether to have unique branding style per biller
         * @param {boolean} byBiller 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async designerSetByBiller(byBiller: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.designerSetByBiller(byBiller, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DesignerApi - factory interface
 * @export
 */
export const DesignerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DesignerApiFp(configuration)
    return {
        /**
         * 
         * @summary Discards the draft of the biller-level branding style
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerDiscardBrandingDraft(billerCode: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerDiscardBrandingDraft(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Discards the draft of the merchant-level branding style
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerDiscardBrandingDraftForMerchant(options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerDiscardBrandingDraftForMerchant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the biller-level branding style
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerGetBranding(billerCode: string, options?: any): AxiosPromise<ApiResponseBranding> {
            return localVarFp.designerGetBranding(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the merchant-level branding style
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerGetBrandingForMerchant(options?: any): AxiosPromise<ApiResponseBranding> {
            return localVarFp.designerGetBrandingForMerchant(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the biller-level branding style (excluding logo) and publishes it
         * @param {string} billerCode 
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBranding(billerCode: string, branding: Branding, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveAndPublishBranding(billerCode, branding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (excluding logo) and publishes it
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBrandingForMerchant(branding: Branding, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveAndPublishBrandingForMerchant(branding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the biller-level branding style (including logo) and publishes it
         * @param {string} billerCode 
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBrandingWithLogo(billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveAndPublishBrandingWithLogo(billerCode, requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (including logo) and publishes it
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveAndPublishBrandingWithLogoForMerchant(requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveAndPublishBrandingWithLogoForMerchant(requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the biller-level branding style (excluding logo) as a draft
         * @param {string} billerCode 
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBranding(billerCode: string, branding: Branding, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveBranding(billerCode, branding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (excluding logo) as a draft
         * @param {Branding} branding 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBrandingForMerchant(branding: Branding, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveBrandingForMerchant(branding, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the biller-level branding style (including logo) as a draft
         * @param {string} billerCode 
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBrandingWithLogo(billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveBrandingWithLogo(billerCode, requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the merchant-level branding style (including logo) as a draft
         * @param {boolean} [requestBrandingIsDraft] 
         * @param {string} [requestBrandingReturnBarFontColour] 
         * @param {string} [requestBrandingReturnBarBackColour] 
         * @param {string} [requestBrandingReturnBarHeight] 
         * @param {string} [requestBrandingBannerFontColour] 
         * @param {string} [requestBrandingBannerBackColour] 
         * @param {string} [requestBrandingBannerLogoFilename] 
         * @param {string} [requestBrandingBannerLogoUrl] 
         * @param {number} [requestBrandingBannerLogoFileSize] 
         * @param {string} [requestBrandingBannerLogoPosition] 
         * @param {string} [requestBrandingBannerHeight] 
         * @param {boolean} [requestBrandingMenuShowTerms] 
         * @param {boolean} [requestBrandingMenuShowPrivacy] 
         * @param {boolean} [requestBrandingMenuShowExtraInfo] 
         * @param {string} [requestBrandingMenuFontColour] 
         * @param {string} [requestBrandingMenuBackColour] 
         * @param {string} [requestBrandingMenuUnderlineColour] 
         * @param {string} [requestBrandingMenuMobileColour] 
         * @param {boolean} [requestBrandingMenuShowTopBorder] 
         * @param {boolean} [requestBrandingMenuShowBottomBorder] 
         * @param {string} [requestBrandingMenuBorderColour] 
         * @param {string} [requestBrandingPageFontFamily] 
         * @param {string} [requestBrandingPageFontColour] 
         * @param {string} [requestBrandingPageBackColour] 
         * @param {string} [requestBrandingPageSectionColour] 
         * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
         * @param {string} [requestBrandingPagePrimaryButtonColour] 
         * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
         * @param {string} [requestBrandingPageSecondaryButtonColour] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSaveBrandingWithLogoForMerchant(requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSaveBrandingWithLogoForMerchant(requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the merchant preference whether to have unique branding style per biller
         * @param {boolean} byBiller 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        designerSetByBiller(byBiller: boolean, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.designerSetByBiller(byBiller, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DesignerApi - object-oriented interface
 * @export
 * @class DesignerApi
 * @extends {BaseAPI}
 */
export class DesignerApi extends BaseAPI {
    /**
     * 
     * @summary Discards the draft of the biller-level branding style
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerDiscardBrandingDraft(billerCode: string, options?: any) {
        return DesignerApiFp(this.configuration).designerDiscardBrandingDraft(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Discards the draft of the merchant-level branding style
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerDiscardBrandingDraftForMerchant(options?: any) {
        return DesignerApiFp(this.configuration).designerDiscardBrandingDraftForMerchant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the biller-level branding style
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerGetBranding(billerCode: string, options?: any) {
        return DesignerApiFp(this.configuration).designerGetBranding(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the merchant-level branding style
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerGetBrandingForMerchant(options?: any) {
        return DesignerApiFp(this.configuration).designerGetBrandingForMerchant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the biller-level branding style (excluding logo) and publishes it
     * @param {string} billerCode 
     * @param {Branding} branding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveAndPublishBranding(billerCode: string, branding: Branding, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveAndPublishBranding(billerCode, branding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the merchant-level branding style (excluding logo) and publishes it
     * @param {Branding} branding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveAndPublishBrandingForMerchant(branding: Branding, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveAndPublishBrandingForMerchant(branding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the biller-level branding style (including logo) and publishes it
     * @param {string} billerCode 
     * @param {boolean} [requestBrandingIsDraft] 
     * @param {string} [requestBrandingReturnBarFontColour] 
     * @param {string} [requestBrandingReturnBarBackColour] 
     * @param {string} [requestBrandingReturnBarHeight] 
     * @param {string} [requestBrandingBannerFontColour] 
     * @param {string} [requestBrandingBannerBackColour] 
     * @param {string} [requestBrandingBannerLogoFilename] 
     * @param {string} [requestBrandingBannerLogoUrl] 
     * @param {number} [requestBrandingBannerLogoFileSize] 
     * @param {string} [requestBrandingBannerLogoPosition] 
     * @param {string} [requestBrandingBannerHeight] 
     * @param {boolean} [requestBrandingMenuShowTerms] 
     * @param {boolean} [requestBrandingMenuShowPrivacy] 
     * @param {boolean} [requestBrandingMenuShowExtraInfo] 
     * @param {string} [requestBrandingMenuFontColour] 
     * @param {string} [requestBrandingMenuBackColour] 
     * @param {string} [requestBrandingMenuUnderlineColour] 
     * @param {string} [requestBrandingMenuMobileColour] 
     * @param {boolean} [requestBrandingMenuShowTopBorder] 
     * @param {boolean} [requestBrandingMenuShowBottomBorder] 
     * @param {string} [requestBrandingMenuBorderColour] 
     * @param {string} [requestBrandingPageFontFamily] 
     * @param {string} [requestBrandingPageFontColour] 
     * @param {string} [requestBrandingPageBackColour] 
     * @param {string} [requestBrandingPageSectionColour] 
     * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
     * @param {string} [requestBrandingPagePrimaryButtonColour] 
     * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
     * @param {string} [requestBrandingPageSecondaryButtonColour] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveAndPublishBrandingWithLogo(billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveAndPublishBrandingWithLogo(billerCode, requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the merchant-level branding style (including logo) and publishes it
     * @param {boolean} [requestBrandingIsDraft] 
     * @param {string} [requestBrandingReturnBarFontColour] 
     * @param {string} [requestBrandingReturnBarBackColour] 
     * @param {string} [requestBrandingReturnBarHeight] 
     * @param {string} [requestBrandingBannerFontColour] 
     * @param {string} [requestBrandingBannerBackColour] 
     * @param {string} [requestBrandingBannerLogoFilename] 
     * @param {string} [requestBrandingBannerLogoUrl] 
     * @param {number} [requestBrandingBannerLogoFileSize] 
     * @param {string} [requestBrandingBannerLogoPosition] 
     * @param {string} [requestBrandingBannerHeight] 
     * @param {boolean} [requestBrandingMenuShowTerms] 
     * @param {boolean} [requestBrandingMenuShowPrivacy] 
     * @param {boolean} [requestBrandingMenuShowExtraInfo] 
     * @param {string} [requestBrandingMenuFontColour] 
     * @param {string} [requestBrandingMenuBackColour] 
     * @param {string} [requestBrandingMenuUnderlineColour] 
     * @param {string} [requestBrandingMenuMobileColour] 
     * @param {boolean} [requestBrandingMenuShowTopBorder] 
     * @param {boolean} [requestBrandingMenuShowBottomBorder] 
     * @param {string} [requestBrandingMenuBorderColour] 
     * @param {string} [requestBrandingPageFontFamily] 
     * @param {string} [requestBrandingPageFontColour] 
     * @param {string} [requestBrandingPageBackColour] 
     * @param {string} [requestBrandingPageSectionColour] 
     * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
     * @param {string} [requestBrandingPagePrimaryButtonColour] 
     * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
     * @param {string} [requestBrandingPageSecondaryButtonColour] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveAndPublishBrandingWithLogoForMerchant(requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveAndPublishBrandingWithLogoForMerchant(requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the biller-level branding style (excluding logo) as a draft
     * @param {string} billerCode 
     * @param {Branding} branding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveBranding(billerCode: string, branding: Branding, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveBranding(billerCode, branding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the merchant-level branding style (excluding logo) as a draft
     * @param {Branding} branding 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveBrandingForMerchant(branding: Branding, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveBrandingForMerchant(branding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the biller-level branding style (including logo) as a draft
     * @param {string} billerCode 
     * @param {boolean} [requestBrandingIsDraft] 
     * @param {string} [requestBrandingReturnBarFontColour] 
     * @param {string} [requestBrandingReturnBarBackColour] 
     * @param {string} [requestBrandingReturnBarHeight] 
     * @param {string} [requestBrandingBannerFontColour] 
     * @param {string} [requestBrandingBannerBackColour] 
     * @param {string} [requestBrandingBannerLogoFilename] 
     * @param {string} [requestBrandingBannerLogoUrl] 
     * @param {number} [requestBrandingBannerLogoFileSize] 
     * @param {string} [requestBrandingBannerLogoPosition] 
     * @param {string} [requestBrandingBannerHeight] 
     * @param {boolean} [requestBrandingMenuShowTerms] 
     * @param {boolean} [requestBrandingMenuShowPrivacy] 
     * @param {boolean} [requestBrandingMenuShowExtraInfo] 
     * @param {string} [requestBrandingMenuFontColour] 
     * @param {string} [requestBrandingMenuBackColour] 
     * @param {string} [requestBrandingMenuUnderlineColour] 
     * @param {string} [requestBrandingMenuMobileColour] 
     * @param {boolean} [requestBrandingMenuShowTopBorder] 
     * @param {boolean} [requestBrandingMenuShowBottomBorder] 
     * @param {string} [requestBrandingMenuBorderColour] 
     * @param {string} [requestBrandingPageFontFamily] 
     * @param {string} [requestBrandingPageFontColour] 
     * @param {string} [requestBrandingPageBackColour] 
     * @param {string} [requestBrandingPageSectionColour] 
     * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
     * @param {string} [requestBrandingPagePrimaryButtonColour] 
     * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
     * @param {string} [requestBrandingPageSecondaryButtonColour] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveBrandingWithLogo(billerCode: string, requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveBrandingWithLogo(billerCode, requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the merchant-level branding style (including logo) as a draft
     * @param {boolean} [requestBrandingIsDraft] 
     * @param {string} [requestBrandingReturnBarFontColour] 
     * @param {string} [requestBrandingReturnBarBackColour] 
     * @param {string} [requestBrandingReturnBarHeight] 
     * @param {string} [requestBrandingBannerFontColour] 
     * @param {string} [requestBrandingBannerBackColour] 
     * @param {string} [requestBrandingBannerLogoFilename] 
     * @param {string} [requestBrandingBannerLogoUrl] 
     * @param {number} [requestBrandingBannerLogoFileSize] 
     * @param {string} [requestBrandingBannerLogoPosition] 
     * @param {string} [requestBrandingBannerHeight] 
     * @param {boolean} [requestBrandingMenuShowTerms] 
     * @param {boolean} [requestBrandingMenuShowPrivacy] 
     * @param {boolean} [requestBrandingMenuShowExtraInfo] 
     * @param {string} [requestBrandingMenuFontColour] 
     * @param {string} [requestBrandingMenuBackColour] 
     * @param {string} [requestBrandingMenuUnderlineColour] 
     * @param {string} [requestBrandingMenuMobileColour] 
     * @param {boolean} [requestBrandingMenuShowTopBorder] 
     * @param {boolean} [requestBrandingMenuShowBottomBorder] 
     * @param {string} [requestBrandingMenuBorderColour] 
     * @param {string} [requestBrandingPageFontFamily] 
     * @param {string} [requestBrandingPageFontColour] 
     * @param {string} [requestBrandingPageBackColour] 
     * @param {string} [requestBrandingPageSectionColour] 
     * @param {string} [requestBrandingPagePrimaryButtonFontColour] 
     * @param {string} [requestBrandingPagePrimaryButtonColour] 
     * @param {string} [requestBrandingPageSecondaryButtonFontColour] 
     * @param {string} [requestBrandingPageSecondaryButtonColour] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSaveBrandingWithLogoForMerchant(requestBrandingIsDraft?: boolean, requestBrandingReturnBarFontColour?: string, requestBrandingReturnBarBackColour?: string, requestBrandingReturnBarHeight?: string, requestBrandingBannerFontColour?: string, requestBrandingBannerBackColour?: string, requestBrandingBannerLogoFilename?: string, requestBrandingBannerLogoUrl?: string, requestBrandingBannerLogoFileSize?: number, requestBrandingBannerLogoPosition?: string, requestBrandingBannerHeight?: string, requestBrandingMenuShowTerms?: boolean, requestBrandingMenuShowPrivacy?: boolean, requestBrandingMenuShowExtraInfo?: boolean, requestBrandingMenuFontColour?: string, requestBrandingMenuBackColour?: string, requestBrandingMenuUnderlineColour?: string, requestBrandingMenuMobileColour?: string, requestBrandingMenuShowTopBorder?: boolean, requestBrandingMenuShowBottomBorder?: boolean, requestBrandingMenuBorderColour?: string, requestBrandingPageFontFamily?: string, requestBrandingPageFontColour?: string, requestBrandingPageBackColour?: string, requestBrandingPageSectionColour?: string, requestBrandingPagePrimaryButtonFontColour?: string, requestBrandingPagePrimaryButtonColour?: string, requestBrandingPageSecondaryButtonFontColour?: string, requestBrandingPageSecondaryButtonColour?: string, options?: any) {
        return DesignerApiFp(this.configuration).designerSaveBrandingWithLogoForMerchant(requestBrandingIsDraft, requestBrandingReturnBarFontColour, requestBrandingReturnBarBackColour, requestBrandingReturnBarHeight, requestBrandingBannerFontColour, requestBrandingBannerBackColour, requestBrandingBannerLogoFilename, requestBrandingBannerLogoUrl, requestBrandingBannerLogoFileSize, requestBrandingBannerLogoPosition, requestBrandingBannerHeight, requestBrandingMenuShowTerms, requestBrandingMenuShowPrivacy, requestBrandingMenuShowExtraInfo, requestBrandingMenuFontColour, requestBrandingMenuBackColour, requestBrandingMenuUnderlineColour, requestBrandingMenuMobileColour, requestBrandingMenuShowTopBorder, requestBrandingMenuShowBottomBorder, requestBrandingMenuBorderColour, requestBrandingPageFontFamily, requestBrandingPageFontColour, requestBrandingPageBackColour, requestBrandingPageSectionColour, requestBrandingPagePrimaryButtonFontColour, requestBrandingPagePrimaryButtonColour, requestBrandingPageSecondaryButtonFontColour, requestBrandingPageSecondaryButtonColour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the merchant preference whether to have unique branding style per biller
     * @param {boolean} byBiller 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignerApi
     */
    public designerSetByBiller(byBiller: boolean, options?: any) {
        return DesignerApiFp(this.configuration).designerSetByBiller(byBiller, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeedbackModel} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackAddFeedback: async (feedback: FeedbackModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedback' is not null or undefined
            assertParamExists('feedbackAddFeedback', 'feedback', feedback)
            const localVarPath = `/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedback, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackGetFeedbackAreas: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/feedback/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeedbackModel} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackAddFeedback(feedback: FeedbackModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackAddFeedback(feedback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackGetFeedbackAreas(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListFeedbackAreaModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackGetFeedbackAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedbackModel} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackAddFeedback(feedback: FeedbackModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.feedbackAddFeedback(feedback, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackGetFeedbackAreas(options?: any): AxiosPromise<ApiResponseListFeedbackAreaModel> {
            return localVarFp.feedbackGetFeedbackAreas(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @param {FeedbackModel} feedback 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackAddFeedback(feedback: FeedbackModel, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackAddFeedback(feedback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackGetFeedbackAreas(options?: any) {
        return FeedbackApiFp(this.configuration).feedbackGetFeedbackAreas(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FraudApi - axios parameter creator
 * @export
 */
export const FraudApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves list of billers fraud settings for a merchant without biller\' intCaptcha is 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetBillersFraudBasicCaptchaSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fraud/GetBillersFraudBasicCaptchaSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves list of billers fraud settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetBillersFraudBasicSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fraud/GetBillersFraudBasicSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the filter options for fraud actions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudActionSources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fraud/GetFraudActionSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the fraud exclusion settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudBasicExclusions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fraud/GetFraudBasicExclusions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the fraud settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudBasicSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fraud/GetFraudBasicSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the filter options for fraud types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudTypeSources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fraud/GetFraudTypeSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fraud rule search method. Take FraudRuleSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a FraudRuleModel and paginated
         * @param {PaginateRequestSearchFraudRulesInputFraudRuleSortFields} pagedRequest PaginateRequest of SearchFraudRulesInput and FraudRuleSortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudSearchFraudRules: async (pagedRequest: PaginateRequestSearchFraudRulesInputFraudRuleSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('fraudSearchFraudRules', 'pagedRequest', pagedRequest)
            const localVarPath = `/Fraud/SearchFraudRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the fraud basic config settings for a biller
         * @param {BillerFraudBasicSettingsModel} model Biller Fraud basic settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUpdateBillerFraudBasicSettings: async (model: BillerFraudBasicSettingsModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('fraudUpdateBillerFraudBasicSettings', 'model', model)
            const localVarPath = `/Fraud/UpdateBillerFraudBasicSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the fraud exclusion settings for a merchant
         * @param {FraudBasicExclusionsModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUpdateFraudBasicExclusions: async (request: FraudBasicExclusionsModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('fraudUpdateFraudBasicExclusions', 'request', request)
            const localVarPath = `/Fraud/UpdateFraudBasicExclusions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the fraud basic settings for a merchant
         * @param {FraudBasicSettingsModel} model Fraud basic settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUpdateFraudBasicSettings: async (model: FraudBasicSettingsModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('fraudUpdateFraudBasicSettings', 'model', model)
            const localVarPath = `/Fraud/UpdateFraudBasicSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the anti fraud rules via file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUploadAntiFraudRulesFile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fraud/UploadAntiFraudRulesFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FraudApi - functional programming interface
 * @export
 */
export const FraudApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FraudApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves list of billers fraud settings for a merchant without biller\' intCaptcha is 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudGetBillersFraudBasicCaptchaSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListBillerFraudBasicSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudGetBillersFraudBasicCaptchaSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves list of billers fraud settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudGetBillersFraudBasicSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListBillerFraudBasicSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudGetBillersFraudBasicSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the filter options for fraud actions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudGetFraudActionSources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseKeyDescriptionListAntiFraudAction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudGetFraudActionSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the fraud exclusion settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudGetFraudBasicExclusions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseFraudBasicExclusionsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudGetFraudBasicExclusions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the fraud settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudGetFraudBasicSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseFraudBasicSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudGetFraudBasicSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the filter options for fraud types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudGetFraudTypeSources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseKeyDescriptionListAntiFraudType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudGetFraudTypeSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fraud rule search method. Take FraudRuleSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a FraudRuleModel and paginated
         * @param {PaginateRequestSearchFraudRulesInputFraudRuleSortFields} pagedRequest PaginateRequest of SearchFraudRulesInput and FraudRuleSortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudSearchFraudRules(pagedRequest: PaginateRequestSearchFraudRulesInputFraudRuleSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListFraudRuleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudSearchFraudRules(pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the fraud basic config settings for a biller
         * @param {BillerFraudBasicSettingsModel} model Biller Fraud basic settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudUpdateBillerFraudBasicSettings(model: BillerFraudBasicSettingsModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudUpdateBillerFraudBasicSettings(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the fraud exclusion settings for a merchant
         * @param {FraudBasicExclusionsModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudUpdateFraudBasicExclusions(request: FraudBasicExclusionsModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudUpdateFraudBasicExclusions(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the fraud basic settings for a merchant
         * @param {FraudBasicSettingsModel} model Fraud basic settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudUpdateFraudBasicSettings(model: FraudBasicSettingsModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudUpdateFraudBasicSettings(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the anti fraud rules via file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fraudUploadAntiFraudRulesFile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUploadAntiFraudRulesFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fraudUploadAntiFraudRulesFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FraudApi - factory interface
 * @export
 */
export const FraudApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FraudApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves list of billers fraud settings for a merchant without biller\' intCaptcha is 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetBillersFraudBasicCaptchaSettings(options?: any): AxiosPromise<ApiResponseListBillerFraudBasicSettingsModel> {
            return localVarFp.fraudGetBillersFraudBasicCaptchaSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves list of billers fraud settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetBillersFraudBasicSettings(options?: any): AxiosPromise<ApiResponseListBillerFraudBasicSettingsModel> {
            return localVarFp.fraudGetBillersFraudBasicSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the filter options for fraud actions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudActionSources(options?: any): AxiosPromise<ApiResponseKeyDescriptionListAntiFraudAction> {
            return localVarFp.fraudGetFraudActionSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the fraud exclusion settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudBasicExclusions(options?: any): AxiosPromise<ApiResponseFraudBasicExclusionsModel> {
            return localVarFp.fraudGetFraudBasicExclusions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the fraud settings for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudBasicSettings(options?: any): AxiosPromise<ApiResponseFraudBasicSettingsModel> {
            return localVarFp.fraudGetFraudBasicSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the filter options for fraud types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudGetFraudTypeSources(options?: any): AxiosPromise<ApiResponseKeyDescriptionListAntiFraudType> {
            return localVarFp.fraudGetFraudTypeSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fraud rule search method. Take FraudRuleSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a FraudRuleModel and paginated
         * @param {PaginateRequestSearchFraudRulesInputFraudRuleSortFields} pagedRequest PaginateRequest of SearchFraudRulesInput and FraudRuleSortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudSearchFraudRules(pagedRequest: PaginateRequestSearchFraudRulesInputFraudRuleSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListFraudRuleModel> {
            return localVarFp.fraudSearchFraudRules(pagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the fraud basic config settings for a biller
         * @param {BillerFraudBasicSettingsModel} model Biller Fraud basic settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUpdateBillerFraudBasicSettings(model: BillerFraudBasicSettingsModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.fraudUpdateBillerFraudBasicSettings(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the fraud exclusion settings for a merchant
         * @param {FraudBasicExclusionsModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUpdateFraudBasicExclusions(request: FraudBasicExclusionsModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.fraudUpdateFraudBasicExclusions(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the fraud basic settings for a merchant
         * @param {FraudBasicSettingsModel} model Fraud basic settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUpdateFraudBasicSettings(model: FraudBasicSettingsModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.fraudUpdateFraudBasicSettings(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the anti fraud rules via file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fraudUploadAntiFraudRulesFile(options?: any): AxiosPromise<ApiResponseUploadAntiFraudRulesFileResponse> {
            return localVarFp.fraudUploadAntiFraudRulesFile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FraudApi - object-oriented interface
 * @export
 * @class FraudApi
 * @extends {BaseAPI}
 */
export class FraudApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves list of billers fraud settings for a merchant without biller\' intCaptcha is 2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudGetBillersFraudBasicCaptchaSettings(options?: any) {
        return FraudApiFp(this.configuration).fraudGetBillersFraudBasicCaptchaSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves list of billers fraud settings for a merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudGetBillersFraudBasicSettings(options?: any) {
        return FraudApiFp(this.configuration).fraudGetBillersFraudBasicSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the filter options for fraud actions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudGetFraudActionSources(options?: any) {
        return FraudApiFp(this.configuration).fraudGetFraudActionSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the fraud exclusion settings for a merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudGetFraudBasicExclusions(options?: any) {
        return FraudApiFp(this.configuration).fraudGetFraudBasicExclusions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the fraud settings for a merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudGetFraudBasicSettings(options?: any) {
        return FraudApiFp(this.configuration).fraudGetFraudBasicSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the filter options for fraud types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudGetFraudTypeSources(options?: any) {
        return FraudApiFp(this.configuration).fraudGetFraudTypeSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fraud rule search method. Take FraudRuleSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a FraudRuleModel and paginated
     * @param {PaginateRequestSearchFraudRulesInputFraudRuleSortFields} pagedRequest PaginateRequest of SearchFraudRulesInput and FraudRuleSortFields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudSearchFraudRules(pagedRequest: PaginateRequestSearchFraudRulesInputFraudRuleSortFields, options?: any) {
        return FraudApiFp(this.configuration).fraudSearchFraudRules(pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the fraud basic config settings for a biller
     * @param {BillerFraudBasicSettingsModel} model Biller Fraud basic settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudUpdateBillerFraudBasicSettings(model: BillerFraudBasicSettingsModel, options?: any) {
        return FraudApiFp(this.configuration).fraudUpdateBillerFraudBasicSettings(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the fraud exclusion settings for a merchant
     * @param {FraudBasicExclusionsModel} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudUpdateFraudBasicExclusions(request: FraudBasicExclusionsModel, options?: any) {
        return FraudApiFp(this.configuration).fraudUpdateFraudBasicExclusions(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the fraud basic settings for a merchant
     * @param {FraudBasicSettingsModel} model Fraud basic settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudUpdateFraudBasicSettings(model: FraudBasicSettingsModel, options?: any) {
        return FraudApiFp(this.configuration).fraudUpdateFraudBasicSettings(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the anti fraud rules via file upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudApi
     */
    public fraudUploadAntiFraudRulesFile(options?: any) {
        return FraudApiFp(this.configuration).fraudUploadAntiFraudRulesFile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternetApi - axios parameter creator
 * @export
 */
export const InternetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internetKeepAlive: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Internet/KeepAlive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternetApi - functional programming interface
 * @export
 */
export const InternetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internetKeepAlive(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internetKeepAlive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternetApi - factory interface
 * @export
 */
export const InternetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternetApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internetKeepAlive(options?: any): AxiosPromise<void> {
            return localVarFp.internetKeepAlive(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternetApi - object-oriented interface
 * @export
 * @class InternetApi
 * @extends {BaseAPI}
 */
export class InternetApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternetApi
     */
    public internetKeepAlive(options?: any) {
        return InternetApiFp(this.configuration).internetKeepAlive(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Invoice total calculation
         * @param {InvoiceModel} invoice Invoice being calculated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCalculateInvoiceTotals: async (invoice: InvoiceModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoice' is not null or undefined
            assertParamExists('invoicesCalculateInvoiceTotals', 'invoice', invoice)
            const localVarPath = `/Invoices/CalculateInvoiceTotals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel an invoice
         * @param {CancelInvoiceRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCancelInvoice: async (request: CancelInvoiceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('invoicesCancelInvoice', 'request', request)
            const localVarPath = `/Invoices/CancelInvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creation of an invoice
         * @param {InvoiceModel} invoice New invoice being generated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCreateInvoice: async (invoice: InvoiceModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoice' is not null or undefined
            assertParamExists('invoicesCreateInvoice', 'invoice', invoice)
            const localVarPath = `/Invoices/CreateInvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns an invoice
         * @param {GetInvoiceRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoice: async (request: GetInvoiceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('invoicesGetInvoice', 'request', request)
            const localVarPath = `/Invoices/GetInvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets invoice billers for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoiceBillers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Invoices/GetInvoiceBillers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the invoice configuration for a merchant - Currently assumes the earlier Biller with type INVOICE is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoiceConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Invoices/GetInvoiceConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the payments made against an invoice
         * @param {GetInvoicePaymentsRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoicePayments: async (request: GetInvoicePaymentsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('invoicesGetInvoicePayments', 'request', request)
            const localVarPath = `/Invoices/GetInvoicePayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the filter options for invoice statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoiceStatusSources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Invoices/GetInvoiceStatusSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend an invoice to a customer or other recipient
         * @param {ResendInvoiceRequest} request Invoice id and recipient email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesResendEmail: async (request: ResendInvoiceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('invoicesResendEmail', 'request', request)
            const localVarPath = `/Invoices/ResendEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invoice search method. Take InvoiceSearchInputModel object and retrieve at most 10000 records which match filter conditions.  Results stripped back to a InvoiceSearchResponseModel and paginated
         * @param {PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields} pagedRequest PaginateRequest of InvoiceSearchInputModel and InvoiceSearchSortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesSearchInvoices: async (pagedRequest: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('invoicesSearchInvoices', 'pagedRequest', pagedRequest)
            const localVarPath = `/Invoices/SearchInvoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the invoice settings - Invoice Item Settings
         * @param {InvoiceConfigModel} model Invoice Item Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpdateInvoiceConfig: async (model: InvoiceConfigModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('invoicesUpdateInvoiceConfig', 'model', model)
            const localVarPath = `/Invoices/UpdateInvoiceConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Invoice total calculation
         * @param {InvoiceModel} invoice Invoice being calculated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesCalculateInvoiceTotals(invoice: InvoiceModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseInvoiceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesCalculateInvoiceTotals(invoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel an invoice
         * @param {CancelInvoiceRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesCancelInvoice(request: CancelInvoiceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesCancelInvoice(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creation of an invoice
         * @param {InvoiceModel} invoice New invoice being generated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesCreateInvoice(invoice: InvoiceModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesCreateInvoice(invoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns an invoice
         * @param {GetInvoiceRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesGetInvoice(request: GetInvoiceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseInvoiceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesGetInvoice(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets invoice billers for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesGetInvoiceBillers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIEnumerableBiller>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesGetInvoiceBillers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the invoice configuration for a merchant - Currently assumes the earlier Biller with type INVOICE is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesGetInvoiceConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseInvoiceConfigModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesGetInvoiceConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the payments made against an invoice
         * @param {GetInvoicePaymentsRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesGetInvoicePayments(request: GetInvoicePaymentsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListInvoicePaymentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesGetInvoicePayments(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the filter options for invoice statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesGetInvoiceStatusSources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseKeyDescriptionListInvoiceStatusFilter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesGetInvoiceStatusSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend an invoice to a customer or other recipient
         * @param {ResendInvoiceRequest} request Invoice id and recipient email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesResendEmail(request: ResendInvoiceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesResendEmail(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invoice search method. Take InvoiceSearchInputModel object and retrieve at most 10000 records which match filter conditions.  Results stripped back to a InvoiceSearchResponseModel and paginated
         * @param {PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields} pagedRequest PaginateRequest of InvoiceSearchInputModel and InvoiceSearchSortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesSearchInvoices(pagedRequest: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListInvoiceSearchResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesSearchInvoices(pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the invoice settings - Invoice Item Settings
         * @param {InvoiceConfigModel} model Invoice Item Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesUpdateInvoiceConfig(model: InvoiceConfigModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesUpdateInvoiceConfig(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Invoice total calculation
         * @param {InvoiceModel} invoice Invoice being calculated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCalculateInvoiceTotals(invoice: InvoiceModel, options?: any): AxiosPromise<ApiResponseInvoiceModel> {
            return localVarFp.invoicesCalculateInvoiceTotals(invoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel an invoice
         * @param {CancelInvoiceRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCancelInvoice(request: CancelInvoiceRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.invoicesCancelInvoice(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creation of an invoice
         * @param {InvoiceModel} invoice New invoice being generated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesCreateInvoice(invoice: InvoiceModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.invoicesCreateInvoice(invoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns an invoice
         * @param {GetInvoiceRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoice(request: GetInvoiceRequest, options?: any): AxiosPromise<ApiResponseInvoiceModel> {
            return localVarFp.invoicesGetInvoice(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets invoice billers for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoiceBillers(options?: any): AxiosPromise<ApiResponseIEnumerableBiller> {
            return localVarFp.invoicesGetInvoiceBillers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the invoice configuration for a merchant - Currently assumes the earlier Biller with type INVOICE is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoiceConfig(options?: any): AxiosPromise<ApiResponseInvoiceConfigModel> {
            return localVarFp.invoicesGetInvoiceConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the payments made against an invoice
         * @param {GetInvoicePaymentsRequest} request An invoice id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoicePayments(request: GetInvoicePaymentsRequest, options?: any): AxiosPromise<ApiResponseListInvoicePaymentModel> {
            return localVarFp.invoicesGetInvoicePayments(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the filter options for invoice statuses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGetInvoiceStatusSources(options?: any): AxiosPromise<ApiResponseKeyDescriptionListInvoiceStatusFilter> {
            return localVarFp.invoicesGetInvoiceStatusSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend an invoice to a customer or other recipient
         * @param {ResendInvoiceRequest} request Invoice id and recipient email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesResendEmail(request: ResendInvoiceRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.invoicesResendEmail(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invoice search method. Take InvoiceSearchInputModel object and retrieve at most 10000 records which match filter conditions.  Results stripped back to a InvoiceSearchResponseModel and paginated
         * @param {PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields} pagedRequest PaginateRequest of InvoiceSearchInputModel and InvoiceSearchSortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesSearchInvoices(pagedRequest: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListInvoiceSearchResponseModel> {
            return localVarFp.invoicesSearchInvoices(pagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the invoice settings - Invoice Item Settings
         * @param {InvoiceConfigModel} model Invoice Item Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesUpdateInvoiceConfig(model: InvoiceConfigModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.invoicesUpdateInvoiceConfig(model, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Invoice total calculation
     * @param {InvoiceModel} invoice Invoice being calculated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesCalculateInvoiceTotals(invoice: InvoiceModel, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesCalculateInvoiceTotals(invoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel an invoice
     * @param {CancelInvoiceRequest} request An invoice id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesCancelInvoice(request: CancelInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesCancelInvoice(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creation of an invoice
     * @param {InvoiceModel} invoice New invoice being generated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesCreateInvoice(invoice: InvoiceModel, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesCreateInvoice(invoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns an invoice
     * @param {GetInvoiceRequest} request An invoice id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesGetInvoice(request: GetInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesGetInvoice(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets invoice billers for a merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesGetInvoiceBillers(options?: any) {
        return InvoicesApiFp(this.configuration).invoicesGetInvoiceBillers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the invoice configuration for a merchant - Currently assumes the earlier Biller with type INVOICE is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesGetInvoiceConfig(options?: any) {
        return InvoicesApiFp(this.configuration).invoicesGetInvoiceConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the payments made against an invoice
     * @param {GetInvoicePaymentsRequest} request An invoice id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesGetInvoicePayments(request: GetInvoicePaymentsRequest, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesGetInvoicePayments(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the filter options for invoice statuses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesGetInvoiceStatusSources(options?: any) {
        return InvoicesApiFp(this.configuration).invoicesGetInvoiceStatusSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend an invoice to a customer or other recipient
     * @param {ResendInvoiceRequest} request Invoice id and recipient email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesResendEmail(request: ResendInvoiceRequest, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesResendEmail(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invoice search method. Take InvoiceSearchInputModel object and retrieve at most 10000 records which match filter conditions.  Results stripped back to a InvoiceSearchResponseModel and paginated
     * @param {PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields} pagedRequest PaginateRequest of InvoiceSearchInputModel and InvoiceSearchSortFields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesSearchInvoices(pagedRequest: PaginateRequestInvoiceSearchInputModelInvoiceSearchSortFields, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesSearchInvoices(pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the invoice settings - Invoice Item Settings
     * @param {InvoiceConfigModel} model Invoice Item Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesUpdateInvoiceConfig(model: InvoiceConfigModel, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesUpdateInvoiceConfig(model, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LCRConfigurationApi - axios parameter creator
 * @export
 */
export const LCRConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lCRConfigurationGetLCRConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LCRConfiguration/GetLCRConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LCRConfigurationModel} lcrConfigurationModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lCRConfigurationUpdateLCRConfiguration: async (lcrConfigurationModel: LCRConfigurationModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lcrConfigurationModel' is not null or undefined
            assertParamExists('lCRConfigurationUpdateLCRConfiguration', 'lcrConfigurationModel', lcrConfigurationModel)
            const localVarPath = `/LCRConfiguration/UpdateLCRConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lcrConfigurationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LCRConfigurationApi - functional programming interface
 * @export
 */
export const LCRConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LCRConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lCRConfigurationGetLCRConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseLCRConfigurationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lCRConfigurationGetLCRConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LCRConfigurationModel} lcrConfigurationModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lCRConfigurationUpdateLCRConfiguration(lcrConfigurationModel: LCRConfigurationModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lCRConfigurationUpdateLCRConfiguration(lcrConfigurationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LCRConfigurationApi - factory interface
 * @export
 */
export const LCRConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LCRConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lCRConfigurationGetLCRConfiguration(options?: any): AxiosPromise<ApiResponseLCRConfigurationModel> {
            return localVarFp.lCRConfigurationGetLCRConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LCRConfigurationModel} lcrConfigurationModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lCRConfigurationUpdateLCRConfiguration(lcrConfigurationModel: LCRConfigurationModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.lCRConfigurationUpdateLCRConfiguration(lcrConfigurationModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LCRConfigurationApi - object-oriented interface
 * @export
 * @class LCRConfigurationApi
 * @extends {BaseAPI}
 */
export class LCRConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LCRConfigurationApi
     */
    public lCRConfigurationGetLCRConfiguration(options?: any) {
        return LCRConfigurationApiFp(this.configuration).lCRConfigurationGetLCRConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LCRConfigurationModel} lcrConfigurationModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LCRConfigurationApi
     */
    public lCRConfigurationUpdateLCRConfiguration(lcrConfigurationModel: LCRConfigurationModel, options?: any) {
        return LCRConfigurationApiFp(this.configuration).lCRConfigurationUpdateLCRConfiguration(lcrConfigurationModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MerchantFacilityConfigApi - axios parameter creator
 * @export
 */
export const MerchantFacilityConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantFacilityConfigGetKlarnaDetails: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant/facility/klarna`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Credentials} credentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantFacilityConfigSetupKlarnaFacility: async (credentials: Credentials, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentials' is not null or undefined
            assertParamExists('merchantFacilityConfigSetupKlarnaFacility', 'credentials', credentials)
            const localVarPath = `/merchant/facility/klarna`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantFacilityConfigApi - functional programming interface
 * @export
 */
export const MerchantFacilityConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MerchantFacilityConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantFacilityConfigGetKlarnaDetails(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantFacilityConfigGetKlarnaDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Credentials} credentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merchantFacilityConfigSetupKlarnaFacility(credentials: Credentials, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merchantFacilityConfigSetupKlarnaFacility(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MerchantFacilityConfigApi - factory interface
 * @export
 */
export const MerchantFacilityConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MerchantFacilityConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantFacilityConfigGetKlarnaDetails(options?: any): AxiosPromise<ApiResponseCredentials> {
            return localVarFp.merchantFacilityConfigGetKlarnaDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Credentials} credentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merchantFacilityConfigSetupKlarnaFacility(credentials: Credentials, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.merchantFacilityConfigSetupKlarnaFacility(credentials, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantFacilityConfigApi - object-oriented interface
 * @export
 * @class MerchantFacilityConfigApi
 * @extends {BaseAPI}
 */
export class MerchantFacilityConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantFacilityConfigApi
     */
    public merchantFacilityConfigGetKlarnaDetails(options?: any) {
        return MerchantFacilityConfigApiFp(this.configuration).merchantFacilityConfigGetKlarnaDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Credentials} credentials 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantFacilityConfigApi
     */
    public merchantFacilityConfigSetupKlarnaFacility(credentials: Credentials, options?: any) {
        return MerchantFacilityConfigApiFp(this.configuration).merchantFacilityConfigSetupKlarnaFacility(credentials, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessagingApi - axios parameter creator
 * @export
 */
export const MessagingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingGetMessageConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/messaging-settings-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingGetMessagingSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/messaging-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageEmailConfigurationModel} model 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingUpdateMessageConfiguration: async (model: MessageEmailConfigurationModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('messagingUpdateMessageConfiguration', 'model', model)
            const localVarPath = `/messaging-settings-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessagingSettingsModel} model 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingUpdateMessagingSettings: async (model: MessagingSettingsModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('messagingUpdateMessagingSettings', 'model', model)
            const localVarPath = `/messaging-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagingApi - functional programming interface
 * @export
 */
export const MessagingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagingGetMessageConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseMessageEmailConfigurationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagingGetMessageConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagingGetMessagingSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseMessagingSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagingGetMessagingSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MessageEmailConfigurationModel} model 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagingUpdateMessageConfiguration(model: MessageEmailConfigurationModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagingUpdateMessageConfiguration(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MessagingSettingsModel} model 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagingUpdateMessagingSettings(model: MessagingSettingsModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagingUpdateMessagingSettings(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagingApi - factory interface
 * @export
 */
export const MessagingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingGetMessageConfiguration(options?: any): AxiosPromise<ApiResponseMessageEmailConfigurationModel> {
            return localVarFp.messagingGetMessageConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingGetMessagingSettings(options?: any): AxiosPromise<ApiResponseMessagingSettingsModel> {
            return localVarFp.messagingGetMessagingSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessageEmailConfigurationModel} model 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingUpdateMessageConfiguration(model: MessageEmailConfigurationModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.messagingUpdateMessageConfiguration(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessagingSettingsModel} model 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagingUpdateMessagingSettings(model: MessagingSettingsModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.messagingUpdateMessagingSettings(model, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagingApi - object-oriented interface
 * @export
 * @class MessagingApi
 * @extends {BaseAPI}
 */
export class MessagingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public messagingGetMessageConfiguration(options?: any) {
        return MessagingApiFp(this.configuration).messagingGetMessageConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public messagingGetMessagingSettings(options?: any) {
        return MessagingApiFp(this.configuration).messagingGetMessagingSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageEmailConfigurationModel} model 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public messagingUpdateMessageConfiguration(model: MessageEmailConfigurationModel, options?: any) {
        return MessagingApiFp(this.configuration).messagingUpdateMessageConfiguration(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessagingSettingsModel} model 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public messagingUpdateMessagingSettings(model: MessagingSettingsModel, options?: any) {
        return MessagingApiFp(this.configuration).messagingUpdateMessagingSettings(model, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoticesApi - axios parameter creator
 * @export
 */
export const NoticesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noticesGetLiveNotices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Notices/GetLiveNotices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticesApi - functional programming interface
 * @export
 */
export const NoticesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noticesGetLiveNotices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListNoticeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noticesGetLiveNotices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoticesApi - factory interface
 * @export
 */
export const NoticesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noticesGetLiveNotices(options?: any): AxiosPromise<ApiResponseListNoticeModel> {
            return localVarFp.noticesGetLiveNotices(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticesApi - object-oriented interface
 * @export
 * @class NoticesApi
 * @extends {BaseAPI}
 */
export class NoticesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesApi
     */
    public noticesGetLiveNotices(options?: any) {
        return NoticesApiFp(this.configuration).noticesGetLiveNotices(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentMethodsApi - axios parameter creator
 * @export
 */
export const PaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsGetEnabledWallets: async (merchantNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('paymentMethodsGetEnabledWallets', 'merchantNumber', merchantNumber)
            const localVarPath = `/merchants/{merchantNumber}/enabled-wallets`
                .replace(`{${"merchantNumber"}}`, encodeURIComponent(String(merchantNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsGetPaymentMethods: async (merchantNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('paymentMethodsGetPaymentMethods', 'merchantNumber', merchantNumber)
            const localVarPath = `/merchants/{merchantNumber}/payment-methods`
                .replace(`{${"merchantNumber"}}`, encodeURIComponent(String(merchantNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentMethodsApi - functional programming interface
 * @export
 */
export const PaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentMethodsGetEnabledWallets(merchantNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIEnumerableWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodsGetEnabledWallets(merchantNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentMethodsGetPaymentMethods(merchantNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIEnumerablePaymentMethod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodsGetPaymentMethods(merchantNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentMethodsApi - factory interface
 * @export
 */
export const PaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentMethodsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsGetEnabledWallets(merchantNumber: string, options?: any): AxiosPromise<ApiResponseIEnumerableWallet> {
            return localVarFp.paymentMethodsGetEnabledWallets(merchantNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentMethodsGetPaymentMethods(merchantNumber: string, options?: any): AxiosPromise<ApiResponseIEnumerablePaymentMethod> {
            return localVarFp.paymentMethodsGetPaymentMethods(merchantNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentMethodsApi - object-oriented interface
 * @export
 * @class PaymentMethodsApi
 * @extends {BaseAPI}
 */
export class PaymentMethodsApi extends BaseAPI {
    /**
     * 
     * @param {string} merchantNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public paymentMethodsGetEnabledWallets(merchantNumber: string, options?: any) {
        return PaymentMethodsApiFp(this.configuration).paymentMethodsGetEnabledWallets(merchantNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} merchantNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public paymentMethodsGetPaymentMethods(merchantNumber: string, options?: any) {
        return PaymentMethodsApiFp(this.configuration).paymentMethodsGetPaymentMethods(merchantNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentPlanApi - axios parameter creator
 * @export
 */
export const PaymentPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BreakdownRequest} breakdownRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCalculatePaymentPlan: async (breakdownRequest: BreakdownRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'breakdownRequest' is not null or undefined
            assertParamExists('paymentPlanCalculatePaymentPlan', 'breakdownRequest', breakdownRequest)
            const localVarPath = `/paymentplan/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(breakdownRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SampleBreakdownRequest} sampleBreakdownRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCalculateSamplePaymentPlan: async (sampleBreakdownRequest: SampleBreakdownRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleBreakdownRequest' is not null or undefined
            assertParamExists('paymentPlanCalculateSamplePaymentPlan', 'sampleBreakdownRequest', sampleBreakdownRequest)
            const localVarPath = `/paymentplan/calculateSample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sampleBreakdownRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentPlanDetailsRequest} cancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCancelPaymentPlan: async (cancelRequest: PaymentPlanDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelRequest' is not null or undefined
            assertParamExists('paymentPlanCancelPaymentPlan', 'cancelRequest', cancelRequest)
            const localVarPath = `/paymentplan/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCheckIfPaymentPlanTermsAndConditionsExists: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('paymentPlanCheckIfPaymentPlanTermsAndConditionsExists', 'billerCode', billerCode)
            const localVarPath = `/paymentplan/tccheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (billerCode !== undefined) {
                localVarQueryParameter['billerCode'] = billerCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentPlanRequest} paymentPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCreatePaymentPlan: async (paymentPlanRequest: PaymentPlanRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentPlanRequest' is not null or undefined
            assertParamExists('paymentPlanCreatePaymentPlan', 'paymentPlanRequest', paymentPlanRequest)
            const localVarPath = `/paymentplan/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentPlanCustomerSummaryRequest} paymentPlanCustomerSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetCustomerSummary: async (paymentPlanCustomerSummaryRequest: PaymentPlanCustomerSummaryRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentPlanCustomerSummaryRequest' is not null or undefined
            assertParamExists('paymentPlanGetCustomerSummary', 'paymentPlanCustomerSummaryRequest', paymentPlanCustomerSummaryRequest)
            const localVarPath = `/paymentplan/customersummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPlanCustomerSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetPaymentPlanCommitment: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('paymentPlanGetPaymentPlanCommitment', 'billerCode', billerCode)
            const localVarPath = `/paymentplan/commitment-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (billerCode !== undefined) {
                localVarQueryParameter['billerCode'] = billerCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentPlanDetailsRequest} paymentPlanDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetPaymentPlanDetails: async (paymentPlanDetailsRequest: PaymentPlanDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentPlanDetailsRequest' is not null or undefined
            assertParamExists('paymentPlanGetPaymentPlanDetails', 'paymentPlanDetailsRequest', paymentPlanDetailsRequest)
            const localVarPath = `/paymentplan/payment-plan-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentPlanDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetPaymentPlanView: async (pagedRequest: PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('paymentPlanGetPaymentPlanView', 'pagedRequest', pagedRequest)
            const localVarPath = `/paymentplan/view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentPlanApi - functional programming interface
 * @export
 */
export const PaymentPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BreakdownRequest} breakdownRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanCalculatePaymentPlan(breakdownRequest: BreakdownRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentBreakdown>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanCalculatePaymentPlan(breakdownRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SampleBreakdownRequest} sampleBreakdownRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanCalculateSamplePaymentPlan(sampleBreakdownRequest: SampleBreakdownRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentBreakdown>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanCalculateSamplePaymentPlan(sampleBreakdownRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaymentPlanDetailsRequest} cancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanCancelPaymentPlan(cancelRequest: PaymentPlanDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseNullableInt32>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanCancelPaymentPlan(cancelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanCheckIfPaymentPlanTermsAndConditionsExists(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanCheckIfPaymentPlanTermsAndConditionsExists(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaymentPlanRequest} paymentPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanCreatePaymentPlan(paymentPlanRequest: PaymentPlanRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentPlanModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanCreatePaymentPlan(paymentPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaymentPlanCustomerSummaryRequest} paymentPlanCustomerSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanGetCustomerSummary(paymentPlanCustomerSummaryRequest: PaymentPlanCustomerSummaryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentPlanCustomerSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanGetCustomerSummary(paymentPlanCustomerSummaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanGetPaymentPlanCommitment(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListPaymentPlanCommitmentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanGetPaymentPlanCommitment(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaymentPlanDetailsRequest} paymentPlanDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanGetPaymentPlanDetails(paymentPlanDetailsRequest: PaymentPlanDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentPlanDetailsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanGetPaymentPlanDetails(paymentPlanDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanGetPaymentPlanView(pagedRequest: PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListPaymentPlanViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanGetPaymentPlanView(pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentPlanApi - factory interface
 * @export
 */
export const PaymentPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentPlanApiFp(configuration)
    return {
        /**
         * 
         * @param {BreakdownRequest} breakdownRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCalculatePaymentPlan(breakdownRequest: BreakdownRequest, options?: any): AxiosPromise<ApiResponsePaymentBreakdown> {
            return localVarFp.paymentPlanCalculatePaymentPlan(breakdownRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SampleBreakdownRequest} sampleBreakdownRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCalculateSamplePaymentPlan(sampleBreakdownRequest: SampleBreakdownRequest, options?: any): AxiosPromise<ApiResponsePaymentBreakdown> {
            return localVarFp.paymentPlanCalculateSamplePaymentPlan(sampleBreakdownRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentPlanDetailsRequest} cancelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCancelPaymentPlan(cancelRequest: PaymentPlanDetailsRequest, options?: any): AxiosPromise<ApiResponseNullableInt32> {
            return localVarFp.paymentPlanCancelPaymentPlan(cancelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCheckIfPaymentPlanTermsAndConditionsExists(billerCode: string, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.paymentPlanCheckIfPaymentPlanTermsAndConditionsExists(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentPlanRequest} paymentPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanCreatePaymentPlan(paymentPlanRequest: PaymentPlanRequest, options?: any): AxiosPromise<ApiResponsePaymentPlanModel> {
            return localVarFp.paymentPlanCreatePaymentPlan(paymentPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentPlanCustomerSummaryRequest} paymentPlanCustomerSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetCustomerSummary(paymentPlanCustomerSummaryRequest: PaymentPlanCustomerSummaryRequest, options?: any): AxiosPromise<ApiResponsePaymentPlanCustomerSummary> {
            return localVarFp.paymentPlanGetCustomerSummary(paymentPlanCustomerSummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetPaymentPlanCommitment(billerCode: string, options?: any): AxiosPromise<ApiResponseListPaymentPlanCommitmentModel> {
            return localVarFp.paymentPlanGetPaymentPlanCommitment(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentPlanDetailsRequest} paymentPlanDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetPaymentPlanDetails(paymentPlanDetailsRequest: PaymentPlanDetailsRequest, options?: any): AxiosPromise<ApiResponsePaymentPlanDetailsModel> {
            return localVarFp.paymentPlanGetPaymentPlanDetails(paymentPlanDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields} pagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanGetPaymentPlanView(pagedRequest: PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields, options?: any): AxiosPromise<ApiResponsePaginatedListPaymentPlanViewModel> {
            return localVarFp.paymentPlanGetPaymentPlanView(pagedRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentPlanApi - object-oriented interface
 * @export
 * @class PaymentPlanApi
 * @extends {BaseAPI}
 */
export class PaymentPlanApi extends BaseAPI {
    /**
     * 
     * @param {BreakdownRequest} breakdownRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanCalculatePaymentPlan(breakdownRequest: BreakdownRequest, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanCalculatePaymentPlan(breakdownRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SampleBreakdownRequest} sampleBreakdownRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanCalculateSamplePaymentPlan(sampleBreakdownRequest: SampleBreakdownRequest, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanCalculateSamplePaymentPlan(sampleBreakdownRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentPlanDetailsRequest} cancelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanCancelPaymentPlan(cancelRequest: PaymentPlanDetailsRequest, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanCancelPaymentPlan(cancelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanCheckIfPaymentPlanTermsAndConditionsExists(billerCode: string, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanCheckIfPaymentPlanTermsAndConditionsExists(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentPlanRequest} paymentPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanCreatePaymentPlan(paymentPlanRequest: PaymentPlanRequest, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanCreatePaymentPlan(paymentPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentPlanCustomerSummaryRequest} paymentPlanCustomerSummaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanGetCustomerSummary(paymentPlanCustomerSummaryRequest: PaymentPlanCustomerSummaryRequest, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanGetCustomerSummary(paymentPlanCustomerSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanGetPaymentPlanCommitment(billerCode: string, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanGetPaymentPlanCommitment(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentPlanDetailsRequest} paymentPlanDetailsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanGetPaymentPlanDetails(paymentPlanDetailsRequest: PaymentPlanDetailsRequest, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanGetPaymentPlanDetails(paymentPlanDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields} pagedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanApi
     */
    public paymentPlanGetPaymentPlanView(pagedRequest: PaginateRequestPaymentPlanViewFilterPaymentPlanViewFilterSortedFields, options?: any) {
        return PaymentPlanApiFp(this.configuration).paymentPlanGetPaymentPlanView(pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentPlanOptionApi - axios parameter creator
 * @export
 */
export const PaymentPlanOptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add payment plan option
         * @param {PaymentPlanOptionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionAddPaymentPlanOption: async (req: PaymentPlanOptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('paymentPlanOptionAddPaymentPlanOption', 'req', req)
            const localVarPath = `/PaymentPlanOption/AddPaymentPlanOption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a payment plan option by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionDeletePaymentPlanOption: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentPlanOptionDeletePaymentPlanOption', 'id', id)
            const localVarPath = `/PaymentPlanOption/DeletePaymentPlanOption/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete payment plan options for a given biller id
         * @param {number} billerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionDeletePaymentPlanOptionsByBillerId: async (billerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerId' is not null or undefined
            assertParamExists('paymentPlanOptionDeletePaymentPlanOptionsByBillerId', 'billerId', billerId)
            const localVarPath = `/PaymentPlanOption/DeletePaymentPlanOptionsByBillerId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (billerId !== undefined) {
                localVarQueryParameter['BillerId'] = billerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the payment plan option by a given id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionGetPaymentPlanOption: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentPlanOptionGetPaymentPlanOption', 'id', id)
            const localVarPath = `/PaymentPlanOption/GetPaymentPlanOption/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the payment plan options by a given biller code
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionGetPaymentPlanOptions: async (billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('paymentPlanOptionGetPaymentPlanOptions', 'billerCode', billerCode)
            const localVarPath = `/PaymentPlanOption/GetPaymentPlanOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (billerCode !== undefined) {
                localVarQueryParameter['billerCode'] = billerCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a given payment plan option
         * @param {PaymentPlanOptionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionUpdatePaymentPlanOption: async (req: PaymentPlanOptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('paymentPlanOptionUpdatePaymentPlanOption', 'req', req)
            const localVarPath = `/PaymentPlanOption/UpdatePaymentPlanOption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentPlanOptionApi - functional programming interface
 * @export
 */
export const PaymentPlanOptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentPlanOptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add payment plan option
         * @param {PaymentPlanOptionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanOptionAddPaymentPlanOption(req: PaymentPlanOptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanOptionAddPaymentPlanOption(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a payment plan option by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanOptionDeletePaymentPlanOption(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanOptionDeletePaymentPlanOption(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete payment plan options for a given biller id
         * @param {number} billerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanOptionDeletePaymentPlanOptionsByBillerId(billerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanOptionDeletePaymentPlanOptionsByBillerId(billerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the payment plan option by a given id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanOptionGetPaymentPlanOption(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentPlanOptionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanOptionGetPaymentPlanOption(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the payment plan options by a given biller code
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanOptionGetPaymentPlanOptions(billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListPaymentPlanOptionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanOptionGetPaymentPlanOptions(billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a given payment plan option
         * @param {PaymentPlanOptionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlanOptionUpdatePaymentPlanOption(req: PaymentPlanOptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlanOptionUpdatePaymentPlanOption(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentPlanOptionApi - factory interface
 * @export
 */
export const PaymentPlanOptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentPlanOptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Add payment plan option
         * @param {PaymentPlanOptionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionAddPaymentPlanOption(req: PaymentPlanOptionRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.paymentPlanOptionAddPaymentPlanOption(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a payment plan option by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionDeletePaymentPlanOption(id: number, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.paymentPlanOptionDeletePaymentPlanOption(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete payment plan options for a given biller id
         * @param {number} billerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionDeletePaymentPlanOptionsByBillerId(billerId: number, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.paymentPlanOptionDeletePaymentPlanOptionsByBillerId(billerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the payment plan option by a given id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionGetPaymentPlanOption(id: number, options?: any): AxiosPromise<ApiResponsePaymentPlanOptionModel> {
            return localVarFp.paymentPlanOptionGetPaymentPlanOption(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the payment plan options by a given biller code
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionGetPaymentPlanOptions(billerCode: string, options?: any): AxiosPromise<ApiResponseListPaymentPlanOptionModel> {
            return localVarFp.paymentPlanOptionGetPaymentPlanOptions(billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a given payment plan option
         * @param {PaymentPlanOptionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlanOptionUpdatePaymentPlanOption(req: PaymentPlanOptionRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.paymentPlanOptionUpdatePaymentPlanOption(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentPlanOptionApi - object-oriented interface
 * @export
 * @class PaymentPlanOptionApi
 * @extends {BaseAPI}
 */
export class PaymentPlanOptionApi extends BaseAPI {
    /**
     * 
     * @summary Add payment plan option
     * @param {PaymentPlanOptionRequest} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanOptionApi
     */
    public paymentPlanOptionAddPaymentPlanOption(req: PaymentPlanOptionRequest, options?: any) {
        return PaymentPlanOptionApiFp(this.configuration).paymentPlanOptionAddPaymentPlanOption(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a payment plan option by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanOptionApi
     */
    public paymentPlanOptionDeletePaymentPlanOption(id: number, options?: any) {
        return PaymentPlanOptionApiFp(this.configuration).paymentPlanOptionDeletePaymentPlanOption(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete payment plan options for a given biller id
     * @param {number} billerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanOptionApi
     */
    public paymentPlanOptionDeletePaymentPlanOptionsByBillerId(billerId: number, options?: any) {
        return PaymentPlanOptionApiFp(this.configuration).paymentPlanOptionDeletePaymentPlanOptionsByBillerId(billerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the payment plan option by a given id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanOptionApi
     */
    public paymentPlanOptionGetPaymentPlanOption(id: number, options?: any) {
        return PaymentPlanOptionApiFp(this.configuration).paymentPlanOptionGetPaymentPlanOption(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the payment plan options by a given biller code
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanOptionApi
     */
    public paymentPlanOptionGetPaymentPlanOptions(billerCode: string, options?: any) {
        return PaymentPlanOptionApiFp(this.configuration).paymentPlanOptionGetPaymentPlanOptions(billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a given payment plan option
     * @param {PaymentPlanOptionRequest} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanOptionApi
     */
    public paymentPlanOptionUpdatePaymentPlanOption(req: PaymentPlanOptionRequest, options?: any) {
        return PaymentPlanOptionApiFp(this.configuration).paymentPlanOptionUpdatePaymentPlanOption(req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceiptDetailsApi - axios parameter creator
 * @export
 */
export const ReceiptDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a merchants receipt details logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsDeleteReceiptDetailsLogo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ReceiptDetails/DeleteReceiptDetailsLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the receipt details for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsGetReceiptDetails: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ReceiptDetails/GetReceiptDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a merchants receipt details
         * @param {ReceiptDetailsModel} model Receipt details model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsUpdateReceiptDetails: async (model: ReceiptDetailsModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('receiptDetailsUpdateReceiptDetails', 'model', model)
            const localVarPath = `/ReceiptDetails/UpdateReceiptDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a merchants receipt details with logo
         * @param {string} [modelReceiptDetailsModelTradingName] 
         * @param {string} [modelReceiptDetailsModelAbn] 
         * @param {string} [modelReceiptDetailsModelAcn] 
         * @param {number} [modelReceiptDetailsModelAddressTypeId] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine1] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine2] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine3] 
         * @param {string} [modelReceiptDetailsModelAddressSuburb] 
         * @param {number} [modelReceiptDetailsModelAddressStateId] 
         * @param {string} [modelReceiptDetailsModelAddressState] 
         * @param {string} [modelReceiptDetailsModelAddressPostcode] 
         * @param {number} [modelReceiptDetailsModelAddressCountryCode] 
         * @param {string} [modelReceiptDetailsModelPhoneNumber] 
         * @param {string} [modelReceiptDetailsModelFaxNumber] 
         * @param {string} [modelReceiptDetailsModelWebsiteUrl] 
         * @param {string} [modelReceiptDetailsModelEmailAddress] 
         * @param {string} [modelReceiptDetailsModelReceiptMessage] 
         * @param {string} [modelReceiptDetailsModelId1] 
         * @param {string} [modelReceiptDetailsModelId1Label] 
         * @param {string} [modelReceiptDetailsModelId2] 
         * @param {string} [modelReceiptDetailsModelId2Label] 
         * @param {string} [modelReceiptDetailsModelLogoFilename] 
         * @param {number} [modelReceiptDetailsModelLogoFileSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsUpdateReceiptDetailsWithLogo: async (modelReceiptDetailsModelTradingName?: string, modelReceiptDetailsModelAbn?: string, modelReceiptDetailsModelAcn?: string, modelReceiptDetailsModelAddressTypeId?: number, modelReceiptDetailsModelAddressAddressLine1?: string, modelReceiptDetailsModelAddressAddressLine2?: string, modelReceiptDetailsModelAddressAddressLine3?: string, modelReceiptDetailsModelAddressSuburb?: string, modelReceiptDetailsModelAddressStateId?: number, modelReceiptDetailsModelAddressState?: string, modelReceiptDetailsModelAddressPostcode?: string, modelReceiptDetailsModelAddressCountryCode?: number, modelReceiptDetailsModelPhoneNumber?: string, modelReceiptDetailsModelFaxNumber?: string, modelReceiptDetailsModelWebsiteUrl?: string, modelReceiptDetailsModelEmailAddress?: string, modelReceiptDetailsModelReceiptMessage?: string, modelReceiptDetailsModelId1?: string, modelReceiptDetailsModelId1Label?: string, modelReceiptDetailsModelId2?: string, modelReceiptDetailsModelId2Label?: string, modelReceiptDetailsModelLogoFilename?: string, modelReceiptDetailsModelLogoFileSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ReceiptDetails/UpdateReceiptDetailsWithLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelReceiptDetailsModelTradingName !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.tradingName'] = modelReceiptDetailsModelTradingName;
            }

            if (modelReceiptDetailsModelAbn !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.abn'] = modelReceiptDetailsModelAbn;
            }

            if (modelReceiptDetailsModelAcn !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.acn'] = modelReceiptDetailsModelAcn;
            }

            if (modelReceiptDetailsModelAddressTypeId !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.typeId'] = modelReceiptDetailsModelAddressTypeId;
            }

            if (modelReceiptDetailsModelAddressAddressLine1 !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.addressLine1'] = modelReceiptDetailsModelAddressAddressLine1;
            }

            if (modelReceiptDetailsModelAddressAddressLine2 !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.addressLine2'] = modelReceiptDetailsModelAddressAddressLine2;
            }

            if (modelReceiptDetailsModelAddressAddressLine3 !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.addressLine3'] = modelReceiptDetailsModelAddressAddressLine3;
            }

            if (modelReceiptDetailsModelAddressSuburb !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.suburb'] = modelReceiptDetailsModelAddressSuburb;
            }

            if (modelReceiptDetailsModelAddressStateId !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.stateId'] = modelReceiptDetailsModelAddressStateId;
            }

            if (modelReceiptDetailsModelAddressState !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.state'] = modelReceiptDetailsModelAddressState;
            }

            if (modelReceiptDetailsModelAddressPostcode !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.postcode'] = modelReceiptDetailsModelAddressPostcode;
            }

            if (modelReceiptDetailsModelAddressCountryCode !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.address.countryCode'] = modelReceiptDetailsModelAddressCountryCode;
            }

            if (modelReceiptDetailsModelPhoneNumber !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.phoneNumber'] = modelReceiptDetailsModelPhoneNumber;
            }

            if (modelReceiptDetailsModelFaxNumber !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.faxNumber'] = modelReceiptDetailsModelFaxNumber;
            }

            if (modelReceiptDetailsModelWebsiteUrl !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.websiteUrl'] = modelReceiptDetailsModelWebsiteUrl;
            }

            if (modelReceiptDetailsModelEmailAddress !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.emailAddress'] = modelReceiptDetailsModelEmailAddress;
            }

            if (modelReceiptDetailsModelReceiptMessage !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.receiptMessage'] = modelReceiptDetailsModelReceiptMessage;
            }

            if (modelReceiptDetailsModelId1 !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.id1'] = modelReceiptDetailsModelId1;
            }

            if (modelReceiptDetailsModelId1Label !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.id1Label'] = modelReceiptDetailsModelId1Label;
            }

            if (modelReceiptDetailsModelId2 !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.id2'] = modelReceiptDetailsModelId2;
            }

            if (modelReceiptDetailsModelId2Label !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.id2Label'] = modelReceiptDetailsModelId2Label;
            }

            if (modelReceiptDetailsModelLogoFilename !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.logoFilename'] = modelReceiptDetailsModelLogoFilename;
            }

            if (modelReceiptDetailsModelLogoFileSize !== undefined) {
                localVarQueryParameter['model.receiptDetailsModel.logoFileSize'] = modelReceiptDetailsModelLogoFileSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceiptDetailsApi - functional programming interface
 * @export
 */
export const ReceiptDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceiptDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a merchants receipt details logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptDetailsDeleteReceiptDetailsLogo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptDetailsDeleteReceiptDetailsLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the receipt details for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptDetailsGetReceiptDetails(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseReceiptDetailsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptDetailsGetReceiptDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a merchants receipt details
         * @param {ReceiptDetailsModel} model Receipt details model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptDetailsUpdateReceiptDetails(model: ReceiptDetailsModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptDetailsUpdateReceiptDetails(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a merchants receipt details with logo
         * @param {string} [modelReceiptDetailsModelTradingName] 
         * @param {string} [modelReceiptDetailsModelAbn] 
         * @param {string} [modelReceiptDetailsModelAcn] 
         * @param {number} [modelReceiptDetailsModelAddressTypeId] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine1] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine2] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine3] 
         * @param {string} [modelReceiptDetailsModelAddressSuburb] 
         * @param {number} [modelReceiptDetailsModelAddressStateId] 
         * @param {string} [modelReceiptDetailsModelAddressState] 
         * @param {string} [modelReceiptDetailsModelAddressPostcode] 
         * @param {number} [modelReceiptDetailsModelAddressCountryCode] 
         * @param {string} [modelReceiptDetailsModelPhoneNumber] 
         * @param {string} [modelReceiptDetailsModelFaxNumber] 
         * @param {string} [modelReceiptDetailsModelWebsiteUrl] 
         * @param {string} [modelReceiptDetailsModelEmailAddress] 
         * @param {string} [modelReceiptDetailsModelReceiptMessage] 
         * @param {string} [modelReceiptDetailsModelId1] 
         * @param {string} [modelReceiptDetailsModelId1Label] 
         * @param {string} [modelReceiptDetailsModelId2] 
         * @param {string} [modelReceiptDetailsModelId2Label] 
         * @param {string} [modelReceiptDetailsModelLogoFilename] 
         * @param {number} [modelReceiptDetailsModelLogoFileSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptDetailsUpdateReceiptDetailsWithLogo(modelReceiptDetailsModelTradingName?: string, modelReceiptDetailsModelAbn?: string, modelReceiptDetailsModelAcn?: string, modelReceiptDetailsModelAddressTypeId?: number, modelReceiptDetailsModelAddressAddressLine1?: string, modelReceiptDetailsModelAddressAddressLine2?: string, modelReceiptDetailsModelAddressAddressLine3?: string, modelReceiptDetailsModelAddressSuburb?: string, modelReceiptDetailsModelAddressStateId?: number, modelReceiptDetailsModelAddressState?: string, modelReceiptDetailsModelAddressPostcode?: string, modelReceiptDetailsModelAddressCountryCode?: number, modelReceiptDetailsModelPhoneNumber?: string, modelReceiptDetailsModelFaxNumber?: string, modelReceiptDetailsModelWebsiteUrl?: string, modelReceiptDetailsModelEmailAddress?: string, modelReceiptDetailsModelReceiptMessage?: string, modelReceiptDetailsModelId1?: string, modelReceiptDetailsModelId1Label?: string, modelReceiptDetailsModelId2?: string, modelReceiptDetailsModelId2Label?: string, modelReceiptDetailsModelLogoFilename?: string, modelReceiptDetailsModelLogoFileSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptDetailsUpdateReceiptDetailsWithLogo(modelReceiptDetailsModelTradingName, modelReceiptDetailsModelAbn, modelReceiptDetailsModelAcn, modelReceiptDetailsModelAddressTypeId, modelReceiptDetailsModelAddressAddressLine1, modelReceiptDetailsModelAddressAddressLine2, modelReceiptDetailsModelAddressAddressLine3, modelReceiptDetailsModelAddressSuburb, modelReceiptDetailsModelAddressStateId, modelReceiptDetailsModelAddressState, modelReceiptDetailsModelAddressPostcode, modelReceiptDetailsModelAddressCountryCode, modelReceiptDetailsModelPhoneNumber, modelReceiptDetailsModelFaxNumber, modelReceiptDetailsModelWebsiteUrl, modelReceiptDetailsModelEmailAddress, modelReceiptDetailsModelReceiptMessage, modelReceiptDetailsModelId1, modelReceiptDetailsModelId1Label, modelReceiptDetailsModelId2, modelReceiptDetailsModelId2Label, modelReceiptDetailsModelLogoFilename, modelReceiptDetailsModelLogoFileSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceiptDetailsApi - factory interface
 * @export
 */
export const ReceiptDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceiptDetailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a merchants receipt details logo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsDeleteReceiptDetailsLogo(options?: any): AxiosPromise<void> {
            return localVarFp.receiptDetailsDeleteReceiptDetailsLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the receipt details for a merchant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsGetReceiptDetails(options?: any): AxiosPromise<ApiResponseReceiptDetailsModel> {
            return localVarFp.receiptDetailsGetReceiptDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a merchants receipt details
         * @param {ReceiptDetailsModel} model Receipt details model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsUpdateReceiptDetails(model: ReceiptDetailsModel, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.receiptDetailsUpdateReceiptDetails(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a merchants receipt details with logo
         * @param {string} [modelReceiptDetailsModelTradingName] 
         * @param {string} [modelReceiptDetailsModelAbn] 
         * @param {string} [modelReceiptDetailsModelAcn] 
         * @param {number} [modelReceiptDetailsModelAddressTypeId] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine1] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine2] 
         * @param {string} [modelReceiptDetailsModelAddressAddressLine3] 
         * @param {string} [modelReceiptDetailsModelAddressSuburb] 
         * @param {number} [modelReceiptDetailsModelAddressStateId] 
         * @param {string} [modelReceiptDetailsModelAddressState] 
         * @param {string} [modelReceiptDetailsModelAddressPostcode] 
         * @param {number} [modelReceiptDetailsModelAddressCountryCode] 
         * @param {string} [modelReceiptDetailsModelPhoneNumber] 
         * @param {string} [modelReceiptDetailsModelFaxNumber] 
         * @param {string} [modelReceiptDetailsModelWebsiteUrl] 
         * @param {string} [modelReceiptDetailsModelEmailAddress] 
         * @param {string} [modelReceiptDetailsModelReceiptMessage] 
         * @param {string} [modelReceiptDetailsModelId1] 
         * @param {string} [modelReceiptDetailsModelId1Label] 
         * @param {string} [modelReceiptDetailsModelId2] 
         * @param {string} [modelReceiptDetailsModelId2Label] 
         * @param {string} [modelReceiptDetailsModelLogoFilename] 
         * @param {number} [modelReceiptDetailsModelLogoFileSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDetailsUpdateReceiptDetailsWithLogo(modelReceiptDetailsModelTradingName?: string, modelReceiptDetailsModelAbn?: string, modelReceiptDetailsModelAcn?: string, modelReceiptDetailsModelAddressTypeId?: number, modelReceiptDetailsModelAddressAddressLine1?: string, modelReceiptDetailsModelAddressAddressLine2?: string, modelReceiptDetailsModelAddressAddressLine3?: string, modelReceiptDetailsModelAddressSuburb?: string, modelReceiptDetailsModelAddressStateId?: number, modelReceiptDetailsModelAddressState?: string, modelReceiptDetailsModelAddressPostcode?: string, modelReceiptDetailsModelAddressCountryCode?: number, modelReceiptDetailsModelPhoneNumber?: string, modelReceiptDetailsModelFaxNumber?: string, modelReceiptDetailsModelWebsiteUrl?: string, modelReceiptDetailsModelEmailAddress?: string, modelReceiptDetailsModelReceiptMessage?: string, modelReceiptDetailsModelId1?: string, modelReceiptDetailsModelId1Label?: string, modelReceiptDetailsModelId2?: string, modelReceiptDetailsModelId2Label?: string, modelReceiptDetailsModelLogoFilename?: string, modelReceiptDetailsModelLogoFileSize?: number, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.receiptDetailsUpdateReceiptDetailsWithLogo(modelReceiptDetailsModelTradingName, modelReceiptDetailsModelAbn, modelReceiptDetailsModelAcn, modelReceiptDetailsModelAddressTypeId, modelReceiptDetailsModelAddressAddressLine1, modelReceiptDetailsModelAddressAddressLine2, modelReceiptDetailsModelAddressAddressLine3, modelReceiptDetailsModelAddressSuburb, modelReceiptDetailsModelAddressStateId, modelReceiptDetailsModelAddressState, modelReceiptDetailsModelAddressPostcode, modelReceiptDetailsModelAddressCountryCode, modelReceiptDetailsModelPhoneNumber, modelReceiptDetailsModelFaxNumber, modelReceiptDetailsModelWebsiteUrl, modelReceiptDetailsModelEmailAddress, modelReceiptDetailsModelReceiptMessage, modelReceiptDetailsModelId1, modelReceiptDetailsModelId1Label, modelReceiptDetailsModelId2, modelReceiptDetailsModelId2Label, modelReceiptDetailsModelLogoFilename, modelReceiptDetailsModelLogoFileSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceiptDetailsApi - object-oriented interface
 * @export
 * @class ReceiptDetailsApi
 * @extends {BaseAPI}
 */
export class ReceiptDetailsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a merchants receipt details logo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptDetailsApi
     */
    public receiptDetailsDeleteReceiptDetailsLogo(options?: any) {
        return ReceiptDetailsApiFp(this.configuration).receiptDetailsDeleteReceiptDetailsLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the receipt details for a merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptDetailsApi
     */
    public receiptDetailsGetReceiptDetails(options?: any) {
        return ReceiptDetailsApiFp(this.configuration).receiptDetailsGetReceiptDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a merchants receipt details
     * @param {ReceiptDetailsModel} model Receipt details model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptDetailsApi
     */
    public receiptDetailsUpdateReceiptDetails(model: ReceiptDetailsModel, options?: any) {
        return ReceiptDetailsApiFp(this.configuration).receiptDetailsUpdateReceiptDetails(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a merchants receipt details with logo
     * @param {string} [modelReceiptDetailsModelTradingName] 
     * @param {string} [modelReceiptDetailsModelAbn] 
     * @param {string} [modelReceiptDetailsModelAcn] 
     * @param {number} [modelReceiptDetailsModelAddressTypeId] 
     * @param {string} [modelReceiptDetailsModelAddressAddressLine1] 
     * @param {string} [modelReceiptDetailsModelAddressAddressLine2] 
     * @param {string} [modelReceiptDetailsModelAddressAddressLine3] 
     * @param {string} [modelReceiptDetailsModelAddressSuburb] 
     * @param {number} [modelReceiptDetailsModelAddressStateId] 
     * @param {string} [modelReceiptDetailsModelAddressState] 
     * @param {string} [modelReceiptDetailsModelAddressPostcode] 
     * @param {number} [modelReceiptDetailsModelAddressCountryCode] 
     * @param {string} [modelReceiptDetailsModelPhoneNumber] 
     * @param {string} [modelReceiptDetailsModelFaxNumber] 
     * @param {string} [modelReceiptDetailsModelWebsiteUrl] 
     * @param {string} [modelReceiptDetailsModelEmailAddress] 
     * @param {string} [modelReceiptDetailsModelReceiptMessage] 
     * @param {string} [modelReceiptDetailsModelId1] 
     * @param {string} [modelReceiptDetailsModelId1Label] 
     * @param {string} [modelReceiptDetailsModelId2] 
     * @param {string} [modelReceiptDetailsModelId2Label] 
     * @param {string} [modelReceiptDetailsModelLogoFilename] 
     * @param {number} [modelReceiptDetailsModelLogoFileSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptDetailsApi
     */
    public receiptDetailsUpdateReceiptDetailsWithLogo(modelReceiptDetailsModelTradingName?: string, modelReceiptDetailsModelAbn?: string, modelReceiptDetailsModelAcn?: string, modelReceiptDetailsModelAddressTypeId?: number, modelReceiptDetailsModelAddressAddressLine1?: string, modelReceiptDetailsModelAddressAddressLine2?: string, modelReceiptDetailsModelAddressAddressLine3?: string, modelReceiptDetailsModelAddressSuburb?: string, modelReceiptDetailsModelAddressStateId?: number, modelReceiptDetailsModelAddressState?: string, modelReceiptDetailsModelAddressPostcode?: string, modelReceiptDetailsModelAddressCountryCode?: number, modelReceiptDetailsModelPhoneNumber?: string, modelReceiptDetailsModelFaxNumber?: string, modelReceiptDetailsModelWebsiteUrl?: string, modelReceiptDetailsModelEmailAddress?: string, modelReceiptDetailsModelReceiptMessage?: string, modelReceiptDetailsModelId1?: string, modelReceiptDetailsModelId1Label?: string, modelReceiptDetailsModelId2?: string, modelReceiptDetailsModelId2Label?: string, modelReceiptDetailsModelLogoFilename?: string, modelReceiptDetailsModelLogoFileSize?: number, options?: any) {
        return ReceiptDetailsApiFp(this.configuration).receiptDetailsUpdateReceiptDetailsWithLogo(modelReceiptDetailsModelTradingName, modelReceiptDetailsModelAbn, modelReceiptDetailsModelAcn, modelReceiptDetailsModelAddressTypeId, modelReceiptDetailsModelAddressAddressLine1, modelReceiptDetailsModelAddressAddressLine2, modelReceiptDetailsModelAddressAddressLine3, modelReceiptDetailsModelAddressSuburb, modelReceiptDetailsModelAddressStateId, modelReceiptDetailsModelAddressState, modelReceiptDetailsModelAddressPostcode, modelReceiptDetailsModelAddressCountryCode, modelReceiptDetailsModelPhoneNumber, modelReceiptDetailsModelFaxNumber, modelReceiptDetailsModelWebsiteUrl, modelReceiptDetailsModelEmailAddress, modelReceiptDetailsModelReceiptMessage, modelReceiptDetailsModelId1, modelReceiptDetailsModelId1Label, modelReceiptDetailsModelId2, modelReceiptDetailsModelId2Label, modelReceiptDetailsModelLogoFilename, modelReceiptDetailsModelLogoFileSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a specified file from the merchant\'s report directory, if it exists
         * @param {DailyReportFileRequest} request Name of the file to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetDailyReport: async (request: DailyReportFileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('reportsGetDailyReport', 'request', request)
            const localVarPath = `/Reports/GetDailyReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of names of a merchant\'s reports with a creation date during the requested period
         * @param {number} pageNo 
         * @param {number} resultsPerPage 
         * @param {DailyReportsRequest} input If ChildMerchantNumber is empty, return reports for this merchant and all its child merchants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetDailyReports: async (pageNo: number, resultsPerPage: number, input: DailyReportsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('reportsGetDailyReports', 'pageNo', pageNo)
            // verify required parameter 'resultsPerPage' is not null or undefined
            assertParamExists('reportsGetDailyReports', 'resultsPerPage', resultsPerPage)
            // verify required parameter 'input' is not null or undefined
            assertParamExists('reportsGetDailyReports', 'input', input)
            const localVarPath = `/Reports/GetDailyReports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (resultsPerPage !== undefined) {
                localVarQueryParameter['resultsPerPage'] = resultsPerPage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a specified file from the merchant\'s report directory, if it exists
         * @param {DailyReportFileRequest} request Name of the file to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetDailyReport(request: DailyReportFileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDownloadBatchFileResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetDailyReport(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of names of a merchant\'s reports with a creation date during the requested period
         * @param {number} pageNo 
         * @param {number} resultsPerPage 
         * @param {DailyReportsRequest} input If ChildMerchantNumber is empty, return reports for this merchant and all its child merchants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsGetDailyReports(pageNo: number, resultsPerPage: number, input: DailyReportsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDailyReportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsGetDailyReports(pageNo, resultsPerPage, input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a specified file from the merchant\'s report directory, if it exists
         * @param {DailyReportFileRequest} request Name of the file to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetDailyReport(request: DailyReportFileRequest, options?: any): AxiosPromise<ApiResponseDownloadBatchFileResultResponse> {
            return localVarFp.reportsGetDailyReport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of names of a merchant\'s reports with a creation date during the requested period
         * @param {number} pageNo 
         * @param {number} resultsPerPage 
         * @param {DailyReportsRequest} input If ChildMerchantNumber is empty, return reports for this merchant and all its child merchants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsGetDailyReports(pageNo: number, resultsPerPage: number, input: DailyReportsRequest, options?: any): AxiosPromise<ApiResponseDailyReportsResponse> {
            return localVarFp.reportsGetDailyReports(pageNo, resultsPerPage, input, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Get a specified file from the merchant\'s report directory, if it exists
     * @param {DailyReportFileRequest} request Name of the file to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetDailyReport(request: DailyReportFileRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsGetDailyReport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of names of a merchant\'s reports with a creation date during the requested period
     * @param {number} pageNo 
     * @param {number} resultsPerPage 
     * @param {DailyReportsRequest} input If ChildMerchantNumber is empty, return reports for this merchant and all its child merchants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsGetDailyReports(pageNo: number, resultsPerPage: number, input: DailyReportsRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsGetDailyReports(pageNo, resultsPerPage, input, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RequestApi - axios parameter creator
 * @export
 */
export const RequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancels a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCancelRequest: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestCancelRequest', 'id', id)
            const localVarPath = `/request/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCreateNewQrRequest: async (request: RequestModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('requestCreateNewQrRequest', 'request', request)
            const localVarPath = `/request/qr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCreateNewRequest: async (request: RequestModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('requestCreateNewRequest', 'request', request)
            const localVarPath = `/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the hosted payment page URL of the merchant
         * @param {PaymentUrlRequestModel} paymentUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetPaymentPageUrl: async (paymentUrlRequest: PaymentUrlRequestModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentUrlRequest' is not null or undefined
            assertParamExists('requestGetPaymentPageUrl', 'paymentUrlRequest', paymentUrlRequest)
            const localVarPath = `/request/payment-page-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetPaymentRequestSettings: async (merchantNumber: string, billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('requestGetPaymentRequestSettings', 'merchantNumber', merchantNumber)
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('requestGetPaymentRequestSettings', 'billerCode', billerCode)
            const localVarPath = `/request/config/{merchantNumber}/{billerCode}`
                .replace(`{${"merchantNumber"}}`, encodeURIComponent(String(merchantNumber)))
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the QR code that provides the payment request\'s URL associated with the request ID.
         * @param {string} requestId QR payment request\&#39;s Guid
         * @param {number} [width] Width of the QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetQrCode: async (requestId: string, width?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('requestGetQrCode', 'requestId', requestId)
            const localVarPath = `/request/qr/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the hosted payment page URL of the merchant in the form of a QR code
         * @param {PaymentUrlRequestModel} paymentUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetQrCodeForPaymentPage: async (paymentUrlRequest: PaymentUrlRequestModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentUrlRequest' is not null or undefined
            assertParamExists('requestGetQrCodeForPaymentPage', 'paymentUrlRequest', paymentUrlRequest)
            const localVarPath = `/request/payment-page-qrcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetRequest: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestGetRequest', 'id', id)
            const localVarPath = `/request/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a request\'s details
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetRequestStatus: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestGetRequestStatus', 'id', id)
            const localVarPath = `/request/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestRequestActions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/request/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestRequestStatuses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/request/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resends a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResendRequest: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestResendRequest', 'id', id)
            const localVarPath = `/request/{id}/resend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of RequestSummaryModel matching the provided filters
         * @param {PaginateRequestSearchRequestRequestSearchRequestSortFields} request A PaginatedList object of type SearchRequestRequest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestSearchRequests: async (request: PaginateRequestSearchRequestRequestSearchRequestSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('requestSearchRequests', 'request', request)
            const localVarPath = `/request/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {PaymentRequestSettings} requestConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdatePaymentRequestSettings: async (merchantNumber: string, billerCode: string, requestConfig: PaymentRequestSettings, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('requestUpdatePaymentRequestSettings', 'merchantNumber', merchantNumber)
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('requestUpdatePaymentRequestSettings', 'billerCode', billerCode)
            // verify required parameter 'requestConfig' is not null or undefined
            assertParamExists('requestUpdatePaymentRequestSettings', 'requestConfig', requestConfig)
            const localVarPath = `/request/config/{billerCode}`
                .replace(`{${"billerCode"}}`, encodeURIComponent(String(billerCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantNumber !== undefined) {
                localVarQueryParameter['merchantNumber'] = merchantNumber;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestApi - functional programming interface
 * @export
 */
export const RequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancels a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestCancelRequest(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestCancelRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestCreateNewQrRequest(request: RequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestCreateNewQrRequest(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RequestModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestCreateNewRequest(request: RequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestCreateNewRequest(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the hosted payment page URL of the merchant
         * @param {PaymentUrlRequestModel} paymentUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGetPaymentPageUrl(paymentUrlRequest: PaymentUrlRequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGetPaymentPageUrl(paymentUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGetPaymentRequestSettings(merchantNumber: string, billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentRequestSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGetPaymentRequestSettings(merchantNumber, billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the QR code that provides the payment request\'s URL associated with the request ID.
         * @param {string} requestId QR payment request\&#39;s Guid
         * @param {number} [width] Width of the QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGetQrCode(requestId: string, width?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGetQrCode(requestId, width, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the hosted payment page URL of the merchant in the form of a QR code
         * @param {PaymentUrlRequestModel} paymentUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGetQrCodeForPaymentPage(paymentUrlRequest: PaymentUrlRequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGetQrCodeForPaymentPage(paymentUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGetRequest(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseRequestModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGetRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a request\'s details
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestGetRequestStatus(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentRequestStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestGetRequestStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestRequestActions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseRequestActionsData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestRequestActions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestRequestStatuses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseRequestStatusesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestRequestStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resends a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestResendRequest(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestResendRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of RequestSummaryModel matching the provided filters
         * @param {PaginateRequestSearchRequestRequestSearchRequestSortFields} request A PaginatedList object of type SearchRequestRequest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestSearchRequests(request: PaginateRequestSearchRequestRequestSearchRequestSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListRequestSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestSearchRequests(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {PaymentRequestSettings} requestConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestUpdatePaymentRequestSettings(merchantNumber: string, billerCode: string, requestConfig: PaymentRequestSettings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestUpdatePaymentRequestSettings(merchantNumber, billerCode, requestConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestApi - factory interface
 * @export
 */
export const RequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancels a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCancelRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestCancelRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCreateNewQrRequest(request: RequestModel, options?: any): AxiosPromise<ApiResponseRequestResponse> {
            return localVarFp.requestCreateNewQrRequest(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestModel} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCreateNewRequest(request: RequestModel, options?: any): AxiosPromise<ApiResponseRequestResponse> {
            return localVarFp.requestCreateNewRequest(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the hosted payment page URL of the merchant
         * @param {PaymentUrlRequestModel} paymentUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetPaymentPageUrl(paymentUrlRequest: PaymentUrlRequestModel, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.requestGetPaymentPageUrl(paymentUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetPaymentRequestSettings(merchantNumber: string, billerCode: string, options?: any): AxiosPromise<ApiResponsePaymentRequestSettings> {
            return localVarFp.requestGetPaymentRequestSettings(merchantNumber, billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the QR code that provides the payment request\'s URL associated with the request ID.
         * @param {string} requestId QR payment request\&#39;s Guid
         * @param {number} [width] Width of the QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetQrCode(requestId: string, width?: number, options?: any): AxiosPromise<object> {
            return localVarFp.requestGetQrCode(requestId, width, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the hosted payment page URL of the merchant in the form of a QR code
         * @param {PaymentUrlRequestModel} paymentUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetQrCodeForPaymentPage(paymentUrlRequest: PaymentUrlRequestModel, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.requestGetQrCodeForPaymentPage(paymentUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetRequest(id: string, options?: any): AxiosPromise<ApiResponseRequestModel> {
            return localVarFp.requestGetRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a request\'s details
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGetRequestStatus(id: string, options?: any): AxiosPromise<ApiResponsePaymentRequestStatus> {
            return localVarFp.requestGetRequestStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestRequestActions(options?: any): AxiosPromise<ApiResponseRequestActionsData> {
            return localVarFp.requestRequestActions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestRequestStatuses(options?: any): AxiosPromise<ApiResponseRequestStatusesData> {
            return localVarFp.requestRequestStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resends a request
         * @param {string} id Request Guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResendRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestResendRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of RequestSummaryModel matching the provided filters
         * @param {PaginateRequestSearchRequestRequestSearchRequestSortFields} request A PaginatedList object of type SearchRequestRequest.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestSearchRequests(request: PaginateRequestSearchRequestRequestSearchRequestSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListRequestSummaryModel> {
            return localVarFp.requestSearchRequests(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {PaymentRequestSettings} requestConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdatePaymentRequestSettings(merchantNumber: string, billerCode: string, requestConfig: PaymentRequestSettings, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.requestUpdatePaymentRequestSettings(merchantNumber, billerCode, requestConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RequestApi - object-oriented interface
 * @export
 * @class RequestApi
 * @extends {BaseAPI}
 */
export class RequestApi extends BaseAPI {
    /**
     * 
     * @summary Cancels a request
     * @param {string} id Request Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestCancelRequest(id: string, options?: any) {
        return RequestApiFp(this.configuration).requestCancelRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestModel} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestCreateNewQrRequest(request: RequestModel, options?: any) {
        return RequestApiFp(this.configuration).requestCreateNewQrRequest(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestModel} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestCreateNewRequest(request: RequestModel, options?: any) {
        return RequestApiFp(this.configuration).requestCreateNewRequest(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the hosted payment page URL of the merchant
     * @param {PaymentUrlRequestModel} paymentUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestGetPaymentPageUrl(paymentUrlRequest: PaymentUrlRequestModel, options?: any) {
        return RequestApiFp(this.configuration).requestGetPaymentPageUrl(paymentUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} merchantNumber 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestGetPaymentRequestSettings(merchantNumber: string, billerCode: string, options?: any) {
        return RequestApiFp(this.configuration).requestGetPaymentRequestSettings(merchantNumber, billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the QR code that provides the payment request\'s URL associated with the request ID.
     * @param {string} requestId QR payment request\&#39;s Guid
     * @param {number} [width] Width of the QR code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestGetQrCode(requestId: string, width?: number, options?: any) {
        return RequestApiFp(this.configuration).requestGetQrCode(requestId, width, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the hosted payment page URL of the merchant in the form of a QR code
     * @param {PaymentUrlRequestModel} paymentUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestGetQrCodeForPaymentPage(paymentUrlRequest: PaymentUrlRequestModel, options?: any) {
        return RequestApiFp(this.configuration).requestGetQrCodeForPaymentPage(paymentUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a request
     * @param {string} id Request Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestGetRequest(id: string, options?: any) {
        return RequestApiFp(this.configuration).requestGetRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a request\'s details
     * @param {string} id Request Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestGetRequestStatus(id: string, options?: any) {
        return RequestApiFp(this.configuration).requestGetRequestStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestRequestActions(options?: any) {
        return RequestApiFp(this.configuration).requestRequestActions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestRequestStatuses(options?: any) {
        return RequestApiFp(this.configuration).requestRequestStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resends a request
     * @param {string} id Request Guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestResendRequest(id: string, options?: any) {
        return RequestApiFp(this.configuration).requestResendRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of RequestSummaryModel matching the provided filters
     * @param {PaginateRequestSearchRequestRequestSearchRequestSortFields} request A PaginatedList object of type SearchRequestRequest.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestSearchRequests(request: PaginateRequestSearchRequestRequestSearchRequestSortFields, options?: any) {
        return RequestApiFp(this.configuration).requestSearchRequests(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} merchantNumber 
     * @param {string} billerCode 
     * @param {PaymentRequestSettings} requestConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestApi
     */
    public requestUpdatePaymentRequestSettings(merchantNumber: string, billerCode: string, requestConfig: PaymentRequestSettings, options?: any) {
        return RequestApiFp(this.configuration).requestUpdatePaymentRequestSettings(merchantNumber, billerCode, requestConfig, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScheduledPaymentApi - axios parameter creator
 * @export
 */
export const ScheduledPaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a Scheduled Payment (TxnRequest)
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentCancelScheduledPayment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledPaymentCancelScheduledPayment', 'id', id)
            const localVarPath = `/scheduled-payments/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a ScheduledPaymentModel (TxnRequest) details
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentGetScheduledPayment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledPaymentGetScheduledPayment', 'id', id)
            const localVarPath = `/scheduled-payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of ScheduledPaymentModel matching the provided filters
         * @param {PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields} request A PaginatedList object of type ScheduledPaymentsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentGetScheduledPayments: async (request: PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('scheduledPaymentGetScheduledPayments', 'request', request)
            const localVarPath = `/scheduled-payments/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry a Scheduled Payment (TxnRequest)
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentRetryScheduledPayment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledPaymentRetryScheduledPayment', 'id', id)
            const localVarPath = `/scheduled-payments/{id}/retry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduledPaymentApi - functional programming interface
 * @export
 */
export const ScheduledPaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScheduledPaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel a Scheduled Payment (TxnRequest)
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledPaymentCancelScheduledPayment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledPaymentCancelScheduledPayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a ScheduledPaymentModel (TxnRequest) details
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledPaymentGetScheduledPayment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseScheduledPaymentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledPaymentGetScheduledPayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of ScheduledPaymentModel matching the provided filters
         * @param {PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields} request A PaginatedList object of type ScheduledPaymentsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledPaymentGetScheduledPayments(request: PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListScheduledPaymentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledPaymentGetScheduledPayments(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry a Scheduled Payment (TxnRequest)
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledPaymentRetryScheduledPayment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledPaymentRetryScheduledPayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScheduledPaymentApi - factory interface
 * @export
 */
export const ScheduledPaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScheduledPaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel a Scheduled Payment (TxnRequest)
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentCancelScheduledPayment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.scheduledPaymentCancelScheduledPayment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a ScheduledPaymentModel (TxnRequest) details
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentGetScheduledPayment(id: number, options?: any): AxiosPromise<ApiResponseScheduledPaymentModel> {
            return localVarFp.scheduledPaymentGetScheduledPayment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of ScheduledPaymentModel matching the provided filters
         * @param {PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields} request A PaginatedList object of type ScheduledPaymentsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentGetScheduledPayments(request: PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListScheduledPaymentModel> {
            return localVarFp.scheduledPaymentGetScheduledPayments(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry a Scheduled Payment (TxnRequest)
         * @param {number} id TxnRequestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledPaymentRetryScheduledPayment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.scheduledPaymentRetryScheduledPayment(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScheduledPaymentApi - object-oriented interface
 * @export
 * @class ScheduledPaymentApi
 * @extends {BaseAPI}
 */
export class ScheduledPaymentApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a Scheduled Payment (TxnRequest)
     * @param {number} id TxnRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledPaymentApi
     */
    public scheduledPaymentCancelScheduledPayment(id: number, options?: any) {
        return ScheduledPaymentApiFp(this.configuration).scheduledPaymentCancelScheduledPayment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a ScheduledPaymentModel (TxnRequest) details
     * @param {number} id TxnRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledPaymentApi
     */
    public scheduledPaymentGetScheduledPayment(id: number, options?: any) {
        return ScheduledPaymentApiFp(this.configuration).scheduledPaymentGetScheduledPayment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of ScheduledPaymentModel matching the provided filters
     * @param {PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields} request A PaginatedList object of type ScheduledPaymentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledPaymentApi
     */
    public scheduledPaymentGetScheduledPayments(request: PaginateRequestScheduledPaymentsRequestScheduledPaymentsSortFields, options?: any) {
        return ScheduledPaymentApiFp(this.configuration).scheduledPaymentGetScheduledPayments(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry a Scheduled Payment (TxnRequest)
     * @param {number} id TxnRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledPaymentApi
     */
    public scheduledPaymentRetryScheduledPayment(id: number, options?: any) {
        return ScheduledPaymentApiFp(this.configuration).scheduledPaymentRetryScheduledPayment(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettlementApi - axios parameter creator
 * @export
 */
export const SettlementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DownloadSettlementExportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementDownloadSettlementExport: async (request: DownloadSettlementExportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('settlementDownloadSettlementExport', 'request', request)
            const localVarPath = `/Settlement/DownloadSettlementExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadSettlementSummaryRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementDownloadSettlementSummary: async (request: DownloadSettlementSummaryRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('settlementDownloadSettlementSummary', 'request', request)
            const localVarPath = `/Settlement/DownloadSettlementSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementExportColumns: async (request: GetExportDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('settlementGetSettlementExportColumns', 'request', request)
            const localVarPath = `/Settlement/GetSettlementExportColumns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementExportOptions: async (request: GetExportDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('settlementGetSettlementExportOptions', 'request', request)
            const localVarPath = `/Settlement/GetSettlementExportOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementExportTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Settlement/GetSettlementExportTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get settlement data for a merchant between the requested period
         * @param {SettlementReportRequest} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementReportData: async (input: SettlementReportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('settlementGetSettlementReportData', 'input', input)
            const localVarPath = `/Settlement/GetSettlementReportData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettlementApi - functional programming interface
 * @export
 */
export const SettlementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettlementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DownloadSettlementExportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settlementDownloadSettlementExport(request: DownloadSettlementExportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settlementDownloadSettlementExport(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadSettlementSummaryRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settlementDownloadSettlementSummary(request: DownloadSettlementSummaryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settlementDownloadSettlementSummary(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settlementGetSettlementExportColumns(request: GetExportDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListGetExportColumnsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settlementGetSettlementExportColumns(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settlementGetSettlementExportOptions(request: GetExportDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListGetExportOptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settlementGetSettlementExportOptions(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settlementGetSettlementExportTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseExportTypesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settlementGetSettlementExportTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get settlement data for a merchant between the requested period
         * @param {SettlementReportRequest} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settlementGetSettlementReportData(input: SettlementReportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSettlementReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settlementGetSettlementReportData(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettlementApi - factory interface
 * @export
 */
export const SettlementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettlementApiFp(configuration)
    return {
        /**
         * 
         * @param {DownloadSettlementExportRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementDownloadSettlementExport(request: DownloadSettlementExportRequest, options?: any): AxiosPromise<object> {
            return localVarFp.settlementDownloadSettlementExport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadSettlementSummaryRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementDownloadSettlementSummary(request: DownloadSettlementSummaryRequest, options?: any): AxiosPromise<object> {
            return localVarFp.settlementDownloadSettlementSummary(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementExportColumns(request: GetExportDetailsRequest, options?: any): AxiosPromise<ApiResponseListGetExportColumnsResponse> {
            return localVarFp.settlementGetSettlementExportColumns(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetExportDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementExportOptions(request: GetExportDetailsRequest, options?: any): AxiosPromise<ApiResponseListGetExportOptionsResponse> {
            return localVarFp.settlementGetSettlementExportOptions(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementExportTypes(options?: any): AxiosPromise<ApiResponseExportTypesData> {
            return localVarFp.settlementGetSettlementExportTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get settlement data for a merchant between the requested period
         * @param {SettlementReportRequest} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementGetSettlementReportData(input: SettlementReportRequest, options?: any): AxiosPromise<ApiResponseSettlementReportModel> {
            return localVarFp.settlementGetSettlementReportData(input, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettlementApi - object-oriented interface
 * @export
 * @class SettlementApi
 * @extends {BaseAPI}
 */
export class SettlementApi extends BaseAPI {
    /**
     * 
     * @param {DownloadSettlementExportRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public settlementDownloadSettlementExport(request: DownloadSettlementExportRequest, options?: any) {
        return SettlementApiFp(this.configuration).settlementDownloadSettlementExport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadSettlementSummaryRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public settlementDownloadSettlementSummary(request: DownloadSettlementSummaryRequest, options?: any) {
        return SettlementApiFp(this.configuration).settlementDownloadSettlementSummary(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetExportDetailsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public settlementGetSettlementExportColumns(request: GetExportDetailsRequest, options?: any) {
        return SettlementApiFp(this.configuration).settlementGetSettlementExportColumns(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetExportDetailsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public settlementGetSettlementExportOptions(request: GetExportDetailsRequest, options?: any) {
        return SettlementApiFp(this.configuration).settlementGetSettlementExportOptions(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public settlementGetSettlementExportTypes(options?: any) {
        return SettlementApiFp(this.configuration).settlementGetSettlementExportTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get settlement data for a merchant between the requested period
     * @param {SettlementReportRequest} input 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public settlementGetSettlementReportData(input: SettlementReportRequest, options?: any) {
        return SettlementApiFp(this.configuration).settlementGetSettlementReportData(input, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SinglePaymentApi - axios parameter creator
 * @export
 */
export const SinglePaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CardSurchargeInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentGetCardSurchargeDetails: async (input: CardSurchargeInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('singlePaymentGetCardSurchargeDetails', 'input', input)
            const localVarPath = `/SinglePayment/GetCardSurchargeDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get business address details of merchant.                In the future we could look at eliminating this method and calling /businessdetails  (BusinessDetailsController.GetBusinessDetails()) from the UX, and this would cut down on  duplication of the model, mapper and tests, however /businessdetails does not return the  CompanyIdLabels - they are returned by Utilities/GetCompanyIdLabels, so some UX changes to  cache these labels for a session may be in order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentGetMerchantAddress: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/SinglePayment/GetMerchantAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the receipt details for a merchant
         * @param {number} merchantId 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentGetReceiptDetails: async (merchantId: number, merchantNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantId' is not null or undefined
            assertParamExists('singlePaymentGetReceiptDetails', 'merchantId', merchantId)
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('singlePaymentGetReceiptDetails', 'merchantNumber', merchantNumber)
            const localVarPath = `/SinglePayment/GetReceiptDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }

            if (merchantNumber !== undefined) {
                localVarQueryParameter['merchantNumber'] = merchantNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processed payment on a pre-auth txn
         * @param {ProcessCaptureInput} input Input details for txn Capture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentProcessCapture: async (input: ProcessCaptureInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('singlePaymentProcessCapture', 'input', input)
            const localVarPath = `/SinglePayment/ProcessCapture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process a single payment.
         * @param {ProcessTransactionInput} input The input details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentProcessPayment: async (input: ProcessTransactionInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('singlePaymentProcessPayment', 'input', input)
            const localVarPath = `/SinglePayment/ProcessPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process a refund.
         * @param {ProcessRefundInput} input The input details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentProcessRefund: async (input: ProcessRefundInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('singlePaymentProcessRefund', 'input', input)
            const localVarPath = `/SinglePayment/ProcessRefund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SinglePaymentReceiptInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentSendReceipt: async (input: SinglePaymentReceiptInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'input' is not null or undefined
            assertParamExists('singlePaymentSendReceipt', 'input', input)
            const localVarPath = `/SinglePayment/SendReceipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(input, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SinglePaymentApi - functional programming interface
 * @export
 */
export const SinglePaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SinglePaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CardSurchargeInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singlePaymentGetCardSurchargeDetails(input: CardSurchargeInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCardSurchargeDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singlePaymentGetCardSurchargeDetails(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get business address details of merchant.                In the future we could look at eliminating this method and calling /businessdetails  (BusinessDetailsController.GetBusinessDetails()) from the UX, and this would cut down on  duplication of the model, mapper and tests, however /businessdetails does not return the  CompanyIdLabels - they are returned by Utilities/GetCompanyIdLabels, so some UX changes to  cache these labels for a session may be in order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singlePaymentGetMerchantAddress(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseMerchantAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singlePaymentGetMerchantAddress(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the receipt details for a merchant
         * @param {number} merchantId 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singlePaymentGetReceiptDetails(merchantId: number, merchantNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseReceiptDetailsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singlePaymentGetReceiptDetails(merchantId, merchantNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Processed payment on a pre-auth txn
         * @param {ProcessCaptureInput} input Input details for txn Capture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singlePaymentProcessCapture(input: ProcessCaptureInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentProcessedTxnModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singlePaymentProcessCapture(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Process a single payment.
         * @param {ProcessTransactionInput} input The input details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singlePaymentProcessPayment(input: ProcessTransactionInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentProcessedTxnModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singlePaymentProcessPayment(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Process a refund.
         * @param {ProcessRefundInput} input The input details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singlePaymentProcessRefund(input: ProcessRefundInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaymentProcessedTxnModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singlePaymentProcessRefund(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SinglePaymentReceiptInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singlePaymentSendReceipt(input: SinglePaymentReceiptInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singlePaymentSendReceipt(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SinglePaymentApi - factory interface
 * @export
 */
export const SinglePaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SinglePaymentApiFp(configuration)
    return {
        /**
         * 
         * @param {CardSurchargeInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentGetCardSurchargeDetails(input: CardSurchargeInput, options?: any): AxiosPromise<ApiResponseCardSurchargeDetails> {
            return localVarFp.singlePaymentGetCardSurchargeDetails(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get business address details of merchant.                In the future we could look at eliminating this method and calling /businessdetails  (BusinessDetailsController.GetBusinessDetails()) from the UX, and this would cut down on  duplication of the model, mapper and tests, however /businessdetails does not return the  CompanyIdLabels - they are returned by Utilities/GetCompanyIdLabels, so some UX changes to  cache these labels for a session may be in order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentGetMerchantAddress(options?: any): AxiosPromise<ApiResponseMerchantAddress> {
            return localVarFp.singlePaymentGetMerchantAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the receipt details for a merchant
         * @param {number} merchantId 
         * @param {string} merchantNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentGetReceiptDetails(merchantId: number, merchantNumber: string, options?: any): AxiosPromise<ApiResponseReceiptDetailsModel> {
            return localVarFp.singlePaymentGetReceiptDetails(merchantId, merchantNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processed payment on a pre-auth txn
         * @param {ProcessCaptureInput} input Input details for txn Capture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentProcessCapture(input: ProcessCaptureInput, options?: any): AxiosPromise<ApiResponsePaymentProcessedTxnModel> {
            return localVarFp.singlePaymentProcessCapture(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process a single payment.
         * @param {ProcessTransactionInput} input The input details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentProcessPayment(input: ProcessTransactionInput, options?: any): AxiosPromise<ApiResponsePaymentProcessedTxnModel> {
            return localVarFp.singlePaymentProcessPayment(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process a refund.
         * @param {ProcessRefundInput} input The input details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentProcessRefund(input: ProcessRefundInput, options?: any): AxiosPromise<ApiResponsePaymentProcessedTxnModel> {
            return localVarFp.singlePaymentProcessRefund(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SinglePaymentReceiptInput} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singlePaymentSendReceipt(input: SinglePaymentReceiptInput, options?: any): AxiosPromise<void> {
            return localVarFp.singlePaymentSendReceipt(input, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SinglePaymentApi - object-oriented interface
 * @export
 * @class SinglePaymentApi
 * @extends {BaseAPI}
 */
export class SinglePaymentApi extends BaseAPI {
    /**
     * 
     * @param {CardSurchargeInput} input 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SinglePaymentApi
     */
    public singlePaymentGetCardSurchargeDetails(input: CardSurchargeInput, options?: any) {
        return SinglePaymentApiFp(this.configuration).singlePaymentGetCardSurchargeDetails(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get business address details of merchant.                In the future we could look at eliminating this method and calling /businessdetails  (BusinessDetailsController.GetBusinessDetails()) from the UX, and this would cut down on  duplication of the model, mapper and tests, however /businessdetails does not return the  CompanyIdLabels - they are returned by Utilities/GetCompanyIdLabels, so some UX changes to  cache these labels for a session may be in order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SinglePaymentApi
     */
    public singlePaymentGetMerchantAddress(options?: any) {
        return SinglePaymentApiFp(this.configuration).singlePaymentGetMerchantAddress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the receipt details for a merchant
     * @param {number} merchantId 
     * @param {string} merchantNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SinglePaymentApi
     */
    public singlePaymentGetReceiptDetails(merchantId: number, merchantNumber: string, options?: any) {
        return SinglePaymentApiFp(this.configuration).singlePaymentGetReceiptDetails(merchantId, merchantNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processed payment on a pre-auth txn
     * @param {ProcessCaptureInput} input Input details for txn Capture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SinglePaymentApi
     */
    public singlePaymentProcessCapture(input: ProcessCaptureInput, options?: any) {
        return SinglePaymentApiFp(this.configuration).singlePaymentProcessCapture(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process a single payment.
     * @param {ProcessTransactionInput} input The input details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SinglePaymentApi
     */
    public singlePaymentProcessPayment(input: ProcessTransactionInput, options?: any) {
        return SinglePaymentApiFp(this.configuration).singlePaymentProcessPayment(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process a refund.
     * @param {ProcessRefundInput} input The input details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SinglePaymentApi
     */
    public singlePaymentProcessRefund(input: ProcessRefundInput, options?: any) {
        return SinglePaymentApiFp(this.configuration).singlePaymentProcessRefund(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SinglePaymentReceiptInput} input 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SinglePaymentApi
     */
    public singlePaymentSendReceipt(input: SinglePaymentReceiptInput, options?: any) {
        return SinglePaymentApiFp(this.configuration).singlePaymentSendReceipt(input, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StationeryShopApi - axios parameter creator
 * @export
 */
export const StationeryShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Place an order for Stationery Shop
         * @param {StationeryShopOrderModel} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopCreateOrder: async (order: StationeryShopOrderModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('stationeryShopCreateOrder', 'order', order)
            const localVarPath = `/StationeryShop/CreateOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(order, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all enabled Categories for the Stationery Shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/StationeryShop/GetCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Freight Fee / postage product for Stationery Shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetFreightFee: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/StationeryShop/GetFreightFee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetOrder: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('stationeryShopGetOrder', 'orderId', orderId)
            const localVarPath = `/StationeryShop/GetOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a paginated list of orders for the requested Customer.
         * @param {PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetOrders: async (request: PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('stationeryShopGetOrders', 'request', request)
            const localVarPath = `/StationeryShop/GetOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all available products mapped to a category.
         * @param {StationeryShopProductRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetProductsByCategory: async (request: StationeryShopProductRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('stationeryShopGetProductsByCategory', 'request', request)
            const localVarPath = `/StationeryShop/GetProductsByCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get billing address plus two most recently used delivery addresses (if they\'re different) for supplied customerId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetRecentAddresses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/StationeryShop/GetRecentAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StationeryShopApi - functional programming interface
 * @export
 */
export const StationeryShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StationeryShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Place an order for Stationery Shop
         * @param {StationeryShopOrderModel} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationeryShopCreateOrder(order: StationeryShopOrderModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseStationeryShopOrderSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationeryShopCreateOrder(order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all enabled Categories for the Stationery Shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationeryShopGetCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListStationeryShopCategoryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationeryShopGetCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Freight Fee / postage product for Stationery Shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationeryShopGetFreightFee(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseStationeryShopProductModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationeryShopGetFreightFee(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationeryShopGetOrder(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseStationeryShopOrderModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationeryShopGetOrder(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a paginated list of orders for the requested Customer.
         * @param {PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationeryShopGetOrders(request: PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListStationeryShopOrderSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationeryShopGetOrders(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all available products mapped to a category.
         * @param {StationeryShopProductRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationeryShopGetProductsByCategory(request: StationeryShopProductRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListStationeryShopProductModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationeryShopGetProductsByCategory(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get billing address plus two most recently used delivery addresses (if they\'re different) for supplied customerId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationeryShopGetRecentAddresses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListStationeryShopAddressModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationeryShopGetRecentAddresses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StationeryShopApi - factory interface
 * @export
 */
export const StationeryShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StationeryShopApiFp(configuration)
    return {
        /**
         * 
         * @summary Place an order for Stationery Shop
         * @param {StationeryShopOrderModel} order 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopCreateOrder(order: StationeryShopOrderModel, options?: any): AxiosPromise<ApiResponseStationeryShopOrderSummaryModel> {
            return localVarFp.stationeryShopCreateOrder(order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all enabled Categories for the Stationery Shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetCategories(options?: any): AxiosPromise<ApiResponseListStationeryShopCategoryModel> {
            return localVarFp.stationeryShopGetCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Freight Fee / postage product for Stationery Shop
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetFreightFee(options?: any): AxiosPromise<ApiResponseStationeryShopProductModel> {
            return localVarFp.stationeryShopGetFreightFee(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetOrder(orderId: number, options?: any): AxiosPromise<ApiResponseStationeryShopOrderModel> {
            return localVarFp.stationeryShopGetOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a paginated list of orders for the requested Customer.
         * @param {PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetOrders(request: PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields, options?: any): AxiosPromise<ApiResponsePaginatedListStationeryShopOrderSummaryModel> {
            return localVarFp.stationeryShopGetOrders(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all available products mapped to a category.
         * @param {StationeryShopProductRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetProductsByCategory(request: StationeryShopProductRequest, options?: any): AxiosPromise<ApiResponseListStationeryShopProductModel> {
            return localVarFp.stationeryShopGetProductsByCategory(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get billing address plus two most recently used delivery addresses (if they\'re different) for supplied customerId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationeryShopGetRecentAddresses(options?: any): AxiosPromise<ApiResponseListStationeryShopAddressModel> {
            return localVarFp.stationeryShopGetRecentAddresses(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StationeryShopApi - object-oriented interface
 * @export
 * @class StationeryShopApi
 * @extends {BaseAPI}
 */
export class StationeryShopApi extends BaseAPI {
    /**
     * 
     * @summary Place an order for Stationery Shop
     * @param {StationeryShopOrderModel} order 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationeryShopApi
     */
    public stationeryShopCreateOrder(order: StationeryShopOrderModel, options?: any) {
        return StationeryShopApiFp(this.configuration).stationeryShopCreateOrder(order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all enabled Categories for the Stationery Shop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationeryShopApi
     */
    public stationeryShopGetCategories(options?: any) {
        return StationeryShopApiFp(this.configuration).stationeryShopGetCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Freight Fee / postage product for Stationery Shop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationeryShopApi
     */
    public stationeryShopGetFreightFee(options?: any) {
        return StationeryShopApiFp(this.configuration).stationeryShopGetFreightFee(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationeryShopApi
     */
    public stationeryShopGetOrder(orderId: number, options?: any) {
        return StationeryShopApiFp(this.configuration).stationeryShopGetOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a paginated list of orders for the requested Customer.
     * @param {PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationeryShopApi
     */
    public stationeryShopGetOrders(request: PaginateRequestStationeryShopOrderListRequestOrderSummarySortFields, options?: any) {
        return StationeryShopApiFp(this.configuration).stationeryShopGetOrders(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all available products mapped to a category.
     * @param {StationeryShopProductRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationeryShopApi
     */
    public stationeryShopGetProductsByCategory(request: StationeryShopProductRequest, options?: any) {
        return StationeryShopApiFp(this.configuration).stationeryShopGetProductsByCategory(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get billing address plus two most recently used delivery addresses (if they\'re different) for supplied customerId.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationeryShopApi
     */
    public stationeryShopGetRecentAddresses(options?: any) {
        return StationeryShopApiFp(this.configuration).stationeryShopGetRecentAddresses(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a Subscription from SubscriptionRequest and returns a SubscriptionModel
         * @param {SubscriptionRequest} request An object of type SubscriptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsAddSubscription: async (request: SubscriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('subscriptionsAddSubscription', 'request', request)
            const localVarPath = `/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels a subscription by passing through the subscription id
         * @param {number} id Subscription Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsCancelSubscription: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscriptionsCancelSubscription', 'id', id)
            const localVarPath = `/subscriptions/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a subscription\'s details as well as its associated datavaultId
         * @param {number} id SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsGetSubscription: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscriptionsGetSubscription', 'id', id)
            const localVarPath = `/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resumes a subscription
         * @param {number} id SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsResumeSubscription: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscriptionsResumeSubscription', 'id', id)
            const localVarPath = `/subscriptions/{id}/resume`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of SubscriptionSummaryModel matching the provided filters
         * @param {PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields} request A PaginatedList object of type SearchSubscriptionRequest. Request contains Subscription CRNs, Token CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSearchSubscriptions: async (request: PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('subscriptionsSearchSubscriptions', 'request', request)
            const localVarPath = `/subscriptions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSubscriptionFrequencies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/frequencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSubscriptionPaymentStatuses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/payment-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSubscriptionStatuses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suspends a subscription
         * @param {number} id SubscriptionId
         * @param {SubscriptionSuspendRequest} request details of how long to suspend for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSuspendSubscription: async (id: number, request: SubscriptionSuspendRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscriptionsSuspendSubscription', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('subscriptionsSuspendSubscription', 'request', request)
            const localVarPath = `/subscriptions/{id}/suspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Subscription from SubscriptionRequest and returns a SubscriptionModel
         * @param {number} id subscriptionId to update
         * @param {SubscriptionRequest} request An object of type SubscriptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsUpdateSubscription: async (id: number, request: SubscriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subscriptionsUpdateSubscription', 'id', id)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('subscriptionsUpdateSubscription', 'request', request)
            const localVarPath = `/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a Subscription from SubscriptionRequest and returns a SubscriptionModel
         * @param {SubscriptionRequest} request An object of type SubscriptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsAddSubscription(request: SubscriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSubscriptionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsAddSubscription(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels a subscription by passing through the subscription id
         * @param {number} id Subscription Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsCancelSubscription(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsCancelSubscription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a subscription\'s details as well as its associated datavaultId
         * @param {number} id SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsGetSubscription(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSubscriptionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsGetSubscription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resumes a subscription
         * @param {number} id SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsResumeSubscription(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsResumeSubscription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of SubscriptionSummaryModel matching the provided filters
         * @param {PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields} request A PaginatedList object of type SearchSubscriptionRequest. Request contains Subscription CRNs, Token CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsSearchSubscriptions(request: PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListSubscriptionSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsSearchSubscriptions(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsSubscriptionFrequencies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSubscriptionFrequenciesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsSubscriptionFrequencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsSubscriptionPaymentStatuses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSubscriptionPaymentStatusData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsSubscriptionPaymentStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsSubscriptionStatuses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSubscriptionStatusesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsSubscriptionStatuses(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Suspends a subscription
         * @param {number} id SubscriptionId
         * @param {SubscriptionSuspendRequest} request details of how long to suspend for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsSuspendSubscription(id: number, request: SubscriptionSuspendRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsSuspendSubscription(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a Subscription from SubscriptionRequest and returns a SubscriptionModel
         * @param {number} id subscriptionId to update
         * @param {SubscriptionRequest} request An object of type SubscriptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionsUpdateSubscription(id: number, request: SubscriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSubscriptionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsUpdateSubscription(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a Subscription from SubscriptionRequest and returns a SubscriptionModel
         * @param {SubscriptionRequest} request An object of type SubscriptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsAddSubscription(request: SubscriptionRequest, options?: any): AxiosPromise<ApiResponseSubscriptionModel> {
            return localVarFp.subscriptionsAddSubscription(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels a subscription by passing through the subscription id
         * @param {number} id Subscription Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsCancelSubscription(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionsCancelSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a subscription\'s details as well as its associated datavaultId
         * @param {number} id SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsGetSubscription(id: number, options?: any): AxiosPromise<ApiResponseSubscriptionModel> {
            return localVarFp.subscriptionsGetSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resumes a subscription
         * @param {number} id SubscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsResumeSubscription(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionsResumeSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of SubscriptionSummaryModel matching the provided filters
         * @param {PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields} request A PaginatedList object of type SearchSubscriptionRequest. Request contains Subscription CRNs, Token CRNs etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSearchSubscriptions(request: PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListSubscriptionSummaryModel> {
            return localVarFp.subscriptionsSearchSubscriptions(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSubscriptionFrequencies(options?: any): AxiosPromise<ApiResponseSubscriptionFrequenciesData> {
            return localVarFp.subscriptionsSubscriptionFrequencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSubscriptionPaymentStatuses(options?: any): AxiosPromise<ApiResponseSubscriptionPaymentStatusData> {
            return localVarFp.subscriptionsSubscriptionPaymentStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSubscriptionStatuses(options?: any): AxiosPromise<ApiResponseSubscriptionStatusesData> {
            return localVarFp.subscriptionsSubscriptionStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Suspends a subscription
         * @param {number} id SubscriptionId
         * @param {SubscriptionSuspendRequest} request details of how long to suspend for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsSuspendSubscription(id: number, request: SubscriptionSuspendRequest, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionsSuspendSubscription(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Subscription from SubscriptionRequest and returns a SubscriptionModel
         * @param {number} id subscriptionId to update
         * @param {SubscriptionRequest} request An object of type SubscriptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionsUpdateSubscription(id: number, request: SubscriptionRequest, options?: any): AxiosPromise<ApiResponseSubscriptionModel> {
            return localVarFp.subscriptionsUpdateSubscription(id, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Creates a Subscription from SubscriptionRequest and returns a SubscriptionModel
     * @param {SubscriptionRequest} request An object of type SubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsAddSubscription(request: SubscriptionRequest, options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsAddSubscription(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels a subscription by passing through the subscription id
     * @param {number} id Subscription Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsCancelSubscription(id: number, options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsCancelSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a subscription\'s details as well as its associated datavaultId
     * @param {number} id SubscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsGetSubscription(id: number, options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsGetSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resumes a subscription
     * @param {number} id SubscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsResumeSubscription(id: number, options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsResumeSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of SubscriptionSummaryModel matching the provided filters
     * @param {PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields} request A PaginatedList object of type SearchSubscriptionRequest. Request contains Subscription CRNs, Token CRNs etc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsSearchSubscriptions(request: PaginateRequestSearchSubscriptionRequestSearchSubscriptionSortFields, options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsSearchSubscriptions(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsSubscriptionFrequencies(options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsSubscriptionFrequencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsSubscriptionPaymentStatuses(options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsSubscriptionPaymentStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsSubscriptionStatuses(options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsSubscriptionStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Suspends a subscription
     * @param {number} id SubscriptionId
     * @param {SubscriptionSuspendRequest} request details of how long to suspend for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsSuspendSubscription(id: number, request: SubscriptionSuspendRequest, options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsSuspendSubscription(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Subscription from SubscriptionRequest and returns a SubscriptionModel
     * @param {number} id subscriptionId to update
     * @param {SubscriptionRequest} request An object of type SubscriptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public subscriptionsUpdateSubscription(id: number, request: SubscriptionRequest, options?: any) {
        return SubscriptionsApiFp(this.configuration).subscriptionsUpdateSubscription(id, request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TelemetryApi - axios parameter creator
 * @export
 */
export const TelemetryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ClientsideErrorModel} clientsideError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telemetryStoreExternalErrorLog: async (clientsideError: ClientsideErrorModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientsideError' is not null or undefined
            assertParamExists('telemetryStoreExternalErrorLog', 'clientsideError', clientsideError)
            const localVarPath = `/telemetry/error-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientsideError, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TelemetryApi - functional programming interface
 * @export
 */
export const TelemetryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TelemetryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ClientsideErrorModel} clientsideError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async telemetryStoreExternalErrorLog(clientsideError: ClientsideErrorModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.telemetryStoreExternalErrorLog(clientsideError, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TelemetryApi - factory interface
 * @export
 */
export const TelemetryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TelemetryApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientsideErrorModel} clientsideError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telemetryStoreExternalErrorLog(clientsideError: ClientsideErrorModel, options?: any): AxiosPromise<void> {
            return localVarFp.telemetryStoreExternalErrorLog(clientsideError, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TelemetryApi - object-oriented interface
 * @export
 * @class TelemetryApi
 * @extends {BaseAPI}
 */
export class TelemetryApi extends BaseAPI {
    /**
     * 
     * @param {ClientsideErrorModel} clientsideError 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelemetryApi
     */
    public telemetryStoreExternalErrorLog(clientsideError: ClientsideErrorModel, options?: any) {
        return TelemetryApiFp(this.configuration).telemetryStoreExternalErrorLog(clientsideError, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionsSearchApi - axios parameter creator
 * @export
 */
export const TransactionsSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DownloadTransactionsReportInput} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchDownloadTransactionsReport: async (request: DownloadTransactionsReportInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('transactionsSearchDownloadTransactionsReport', 'request', request)
            const localVarPath = `/TransactionsSearch/DownloadTransactionsReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetCardTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TransactionsSearch/GetCardTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve detailed data for a specified transaction.
         * @param {number} detailTxnId The required transaction
         * @param {boolean} [isExternal] Allows searching in external db. Used by Foxtel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactionDetails: async (detailTxnId: number, isExternal?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'detailTxnId' is not null or undefined
            assertParamExists('transactionsSearchGetTransactionDetails', 'detailTxnId', detailTxnId)
            const localVarPath = `/TransactionsSearch/GetTransactionDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (detailTxnId !== undefined) {
                localVarQueryParameter['detailTxnId'] = detailTxnId;
            }

            if (isExternal !== undefined) {
                localVarQueryParameter['isExternal'] = isExternal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactionSources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TransactionsSearch/GetTransactionSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of \"TransactionTypes\" (TxnFinancialType)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactionTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TransactionsSearch/GetTransactionTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transaction search method. Take TransactionsSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a TransactionSummaryModel and paginated
         * @param {PaginateRequestSearchTransactionsInputTransactionSummarySortFields} pagedRequest PaginateRequest of SearchTransactionsInput and TransactionSummarySortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactions: async (pagedRequest: PaginateRequestSearchTransactionsInputTransactionSummarySortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pagedRequest' is not null or undefined
            assertParamExists('transactionsSearchGetTransactions', 'pagedRequest', pagedRequest)
            const localVarPath = `/TransactionsSearch/GetTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnSources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TransactionsSearch/GetTxnSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnSubSources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TransactionsSearch/GetTxnSubSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnSubTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TransactionsSearch/GetTxnSubTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TransactionsSearch/GetTxnTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsSearchApi - functional programming interface
 * @export
 */
export const TransactionsSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DownloadTransactionsReportInput} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchDownloadTransactionsReport(request: DownloadTransactionsReportInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchDownloadTransactionsReport(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetCardTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCardTypesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetCardTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve detailed data for a specified transaction.
         * @param {number} detailTxnId The required transaction
         * @param {boolean} [isExternal] Allows searching in external db. Used by Foxtel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTransactionDetails(detailTxnId: number, isExternal?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseTransactionDetailModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTransactionDetails(detailTxnId, isExternal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTransactionSources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseTransactionSourcesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTransactionSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a list of \"TransactionTypes\" (TxnFinancialType)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTransactionTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseTransactionTypesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTransactionTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transaction search method. Take TransactionsSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a TransactionSummaryModel and paginated
         * @param {PaginateRequestSearchTransactionsInputTransactionSummarySortFields} pagedRequest PaginateRequest of SearchTransactionsInput and TransactionSummarySortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTransactions(pagedRequest: PaginateRequestSearchTransactionsInputTransactionSummarySortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListTransactionSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTransactions(pagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTxnSources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseKeyDescriptionListTxnSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTxnSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTxnSubSources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseKeyDescriptionListTxnSubSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTxnSubSources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTxnSubTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseKeyDescriptionListTxnSubType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTxnSubTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsSearchGetTxnTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseKeyDescriptionListTxnType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsSearchGetTxnTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionsSearchApi - factory interface
 * @export
 */
export const TransactionsSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsSearchApiFp(configuration)
    return {
        /**
         * 
         * @param {DownloadTransactionsReportInput} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchDownloadTransactionsReport(request: DownloadTransactionsReportInput, options?: any): AxiosPromise<object> {
            return localVarFp.transactionsSearchDownloadTransactionsReport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetCardTypes(options?: any): AxiosPromise<ApiResponseCardTypesData> {
            return localVarFp.transactionsSearchGetCardTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve detailed data for a specified transaction.
         * @param {number} detailTxnId The required transaction
         * @param {boolean} [isExternal] Allows searching in external db. Used by Foxtel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactionDetails(detailTxnId: number, isExternal?: boolean, options?: any): AxiosPromise<ApiResponseTransactionDetailModel> {
            return localVarFp.transactionsSearchGetTransactionDetails(detailTxnId, isExternal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactionSources(options?: any): AxiosPromise<ApiResponseTransactionSourcesData> {
            return localVarFp.transactionsSearchGetTransactionSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of \"TransactionTypes\" (TxnFinancialType)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactionTypes(options?: any): AxiosPromise<ApiResponseTransactionTypesData> {
            return localVarFp.transactionsSearchGetTransactionTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transaction search method. Take TransactionsSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a TransactionSummaryModel and paginated
         * @param {PaginateRequestSearchTransactionsInputTransactionSummarySortFields} pagedRequest PaginateRequest of SearchTransactionsInput and TransactionSummarySortFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTransactions(pagedRequest: PaginateRequestSearchTransactionsInputTransactionSummarySortFields, options?: any): AxiosPromise<ApiResponsePaginatedListTransactionSummaryModel> {
            return localVarFp.transactionsSearchGetTransactions(pagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnSources(options?: any): AxiosPromise<ApiResponseKeyDescriptionListTxnSource> {
            return localVarFp.transactionsSearchGetTxnSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnSubSources(options?: any): AxiosPromise<ApiResponseKeyDescriptionListTxnSubSource> {
            return localVarFp.transactionsSearchGetTxnSubSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnSubTypes(options?: any): AxiosPromise<ApiResponseKeyDescriptionListTxnSubType> {
            return localVarFp.transactionsSearchGetTxnSubTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsSearchGetTxnTypes(options?: any): AxiosPromise<ApiResponseKeyDescriptionListTxnType> {
            return localVarFp.transactionsSearchGetTxnTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsSearchApi - object-oriented interface
 * @export
 * @class TransactionsSearchApi
 * @extends {BaseAPI}
 */
export class TransactionsSearchApi extends BaseAPI {
    /**
     * 
     * @param {DownloadTransactionsReportInput} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchDownloadTransactionsReport(request: DownloadTransactionsReportInput, options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchDownloadTransactionsReport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetCardTypes(options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetCardTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve detailed data for a specified transaction.
     * @param {number} detailTxnId The required transaction
     * @param {boolean} [isExternal] Allows searching in external db. Used by Foxtel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTransactionDetails(detailTxnId: number, isExternal?: boolean, options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTransactionDetails(detailTxnId, isExternal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTransactionSources(options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTransactionSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of \"TransactionTypes\" (TxnFinancialType)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTransactionTypes(options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTransactionTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transaction search method. Take TransactionsSearchModel object and retrieve at most 1000 records which match filter conditions.  Results stripped back to a TransactionSummaryModel and paginated
     * @param {PaginateRequestSearchTransactionsInputTransactionSummarySortFields} pagedRequest PaginateRequest of SearchTransactionsInput and TransactionSummarySortFields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTransactions(pagedRequest: PaginateRequestSearchTransactionsInputTransactionSummarySortFields, options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTransactions(pagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTxnSources(options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTxnSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTxnSubSources(options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTxnSubSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTxnSubTypes(options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTxnSubTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsSearchApi
     */
    public transactionsSearchGetTxnTypes(options?: any) {
        return TransactionsSearchApiFp(this.configuration).transactionsSearchGetTxnTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserProfileApi - axios parameter creator
 * @export
 */
export const UserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Takes supplied values and passes to backend for updating a user\'s profile.
         * @param {UpdateUserProfileRequest} updateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileUpdateUserProfile: async (updateRequest: UpdateUserProfileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRequest' is not null or undefined
            assertParamExists('userProfileUpdateUserProfile', 'updateRequest', updateRequest)
            const localVarPath = `/UserProfile/UpdateUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileApi - functional programming interface
 * @export
 */
export const UserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Takes supplied values and passes to backend for updating a user\'s profile.
         * @param {UpdateUserProfileRequest} updateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfileUpdateUserProfile(updateRequest: UpdateUserProfileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUserProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfileUpdateUserProfile(updateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProfileApi - factory interface
 * @export
 */
export const UserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Takes supplied values and passes to backend for updating a user\'s profile.
         * @param {UpdateUserProfileRequest} updateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileUpdateUserProfile(updateRequest: UpdateUserProfileRequest, options?: any): AxiosPromise<ApiResponseUserProfileModel> {
            return localVarFp.userProfileUpdateUserProfile(updateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
export class UserProfileApi extends BaseAPI {
    /**
     * 
     * @summary Takes supplied values and passes to backend for updating a user\'s profile.
     * @param {UpdateUserProfileRequest} updateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public userProfileUpdateUserProfile(updateRequest: UpdateUserProfileRequest, options?: any) {
        return UserProfileApiFp(this.configuration).userProfileUpdateUserProfile(updateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAddUser: async (request: AddUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersAddUser', 'request', request)
            const localVarPath = `/Users/AddUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUser: async (request: DeleteUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersDeleteUser', 'request', request)
            const localVarPath = `/Users/DeleteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve details for a user
         * @param {GetUserRequest} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUser: async (request: GetUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersGetUser', 'request', request)
            const localVarPath = `/Users/GetUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserRoles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/GetUserRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of Users matching the provided filters
         * @param {PaginateRequestGetUsersRequestUserModelSortFields} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUsers: async (request: PaginateRequestGetUsersRequestUserModelSortFields, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersGetUsers', 'request', request)
            const localVarPath = `/Users/GetUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetUserPasswordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetPassword: async (request: ResetUserPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersResetPassword', 'request', request)
            const localVarPath = `/Users/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUser: async (request: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersUpdateUser', 'request', request)
            const localVarPath = `/Users/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAddUser(request: AddUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAddUser(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteUser(request: DeleteUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUser(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve details for a user
         * @param {GetUserRequest} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetUser(request: GetUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseUserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUser(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetUserRoles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUserRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of Users matching the provided filters
         * @param {PaginateRequestGetUsersRequestUserModelSortFields} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetUsers(request: PaginateRequestGetUsersRequestUserModelSortFields, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePaginatedListUserSummaryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUsers(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetUserPasswordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersResetPassword(request: ResetUserPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersResetPassword(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUser(request: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUser(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {AddUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAddUser(request: AddUserRequest, options?: any): AxiosPromise<ApiResponseUserModel> {
            return localVarFp.usersAddUser(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUser(request: DeleteUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersDeleteUser(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve details for a user
         * @param {GetUserRequest} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUser(request: GetUserRequest, options?: any): AxiosPromise<ApiResponseUserModel> {
            return localVarFp.usersGetUser(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserRoles(options?: any): AxiosPromise<ApiResponseGetUserRolesResponse> {
            return localVarFp.usersGetUserRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of Users matching the provided filters
         * @param {PaginateRequestGetUsersRequestUserModelSortFields} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUsers(request: PaginateRequestGetUsersRequestUserModelSortFields, options?: any): AxiosPromise<ApiResponsePaginatedListUserSummaryModel> {
            return localVarFp.usersGetUsers(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetUserPasswordRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetPassword(request: ResetUserPasswordRequest, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.usersResetPassword(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUser(request: UpdateUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersUpdateUser(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {AddUserRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAddUser(request: AddUserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersAddUser(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUserRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDeleteUser(request: DeleteUserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersDeleteUser(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve details for a user
     * @param {GetUserRequest} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetUser(request: GetUserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersGetUser(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetUserRoles(options?: any) {
        return UsersApiFp(this.configuration).usersGetUserRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of Users matching the provided filters
     * @param {PaginateRequestGetUsersRequestUserModelSortFields} request A PaginatedList object of type GetUsersRequest. Request contains Name, Email Address, Phone number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetUsers(request: PaginateRequestGetUsersRequestUserModelSortFields, options?: any) {
        return UsersApiFp(this.configuration).usersGetUsers(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetUserPasswordRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersResetPassword(request: ResetUserPasswordRequest, options?: any) {
        return UsersApiFp(this.configuration).usersResetPassword(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUser(request: UpdateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersUpdateUser(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetBillerActions: async (merchantNumber: string, billerCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantNumber' is not null or undefined
            assertParamExists('utilitiesGetBillerActions', 'merchantNumber', merchantNumber)
            // verify required parameter 'billerCode' is not null or undefined
            assertParamExists('utilitiesGetBillerActions', 'billerCode', billerCode)
            const localVarPath = `/Utilities/GetBillerActions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (merchantNumber !== undefined) {
                localVarQueryParameter['merchantNumber'] = merchantNumber;
            }

            if (billerCode !== undefined) {
                localVarQueryParameter['billerCode'] = billerCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the merchant\'s biller configurations.
         * @param {string} [inputChildMerchantNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetBillers: async (inputChildMerchantNumber?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetBillers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (inputChildMerchantNumber !== undefined) {
                localVarQueryParameter['input.childMerchantNumber'] = inputChildMerchantNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the child merchant configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetChildMerchants: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetChildMerchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCompanyIdLabels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetCompanyIdLabels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetCountries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [countryCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCountryStates: async (countryCode?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetCountryStates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCurrencies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetCurrencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetOrderTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetOrderTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the platform configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetPlatformConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetPlatformConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Process Transaction order types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetProcessTransactionOrderTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Utilities/GetProcessTransactionOrderTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetBillerActions(merchantNumber: string, billerCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListBillerActionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetBillerActions(merchantNumber, billerCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the merchant\'s biller configurations.
         * @param {string} [inputChildMerchantNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetBillers(inputChildMerchantNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListBillerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetBillers(inputChildMerchantNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the child merchant configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetChildMerchants(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListMerchantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetChildMerchants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetCompanyIdLabels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCompanyIdLabels>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetCompanyIdLabels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListCountryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [countryCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetCountryStates(countryCode?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListCountryStateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetCountryStates(countryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetCurrencies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListCurrencyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetCurrencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetOrderTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOrderTypesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetOrderTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the platform configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetPlatformConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseClientPlatformConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetPlatformConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets Process Transaction order types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilitiesGetProcessTransactionOrderTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseOrderTypesData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilitiesGetProcessTransactionOrderTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} merchantNumber 
         * @param {string} billerCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetBillerActions(merchantNumber: string, billerCode: string, options?: any): AxiosPromise<ApiResponseListBillerActionModel> {
            return localVarFp.utilitiesGetBillerActions(merchantNumber, billerCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the merchant\'s biller configurations.
         * @param {string} [inputChildMerchantNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetBillers(inputChildMerchantNumber?: string, options?: any): AxiosPromise<ApiResponseListBillerModel> {
            return localVarFp.utilitiesGetBillers(inputChildMerchantNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the child merchant configurations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetChildMerchants(options?: any): AxiosPromise<ApiResponseListMerchantModel> {
            return localVarFp.utilitiesGetChildMerchants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCompanyIdLabels(options?: any): AxiosPromise<ApiResponseCompanyIdLabels> {
            return localVarFp.utilitiesGetCompanyIdLabels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCountries(options?: any): AxiosPromise<ApiResponseListCountryModel> {
            return localVarFp.utilitiesGetCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [countryCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCountryStates(countryCode?: number, options?: any): AxiosPromise<ApiResponseListCountryStateModel> {
            return localVarFp.utilitiesGetCountryStates(countryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetCurrencies(options?: any): AxiosPromise<ApiResponseListCurrencyModel> {
            return localVarFp.utilitiesGetCurrencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetOrderTypes(options?: any): AxiosPromise<ApiResponseOrderTypesData> {
            return localVarFp.utilitiesGetOrderTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the platform configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetPlatformConfiguration(options?: any): AxiosPromise<ApiResponseClientPlatformConfiguration> {
            return localVarFp.utilitiesGetPlatformConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Process Transaction order types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilitiesGetProcessTransactionOrderTypes(options?: any): AxiosPromise<ApiResponseOrderTypesData> {
            return localVarFp.utilitiesGetProcessTransactionOrderTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI {
    /**
     * 
     * @param {string} merchantNumber 
     * @param {string} billerCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetBillerActions(merchantNumber: string, billerCode: string, options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetBillerActions(merchantNumber, billerCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the merchant\'s biller configurations.
     * @param {string} [inputChildMerchantNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetBillers(inputChildMerchantNumber?: string, options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetBillers(inputChildMerchantNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the child merchant configurations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetChildMerchants(options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetChildMerchants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetCompanyIdLabels(options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetCompanyIdLabels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetCountries(options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [countryCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetCountryStates(countryCode?: number, options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetCountryStates(countryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetCurrencies(options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetCurrencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetOrderTypes(options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetOrderTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the platform configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetPlatformConfiguration(options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetPlatformConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Process Transaction order types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public utilitiesGetProcessTransactionOrderTypes(options?: any) {
        return UtilitiesApiFp(this.configuration).utilitiesGetProcessTransactionOrderTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


