import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Tooltip, TooltipTrigger } from '@premier/ui';
import { getCardInfo } from '@premier/cards';
import InputField from './InputField';

import cvnImage from 'images/cvn.png';
import cvnAmexImage from 'images/cvn_amex.png';
import './CardVerificationField.scss';

const defaultCardInfo = getCardInfo();


/** CVN / CCV / CVV InputField + its "Where to find" Tooltip */
const CardVerificationField = props => {
    const { label, name, maxLength, ...otherProps } = props;

    return (
        <>
            <InputField
                name={name}
                digitsOnly
                noSpinner
                label={label}
                maxLength={maxLength || defaultCardInfo.cvcLength}
                help={
                    <TooltipTrigger tipId='tip-cvn'>
                        <Icon question />
                    </TooltipTrigger>
                }
                {...otherProps}
            />

            <Tooltip id='tip-cvn'>
                <h3 className='sr-only'>{`Tooltip: Where can I find my ${label}?`}</h3>

                <div className='grid-container'>
                    <h4>Mastercard, VISA, JCB and Diners Club</h4>
                    <div>3 digits located on the back of your card.</div>
                    <div><img src={cvnImage} alt='CVN location' aria-hidden /></div>

                    <h4>American Express</h4>
                    <div>4 digits located on the front of your card.</div>
                    <div><img src={cvnAmexImage} alt='Amex CVN location' aria-hidden /></div>
                </div>
            </Tooltip>
        </>
    );
};

CardVerificationField.propTypes = {
    // Required
    name: PropTypes.string.isRequired,

    // Optional
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxLength: PropTypes.number
};

export default CardVerificationField;
