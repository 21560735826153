import { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import _ from "lodash";

// @ts-ignore
import { PaymentTypeKey } from "constants/billpay";

import * as billpayUtil from "@premier/utils/billpay";
import { RadioInput, CardLogo, Divider, ErrorMessage } from "@premier/ui";
import { FormContext, validate, Form, CardInputForm, CheckboxField } from "@premier/form";
import { PaymentDetailsForms, PaymentTypeInputForm } from "components/Transactions";
import { useLocation } from "react-router-dom";
import { PlatformRoutesConfiguration } from "components/Routing";

import "./PaymentInputSelectorForm.scss";
import { Token } from "packages/utils/models/Customer";

export const RADIO_VALUE_NEW = "new";

type Props = {
    /** The name of the sub-form. Default = 'paymentInputSelector' */
    name?: string;
    /** Renders as H2. Leave as undefined/null for automatic label */
    label: string;
    className?: string;
    /** Existing payment methods to choose from */
    paymentMethods: Token[] | undefined;
    /** Allows for bank account / card selector to be shown, instead of just cards. */
    allowBankAccount?: boolean;
    required: boolean;
    onTabChange: (tabKey: string | null) => void;
}

const PaymentInputSelectorForm = ({
    name = "paymentInputSelector", label, className, paymentMethods, //data
    allowBankAccount, required, //logic renders
    onTabChange //function
}: Props) => {
    const isPaymentFromPaymentMethodPage = useLocation().pathname.startsWith(PlatformRoutesConfiguration.tokenRoute!.manageTokens.path);
    const parentContext = useContext(FormContext);

    const anyExistingTokens = paymentMethods && paymentMethods.length > 0;

    const [showNewPaymentForm, setShowNewPaymentForm] = useState(!anyExistingTokens);
    const [id] = useState(_.uniqueId(`${name}_`));

    const radioGroupName = `${id}_radio`;
    const radioFieldName = "radio";

    useEffect(() => {
        parentContext.setValidation(
            `${name}.radio`,
            required && validate().required("Please select a payment method")
        );
    }, [required]);

    function renderLabel() {
        if (label != null)
            return label;

        return anyExistingTokens ? "Select payment method" : "Add payment method";
    }

    function renderExistingTokens(context: any) {
        if (!anyExistingTokens)
            return null;

        return paymentMethods.map(token => {
            const radioId = `${radioGroupName}_${token.token}`;

            return (
                <tr key={token.token}>
                    <td className="radio">
                        <RadioInput
                            id={radioId}
                            name={radioGroupName}
                            value={token.token.toString()}
                            checked={context.values[radioFieldName] === token.token.toString()}
                            onChange={(e) => handleRadioClick(e.target.value, context)}
                        >&nbsp;</RadioInput>
                    </td>
                    <td>
                        <label htmlFor={radioId}>
                            {token.type === PaymentTypeKey.CARD
                                ? <CardLogo network={{ serverCode: token.cardTypeCode }} />
                                : "Bank account"
                            }
                        </label>
                    </td>
                    <td>
                        {token.type === PaymentTypeKey.CARD
                            ? token.maskedCardNumber
                            : `${token.deBsbNumber} - ${token.deAccountNumber}`
                        }
                    </td>
                    <td>
                        {token.type === PaymentTypeKey.CARD && (
                            billpayUtil.formatExpiry(token.expiryDate)
                        )}
                    </td>
                </tr>
            );
        });
    }

    function handleRadioClick(val: string, context: any) {
        context.setValue(radioFieldName, val);

        setTimeout(() => {
            setShowNewPaymentForm(val === RADIO_VALUE_NEW);
        }, 150);  // child form bug workaround: Show child form only after the parent form values finished updating
    }

    return (
        <div className={classNames("payment-input-selector", className)}>
            <Form
                name={name}
                initialValues={{
                    radio: anyExistingTokens ? null : RADIO_VALUE_NEW,
                    saveNewToken: true
                }}
                render={context => {
                    return (<>
                        <h2>{renderLabel()}</h2>

                        {/* pull out onChange of tab to this, pass back up to modal */}
                        <PaymentDetailsForms
                            onTabChange={onTabChange}
                            customPaymentForm={<>
                                {anyExistingTokens && (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Account type</th>
                                                <th>Card/bank account</th>
                                                <th>Expiry date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderExistingTokens(context)}
                                            {!isPaymentFromPaymentMethodPage && (
                                                <tr>
                                                    <td className="radio">
                                                        <RadioInput
                                                            id={`${radioGroupName}_new`}
                                                            name={radioGroupName}
                                                            value={RADIO_VALUE_NEW}
                                                            checked={context.values[radioFieldName] === RADIO_VALUE_NEW}
                                                            onChange={(e) => handleRadioClick(e.target.value, context)}
                                                        >&nbsp;</RadioInput>
                                                    </td>
                                                    <td colSpan={3}>
                                                        <label htmlFor={`${radioGroupName}_new`}>New payment method</label>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>
                                )}

                                {showNewPaymentForm && (
                                    <div className={classNames("new-token-container", {
                                        "standalone": !anyExistingTokens,
                                        "grey": anyExistingTokens ? !(paymentMethods.length % 2) : false
                                    })}>
                                        {allowBankAccount
                                            ? <PaymentTypeInputForm />
                                            : <CardInputForm required={required} disableCvn={parentContext?.getValue("orderType") === "MAIL_FAX" || parentContext?.getValue("orderType") === "RECURRING"} />
                                        }

                                        <Divider />
                                        <CheckboxField name="saveNewToken">Save payment method</CheckboxField>
                                        <Divider />
                                    </div>
                                )}
                            </>}
                        />

                        {anyExistingTokens && (
                            <div className="selector-group">  {/* This class is to render ErrorMessage correctly */}
                                <ErrorMessage error={context.getError(radioFieldName)} />
                            </div>
                        )}
                    </>);
                }}
            />
        </div>
    );
};

export default PaymentInputSelectorForm;