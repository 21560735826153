import moment from 'moment-timezone';
import timezones from './timezones';

const DEFAULT_DOTNET_DATE_FORMAT_FOR_DATEPICKER : string = 'd/MM/yyyy';
const DEFAULT_DOTNET_TIME_FORMAT_FOR_DATEPICKER : string = 'HH:mm';

export class DateUtil {
    dateFormat: string;
    timeFormat: string;
    datePlaceholder: string;
    timePlaceholder: string;
    timeZone: string;
    constructor() {
        this.dateFormat = 'D MMM YYYY';
        this.timeFormat = 'hh:mm A';
        this.datePlaceholder = 'dd Mon yyyy';
        this.timePlaceholder = 'hh:mm AM/PM';
        this.timeZone = 'Australia/Melbourne';

    }
    //TODO using local time instead of merchant's timezone. Should be ok most of the time, but probably should fix.

    //#region ----- UI to API -----


    /**
     * Converts user selected 'local' date to a moment with the equivalent date & time in the merchant's timezone
     */
    localToMerchantTimeZone(userDateUtc: Date) {
        if(!userDateUtc) return userDateUtc;
        var selectedTime = moment.utc(userDateUtc).local().format('YYYY-MM-DD HH:mm:ss.SSS');
        return moment.tz(selectedTime, this.timeZone);
    }

    toUtcApiDateTime(datetime: Date | moment.Moment) {
        if(!datetime) return datetime;
        return moment.utc(datetime).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    /** Converts user-selected date/time to API-format.
      * ie. Take a user-input date/time (eg. user in Perth selected 1/1/2000 00:00) which we would get as a Date object ('1999-12-31T16:00:00.000Z'),
      * convert it back to local time to get what the user actually selected (2000-01-01 00:00:00),
      * then set the timezone to Merchant timezone (eg. 2000-01-01 00:00 +10),
      * then convert it UTC to be sent to the API (eg. '1999-12-31T14:00:00.000Z')
      * @param {Date} userDateUtc The Date object of what the user selected, eg. '1999-12-31T13:00:00.000Z' for 1 Jan 2000
      * @return {string} The UTC value to be sent to the server, eg. '1999-12-31 14:00:00.000'
      */
    convertToApiValue(userDateUtc: Date) {
        return this.toUtcApiDateTime(this.localToMerchantTimeZone(userDateUtc));
    }

    convertDateRangeToApiDateTimeRange(dates: Date[]) {
        if (!Array.isArray(dates))
            throw new Error("dates must be an array")

        // input is a date only (no time)
        // so to date needs to be shifted forwards 1 day
        var toDate = new Date(dates[dates.length - 1]);
        toDate.setDate(toDate.getDate() + 1)

        return {
            from: this.convertToApiValue(dates[0]),
            to: this.convertToApiValue(toDate),
        }
    }

    /** Takes a user input date (eg. user selected 1 Jan 2000) which we would get as a Date object (eg. '1999-12-31T13:00:00.000Z'),
      * convert it back to local time, strip out the time and send the date only to the server.
      * @param {Date} userDateUtc The Date object of what the user selected, eg. '1999-12-31T13:00:00.000Z' for 1 Jan 2000
      * @return {string} The date string to be sent to the server, eg. '2000-01-01'
      */
    convertDateToApiValue(userDateUtc: Date | number) {
        return userDateUtc && moment.utc(userDateUtc).local().format('YYYY-MM-DD');
    }

    /**
     * Takes a user input date object, and formats it to the API required format. No timezone shenanigans
     * @param {Date} userDate  e.g. Thu Oct 01 2020 00:00:00 GMT+1000 (Australian Eastern Standard Time)
     * @return {string} The date string to be sent to the server, e.g. '2020-10-01'
     */
    convertDateToApiDate(userDate: Date | number) {
        return userDate && moment(userDate).local().format('YYYY-MM-DD');
    }

    nowToApiValue() {
        return moment.utc().format();
    }

    //#endregion

    //#region ----- API to UI -----

    // private
    convertUtcToMerchantTime(utcValue: string | Date, format: string) {
        var dateValue = utcValue && moment.tz(utcValue, "UTC"); //Create a moment utc date object
        return dateValue && dateValue.clone().tz(this.timeZone).format(format);
    }

    /** Converts API format (eg. '2018-12-05 22:00:00' assuming it is UTC) into the merchant timezone & format (eg. '6 Dec 2018') */
    convertToDateString(apiValue: string | Date) {
        return this.convertUtcToMerchantTime(apiValue, this.dateFormat);
    }

    /** Converts API format (eg. '2018-12-05 22:00:00' assuming it is UTC) into the merchant timezone & format (eg. '6:00 AM') */
    convertToTimeString(apiValue: string | Date) {
        return this.convertUtcToMerchantTime(apiValue, this.timeFormat);
    }

    /** Converts API format (eg. '2018-12-05 22:00:00' assuming it is UTC) into the merchant timezone & format (eg. '6 Dec 2018 6:00 AM') */
    convertToDateTimeString(apiValue: string | Date) {
        return this.convertUtcToMerchantTime(apiValue, this.dateFormat + ' ' + this.timeFormat);
    }

    /** Converts API format (eg. '2018-12-05 22:00:00' assuming it is UTC) into the merchant timezone & format (eg. '6 Dec 2018 6:00 AM') */
    convertToMerchantDate(apiValue: string | Date) {
        var dateValue = apiValue && moment.tz(apiValue, "UTC"); //Create a moment utc date object
        return dateValue && new Date(dateValue.clone().tz(this.timeZone).format('YYYY-MM-DDTHH:mm:ss.SSS')); // remove the merchant timezone
    }

    /* Format a datetime to date and time string, no conversion to UTC or merchant's timezone */
    formatToDateTimeString(localApiValue: string | Date) {
        return moment(localApiValue).format(this.dateFormat + ' ' + this.timeFormat);
    }

    /* Format a datetime to date string, no conversion to UTC or merchant's timezone */
    formatToDateString(localApiValue: string | Date) {
        return moment(localApiValue).format(this.dateFormat);
    }
    //#endregion

    /** Get Now in Merchant's timezone, return un-formatted moment */
    getMerchantNow() {
        return moment.utc().clone().tz(this.timeZone);
    }
    /** Get the merchant's now time, but remove the timezone info (for date pickers that treat dates as local) */
    getMerchantNowAsLocal() {
        var timeStr = this.getMerchantNow().format('YYYY-MM-DD HH:mm:ss.SSS'); // remove the merchant timezone
        return moment(timeStr);
    }

    /** Returns the end of minute of a given Date object only if the minute does not end with 0.
      * This is for the end time of a range (eg. 16:59 becomes 16:59:59.999, while 17:00 stays as it is)
      * @param datetime A Date object
      */
    roundEndOfMinute(datetime: Date) {
        if(!datetime) return datetime;
        if(datetime.getMinutes() % 10 === 0) return datetime;

        return moment(datetime).endOf('minute').toDate();
    }

    dotnetToMomentFormat(dotnetFormat: string) {
        if(!dotnetFormat)
            return dotnetFormat;

        const DIFFERENCES = [ // This list must not contain any overlapping tokens (eg. d vs ddd)
            //.NET: Moment
            {dotnet: 'f', moment: 'S' },
            {dotnet: 'ff', moment: 'SS' },
            {dotnet: 'fff', moment: 'SSS' },
            {dotnet: 'y', moment: 'YY' },
            {dotnet: 'yy', moment: 'YY' },
            {dotnet: 'yyyy', moment: 'YYYY' },
            {dotnet: 'zzz', moment: 'Z' },
            {dotnet: 't', moment: 'a'}, // A -> am (no equivalent)
            {dotnet: 'tt', moment: 'A' },
        ];

        var format = dotnetFormat;

        // Overlapping ones

        // Replace d / dd but not ddd / dddd - Method 1 (There is no uppercase D in .NET format so this is safe)
        format = format.replace(/d/g,'D');
        format = format.replace('DDDD','dddd');
        format = format.replace('DDD','ddd');

        // Replace d / dd but not ddd / dddd - Method 2 (only do this after all browsers support lookbehind)
        //format = format.replace(/(?<!d)dd(?!d)/, 'DD');
        //format = format.replace(/(?<!d)d(?!d)/, 'D');


        // Non-overlapping ones
        const dotnetTokens = DIFFERENCES.map(d => d.dotnet).sort((a,b) => b.length - a.length); // sort by length DESC
        dotnetTokens.forEach(t => {
            format = format.replace(t, DIFFERENCES.find(d => d.dotnet === t)?.moment ?? t);
        })

        return format;
    }

    getTzFromWindows(windowsDesc: string) {
        var timezone = timezones.find(t => t.windows === windowsDesc);
        return timezone && timezone.tz;
    }

    getDateFormat(dotnetDateFormat?: string) {
        return (dotnetDateFormat && dateUtil.dotnetToMomentFormat(dotnetDateFormat)) || dateUtil.dateFormat || dateUtil.dotnetToMomentFormat(DEFAULT_DOTNET_DATE_FORMAT_FOR_DATEPICKER);
    }

    getTimeFormat(dotnetTimeFormat?: string) {
        return (dotnetTimeFormat && dateUtil.dotnetToMomentFormat(dotnetTimeFormat)) || dateUtil.timeFormat || dateUtil.dotnetToMomentFormat(DEFAULT_DOTNET_TIME_FORMAT_FOR_DATEPICKER);
    }
}

const dateUtil = new DateUtil();
export default dateUtil;

//exporting as its own method allows for a run-once global configuration.
/** Set a global date/time format + timezone. If any of these is invalid, it won't be updated.
  * @param dateFormat Merchant's .NET date format, eg. 'dd/MM/yyyy'
  * @param timeFormat Merchant's .NET time format, eg. 'HH:mm'
  * @param timeZone Merchant's .NET/Windows timezone description, eg. 'AUS Eastern Standard Time' (not tz/IANA names that moment uses)
  */
export const setDateTimeFormat = (dateFormat?: string, timeFormat?: string, datePlaceholder?: string, timePlaceholder?: string, timeZone?: string) => {
    dateUtil.dateFormat = (dateFormat && dateUtil.dotnetToMomentFormat(dateFormat)) || dateUtil.dateFormat;
    dateUtil.timeFormat = (timeFormat && dateUtil.dotnetToMomentFormat(timeFormat)) || dateUtil.timeFormat;

    dateUtil.datePlaceholder = datePlaceholder || dateUtil.dateFormat;
    dateUtil.timePlaceholder = timePlaceholder || dateUtil.timeFormat;

    dateUtil.timeZone = (timeZone && dateUtil.getTzFromWindows(timeZone)) || dateUtil.timeZone;
}


/** An array of 2 elements: [18-months-ago, end-of-today] */
export const searchableTransactionDateRange = [
    dateUtil.getMerchantNowAsLocal().startOf('day').add(-18,'months').toDate(),  // 18 months ago
    dateUtil.getMerchantNowAsLocal().endOf('day').toDate()  // end of today
];
