import React from "react";
import { ImageFromUrl } from "components/Common";
import { Dialog, Button, LoadingIndicator } from "@premier/ui";
import { useApiCall, APICallRequestState } from "components/Common";
import { paymentRequestApi } from "api";
import { PaymentUrlRequestModelPaymentFlowTypeEnum, HppQueryStringModel } from "@premier/webapi-client";

type QrCodeForPaymentUrlModalProps = {
    downloadFilename : string;
    billerCode: string | undefined;
    paymentFlowType?: PaymentUrlRequestModelPaymentFlowTypeEnum;
    subText : string;
    altText : string;
    title : string;
    show : boolean;
    onClose : React.MouseEventHandler;
}

// imageData must be a base 64 string whose binary type is image/png, expects a prefix eg. data:image/png;base64,<base64string>
// billerCode, paymentType should be passed in as props if the URL needs to reflect these params
const QrCodeForPaymentUrlModal : React.FC<QrCodeForPaymentUrlModalProps> = ({ downloadFilename, billerCode, paymentFlowType, subText, altText, title, show, onClose }) => {
    // Triggers an actual download for the user rather than loading it within the browser
    const downloadQrCode = () => {
        const a = document.createElement("a");
        a.href = imageData ?? "";
        a.download = downloadFilename;
        a.click();
    };

    const [imageData, imageDataStatus] = useApiCall(() => {
        const queryString : HppQueryStringModel[] = [];

        if (billerCode) {
            queryString.push({ hppQueryType: "BillerCode", queryValue: billerCode });
        }

        // @ts-ignore
        return paymentRequestApi.getQrCodeForPaymentPage(paymentFlowType, null, queryString);
    }, [billerCode, paymentFlowType]);

    return <Dialog
        dialogClassName="qr-request-pending-dialog"
        title={title}
        show={show}
        onClose={onClose}
        closeButton
        footerButtons={<>
            <Button primary onClick={downloadQrCode}>Download QR code</Button>
            <Button onClick={onClose}>Cancel</Button>
        </>}>
        <p>
            {subText}
        </p>
        {imageDataStatus === APICallRequestState.SUCCESSFUL ? <ImageFromUrl url={imageData} altText={altText} /> : <LoadingIndicator /> }
    </Dialog>;
};

export default QrCodeForPaymentUrlModal;
