import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import './FormikDropdown.scss';

type ValueType = string | number;

interface Option {
    value : ValueType,
    label : string
}

export type FormikDropdownProps = {
    name: string,
    options: Option[],
    /** A more compact version of the dropdown, reducing the width, but keeping the height */
    compact?: boolean,
    /** The text displayed when no option is selected (Default = Select...) */
    placeholder?: string,
    isSearchable?: boolean,
    isClearable?: boolean,
    disabled?: boolean,
    onChange?: (selectedOption : Option | null) => void,
    className?: string,
    id?: string,
};

const FormikDropdown : React.FC<FormikDropdownProps> = ({
    name, options,  // data
    compact, className,  // style
    disabled,  // UI state
}) => {
    const [ initialized, setInitialized ] = useState<boolean>(false);
    const [ field, meta, helpers ] = useField(name);
    const [ defaultValue ] = useState(options?.filter(o => o.value === field.value)[0] ?? options[0]);
    const [ selectedValue, setSelectedValue ] = useState<Option | null >(defaultValue);
    const { setValue } = helpers;

    // This is to prevent ReactDropdown from attempting to load null default values
    // If options contains Null as value, then can set value to null
    useEffect(() => {
        if (!initialized && setValue && (options.some(option => option.value === null) || defaultValue?.value)) {
            setValue(defaultValue.value)
            setInitialized(true);
        }
    }, []);

    // when field.value changed by Formik, change the displayed value
    useEffect(() => {
        setSelectedValue(options?.find((o) => o.value === field.value) ?? null); 
      }, [field.value, options]);

    return (
        <ReactSelect
            className={classNames('dropdown', className, {
                'compact': compact,
                'has-error': meta.touched && meta.error
            })}
            classNamePrefix='rselect'
            options={options}
            defaultValue={defaultValue}
            isDisabled={disabled}
            onChange={(option : Option | null) => { setSelectedValue(option); setValue(option?.value); }}
            value={selectedValue}
            onBlur={field.onBlur}
        />
    );
};

export default FormikDropdown;
