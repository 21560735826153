import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApiCallDeprecated, APICallRequestState } from 'components/Common';
import { Switch, PaddedContainer, LoadingIndicator } from '@premier/ui';
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';
import { billerApi } from 'api';

enum ComponentState {
    INITIATED,
    UPDATING_SETTING,
    READY
}

interface Props {
    billerCode? : string
}

/**
 * A PaddedContainer that allows the user to enable/disable Apple Pay for one biller code.
 */
const EnableApplePaySection : React.FC<Props> = ({
    billerCode
}) => {
    const authenticatedUser = useSelector((state: any) => state.accounts.users.authenticatedUser);
    const [ settingChangesAllowed ] = useState<boolean>(SecurityUtil.hasAccess([userRoles.internet], authenticatedUser));
    const [ applePayEnabled, setApplePayEnabled ] = useState<boolean>(false);
    const [ componentState, setComponentState ] = useState<ComponentState>(ComponentState.INITIATED);

    const [ applePayWallet, applePayWalletStatus ] = useApiCallDeprecated(() => {
        if (settingChangesAllowed && billerCode) {
            // @ts-ignore
            return billerApi.getApplePayWallet(billerCode);
        }
    }, [billerCode]);

    // Handler to determine if component is ready for use
    useEffect(() => {
        // Safeguard against API failure and misconfiguration of wallet
        if (applePayWalletStatus === APICallRequestState.SUCCESSFUL && typeof applePayWallet?.enabled === 'boolean') {
            setApplePayEnabled(applePayWallet.enabled);
            setComponentState(ComponentState.READY);
        }
    }, [applePayWallet, applePayWalletStatus]);

    // Callback handler when using enable/disable switch
    useApiCallDeprecated(() => {
        if (componentState === ComponentState.UPDATING_SETTING && billerCode) {
            const newValue = !applePayEnabled;
            setApplePayEnabled(newValue);
            setComponentState(ComponentState.READY);
            return billerApi.updateWallets(billerCode, [{ walletType: 'ApplePay', enabled: newValue }]);
        }
    }, [componentState, billerCode]);

    return (
        settingChangesAllowed ? <PaddedContainer title='Accept payment via Apple Pay on payment page?' switchOnRight noDivider lessMargin>
            <p>Once disabled, you won't able to accept payments via Apple Pay from this biller code {billerCode}.</p>
            { componentState !== ComponentState.INITIATED ?
                <Switch on={applePayEnabled} onChange={() => setComponentState(ComponentState.UPDATING_SETTING)} /> : <LoadingIndicator /> }
        </PaddedContainer> : null
    );
};

export default EnableApplePaySection;