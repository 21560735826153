import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import _ from "lodash";

import {
    PaddedContainer,
    PageSection,
    Icon,
    Button,
    Row,
    Link,
    CardLogo,
    LoadingIndicator,
    Divider,
    TooltipTrigger,
    Tooltip,
    DescriptionList,
    IconText
} from "@premier/ui";
import { PageHeader, FormError, useApiCall, APICallRequestState, WalletLogo } from "components/Common";
import { PlatformRoutesConfiguration } from "components/Routing";
import { EditBillerCodeNameDialog, BillerSurchargeTableModal, AcceptedAmountSection, TokenisationSettingsSection, EnableApplePaySection, CustomerReferencesSection, AdditionalPaymentsSection } from "components/Settings";
import { billerApi } from "api";
import * as merchantActions from "components/Settings/_actions/merchantActions";
import * as billerActions from "components/Settings/_actions/billerActions";
import { RootState } from "store/store";
import { SurchargeRule } from "packages/utils/models";
import { Wallet } from "models";
import SecurityUtil, { User } from "@premier/utils/security";
import { userRoles } from "components/Routing";

import "./BillerDetailsPage.scss";

type Props = {
    merchantActions: any;
    billerActions: any; // API actions
    billerCode: string; // url param
    biller: Biller;
    paymentMethodsState: any;
    surchargesState: any;
    updateBiller: any; // Redux States (new way)
    authenticatedUser: User;
}

type Biller = {
    merchantNumber: string;
    billerDescription: string;
    billerCode: string;
    enabled: boolean;
}

/** Payment settings for a single biller code */
const BillerDetailsPage = ({
    merchantActions,
    billerActions, // API actions
    billerCode, // url param
    biller,
    paymentMethodsState,
    surchargesState,
    updateBiller, // Redux States (new way)
    authenticatedUser
}: Props) => {
    const paymentMethods = paymentMethodsState.data;
    const surchargeRules: SurchargeRule[] = surchargesState.data;

    const [showSurchargeDialog, setShowSurchargeDialog] = useState(false);
    const [showEditBillerCodeNameDialog, setShowEditBillerCodeNameDialog] = useState(false);

    useEffect(() => {
        if (billerCode)
            billerActions.getSurchargeRules(billerCode);
    }, [billerCode]);

    const [wallets, walletsStatus] = useApiCall(() => {
        if (billerCode) {
            return billerApi.getWallets(billerCode);
        }
    }, [billerCode]);

    useEffect(() => {
        // when created a new billerCode, if biller is passed in as undefined, reload the page to get
        // the new biller
        if (!biller)
            window.location.reload();
        if (biller)
            merchantActions.getPaymentMethods(biller.merchantNumber);
    }, [biller]);
    //Move function out to settings util? used in 3 places
    function returnCardOptions() {
        return _.uniq(surchargeRules.map((r) => r.cardTypeCode))
            .filter((pm) => pm !== "BA") // Exclude bank account
            .map((pm, idx) => <CardLogo cardTypeCode={pm} key={idx} />);
    }

    function returnWalletOptions() {
        const enabledWallets: Wallet[] | undefined = wallets?.wallets?.filter((w: Wallet) => w.enabled === true);
        if (!enabledWallets?.length) {
            return <span>No active wallets</span>;
        }

        return enabledWallets// hide disabled wallets
            .map((w) => <WalletLogo key={w.walletType} wallet={{ key: w.walletType }} className={"biller-wallet-logo"} />);
    }

    function getBillerDetails() {
        return [
            { name: "Biller code name", value: biller?.billerDescription },
            { name: "Biller code", value: billerCode },
            { name: "Biller code status", value: biller?.enabled ? <IconText tick>Active</IconText> : <IconText info>Inactive</IconText> },
        ];
    }

    return (
        <>
            {paymentMethodsState.isLoading || surchargesState.isLoading ? <LoadingIndicator /> : <>
                <PageSection noDivider className="biller-details">
                    <PageHeader backButton title="Payment settings" subtitle={<DescriptionList greyLabel noBreakLine items={getBillerDetails()} />}>
                        <Button onClick={() => {setShowEditBillerCodeNameDialog(true);}}>Edit biller code</Button>
                    </PageHeader>

                    <FormError apiErrors={paymentMethodsState.errors} />
                    <FormError apiErrors={surchargesState.errors} />
                    <FormError apiErrors={updateBiller.errors} />

                    {paymentMethodsState.hasSucceeded && surchargesState.hasSucceeded && (
                        <>
                            <PaddedContainer
                                lessMargin
                                noDivider
                                icon={<Icon card />}
                                title="Accepted cards and surcharge"
                                titleSuffix={<TooltipTrigger tipId="tip-cards">What&apos;s this?</TooltipTrigger>}
                                button={
                                    <>
                                        <Link button to={PlatformRoutesConfiguration.settingsRoute?.acceptedCards.generatePath(billerCode)}>
                                            Edit
                                        </Link>
                                    </>
                                }
                            >
                                {(paymentMethodsState.isLoading || surchargesState.isLoading) && <LoadingIndicator />}
                                {paymentMethodsState.hasSucceeded && surchargesState.hasSucceeded && (
                                    <Row divided>
                                        <div className="col-lg-4">
                                            <h3>Accepted cards</h3>
                                            {returnCardOptions()}
                                        </div>
                                        <div className="col-lg-4">
                                            <Divider className="d-lg-none" />
                                            <h3>Accepted wallets</h3>
                                            {walletsStatus === APICallRequestState.SUCCESSFUL ? returnWalletOptions() : <LoadingIndicator />}
                                        </div>
                                        <div className="col-lg-4">
                                            <Divider className="d-lg-none" />
                                            <h3>Surcharge</h3>
                                            <Button
                                                subtle
                                                onClick={() => setShowSurchargeDialog(true)}
                                                disabled={!surchargesState.hasSucceeded}
                                            >
                                                View Example
                                            </Button>
                                        </div>
                                    </Row>
                                )}
                            </PaddedContainer>
                            <EnableApplePaySection billerCode={billerCode} />
                            <AcceptedAmountSection billerCode={billerCode} />

                            {/*
                        Move References to it's own component when implementing .
                        To be re-enabled when implementing the following sections.
                    */}

                            {/*
                    <PaddedContainer
                        lessMargin
                        noDivider
                        icon={<Icon card />}
                        title='References'
                        titleSuffix={<TooltipTrigger tipId='tip-reference'>What's this?</TooltipTrigger>}
                    >
                        <p>Use up to 3 references to identify payments</p>
                        <PaddedContainer
                            greyBorder
                            lessMargin
                            noDivider
                            title='Reference 1'
                            button={<>
                                <Button onClick={() => window.alert('Reference editing not implemented yet')}>
                                    Edit
                                </Button>
                            </>}
                        >
                            <p>Reference 1 description</p>
                        </PaddedContainer>

                        <PaddedContainer blueBorder lessMargin>
                            Add Reference
                        </PaddedContainer>
                    </PaddedContainer>

                    */}

                            <TokenisationSettingsSection billerCode={billerCode} />
                            <CustomerReferencesSection billerCode={billerCode} />
                            {SecurityUtil.hasAccess([userRoles.additionalPayments], authenticatedUser) && <AdditionalPaymentsSection billerCode={billerCode} />}

                            <EditBillerCodeNameDialog
                                billerCode={billerCode}
                                currentBillerCodeName={biller?.billerDescription}
                                currentEnableBillerCode={biller?.enabled}
                                show={showEditBillerCodeNameDialog}
                                onClose={() => setShowEditBillerCodeNameDialog(false)}
                            />

                            <BillerSurchargeTableModal
                                paymentMethods={paymentMethods}
                                surchargeRules={surchargeRules}
                                show={showSurchargeDialog}
                                onClose={() => setShowSurchargeDialog(false)}
                            />
                        </>
                    )}
                </PageSection>

                {/* to be re-enabled when implementing references */}
                {/* <Tooltip id='tip-reference'>
                <p>
                    Reference fields can be used to identify the customer who makes the payment. This helps to make the reconciliation process easier.
                </p>
                <p>
                    The labels of the reference fields can be
                    configured in Settings, where you can select labels such as Customer Number, Account Number, Invoice
                    Number and more.
                </p>
            </Tooltip> */}

                <Tooltip id="tip-cards">
                    <p>
                        Setup the card types you want to accept through the different channels. For each card type, you can
                        also set a surcharge which allows you to recover the cost of your card payments.
                    </p>
                    <p>Set up surcharge by card scheme, domestic or international cards and debit or credit cards</p>
                </Tooltip>
            </>}
        </>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    const billerCode = ownProps.match.params.billerCode;
    const biller =
        state.accounts.users.billers && state.accounts.users.billers.find((biller: Biller) => biller.billerCode === billerCode);

    return {
        billerCode,
        biller,

        // new way
        paymentMethodsState: state.settings.paymentSettings.merchantPaymentMethods,
        surchargesState: state.settings.paymentSettings.surcharges,
        updateBiller: state.settings.paymentSettings.updateBiller,
        authenticatedUser: state.accounts.users.authenticatedUser,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        merchantActions: bindActionCreators(merchantActions, dispatch),
        billerActions: bindActionCreators(billerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillerDetailsPage);
