// This file represents the routes for a given platform (eg. BPOINT, MerchantSuite, StationaryShop)
// This will automatically detect what platform is being used and provide the routes as required

// Do not provide more than what is needed for a Platform because this could lead to security leaks
// For example, you shouldn't be allowed to access a BPOINT route if you are using the StationaryShop.

// DO NOT IMPORT ROUTES FROM routing/routes/base,  >>>> always import routes from this file <<<<
import { accountRoute as accountBase }  from './routes/base/accountRoutes';
import { default as accountStationery } from './routes/stationery_shop/accountRoutes';

import { dataVaultRoute as baseDataVaultRoute, customerRoute as baseCustomerRoute, tokenRoute as baseTokenRoute} from './routes/base/dataVaultRoutes';
import { feedbackRoute as baseFeedbackRoute } from './routes/base/feedbackRoutes';
import { paymentRequestRoute as basePaymentRequestRoute, batchPaymentRequestRoute as baseBatchPaymentRequestRoute } from './routes/base/paymentRequestRoutes';
import { reportRoute as baseReportRoute } from './routes/base/reportRoutes';
import { settingsRoute as baseSettingsRoute, messagingRoute as baseMessagingRoute, businessDetailsSettingsRoute as baseBusinessDetailsSettingsRoute, fraudSettingsRoute as baseFraudSettingsRoute, iServicesSettingsRoute as baseiServicesSettingsRoute } from './routes/base/settingsRoutes';
import { designerRoute as baseDesignerRoute } from './routes/base/designerRoutes';
import { usersRoute as baseUsersRoute } from './routes/base/usersRoute';
import { paymentPlanSettingsRoute as basePaymentPlanSettingsRoute } from './routes/base/paymentPlanSettingsRoutes';

import { stationeryRoute as baseStationeryRoute } from './routes/stationery_shop/stationeryShopRoutes';
import { transactionRoute as baseTransactionRoute } from './routes/base/transactionRoutes';
import { invoicingRoute as baseInvoicingRoute, invoiceSettingsRoute as baseInvoiceSettingsRoute } from './routes/base/invoicingRoutes'
import { getPlatform, Platform } from 'platforms/current/util';
import { RouteConfigurationMap } from './RouteConfigurationTypes';

// These represent possible routes that can be exported (that can be consumed by the entire platform eg. some pages may require redirects to different
// features like going )
// If the route is null, that means that route is invalid for that platform and throw an error if a page from the platform imports and attempts to use it.
// You can also use this to have specific routing for a specific platform (eg. see accountRoute)
let accountRoute : RouteConfigurationMap | null = accountBase;
let dataVaultRoute : RouteConfigurationMap | null = baseDataVaultRoute;
let customerRoute : RouteConfigurationMap | null = baseCustomerRoute;
let tokenRoute : RouteConfigurationMap | null = baseTokenRoute;
let feedbackRoute : RouteConfigurationMap | null = baseFeedbackRoute;
let paymentRequestRoute : RouteConfigurationMap | null = basePaymentRequestRoute;
let batchPaymentRequestRoute : RouteConfigurationMap | null = baseBatchPaymentRequestRoute;
let reportRoute : RouteConfigurationMap | null = baseReportRoute;
let settingsRoute : RouteConfigurationMap | null = baseSettingsRoute;
let usersRoute : RouteConfigurationMap | null = baseUsersRoute;
let businessDetailsSettingsRoute : RouteConfigurationMap | null = baseBusinessDetailsSettingsRoute;
let fraudSettingsRoute : RouteConfigurationMap | null = baseFraudSettingsRoute;
let invoicingRoute : RouteConfigurationMap | null = baseInvoicingRoute;
let invoiceSettingsRoute : RouteConfigurationMap | null = baseInvoiceSettingsRoute;
let iServicesSettingsRoute : RouteConfigurationMap | null = baseiServicesSettingsRoute;
let messagingRoute : RouteConfigurationMap | null = baseMessagingRoute;
let transactionRoute : RouteConfigurationMap | null = baseTransactionRoute;
let designerRoute : RouteConfigurationMap | null = baseDesignerRoute;
let paymentPlanSettingsRoute : RouteConfigurationMap | null = basePaymentPlanSettingsRoute;
let stationeryRoute : RouteConfigurationMap | null = null;

const currentPlatform : Platform = getPlatform();

// Modify routes as required based on the platform selected in the config
switch (currentPlatform) {
    case "cba":
    case "merchantsuite":
        break;
    case "stationery_shop":
        accountRoute = accountStationery;
        stationeryRoute = baseStationeryRoute;
        dataVaultRoute = null;
        customerRoute = null;
        tokenRoute = null;
        feedbackRoute = null;
        paymentRequestRoute = null;
        batchPaymentRequestRoute = null;
        reportRoute = null;
        settingsRoute = null;
        usersRoute = null;
        businessDetailsSettingsRoute = null;
        fraudSettingsRoute = null;
        invoicingRoute = null;
        invoiceSettingsRoute = null;
        iServicesSettingsRoute = null;
        messagingRoute = null;
        transactionRoute = null;
        designerRoute = null;
        paymentPlanSettingsRoute = null;
        break;
    default:
        throw new Error("Unable to deduce selected platform.");
}


// Export all the above
export default {
    accountRoute,
    dataVaultRoute, customerRoute, tokenRoute,
    feedbackRoute,
    paymentRequestRoute, batchPaymentRequestRoute,
    reportRoute,
    settingsRoute, usersRoute,
    businessDetailsSettingsRoute,
    fraudSettingsRoute,
    invoicingRoute,
    invoiceSettingsRoute,
    iServicesSettingsRoute,
    messagingRoute,
    stationeryRoute,
    transactionRoute,
    designerRoute,
    paymentPlanSettingsRoute
};
