import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import deprecated from '@premier/utils/deprecatedPropType';
import _ from 'lodash';
import { Input } from '@premier/ui';
import FormGroup from './FormGroup';
import withField from './withField';

/** Note similar component: EmailAddressField (an InputField with email address validation). */
const InputField = props => {
    var {
        name,
        suffix,
        type, // Input specific props
        formProps, groupProps, ...otherProps
    } = props;

    const [id] = useState(_.uniqueId(`${name}_`));
    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        // when input type is text, set cursor position after value is seted to avoid
        // cursor jumping to the end of input
        if (formProps.value !== '' && (type === 'text' || type === undefined)) {
            const input = ref.current;
            if (input) {
                input.setSelectionRange(cursor, cursor);
            }
        }
    }, [formProps.value]);

    function handleChange(e) {
        formProps.setValue(e.target.value);
        setCursor(e.target.selectionStart);
    }

    if (suffix) {
        return (
            <FormGroup fieldId={id} {...groupProps}>
                <InputGroup>
                    <Input
                        ref={ref}
                        name={name}
                        id={id}
                        type={type || 'text'}
                        onChange={handleChange}
                        value={formProps.value || ''}
                        error={formProps.error}
                        aria-labelledby={groupProps.label && `${id}_label`}
                        aria-required={groupProps.mandatory}
                        aria-invalid={!!formProps.error}
                        aria-describedby={formProps.error && `${id}_error`}
                        {...otherProps}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text>
                            <strong>{suffix}</strong>
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </FormGroup>
        );
    }
    else {
        return (
            <FormGroup fieldId={id} {...groupProps}>
                <Input
                    ref={ref}
                    name={name}
                    id={id}
                    type={type || 'text'}
                    onChange={handleChange}
                    value={formProps.value || ''}
                    error={formProps.error}
                    aria-labelledby={groupProps.label && `${id}_label`}
                    aria-required={groupProps.mandatory}
                    aria-invalid={!!formProps.error}
                    aria-describedby={formProps.error && `${id}_error`}
                    {...otherProps}
                />
            </FormGroup>
        );
    }
};

InputField.propTypes = {
    type: PropTypes.string,
    /** Only allow digits in the textbox */
    digitsOnly: PropTypes.bool,
    /** Only allow non-negative number/decimal in the textbox */
    decimal: PropTypes.bool,
    pattern: PropTypes.string,
    /** To limit the characters that can be entered. eg. key => /[0-9]/.test(key) */
    filterKey: PropTypes.func,

    /** Style the textbox accordingly when true (eg. red border) */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    suffix: PropTypes.string,

    //Events
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onMaxLengthReached: PropTypes.func,
    /** Fires when Enter key is pressed. eg. (e) => { alert(e.currentTarget.value); } */
    onEnter: PropTypes.func,

    // Deprecated
    mandatory: deprecated(PropTypes.bool, 'Please use validate().required() in the Form initialValidation'),
};

export default withField(InputField);
