import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import _ from "lodash";

// @ts-ignore
import errorMaps from "constants/errorMaps";

//import { cardNetwork } from '@premier/cards';  // to be reenabled later

import { getSurchargeableRules } from "@premier/utils/settings";
import { Form, CheckboxGroupField, SubmitButton, FormErrorList } from "@premier/form";
import { PageSection, PaddedContainer, CardLogo, Divider, Tooltip, CardContainer, LoadingIndicator, ButtonContainer, Link, IconText, SuccessModal, Dialog, Icon, Button } from "@premier/ui";
import { PageHeader, WalletLogo, FormError } from "components/Common";
import { BillerSurchargeForm } from "components/Settings";
import SecurityUtil from '@premier/utils/security';
import { userRoles } from 'components/Routing';

import { PlatformRoutesConfiguration } from "components/Routing";
import * as merchantActions from "components/Settings/_actions/merchantActions";
import * as billerActions from "components/Settings/_actions/billerActions";
import "./AcceptedCardsPage.scss";
import { RootState } from "store/store";
import { Biller } from "models/Biller";
import { PaymentMethod } from "packages/utils/models";
import { SurchargeRule } from "models/SurchargeRule";
import { FieldError } from "api/mapErrors";

type Props = {
    merchantActions: any;
    billerActions: any;
    billers: Biller[];
    biller: Biller;
    isLoading: boolean;
    paymentMethodsState: any;
    walletsState: any;
    surchargesState: any;
    walletsSubmission: any;
    surchargesSubmission: any;
    authenticatedUser:any
}

/**
 * Accepted Cards (hidden if the Merchant only has 1 biller) and Surcharge rules for a Biller.
 * Accessed from: Settings > PaymentSettings [> Biller] > Edit Surcharge
 */
const AcceptedCardsPage = ({
    merchantActions, billerActions,
    billers, biller, isLoading, authenticatedUser,  // Redux states (old way)
    paymentMethodsState, walletsState, surchargesState, walletsSubmission, surchargesSubmission,  // Redux states (new way)
}: Props) => {

    const paymentMethods = paymentMethodsState.data;
    const billerWallets = walletsState.data;
    const surchargeRules = surchargesState.data;
    const showAcceptedCards = billers.length > 1;

    const [redirectBack, setRedirectBack] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        // Clear previous submission states if any
        billerActions.clearUpdateBillerWallets();
        billerActions.clearUpdateSurchargeRules();

        // Get what we need for this page from the API
        merchantActions.getPaymentMethods(biller.merchantNumber);
        billerActions.getBillerWallets(biller.billerCode);
        billerActions.getSurchargeRules(biller.billerCode);
    }, []);

    const apiErrors = useMemo(() => {
        //map field returned from API, to the naming of the form field.
        const surchargeErrors = surchargesSubmission.errors && surchargesSubmission.errors.map((x: FieldError) => ({
            ...x,
            field: x.field?.replace("surcharges", "surchargeSettings.rules"),
        }));

        const walletErrors = walletsSubmission.errors; //api returns same structure as used in form

        return _.filter(_.concat(surchargeErrors, walletErrors)); //merges, and filters out undefined array values
    }, [surchargesSubmission.errors, walletsSubmission.errors]);

    function getAcceptedCardsOptions() {
        return _.sortBy(paymentMethods || [], "optional")
            .filter(pm => pm.cardTypeCode !== "BA")  // Exclude bank account
            .map(pm => ({
                value: pm.cardTypeCode,
                label: <CardLogo cardTypeCode={pm.cardTypeCode} />,
                disabled: pm.optional === false,
            }));
    }

    function getInitiallySelectedCards() {
        const allowedCardCodes = paymentMethods.map((pm: PaymentMethod) => pm.cardTypeCode);

        return _.uniq(_.concat(
            paymentMethods.filter((pm: PaymentMethod) => pm.cardTypeCode !== "BA" && !pm.optional).map((pm: PaymentMethod) => pm.cardTypeCode),  // non-optional cards
            surchargeRules.map((r: SurchargeRule) => r.cardTypeCode).filter((c: string) => allowedCardCodes.includes(c)),  // merchant-enabled cards from the rules
        ));
    }

    function handleSubmit(values: any) {
        saveWallets(values.wallets);
        saveSurcharges(values.surchargeSettings.rules, values.cards);
    }

    function saveWallets(selectedWallets: any[]) {
        const wallets = billerWallets.map((w: any) => ({
            walletType: w.walletType,
            enabled: selectedWallets.includes(w.walletType),
        }));
        return billerActions.updateBillerWallets(biller.billerCode, wallets);
    }

    function saveSurcharges(rules: any[], selectedCards: any[]) {
        let rulesDto = _.cloneDeep(rules);
        const surchargeableCardCodes = paymentMethods.filter((pm: PaymentMethod) => pm.allowSurcharge).map((pm: PaymentMethod) => pm.cardTypeCode);

        if (rules[0].cardTypeCode === "UNSPECIFIED") {
            rulesDto = [];

            selectedCards.filter(c => surchargeableCardCodes.includes(c)).forEach((cardTypeCode: string) => {
                rules.forEach(r => {
                    rulesDto.push({ ...r, cardTypeCode });
                });
            });
        }

        const nonSurchargeableCardCodes = selectedCards.filter(c => !rulesDto.map(r => r.cardTypeCode).includes(c));
        nonSurchargeableCardCodes.forEach((cardTypeCode: string) => {
            rulesDto.push({ cardTypeCode });
        });

        return billerActions.updateSurchargeRules(biller.billerCode, rulesDto);
    }

    function handleSuccessModalClose() {
        billerActions.clearUpdateBillerWallets();
        billerActions.clearUpdateSurchargeRules();

        setRedirectBack(true);
    }


    if (redirectBack) {
        return <Navigate to={PlatformRoutesConfiguration.settingsRoute?.billerDetails.generatePath(biller.billerCode)!} />;
    }

    return (
        <div className="accepted-cards-page">
            <PageSection>
                <PageHeader
                    backButton
                    title={showAcceptedCards ? "Accepted cards and surcharge" : "Surcharge"}
                    subtitle={`The following setting apply to all payments (excluding Payment Plans and Pay Later) processed via biller code ${biller.billerCodeWithName}.`}
                />

                {(isLoading || paymentMethodsState.isLoading || walletsState.isLoading || surchargesState.isLoading) && (
                    <CardContainer header="Loading payment settings">
                        <LoadingIndicator />
                    </CardContainer>
                )}

                <FormError apiErrors={paymentMethodsState.errors} />
                <FormError apiErrors={walletsState.errors} />
                <FormError apiErrors={surchargesState.errors} />

                {paymentMethodsState.hasSucceeded && walletsState.hasSucceeded && surchargesState.hasSucceeded && (
                    <Form
                        initialValues={{
                            cards: getInitiallySelectedCards(),
                            wallets: billerWallets.filter((w: any) => w.enabled).map((w: any) => w.walletType),
                        }}
                        errorMaps={errorMaps}
                        errors={apiErrors}
                        onSubmit={handleSubmit}
                        render={context => (<>
                            <PaddedContainer>

                                {showAcceptedCards && (<>
                                    <CheckboxGroupField inlineUpSm
                                        name="cards"
                                        label="Accepted cards"
                                        options={getAcceptedCardsOptions()}
                                    />

                                    {/* Temporarily hidden, to be reenabled later:
                                    <Row>
                                        {_.get(context, 'values.cards', []).includes(cardNetwork.amex.serverCode) && (
                                            <div className='col-lg-6'>
                                                <InputField name='amexMerchantNumber' label='AMEX merchant number' />
                                            </div>
                                        )}
                                        {_.get(context, 'values.cards', []).includes(cardNetwork.diners.serverCode) && (
                                            <div className='col-lg-6'>
                                                <InputField name='dinersMerchantNumber' label='Diners Club merchant number' />
                                            </div>
                                        )}
                                    </Row>
                                    */}

                                    <CheckboxGroupField inlineUpSm
                                        name="wallets"
                                        label="Accepted wallets"
                                        options={(billerWallets || []).map((w: any) => ({
                                            value: w.walletType,
                                            label: <WalletLogo wallet={{ key: w.walletType }} />
                                        }))}
                                    />
                                    <Divider />
                                </>)}

                                <BillerSurchargeForm
                                    paymentMethods={paymentMethods}
                                    initialRules={getSurchargeableRules(surchargeRules, paymentMethods)}
                                    cards={context.getValue("cards")}
                                    lcrEnabled={SecurityUtil.hasAccess([userRoles.LeastCostRouting], authenticatedUser)}
                                />

                                {/* Temporarily hidden, to be reenabled later:
                                <ScheduleField name='applyDate' label='Apply date' nowText='Apply the changes immediately' required />
                                */}

                            </PaddedContainer>

                            {walletsSubmission.hasSucceeded && (<IconText tick>Wallets saved</IconText>)}
                            {surchargesSubmission.hasSucceeded && (<IconText tick>Cards and surcharge rules saved</IconText>)}
                            <FormErrorList />

                            <ButtonContainer>
                                <Button primary disabled={surchargesSubmission.isLoading} onClick={() => setShowConfirmationModal(true)}>Save</Button>
                                <Link button to={PlatformRoutesConfiguration.settingsRoute?.paymentSettings.generatePath()} disabled={surchargesSubmission.isLoading}>Cancel</Link>
                            </ButtonContainer>

                            <Dialog show={showConfirmationModal && !(walletsSubmission.hasSucceeded && surchargesSubmission.hasSucceeded)}
                                title="Change in surcharges"
                                icon={<Icon info />}
                                footerButtons={<>
                                    <SubmitButton loading={isLoading}>Confirm change</SubmitButton>
                                    <Button onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
                                </>}
                            >
                                <p>The change proposed in surcharges could apply to your existing subscriptions and payment plans if confirmed.</p>
                            </Dialog>
                        </>)}
                    />
                )}
            </PageSection>
            <Tooltip />
            <SuccessModal show={walletsSubmission.hasSucceeded && surchargesSubmission.hasSucceeded} onClose={handleSuccessModalClose}>
                Accepted payment methods and surcharge applied successfully!
            </SuccessModal>
        </div>
    );
};

function mapStateToProps(state: RootState, ownProps: any) {
    const billerCode = ownProps.match.params.billerCode;

    return {
        // old way
        billers: state.accounts.users.billers,
        biller: _.find(state.accounts.users.billers, { billerCode: billerCode }),
        isLoading: state.accounts.users.isLoading,
        authenticatedUser: state.accounts.users.authenticatedUser,

        // new way
        paymentMethodsState: state.settings.paymentSettings.merchantPaymentMethods,
        walletsState: state.settings.paymentSettings.billerWallets,
        walletsSubmission: state.settings.paymentSettings.billerWalletsSubmission,
        surchargesState: state.settings.paymentSettings.surcharges,
        surchargesSubmission: state.settings.paymentSettings.surchargesSubmission,
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        merchantActions: bindActionCreators(merchantActions, dispatch),
        billerActions: bindActionCreators(billerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptedCardsPage);
