import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './PageSection.scss';


type Props = {
    noDivider?: boolean,
    noPaddingBottom?: boolean,
    /** Disables the max width that otherwise applies on XL. */
    fullWidth?: boolean,
    className?: string,
    /** Will be added as a H2 */
    header?: ReactNode,
    children?: ReactNode,
};

/**
 * Every page needs to be wrapped in one or more PageSection(s).
 * PageSection has a max width that applies on XL, which can be disabled using the fullWidth prop.
 * Unless it is the last one on the page, a PageSection renders a divider at the bottom (which can be disabled).
 */
const PageSection = ({
    noDivider, noPaddingBottom, fullWidth, className, header, children,
}: Props) => {

    const cssClasses = classNames('page-section', {
        'no-divider': noDivider,
        'no-padding-bottom': noPaddingBottom,
        'full-width': fullWidth
    }, className);

    return (
        <div className={cssClasses}>
            {header && (<h2>{header}</h2>)}
            {children}
        </div>
    );
}

export default PageSection;