import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from '@premier/ui';

const TransactionStatusIcon = (props) => {
    const code = _.get(props, 'transaction.paymentProcessedTxnModel.responseCode') || props.transaction.responseCode;
    
    if (code === '0')
        return <Icon {...props} tick title='Success' />;
    
    return <Icon {...props} alert title='Failed' />;
}

TransactionStatusIcon.propTypes = {
    transaction: PropTypes.shape({
        paymentProcessedTxnModel: PropTypes.shape({
            responseCode: PropTypes.string
        })
    }),
    responseCode: PropTypes.string
};

export default TransactionStatusIcon;