import React, { ReactNode } from 'react';
import { NameValue } from 'models';

type Props<TValue extends string | number | ReactNode> = {
    item: NameValue<TValue>,
    /** Wrap each dt/dd in a div */
    wrapLine?: boolean,
    /** The className of the dt */
    className?: string,
};

/** Renders a dt and a dd, optionally wrapping them in a div */
const DescriptionListItem = <TValue extends string | number | ReactNode>({
    item, wrapLine, className
}: Props<TValue>) => {
    var line = (<>
        <dt className={className}>{item.name}</dt>
        <dd>{item.value}</dd>
    </>);

    return wrapLine
        ? <div>{line}</div>
        : line;
}

export default DescriptionListItem;
